import styled from 'styled-components';
import { ReturnIcon } from '../../icons/ReturnIcon';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { useLocalizedString } from '../../../services/localization';
import { useNavigate } from 'react-router-dom';
import { Body3 } from '../../../styles/FontStyles';

const Button = styled.button.attrs({ type: 'button' })`
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  margin-right: auto;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25rem;
  
  ${Body3};
  font-weight: 600;
  text-transform: uppercase;
  
  &:disabled {
    opacity: .5;
    pointer-events: none;
  }
`;

const BackIcon = styled(ReturnIcon)`
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-1px);
`;

export const BackButton: FC<PropsWithChildren<HTMLAttributes<HTMLButtonElement>>> = (props) => {
  const {
    children,
    ...buttonProps
  } = props;

  const backButtonTitle = useLocalizedString('app.v2.general.back');
  const navigate = useNavigate();

  return (
    <Button {...buttonProps} onClick={(e) => {
      if (buttonProps.onClick) {
        buttonProps.onClick(e);
      } else {
        navigate(-1);
      }
    }}>
      <BackIcon />
      { children || backButtonTitle }
    </Button>
  );
}
