import styled from 'styled-components';
import { ErrorNotification } from '../registration/SignUp';
import { Input } from '../../common/forms/Input';
import { FieldValues, useForm } from 'react-hook-form';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCountries, useGetLocalizedString } from '../../../services/localization';
import { BackButton } from '../../common/general/BackButton';
import { Body2, Body2Bold, Body3 } from '../../../styles/FontStyles';
import { BREAKPOINT, BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import FormWrapper from '../../common/forms/FormWrapper';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { SecondaryButton } from '../../../styles/Buttons';
import { usePurchaseState } from '../../../state/purchase';
import { useProfileAPI } from '../../../services/profile';
import { Dropdown } from '../../common/forms/Dropdown';
import { PageAppear } from '../../../styles/PageTransitions';
import { isEmail } from '../../../utils/validators';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  // page transition
  ${PageAppear};
`;

const BackBtn = styled(BackButton)`
  align-self: flex-start;
  margin: 1.25rem 0 1.5rem;

  ${BREAKPOINT_LG} {
    margin: 0 0 1.5rem;
  }
`;

const DescriptionTitle = styled.h2`
  ${Body2Bold};
  text-transform: none;
  margin-bottom: 0.5rem;

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const DescriptionText = styled.p`
  ${Body2};
  margin-bottom: 5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1rem;
  }

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const RestrictionText = styled.p`
  color: var(--color-primary);

  ${Body2};
  margin-bottom: 1.5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3rem;
  }

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const AddressFormWrapper = styled(FormWrapper)`
  display: grid;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  grid-template-rows: min-content;
  align-content: flex-start;
  justify-content: stretch;
  grid-row-gap: 1rem;
  grid-column-gap: 0.75rem;

  ${BREAKPOINT(1440)} {
    --columns: 2;
  }
`;

const ContinueButton = styled(SecondaryButton)`
  grid-column: 1 / -1;
  justify-self: center;
  margin-top: 2.5rem;

  ${BREAKPOINT(1440)} {
    margin-top: 7.25rem;
  }
`;

const StyledErrorNotification = styled(ErrorNotification).attrs({ autoScroll: true })`
  margin-bottom: 2.5rem;
`;

interface IShippingAddressProps {
  onBackButtonClick?: () => void;
  onSubmit: () => void;
  //Damiano Giampaoli 05/06/2023 - Adding this field also because we don't do email verification, it wouldn't be needed otherwise
  collectEmail?: boolean;
}

export const ShippingAddress: FC<IShippingAddressProps> = (props) => {
  const { onBackButtonClick, onSubmit, collectEmail = false } = props;

  const getLocalizedString = useGetLocalizedString();
  const countries = useCountries();

  const [purchaseState, setPurchaseState] = usePurchaseState();
  const profileAPI = useProfileAPI();
  const form = useForm({ mode: 'onChange', defaultValues: { ...(purchaseState?.shippingAddress || {}), country: 'de' } as any }); // preselect DE as country
  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.reset(purchaseState?.shippingAddress);
  }, [purchaseState]);

  const isValid = useMemo(() => {
    return form.formState.isValid;
  }, [form.formState.isValid]);

  const handleSubmit = async (e: FieldValues) => {
    // update purchase state
    setPurchaseState((state) => ({
      ...(state as any),
      shippingAddress: { ...(e as any) },
    }));

    // update profile data
    setLoading(true);
    setErrorState(null);
    const formData = new FormData();
    Object.entries(e).map(([key, value]) => {
      formData.append(key, value);
    });
    profileAPI
      .patchProfile(formData)
      .then((res) => {
        setLoading(false);
        onSubmit?.();
      })
      .catch((err) => {
        setLoading(false);
        console.error('PROFILE UPDATE', err?.response?.status, err?.response?.data);

        // show error message
        setErrorState({
          headline: getLocalizedString('app.v2.checkout.shipping-address.error-saving-address.headline'),
          text: getLocalizedString('app.v2.checkout.shipping-address.error-saving-address.text'),
        });
      });
  };

  const SHIPPING_ALLOW_LIST = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
  ];

  const countryWhitelist = countries
    .filter(({ alpha2 }) => SHIPPING_ALLOW_LIST.includes(alpha2.toUpperCase()))
    .map(({ alpha2, name }) => ({ value: alpha2, name }));

  return (
    <Wrapper>
      <BackBtn onClick={onBackButtonClick} />
      {!collectEmail && (
        <>
          <DescriptionTitle>{getLocalizedString('app.v2.checkout.shipping-address.description-title')}</DescriptionTitle>
          <DescriptionText>{getLocalizedString('app.v2.checkout.shipping-address.description-text')}</DescriptionText>
          <RestrictionText>{getLocalizedString('app.v2.checkout.shipping-address.restriction-text')}</RestrictionText>
        </>
      )}

      {collectEmail && (
        <>
          <DescriptionTitle>{getLocalizedString('app.v2.claimabletokens.address.headline')}</DescriptionTitle>
          <DescriptionText>{getLocalizedString('app.v2.claimabletokens.address.description')}</DescriptionText>
          <RestrictionText>{getLocalizedString('app.v2.checkout.shipping-address.restriction-text')}</RestrictionText>
        </>
      )}

      {errorState && <StyledErrorNotification {...errorState} onClose={() => setErrorState(null)} />}
      <AddressFormWrapper onSubmit={handleSubmit} form={form}>
        <Input
          name={'firstName'}
          label={getLocalizedString('app.v2.checkout.shipping-address.label.firstname')}
          placeholder={getLocalizedString('app.v2.checkout.shipping-address.placeholder.firstname')}
          options={{ required: true }}
          error={{
            required: getLocalizedString('app.v2.checkout.shipping-address.validation.firstname.required'),
            validate: getLocalizedString('app.v2.checkout.shipping-address.validation.firstname.invalid'),
          }}
        />
        <Input
          name={'lastName'}
          label={getLocalizedString('app.v2.checkout.shipping-address.label.lastname')}
          placeholder={getLocalizedString('app.v2.checkout.shipping-address.placeholder.lastname')}
          options={{ required: true }}
          error={{
            required: getLocalizedString('app.v2.checkout.shipping-address.validation.lastname.required'),
            validate: getLocalizedString('app.v2.checkout.shipping-address.validation.lastname.invalid'),
          }}
        />
        <Input
          name={'address1'}
          label={getLocalizedString('app.v2.checkout.shipping-address.label.address')}
          placeholder={getLocalizedString('app.v2.checkout.shipping-address.placeholder.address')}
          options={{ required: true }}
          error={{
            required: getLocalizedString('app.v2.checkout.shipping-address.validation.address.required'),
            validate: getLocalizedString('app.v2.checkout.shipping-address.validation.address.invalid'),
          }}
        />
        <Input
          name={'postcode'}
          label={getLocalizedString('app.v2.checkout.shipping-address.label.zip')}
          placeholder={getLocalizedString('app.v2.checkout.shipping-address.placeholder.zip')}
          options={{ required: true }}
          error={{
            required: getLocalizedString('app.v2.checkout.shipping-address.validation.zip.required'),
            validate: getLocalizedString('app.v2.checkout.shipping-address.validation.zip.invalid'),
          }}
        />
        <Input
          name={'city'}
          label={getLocalizedString('app.v2.checkout.shipping-address.label.city')}
          placeholder={getLocalizedString('app.v2.checkout.shipping-address.placeholder.city')}
          options={{ required: true }}
          error={{
            required: getLocalizedString('app.v2.checkout.shipping-address.validation.city.required'),
            validate: getLocalizedString('app.v2.checkout.shipping-address.validation.city.invalid'),
          }}
        />
        <Dropdown
          name={'country'}
          defaultValue={'Deutschland'}
          label={getLocalizedString('app.v2.checkout.shipping-address.label.country')}
          placeholder={getLocalizedString('app.v2.checkout.shipping-address.placeholder.country')}
          options={{ required: true }}
          error={{
            required: getLocalizedString('app.v2.checkout.shipping-address.validation.country.required'),
            validate: getLocalizedString('app.v2.checkout.shipping-address.validation.country.invalid'),
          }}
          // DE only until further
          items={countryWhitelist}
        />
        {collectEmail && (
          <Input
            name={'email'}
            label={getLocalizedString('app.v2.claimabletokens.address.confirmemail')}
            placeholder={getLocalizedString('app.v2.registration.placeholder.email')}
            type={'email'}
            autoComplete={'username'}
            options={{ required: true, validate: isEmail }}
            error={{
              required: getLocalizedString('app.v2.registration.validation.email.required'),
              validate: getLocalizedString('app.v2.registration.validation.email.invalid'),
            }}
          />
        )}

        <ContinueButton disabled={!isValid || loading}>
          {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.general.continue')}
        </ContinueButton>
      </AddressFormWrapper>
    </Wrapper>
  );
};
