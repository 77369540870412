import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const AspectRatioWrapper = styled.div`
  --fallback-ratio: 16 / 9;
  overflow: hidden;

  > * {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  @supports (aspect-ratio: 1 / 1) {
    aspect-ratio: calc(var(--aspect-ratio, var(--fallback-ratio)));
  }

  @supports not (aspect-ratio: 1 / 1) {
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: calc(100% * (1 / (var(--aspect-ratio, var(--fallback-ratio)) * 1)));
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

interface IAspectRatioWrapprProps {
  children: ReactNode;
  className?: string;
}

const AspectRatio: FC<IAspectRatioWrapprProps> = ({ children, className }) => (
  <AspectRatioWrapper className={className}>
    {children}
  </AspectRatioWrapper>
);

export default AspectRatio;
