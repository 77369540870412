import { DiscordIcon } from '../../icons/socialMedia/DiscordIcon';
import { WhatsappIcon } from '../../icons/socialMedia/WhatsappIcon';
import { LinkedInIcon } from '../../icons/socialMedia/LinkedInIcon';
import { TwitterIcon } from '../../icons/socialMedia/TwitterIcon';
import CopyIcon from '../../icons/CopyIcon';
import styled from 'styled-components';
import { BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { LinkHandler } from '../general/LinkHandler';
import { FC } from 'react';
import { useGetLocalizedString } from '../../../services/localization';
import { useAddSystemNotification } from '../../../state/systemNotifications';

const ShareMenuWrapper = styled.div<{ open: boolean }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  opacity: ${({ open }) => open ? 1 : 0};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  transition: opacity 450ms ease, visibility 450ms ease;

  ${BREAKPOINT_XL} {
    right: 0;
    left: auto;
    bottom: 50%;
    transform: translate(200%, 50%);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 1.25rem;

  ${BREAKPOINT_XL} {
    flex-direction: column;
  }
`;

const CopyLinkButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: bold;

  svg {
    font-size: 1.5rem;
    transform: translateY(-.125rem);
  }
`;

const SocialMediaLink = styled(LinkHandler)`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  font-size: .75rem;
  text-align: center;

  svg {
    transition: transform 300ms ease-in-out;
  }

  @media (hover: hover) {
    :hover {
      text-decoration: none;

      svg {
        transform: scale(1.125);
      }
    }
  }
`;

const SocialMediaIcon = styled.div`
  width: 4.375rem;
  height: 4.375rem;
  border-radius: .75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

interface AlbumShareMenuProps {
  open: boolean;
  url: string;
  title: string;
  className?: string;
}

export const AlbumShareMenu: FC<AlbumShareMenuProps> = (props) => {
  const { open, url, title, className } = props;
  const getLocalizedString = useGetLocalizedString();
  const addSystemNotification = useAddSystemNotification();

  const copyUrl = async () => {
    await navigator.clipboard.writeText(url)
      .then(() => {
        addSystemNotification({
          type: 'success',
          headline: getLocalizedString('app.v2.share-via-social-media.copied-to-clipboard.headline'),
          text: getLocalizedString('app.v2.share-via-social-media.copied-to-clipboard.text'),
        });
      });
  };

  return (
    <ShareMenuWrapper open={open} className={className}>
      <IconWrapper>
        <SocialMediaLink to={'https://discord.com/app'} onClick={(e) => {
          e.preventDefault();
          copyUrl().finally(() => {
            window.open('https://discord.com/app', '_blank');
          });
        }}>
          <SocialMediaIcon style={{ background: '#5865F2' }}><DiscordIcon /></SocialMediaIcon>
          <p>{getLocalizedString('app.v2.share-via-social-media.label.discord')}</p>
        </SocialMediaLink>
        <SocialMediaLink to={'https://web.whatsapp.com'} onClick={(e) => {
          e.preventDefault();
          copyUrl().finally(() => {
            window.open('https://web.whatsapp.com', '_blank');
          });
        }}>
          <SocialMediaIcon style={{ background: '#25D366' }}><WhatsappIcon /></SocialMediaIcon>
          <p>{getLocalizedString('app.v2.share-via-social-media.label.whatsapp')}</p>
        </SocialMediaLink>
        <SocialMediaLink to={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}>
          <SocialMediaIcon style={{ background: '#0A66C2' }}><LinkedInIcon /></SocialMediaIcon>
          <p>{getLocalizedString('app.v2.share-via-social-media.label.linkedin')}</p>
        </SocialMediaLink>
        <SocialMediaLink to={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}>
          <SocialMediaIcon style={{ background: '#1DA1F2' }}><TwitterIcon /></SocialMediaIcon>
          <p>{getLocalizedString('app.v2.share-via-social-media.label.twitter')}</p>
        </SocialMediaLink>
      </IconWrapper>

      <CopyLinkButton onClick={(e) => {
        e.stopPropagation();
        copyUrl();
      }}>
        <p>{getLocalizedString('app.v2.general.copy.link')}</p>
        <CopyIcon />
      </CopyLinkButton>
    </ShareMenuWrapper>
  );
};