import React from 'react';
import { FC, Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';

const OverflowWrapper = styled.div<{ rarity: number }>`
  overflow: hidden;
  height: 1.25rem;
  border-radius: 2px 2px 0 0;
  color: var(--color-text-highlight);
  font-weight: bold;
  font-size: .75rem;
  line-height: 1.25rem;

  ${({ rarity }) => rarity === 2 && css`
    background: var(--color-primary);
  `};

  // TODO kompatiblen gradient einbauen
  ${({ rarity }) => rarity === 3 && css`
    background: conic-gradient(from 176.21deg at 50% 50%, rgba(0, 0, 0, 0) -24.76deg, #FFFFFF 0.25deg, rgba(0, 0, 0, 0) 50.63deg, rgba(0, 0, 0, 0) 50.66deg, #FFFFFF 88.12deg, rgba(0, 0, 0, 0) 142.5deg, #FFFFFF 196.87deg, rgba(0, 0, 0, 0) 256.87deg, #FFFFFF 300deg, rgba(0, 0, 0, 0) 335.2deg, rgba(0, 0, 0, 0) 335.24deg, #FFFFFF 360.25deg),
    conic-gradient(from 176.21deg at 50% 50%, #000000 -24.66deg, #FFFFFF 0.25deg, #000000 50.63deg, rgba(0, 0, 0, 0) 70.31deg, #FFFFFF 88.12deg, #000000 142.5deg, #FFFFFF 196.87deg, #000000 256.87deg, #FFFFFF 300deg, #000000 335.2deg, #000000 335.34deg, #FFFFFF 360.25deg),
    radial-gradient(95.11% 95.11% at 36.64% 4.89%, #AAFAF7 0%, #FFFFFF 100%);
    background-blend-mode: screen, difference, normal;
  `};
`;

const MarqueeAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
`;

const MarqueeWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  will-change: auto;
`;

const Item = styled.div`
  animation: ${MarqueeAnimation} var(--animation-duration, 30s) linear infinite;
  display: flex;
  gap: .5rem;
  padding: 0 .25rem;
  justify-content: space-around;
`;

interface MarqueeProps {
  rarity: number;
  className?: string;
}

export const Marquee: FC<MarqueeProps> = (props) => {
  const { rarity, className } = props;
  const getLocalizedString = useGetLocalizedString();
  const rarityLabel = getLocalizedString(`app.v2.collectible.rarity.${rarity}`);

  return (
    <OverflowWrapper className={className} rarity={rarity}>
      {
        rarity > 1 &&
        <MarqueeWrapper>
          {
            [0, 0].map((_, index) => (
              <Item key={index}>
                {
                  Array(7).fill('').map((_, index) => (
                    <Fragment key={index}>
                      <span>{rarityLabel}</span>
                      <span>{rarity > 2 ? '+++' : '++'}</span>
                    </Fragment>
                  ))
                }
              </Item>
            ))
          }
        </MarqueeWrapper>
      }
    </OverflowWrapper>
  );
};
