import styled from 'styled-components';
import { useAccount, useNetwork } from 'wagmi';
import { useGetLocalizedString } from '../../services/localization';
import { MAX_BREAKPOINT_SM } from '../../styles/Breakpoints';

const ChainInformationWrapper = styled.div`
  max-width: 320px;
  border-radius: 1.80952px;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: radial-gradient(
    70.42% 70.42% at 50% 50%,
    #0f0f0f 2.48%,
    rgba(8, 232, 222, 0.61) 53.65%,
    rgba(255, 255, 255, 0) 100%
  );

  background: url(.png),
    radial-gradient(
        90.16% 143.01% at 15.32% 21.04%,
        rgba(24, 95, 91, 0.2) 0%,
        rgba(110, 204, 244, 0.0447917) 77.08%,
        rgba(70, 213, 204, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(36.1905px);
  /* Note: backdrop-filter has minimal browser support */

  padding: 0.5rem;

  display: flex;
  justify-content: space-between;

  ${MAX_BREAKPOINT_SM} {
    max-width: 100%;
    width: 100%;
  }
`;

const Labels = styled.div`
  width: 50%;
  color: var(--color-primary);
  font-weight: 600;
`;

const Values = styled.div`
  width: 50%;
  text-align: right;
`;

const TestNetDisclaimer = styled.div`
  color: var(--color-signal-orange);
  font-style: italic;
`;

export const ChainInformation = () => {
  const t = useGetLocalizedString();
  const { address, isConnected, isConnecting } = useAccount();
  const { chain } = useNetwork();

  const shortedWalletID = address?.slice(0, 4) + '...' + address?.slice(-4) || 'xxxx...xxxx';

  if (!address || !isConnected || isConnecting) {
    // <p>{t('app.v2.connect-wallet.not-connected.label')}</p>
    return <></>;
  }

  return (
    <ChainInformationWrapper>
      <Labels>
        <ul>
        <li>{chain?.testnet && <>{t('app.v2.wallet.network.headline')}</>}</li>
          <li>{t('app.v2.wallet.network.chain.id.label')}</li>
          <li>{t('app.v2.wallet.network.chain.name.label')}</li>
          <li>{t('app.v2.wallet.network.wallet-address.label')}</li>
        </ul>
      </Labels>
      <Values>
        <ul>
          <li>{chain?.testnet && <TestNetDisclaimer>{t('app.v2.wallet.network.testnet.label')}</TestNetDisclaimer>}</li>
          <li>{chain?.id}</li>
          <li>{chain?.name}</li>
          <li>
              {shortedWalletID}
          </li>
        </ul>
      </Values>
    </ChainInformationWrapper>
  );
};
