import { FC } from 'react';
import { AlbumCardItem } from './AlbumCardItem';
import styled from 'styled-components';
import { AlbumCardCaptionStyle } from './AlbumCardCaption';
import { useActiveLocale } from '../../../services/localization';

const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    left: -.5rem;
    top: -.5rem;
    width: calc(100% + .75rem);
    height: calc(100% + .75rem);

    border: 2px solid rgba(8, 232, 222, 0.6);
    box-shadow: inset 0px 0px 24px #08E8DE;
    border-radius: 2px;
  }
`;

const Title = styled.h2<{ locale: string }>`
  ${AlbumCardCaptionStyle};
  --text-size-multiplier: ${({ locale = 'en' }) => {
    switch (locale) {
      case 'en':
        return 1;
      case 'de':
        return .85;
      case 'es':
      default:
        return .75;
    }
  }};
`;

interface IAlbumExplanationCardItemProps {
  text?: string;
}

export const AlbumExplanationCardItem: FC<IAlbumExplanationCardItemProps> = (props) => {
  const {
    text = '',
  } = props;

  const locale = useActiveLocale();

  return (
    <AlbumCardItem staticCard>
      <TextWrapper>
        <Title
          locale={locale || 'en'}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </TextWrapper>
    </AlbumCardItem>
  );
};