import { FC } from 'react';
import styled from 'styled-components';
import { IPublishersListItem } from '../../services/publisher';
import AspectRatio from './general/AspectRatio';
import { LinkHandler } from './general/LinkHandler';

const Wrapper = styled(LinkHandler)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  :hover {
    text-decoration: none;
  }
`;

const ImageWrapper = styled(AspectRatio)`
  display: flex;
  justify-content: center;
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: visible;
  max-height: 100px;

  img {
    object-fit: contain;
    // filter: drop-shadow(0px 4px 30px var(--color-primary-dark));
  }
`;

const Img = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const PublisherItemMinimal: FC<IPublishersListItem> = (props) => {
  const { slug, logoUrl, title } = props;

  return (
    <Wrapper to={`/collectibles/creators/${slug}`}>
      <ImageWrapper>
        <Img src={logoUrl ? logoUrl : 'images/UserImageFallback.png'} alt={title} />
      </ImageWrapper>
    </Wrapper>
  );
};
