import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL } from '../../styles/Breakpoints';
import { Body4 } from '../../styles/FontStyles';
import { useRecoilValue } from 'recoil';
import { MenuOpenState } from '../../state/menu';
import { useGetLocalizedString } from '../../services/localization';
import pkg from '../../../package.json';

const BuildVersionView = styled.span<{ visible?: boolean }>`
  position: fixed;
  right: 1rem;
  bottom: 5.5rem;
  z-index: 12;

  pointer-events: none;
  user-select: none;

  ${Body4};

  ${BREAKPOINT_MD} {
    right: var(--nav-padding);
    bottom: .75rem;
  }

  ${BREAKPOINT_LG} {
    right: 3.25rem;
    bottom: .75rem;
  }

  ${BREAKPOINT_XL} {
    right: var(--nav-padding);
    bottom: 2rem;
  }

  transition: opacity 150ms ease-out, visibility 150ms ease-out;
  opacity: ${({ visible = true }) => visible ? 1 : 0};
  visibility: ${({ visible = true }) => visible ? 'visible' : 'hidden'};
`;

export const BuildVersion = () => {
  const menuOpen = useRecoilValue(MenuOpenState);
  const getLocalizedString = useGetLocalizedString();
  const timestamp = process.env.REACT_APP_JOB_STARTED_AT || '';
  const timestampDate = new Date(timestamp);

  const buildTime = !!timestamp
    // ? `(${timestampDate.toLocaleDateString(undefined, { dateStyle: 'medium' })} ${timestampDate.toLocaleTimeString(undefined, { timeStyle: 'short' })})`
    ? `(${timestampDate.toLocaleDateString(undefined, { dateStyle: 'medium' })})`
    : '';

  return (
    <BuildVersionView visible={menuOpen}>
      {getLocalizedString('app.v2.general.version')} {pkg.version} {buildTime && `${buildTime}`}
    </BuildVersionView>
  );

};