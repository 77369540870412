export const wait = (delay: number = 40) => {   // 40ms -> 25 frames/sec
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export const interpolate = async (interpolator: (delta: number) => void, duration: number, fps: number = 25) => {
  const stepDuration = 1000 / fps;
  const stepsCount = Math.floor(duration / stepDuration);
  for (let step = 0; step < stepsCount; step++) {
    interpolator(Math.max(0, Math.min(1, step / (stepsCount - 1))));
    await wait(stepDuration);
  }
}