import { FC, ReactNode, useState } from "react";
import styled from "styled-components";
import { useGetLocalizedString } from "../../../services/localization";
import { SecondaryButton } from "../../../styles/Buttons";
import { CrossedEyeIcon } from "../../icons/EyeIcon";

const Wrapper = styled.div`
  display: grid;

  & > div {
    grid-area: 1 / 1;
  }
`;

const Overlay = styled.div<{ show: boolean }>`
  ${({ show }) => show ? `
    visibility: hidden;
  ` : `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--gradient-linear-100);
    z-index: 1;
    // TODO Fix
    border: 1px solid;
    border-image-source: var(--gradient-radial-900);
    padding-top: 3.25rem;
    padding-bottom: 2rem;
  `}
`;

const Headline = styled.p`
  font-weight: 700;
  margin: 1.25rem 0 .5rem;
  text-align: center;
`;

const Description = styled.p`
  margin-bottom: 1.25rem;
  font-size: .75rem;
  line-height: 1.125rem;
`;

const ContentWrapper = styled.div<{ show: boolean }>`
  ${({ show }) => !show && `
    visibility: hidden;
  `}
`;

interface IHiddenAreaProps {
  children: ReactNode;
  show?: boolean,
  onShow?: () => any;
}

const HiddenArea: FC<IHiddenAreaProps> = ({
  children,
  show,
  onShow,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(show || false);
  const t = useGetLocalizedString();

  const showContent = () => {
    setIsVisible(true);
    onShow?.();
  }

  return (
    <Wrapper>
      <Overlay show={isVisible}>
        <CrossedEyeIcon />
        <Headline>{t('app.v2.create-wallet.show-seedphrase')}</Headline>
        <Description>{t('app.v2.create-wallet.seedphrase-warning')}</Description>
        <SecondaryButton className="cy-button-show" onClick={showContent}>{t('app.v2.create-wallet.show-button')}</SecondaryButton>
      </Overlay>
      <ContentWrapper show={isVisible}>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default HiddenArea;