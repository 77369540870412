import { css } from 'styled-components';

export const FontFaces = css`
  @font-face {
    font-family: "Fuji";
    font-weight: 700;
    src: url("/fonts/Fuji/Fuji-Bold.otf") format("opentype");
  }

  @font-face {
    font-family: "Telegraf";
    font-weight: 400;
    src: url("/fonts/Telegraf/Telegraf-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Telegraf";
    font-weight: 500;
    src: url("/fonts/Telegraf/Telegraf-SemiBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Telegraf";
    font-weight: 600;
    src: url("/fonts/Telegraf/Telegraf-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Dexotick";
    font-weight: 800;
    src: url("/fonts/Dexotick/Dexotick.otf") format("opentype");
  }
`;

const FONT_TELEGRAF = 'Telegraf, sans-serif';
const FONT_FUJI = 'Fuji, sans-serif';
const FONT_DEXOTICK = 'Dexotick, sans-serif';

export const FontFamilies = {
  default: 'var(--font-family-default)',
  headline: 'var(--font-family-headline)',
  artsy: 'var(--font-family-artsy)',
};

export const FontTheme = css`
  :root {
    --font-family-default: ${FONT_TELEGRAF};
    --font-family-headline: ${FONT_FUJI};
    --font-family-artsy: ${FONT_DEXOTICK};
  }
`;