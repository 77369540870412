import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../styles/Breakpoints';
import { HeroTiles } from './teaser/HeroTiles';
import { VideoHomepageTeaser } from './teaser/VideoHomepageTeaser';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0.5rem 0 2rem;
  grid-column: 1 / -1;

  ${BREAKPOINT_LG} {
    grid-column: 3;
  }
`;

const SliderControls = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 1.375rem;
    pointer-events: none;
    display: flex;
  }
`;

const Prev = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
  pointer-events: visible;
  z-index: 1;
  font-size: 3rem;

  ${({ disabled = false }) =>
    disabled &&
    css`
      display: none;
    `};
`;

const Next = styled(Prev)`
  left: auto;
  right: 0;
`;

const SwiperCustom = styled(Swiper)`
  width: 100%;

  .swiper-wrapper {
    height: 100%;
  }
`;

const SwiperSlideCustom = styled(SwiperSlide)`
  height: auto;
`;

const SliderIndicator = styled.div`
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  width: 100%;
  margin-top: 0.5rem;
`;

const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-column: 1 / -1;
  z-index: 1;

  // ${BREAKPOINT_MD} {
  //   justify-content: flex-start;
  //   grid-column: 6 / -1;
  // }
`;

const Dot = styled.div<{ active?: boolean }>`
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 50%;
  margin-right: 0.375rem;
  background-color: var(--color-white);
  cursor: pointer;
  opacity: ${({ active = false }) => (active ? '1' : '.5')};
  filter: drop-shadow(0px 1px 9px var(--color-black));
`;

const Slide = styled.article`
  width: 100%;
  height: auto;

  ${BREAKPOINT_MD} {
    height: 100%;
  }
`;

export const TeaserSlider: FC<any> = (props) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [swiper, setSwiper] = useState<any>(null);

  const onSlideChange = (e: any) => {
    setActiveIndex(e.realIndex + 1);
  };

  return (
    <Wrapper>
      <SwiperCustom onSwiper={setSwiper} navigation={true} slidesPerView={1} onSlideChange={onSlideChange}>
        <SwiperSlideCustom key={'explain-video'}>
          <Slide>
            <HeroTiles />
          </Slide>
        </SwiperSlideCustom>
        {/* <SwiperSlideCustom key={'future'}>
          <Slide>
            <HeroTiles />
          </Slide>
        </SwiperSlideCustom>*/}
      </SwiperCustom>

      {/* <SliderControls>
        <Prev onClick={() => swiper.slidePrev()} aria-label='Previous Teaser' disabled={activeIndex <= 1}>
          <ArrowHeadIcon />
        </Prev>
        <Next onClick={() => swiper.slideNext()} aria-label='Next Teaser' disabled={activeIndex >= 2}>
          <ArrowHeadIcon direction={'right'} />
        </Next>
      </SliderControls> */}

      {/* <SliderIndicator className='indicator'>
        <IndicatorWrapper>
          <Dot key={0} active={1 === activeIndex} onClick={() => swiper.slideTo(0)} aria-label={`News ${1}`} />
          <Dot key={1} active={2 === activeIndex} onClick={() => swiper.slideTo(1)} aria-label={`News ${2}`} />
        </IndicatorWrapper>
      </SliderIndicator> */}
    </Wrapper>
  );
};
