import { useAPIInstance } from './index';
import { AxiosResponse } from 'axios';

export interface IClaimableTokenList {
  collectibles: IClaimableToken[];
  totalRecords: number;
}

export interface IClaimableToken {
  type: string;
  id: string;
  slug: string;
  title: string;
  imageUrl: string;
  imageAnimatedUrl: string;
  imageStaticUrl: string;
  status: string;
  publisherId: string;
  publisherLogoUrl: string;
  publisherName: string;
  publisherSlug: string;
  price: number;
  rarity: number;
  edition: string;
  sku: string;
  purchasedNr?: number;
  tags: string[];
  publishAt: string;
  soldOut: boolean;
  initialSupply: number;
  currentSupply: number;
  isCharityItem: boolean;
  hasPhysicalProduct: boolean;
  isExclusive: boolean;
  isFreemint: boolean;
  isBonus: boolean;
  smartContractAddress: string;
}

interface IClaimableTokenResponse {
  claimableToken: IClaimableToken;
}

export const useClaimableTokensAPI = () => {
  const apiInstance = useAPIInstance();

  const fetchAllClaimableTokens = (offset: number, limit: number) => {
    return apiInstance.get<IClaimableTokenList>(`/claimable-tokens/all?offset=${offset}&limit=${limit}`);
  };

  const userWalletClaimableTokenDetails = (smartContractAddresses: string[]) => {
    return apiInstance.get<IClaimableToken[]>(`/claimable-tokens/smartcontract/${smartContractAddresses.join(',')}`);
  };

  return {
    fetchAllClaimableTokens,
    userWalletClaimableTokenDetails,
  };
};
