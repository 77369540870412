import { FC, useCallback, useMemo, useState } from 'react';
import { useWallet } from '../../../hooks/useWallet';
import { PrimaryButton } from '../../../styles/Buttons';
import QRCodeScanner from '../../common/special/QRCodeScanner';
import SeedPhraseInput from '../../common/special/SeedPhraseInput';
import { useGetLocalizedString } from '../../../services/localization';
import styled from 'styled-components';
import { Divider } from '../../common/special/WalletBackup';
import { Body2Bold, Body3, PageViewHeadline } from '../../../styles/FontStyles';
import { useNavigate } from 'react-router-dom';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { Notification } from '../../common/general/Notification';
import { useRecoilValue } from 'recoil';
import { UserState } from '../../../state/user';
import { PageView } from '../../layout/PageLayout';
import { useScrollPage } from '../../../hooks/useScrollPage';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  place-self: center;
  justify-items: center;

  ${BREAKPOINT_LG} {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
  }
`;

const Wrapper = styled.div`
  ${Body3};
  display: grid;
  row-gap: 1rem;
  grid-column: 7 / -1;
`;

const Headline = styled.div`
  ${Body2Bold};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: center;

  ${BREAKPOINT_SM} {
    margin-top: 2.5rem;
    flex-direction: row;
    gap: 2rem;
  }

  ${BREAKPOINT_LG} {
    margin-top: 5rem;
  }
`;

const StyledButton = styled(PrimaryButton)`
  ${BREAKPOINT_SM} {
    min-width: unset;
    flex: 1;
  }
`;

const ErrorNotification = styled(Notification).attrs({ type: 'error' })`
  margin-bottom: .5rem;
`;

const AlertNotification = styled(Notification).attrs({ type: 'alert' })`
  margin-bottom: .5rem;
`;

type TOldQRCode = {
  myfsqr: string;
  userEmail: string;
};

interface IWalletImport {
  onImport?: () => void;
  password: string;
}

/**
 * Component that includes the wallet import functionality
 * QR Code Scan, File Upload & Seed Phrase Recovery
 */
const WalletImport: FC<IWalletImport> = ({
                                           onImport,
                                           password,
                                         }) => {
  const { importWallet, storeWallet, importWalletFromPrivateKey } = useWallet();
  const navigate = useNavigate();
  const t = useGetLocalizedString();

  const [loading, setLoading] = useState<boolean>(false);
  const [seedPhrase, setSeedPhrase] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [errorState, setErrorState] = useState<{ headline: string, text: string } | null>(null);
  const [alertState, setAlertState] = useState<{ headline: string, text: string } | null>(null);
  const userData = useRecoilValue(UserState);
  const scrollPage = useScrollPage();

  const isValid = useMemo(() => {
    const seedPhraseWords = seedPhrase.split(' ');
    return (seedPhraseWords.length === 12 && !seedPhraseWords.some(w => !(!!w && w !== ' '))) || !!privateKey;
  }, [seedPhrase, privateKey]);

  const onScanSuccess = (value: string) => {
    const isOldQR = value.includes('myfsqr');

    if (isOldQR) {
      const { myfsqr: privateKey } = JSON.parse(value) as TOldQRCode;
      setPrivateKey(privateKey);
      setAlertState({
        headline: t('app.v2.wallet-import.old-qr.headline'),
        text: t('app.v2.wallet-import.old-qr.text'),
      });
      scrollPage();
    } else {
      setSeedPhrase(value);
    }
  };

  const cancel = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const connect = async () => {
    setLoading(true);
    setErrorState(null);

    try {
      const wallet = seedPhrase ? importWallet(seedPhrase) : importWalletFromPrivateKey(privateKey);

      if (userData?.walletAddress === wallet.address) {
        await storeWallet(wallet, password, (progress) => {
          progress === 1 && onImport?.();
        });
      } else {
        throw Error();
      }
    } catch (err) {
      setErrorState({
        headline: t('app.v2.wallet-import.import-error-headline'),
        text: t('app.v2.wallet-import.import-error-text'),
      });
      scrollPage();
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageView>
      <Section>
        <PageViewHeadline>{t('app.v2.wallet-import.title')}</PageViewHeadline>
        <Wrapper>
          <Headline>{t('app.v2.wallet-import.text')}</Headline>
          <p>{t('app.v2.wallet-import.import-from-recovery-phrase')}</p>
          {alertState && (
            <AlertNotification {...alertState} onClose={() => setAlertState(null)} />
          )}
          {errorState && (
            <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />
          )}
          <SeedPhraseInput value={seedPhrase} onChange={setSeedPhrase} />
          <Divider>{t('app.v2.general.or')}</Divider>
          <QRCodeScanner onScanSuccess={onScanSuccess} />
          <ButtonWrapper>
            <StyledButton onClick={cancel}>{t('app.v2.wallet-import.cancel')}</StyledButton>
            <StyledButton disabled={!isValid} $highlighted={isValid} onClick={connect}>
              {loading ? <LoadingAnimation /> : t('app.v2.wallet-import.connect')}
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      </Section>
    </PageView>
  );
};

export default WalletImport;