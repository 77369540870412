import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { ICollectible, useCollectiblesAPI } from '../../../services/collectible';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';
import { LoadingAnimation } from '../../../components/common/general/LoadingAnimation';
import { useNavigate, useParams } from 'react-router-dom';
import { WizardLayout } from '../../../components/layout/WizardLayout';
import { ShippingAddress } from '../../../components/views/checkout/ShippingAddress';
import { DeliveryIcon } from '../../../components/icons/DeliveryIcon';
import { PaymentIcon } from '../../../components/icons/PaymentIcon';
import { ReviewIcon } from '../../../components/icons/ReviewIcon';
import { PaymentMethod } from '../../../components/views/checkout/PaymentMethod';
import { Review } from '../../../components/views/checkout/Review';
import { useProfileAPI } from '../../../services/profile';
import { usePurchaseState } from '../../../state/purchase';
import { useAuthToken } from '../../../state/auth';
import { useWallet } from '../../../hooks/useWallet';
import { PageView } from '../../../components/layout/PageLayout';
import Modal from '../../../components/common/general/Modal';

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 5.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CollectibleCheckout = () => {
  const { id } = useParams();

  const getLocalizedString = useGetLocalizedString();
  const activeLocale = useActiveLocale();

  const authToken = useAuthToken();
  const { encryptedWallet } = useWallet();
  const navigate = useNavigate();

  const collectiblesAPI = useCollectiblesAPI();
  const { getCurrentShippingAddress } = useProfileAPI();

  const [purchaseState, setPurchaseState] = usePurchaseState();

  const [collectible, setCollectible] = useState<ICollectible | null>(null);
  const [loading, setLoading] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  useEffect(() => {
    // check if auth token is available
    if (!authToken) {
      navigate('/login', { state: { redirect: `/collectibles/${id}/purchase` }, replace: true });
      return;
    }

    // ------------------------------------------------------
    // Damiano Giampaoli 18/01/2023
    // Disabling the wallect connection check in purchase process as per user story https://app.clickup.com/t/8676zp6ph
    // ------------------------------------------------------
    // // check if wallet is connected
    // if (typeof encryptedWallet !== 'string') {
    //   navigate('/connect-wallet', { state: { redirect: `/collectibles/${id}/purchase` }, replace: true });
    //   return;
    // }
  }, [authToken, encryptedWallet]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      collectiblesAPI.collectible(id)
        .then(async (res) => {
          setLoading(false);
          setCollectible(res.data?.collectible || null);

          const shippingAddress = (await getCurrentShippingAddress())?.data?.address || {};
          setPurchaseState({
            collectibleId: id,
            shippingAddress,
            paymentMethod: 'stripe',
          });
        })
        .catch((err) => {
          setLoading(false);
          setCollectible(null);
          console.error("COLLECTIBLE CHECKOUT", id, err?.response?.status, err?.response?.data);

          if (err?.response?.status !== 401) {
            // navigate to 404 page
            navigate('/404', { replace: true });
          }
        });
    } else {
      // navigate to 404 page
      navigate('/404', { replace: true });
    }
  }, [id, activeLocale]);

  const step = useMemo((): 'shipping-address' | 'payment' | 'review' | 'error' => {
    if (collectible && purchaseState) {
      if (collectible.hasPhysicalProduct) {
        switch (checkoutStep) {
          case 0: return 'shipping-address';
          case 1: return 'payment';
          case 2: return 'review';
        }
      } else {
        switch (checkoutStep) {
          case 0: return 'payment';
          case 1: return 'review';
        }
      }
    }
    return 'error';
  }, [collectible, purchaseState, checkoutStep]);

  const title = useMemo(() => {
    switch (step) {
      case 'shipping-address': return getLocalizedString('app.v2.checkout.shipping-address.title');
      case 'payment': return getLocalizedString('app.v2.checkout.payment.title');
      case 'review': return getLocalizedString('app.v2.checkout.review.title');
    }
    return '';
  }, [step, getLocalizedString]);

  return (
    <PageView>
      <WizardLayout
        headline={title}
        wizardStep={checkoutStep}
        wizardContent={[
          collectible?.hasPhysicalProduct &&
          <DeliveryIcon key={"delivery"} />,
          <PaymentIcon key={"payment"} />,
          <ReviewIcon key={"review"} />,
        ].filter(Boolean)}
      >
        {
          step === 'shipping-address' &&
          <ShippingAddress
            onSubmit={() => setCheckoutStep(1)}
          />
        }
        {
          step === 'payment' &&
          <PaymentMethod
            onSubmit={() => setCheckoutStep(collectible?.hasPhysicalProduct ? 2 : 1)}
            onBackButtonClick={collectible?.hasPhysicalProduct ? () => setCheckoutStep(0) : undefined}
            collectible={collectible}
          />
        }
        {
          step === 'review' &&
          <Review
            collectible={collectible!}
            onSubmit={() => {}}
            onBackButtonClick={() => setCheckoutStep(collectible?.hasPhysicalProduct ? 1 : 0)}
            onEditButtonClick={(step) => setCheckoutStep(step === 'shipping-address' ? 0 : collectible?.hasPhysicalProduct ? 1 : 0)}
            onDeleteButtonClick={() => setShowCancelDialog(true)}
          />
        }

        {
          loading &&
          <LoadingWrapper>
            <LoadingAnimation />
          </LoadingWrapper>
        }

        <Modal
          open={showCancelDialog}
          onClose={() => setShowCancelDialog(false)}
          headline={getLocalizedString('app.v2.checkout.cancel-dialog.headline')}
          text={getLocalizedString('app.v2.checkout.cancel-dialog.text')}
          primaryButton={{
            title: getLocalizedString('app.v2.general.no'),
          }}
          secondaryButton={{
            title: getLocalizedString('app.v2.general.yes'),
            onClick: () => navigate(`/collectibles/${id}`),
          }}
        />
      </WizardLayout>
    </PageView>
  );
}