import styled, { css } from 'styled-components';
import { FC, InputHTMLAttributes } from 'react';
import Text from '../general/Text';
import { useFormContext } from 'react-hook-form';

const Wrapper = styled.div`
  display: grid;
  gap: .125rem
`;

const FormItemWrapper = styled.label<{ error: boolean, disabled: boolean }>`
  display: flex;
  gap: .75rem;
  align-items: center;

  ${({ error }) => error && css`
    color: var(--color-signal-red);
  `}

  ${({ disabled }) => disabled && css`
    color: var(--color-grey-700);
  `}
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })<{ error: boolean }>`
  position: relative;
  height: 1.25rem;
  width: 1.25rem;
  flex-shrink: 0;
  border: 1.5px solid var(--color-text-default);
  border-radius: .125rem;
  border-color: ${({ error }) => error ? 'var(--color-signal-red)' : 'var(--color-text-default)'};
  appearance: none;
  background: none;

  :disabled {
    border-color: var(--color-grey-700);
  }

  :after {
    content: '×';
    position: absolute;
    font-size: 1.875rem;
    line-height: 1rem;
    top: 0;
    left: 0;

    opacity: 0;
    transform: scale(.75);
    transition: opacity 25ms ease-out, transform 250ms ease-out;
  }

  :checked {
    :after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export const Checkbox: FC<ICheckboxProps> = (props) => {
  const { label, name, error, disabled = false, required = false, className, onClick } = props;
  const { register } = useFormContext();

  return (
    <Wrapper className={className}>
      <FormItemWrapper error={!!error} disabled={disabled}>
        <CheckboxInput
          error={!!error}
          disabled={disabled}
          required={required}
          onClick={onClick}
          {...register(name, { required })}
        />
        <Text content={label} />
      </FormItemWrapper>
      {error && error}
    </Wrapper>
  );
};