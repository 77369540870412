import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IClaimableToken } from '../../../services/claimableToken';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS } from '../../../styles/Breakpoints';
import { Body1Bold, Body2Bold, Body3 } from '../../../styles/FontStyles';
import { ClaimableTokenImage } from '../collectibles/ClaimableTokenImage';
import { GlassCard } from '../collectibles/GlassCard';
import { LinkHandler } from '../general/LinkHandler';
import { Marquee } from '../general/Marquee';
import { useAlchemy } from '../../../hooks/useAlchemy';
import { Nft } from './WalletItems';
import { SecondaryButton } from '../../../styles/Buttons';
import { useNavigate } from 'react-router-dom';
import BlockchainInfo from './BlockchainInfo';
import { ethers } from 'ethers';
import { client } from '../../../web3/client';
import { useDataCache } from 'src/state/dataCache';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  align-self: stretch;
  min-width: 0; // safari fix

  display: flex;
  flex-direction: column;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  display: flex;
  flex-direction: column;

  padding-top: 3rem;

  ${BREAKPOINT_MD} {
    padding-top: 4.5rem;
  }
`;

const MarqueeOverlay = styled(Marquee)`
  position: absolute;
  left: 0;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  flex: 1;

  padding: 0 0.5rem 1.25rem;

  ${BREAKPOINT_LG} {
    padding: 0 1.5rem 2rem;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
`;

const CreatorLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  height: 1.25rem;

  ${BREAKPOINT_SM} {
    gap: 1rem;
  }
`;

const Creator = styled(LinkHandler)`
  flex: 1;
  min-width: min-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 0.75rem;
  }
`;

const CreatorName = styled.div<{ hideCreatorNameOnMobile?: boolean }>`
  display: ${({ hideCreatorNameOnMobile = false }) => (hideCreatorNameOnMobile ? 'none' : '-webkit-box')};
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  ${Body3};
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  ${BREAKPOINT_XS} {
    display: -webkit-box;
  }
`;

const CreatorIcon = styled.img`
  height: 1.375rem;
  width: 1.375rem;
  object-fit: contain;

  ${BREAKPOINT_MD} {
    height: 1.875rem;
    width: 1.875rem;
  }
`;

const Title = styled.p`
  ${Body2Bold};
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 2.375rem;
  margin-bottom: auto;

  ${BREAKPOINT_SM} {
    ${Body1Bold};
    height: 3rem;
  }

  ${BREAKPOINT_LG} {
    height: 3.75rem;
  }
`;

const ClaimableTokenImageWrapper = styled(ClaimableTokenImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface IClaimableTokenItemProps extends IClaimableToken {
  isProfile?: boolean;
  hideCreatorNameOnMobile?: boolean;
  className?: string;
  blockchainData?: Nft;
  showClaimButton?: boolean;
}

interface IClaimButtonProps {
  collectibleId: string;
  sku: string;
  showClaimButton?: boolean;
}

const ClaimButtonWrapper = styled.div`
  top: 30%;
  padding-bottom: 4%;
`;

const ClaimButton: FC<IClaimButtonProps> = (props) => {
  const {
    collectibleId,
    sku,
  } = props;
  const navigate = useNavigate();

  const claimIt = () => {
    navigate(`/collectibles/${collectibleId}/claim/${sku}`, { state: { redirect: '/profile' } });
  }
  const [isClaimEnabled, setIsClaimEnabled] = useState(false);
  const [dataCache, setDataCache] = useDataCache();
  const {
    userCollectiblesData = null
  } = dataCache;

  const getLocalizedString = useGetLocalizedString();

  const isClaimed = async() : Promise<boolean> => {
    if(userCollectiblesData){
      const tokenToClaim = userCollectiblesData.filter((collectible) => (
        collectible.id === collectibleId
      ));
      const {smartContractAddress} = tokenToClaim.filter(t => t.sku === sku)[0];
      const contractABI = require("../../../web3/contracts-abi/claimableContract.json");
      const contract = new ethers.Contract(smartContractAddress, contractABI.abi, client.provider);
      const result = await contract.isClaimed(sku);
      setIsClaimEnabled(!result);
      return result;
    }
    return false;
  }

  useEffect(() => {
    isClaimed();
  },[]);

  return (
    <ClaimButtonWrapper>
      <SecondaryButton disabled={!isClaimEnabled} style={{ margin:"0 auto" }} onClick={claimIt}>
        { isClaimEnabled ? 
          getLocalizedString("app.v2.claimabletokens.card.cta.enabled"):
          getLocalizedString("app.v2.claimabletokens.card.cta.disabled")}
      </SecondaryButton>
    </ClaimButtonWrapper>
  )
}

export const ClaimableTokenItem: FC<IClaimableTokenItemProps> = (props) => {
  const {
    id,
    slug,
    price,
    blockchainData,
    publisherId,
    purchasedNr,
    publisherLogoUrl,
    publisherName,
    publisherSlug,
    rarity,
    title,
    isProfile = false,
    isExclusive = false,
    isFreemint = false,
    isBonus = false,
    hideCreatorNameOnMobile = false,
    tags,
    status,
    className,
    sku,
    smartContractAddress,
    ...stickerProps
  } = props;
  const [uri, setUri] = useState('');
  const { nftMetadata, refreshNftMetadata } = useAlchemy();

  const getMetadataImageURI = async (blockchainData: Nft | undefined) => {
    // setUri(blockchainData.media[0].gateway);
    //Damiano Giampaoli 14/06/2023 - In theory we should just call setUri as commented line above but there are weird issues with Alchemy metadata cache so...
    if(blockchainData?.contract?.address && blockchainData?.tokenId){
      const contractAddress = blockchainData?.contract?.address;
      const tokenId = parseInt(blockchainData?.tokenId);
      await refreshNftMetadata(contractAddress, tokenId);
      const nft = await nftMetadata(contractAddress, tokenId);
      setUri("https://violet-adjacent-mouse-650.mypinata.cloud/ipfs" + nft.media[0].raw.substring(6));
    }
  };

  useEffect(() => {
    getMetadataImageURI(blockchainData);
  }, []);

  return (
    <Wrapper className={className}>
      <BackgroundWrapper>
        <MarqueeOverlay rarity={rarity} />
        <GlassCard rarity={rarity} rounded={true} />
      </BackgroundWrapper>

      <Inner>
        <ImageWrapper>
          <ClaimableTokenImageWrapper tokenURI={uri} claimableToken={props as never as IClaimableToken} />
        </ImageWrapper>
        <Infos>
          <ClaimButton collectibleId={id} sku={sku}/>
          <CreatorLineWrapper>
            <Creator to={`/collectibles/creators/${publisherSlug}`}>
              <CreatorIcon src={publisherLogoUrl} alt={publisherName} />
              <CreatorName hideCreatorNameOnMobile={hideCreatorNameOnMobile} title={publisherName}>
                {publisherName}
              </CreatorName>
            </Creator>
          </CreatorLineWrapper>

          <Title>{title}</Title>
          <>
            <BlockchainInfo sku={`${blockchainData?.tokenId}`} initialSupply={stickerProps.initialSupply} smartContractAddress={smartContractAddress}/>
          </>
        </Infos>
      </Inner>
    </Wrapper>
  );
};
