import styled from 'styled-components';
import { FC, useEffect, useRef } from 'react';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { CloseIcon } from '../../icons/CloseIcon';

const AccordionItemWrapper = styled.div<{ open: boolean }>`
  border-bottom: 1px solid var(--color-white);
  transition: padding .5s ease;
  padding-bottom: ${({ open }) => open ? '1.5rem' : 0};
`;

const Header = styled.button`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem 1.25rem 0;
  text-align: left;
  text-decoration: bold;

  ${BREAKPOINT_MD} {
    padding: 1.5rem 1.5rem 1.5rem 0;
    font-size: 1.25rem;
  }
`;

const StyledCloseIcon = styled(CloseIcon)<{ open: boolean }>`
  transform: rotate(${({ open }) => open ? 45 : 0}deg);
  transition: transform .3s ease;
  transform-origin: center;
`;

const Content = styled.div`
  max-height: 0;
  overflow: hidden;
  margin-right: 4rem;
  transition: max-height .5s ease;
`;

export interface IAccordion {
  head: string;
  body: string;
}

export interface IAccordionProps extends IAccordion {
  open: boolean;
  onClick: () => void;
}

// TODO Accessiblity
export const Accordion: FC<IAccordionProps> = ({
  open,
  head,
  body,
  onClick
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef.current) return;
    const height = open ? contentRef.current?.scrollHeight : 0;
    contentRef.current.style.maxHeight = `${height}px`;
  }, [open]);

  return (
    <AccordionItemWrapper open={open}>
      <Header onClick={onClick}>
        <p>{head}</p>
        <StyledCloseIcon open={!open} />
      </Header>

      <Content ref={contentRef}>
        {body}
      </Content>
    </AccordionItemWrapper>
  );
};
