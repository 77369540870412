import { FC } from 'react';
import { OverlayIcon } from './OverlayIcon';

export const UserIconOutline: FC<{ className?: string }> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle r="4" transform="matrix(-1 0 0 1 12 7.25)" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M5 17.1847C5 16.3243 5.54085 15.5568 6.35109 15.2675V15.2675C10.004 13.9628 13.996 13.9628 17.6489 15.2675V15.2675C18.4591 15.5568 19 16.3243 19 17.1847V18.5002C19 19.6876 17.9483 20.5998 16.7728 20.4318L15.8184 20.2955C13.2856 19.9337 10.7144 19.9337 8.18162 20.2955L7.22721 20.4318C6.0517 20.5998 5 19.6876 5 18.5002V17.1847Z" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

export const UserIconFilled: FC<{ className?: string }> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle r="4" transform="matrix(-1 0 0 1 12 7.25)" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M5 17.1847C5 16.3243 5.54085 15.5568 6.35109 15.2675C10.004 13.9628 13.996 13.9628 17.6489 15.2675C18.4591 15.5568 19 16.3243 19 17.1847V18.5002C19 19.6876 17.9483 20.5998 16.7728 20.4318L15.8184 20.2955C13.2856 19.9337 10.7144 19.9337 8.18162 20.2955L7.22721 20.4318C6.0517 20.5998 5 19.6876 5 18.5002V17.1847Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

export const UserIcon: FC<{ className?: string, active?: boolean }> = (props) => (
  <OverlayIcon {...props}>
    <UserIconOutline />
    <UserIconFilled />
  </OverlayIcon>
);
