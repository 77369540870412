import printJS from 'print-js';

/**
 * Create a printing context from a canvas
 */
const printCanvas = (canvas: HTMLCanvasElement) => {
    const data = canvas.toDataURL();
    printJS({printable: data, type: 'image', header: 'Keep this QR Code in a save space.'});
}

export default printCanvas;
