import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const PrinterIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.10526 15.9687H3C1.89543 15.9687 1 15.0733 1 13.9687V6.96875C1 5.86418 1.89543 4.96875 3 4.96875H19C20.1046 4.96875 21 5.86418 21 6.96875V13.9687C21 15.0733 20.1046 15.9687 19 15.9687H18.8947'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <rect x='5' y='10.3438' width='12' height='8' rx='2' stroke='currentColor' strokeWidth='1.5' />
    <path
      d='M5 3.65625C5 2.55168 5.89543 1.65625 7 1.65625H15C16.1046 1.65625 17 2.55168 17 3.65625V4.65625H5V3.65625Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
);

export default PrinterIcon;
