import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const ChevronIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.5 7L14.5 12.2174L9.5 17'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);