import styled, { css } from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { IProfile } from '../../../services/profile';
import { useLocalizedString } from '../../../services/localization';
import { LinkHandler } from '../general/LinkHandler';
import { useProfileAPI } from '../../../services/profile';
import { useDataCache } from '../../../state/dataCache';
import { FanseaImage } from '../FanseaImage';
import { CloudinaryQuality } from '../../../hooks/useCloudinary';
import { useAccount } from 'wagmi';

const ImageWrapper = styled.div<Props>`
  display: inline-block;
  position: relative;
  margin-bottom: 0.075rem;
  // margin-left: 1.25rem;
  line-height: 0;
  flex-shrink: 0;

  ${BREAKPOINT_MD} {
    margin-bottom: 0;
  }
`;

const Image = styled.picture<Props>`
  img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    background-color: var(--color-grey-800);
    border: 0.125rem solid var(--color-primary);

    ${BREAKPOINT_MD} {
      height: 3rem;
      width: 3rem;
      transition: width 450ms ease, height 450ms ease;
    }
  }
`;

interface Props {
  userImage?: string;
}

interface IProfileTopProps extends Props {
  onConnect?: () => any;
  className?: string;
  profileData: IProfile | null;
}

export const ProfileHeader: FC<IProfileTopProps> = (props) => {
  const profileAPI = useProfileAPI();
  const [dataCache, setDataCache] = useDataCache();
  const { isConnected } = useAccount();

  useEffect(() => {
    const hasCachedData = !!profileData;
    if (!hasCachedData) {
      Promise.all([
        profileAPI.profile().then((response) => {
          setDataCache((state) => ({ ...state, profileData: response.data || null }));
        }),
      ]).catch((err) => {
        console.log(err);
      });
    }
  });
  const { profileData } = props;

  const userImageAlt = useLocalizedString('app.v2.profile.user.image.alt');

  return (
    <ImageWrapper>
      <LinkHandler to={isConnected ? '/wallet' : '/profile'}>
        <Image>
          <FanseaImage
            altText={userImageAlt}
            imageUrl={profileData?.profile.imageUrl ? profileData?.profile.imageUrl : 'images/UserImageFallback.png'}
            isJpegConverted
            size='256'
            quality={CloudinaryQuality.GOOD}
          />
        </Image>
      </LinkHandler>
    </ImageWrapper>
  );
};
