import QRCode from 'qrcode';

/**
 * Generator and Scanner are split, to create more lightweight
 * bundles that can be imported individually without both
 * dependencies
 */
const generateQRCode = (content: string, canvas?: HTMLCanvasElement, width?: number) => {
  try {
    const opts = {
      width: width,
      quality: 1,
    }

    if (canvas) {
      // @ts-ignore
      return QRCode.toDataURL(canvas, content, opts) as string;
    } else {
      // @ts-ignore
      return QRCode.toDataURL(content, opts) as string;
    }
  } catch (err) {
    console.error(err)
  }
}

export default generateQRCode;