import styled from 'styled-components';
import { Input } from '../../common/forms/Input';
import { FC, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../styles/Buttons';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FormWrapper from '../../common/forms/FormWrapper';
import { FieldValues, useForm } from 'react-hook-form';
import { isEmail } from '../../../utils/validators';
import { useAuthAPI } from '../../../services/auth';
import { Notification } from '../../common/general/Notification';
import { Body2Bold, Body3Bold, Headline4 } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';
import { useSetRecoilState } from 'recoil';
import { UserState } from '../../../state/user';
import { BREAKPOINT_LG, BREAKPOINTMAX, DIMEN_BREAKPOINT_LG, MAX_BREAKPOINT_XS } from '../../../styles/Breakpoints';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { PageView } from '../../layout/PageLayout';
import Text from '../../common/general/Text';

const Section = styled.section`
  align-self: stretch;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${BREAKPOINT_LG} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
  }
`;

const GraphicWrapper = styled.div`
  display: none;

  ${BREAKPOINT_LG} {
    display: unset;
    position: absolute;
    height: 55vh;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    position: relative;
    grid-column: 1 / 7;
    width: 100%;
    height: 100%;
    max-height: 75vh;
    left: initial;
    transform: translateY(0%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      align-self: start;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  z-index: 1;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 21.5rem;
  width: 100%;

  ${BREAKPOINT_LG} {
    grid-column: 8 / -2;
  }

  ${SecondaryButton} {
    align-self: center;
  }
`;

const Headline = styled.h2`
  ${Headline4};
`;

const StyledText = styled(Text)`
  margin-bottom: 0.25rem;
`;

const StyledForm = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  --spinner-color: var(--color-text-highlight);

  ${PrimaryButton} {
    align-self: center;
  }
`;

const PasswordResetLink = styled.button.attrs({ type: 'button' })`
  ${Body3Bold};
  font-weight: 600;
  color: var(--color-primary);
  align-self: flex-start;
`;

const CreateAccountLink = styled.p`
  ${Body2Bold};
  color: var(--color-grey-600);
  text-align: center;

  a {
    color: var(--color-primary);
  }
`;

const RegisterButton = styled(PrimaryButton)`
  align-self: center;
`;

const ErrorNotification = styled(Notification).attrs({ type: 'error' })`
  margin-bottom: 0.25rem;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

export interface ILoginFunctionProps {
  email: string;
  password: string;
}

interface ILoginProps {
  onLogin: (data: ILoginFunctionProps) => void;
}

const Login: FC<ILoginProps> = ({ onLogin }) => {
  const getLocalizedString = useGetLocalizedString();

  const form = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { login, me } = useAuthAPI();

  const setUserState = useSetRecoilState(UserState);

  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(location?.state?.error || null);
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState<boolean>(false);

  const submitHandler = async ({ email, password }: FieldValues) => {
    setLoading(true);
    try {
      const loginRepsonse = await login({ email, password });
      const userData = (await me()).data.value;
      setUserState(userData);

      if(!loginRepsonse?.data?.hasAcceptedLatestTerms) {
        navigate('/accept-terms', {state: { showAcceptButton: true }});
        return;
      }

      if (userData.walletAddress) {
        const { redirect = '/' } = location.state || {};
        navigate(redirect, { state: { login: true } });
        // Damiano Giampaoli 17/01/2023
        // as per User Story https://app.clickup.com/t/8676zp6ph we disable the wallet loading after login
        // onLogin({ email, password });
      } else {
        navigate('/registration', { state: { ...location.state, step: 2, password } });
      }

      setLoading(false);
    } catch (err: any) {
      console.error('login error', err);
      switch (err?.response?.status || 500) {
        default:
        case 401:
          setErrorState({
            headline: getLocalizedString('app.v2.login.error401.title'),
            text: getLocalizedString('app.v2.login.error401.text'),
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageView>
      <Section>
        <GraphicWrapper>
          <picture>
            <source media={`(min-width: ${DIMEN_BREAKPOINT_LG}px)`} srcSet='/images/fansea_holo_desktop.png' />
            <img src={'/images/fansea_holo_mobile.png'} alt={'fansea holo logo'} />
          </picture>
        </GraphicWrapper>
        <ContentWrapper>
          <Headline>{getLocalizedString('app.v2.login.title')}</Headline>
          <StyledText content={getLocalizedString('app.v2.login.text')} />
          {errorState && <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />}
          <StyledForm onSubmit={submitHandler} form={form}>
            <StyledInput
              name={'email'}
              label={getLocalizedString('app.v2.login.label.email')}
              placeholder={getLocalizedString('app.v2.login.placeholder.email')}
              type={'email'}
              autoComplete={'username'}
              options={{ required: true, validate: isEmail }}
              error={{
                required: getLocalizedString('app.v2.login.validation.email.required'),
                validate: getLocalizedString('app.v2.login.validation.email.invalid'),
              }}
            />
            <StyledInput
              name={'password'}
              label={getLocalizedString('app.v2.login.label.password')}
              placeholder={getLocalizedString('app.v2.login.placeholder.password')}
              type={'password'}
              autoComplete={'current-password'}
              options={{ required: true }}
              error={{
                required: getLocalizedString('app.v2.login.validation.password.required'),
                validate: getLocalizedString('app.v2.login.validation.password.invalid'),
              }}
            />

            <PasswordResetLink onClick={() => setPasswordResetDialogOpen(true)}>
              {getLocalizedString('app.v2.login.forgot-password')}
            </PasswordResetLink>

            <SecondaryButton className='cy-login-button'>
              {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.login.login')}
            </SecondaryButton>

            {/* TODO: @ Barry do we need this in the future?
            <CreateAccountLink>
              {getLocalizedString('app.v2.login.not-a-member')}{' '}
              <Link to={'/registration'}>{getLocalizedString('app.v2.login.register-now')}</Link>
            </CreateAccountLink> */}
          </StyledForm>
          <RegisterButton
            className='cy-registration-button'
            onClick={() => navigate('/registration', { state: { ...location.state } })}>
            {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.login.register-now')}
          </RegisterButton>
          <ForgotPasswordModal
            open={passwordResetDialogOpen}
            onClose={() => {
              setPasswordResetDialogOpen(false);
            }}
          />
        </ContentWrapper>
      </Section>
    </PageView>
  );
};

export default Login;
