import styled, {css} from 'styled-components';
import { FC, useRef , useState} from 'react';
import { IAlbumContentItem, IAlbumDetails } from '../../../services/albums';
import { MAX_BREAKPOINT_SM} from '../../../styles/Breakpoints';
import { Headline6, Body3Bold } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';

const Wrapper = styled.div.attrs({ as: 'section' })`
  text-align: center;
`
const WrapperRings = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 49% 49%;
  gap: 8% 2%;
`

const RingCell = styled.div`
  text-align: center;
`

const RingImage = styled.img`
  width: 20vw;
`

const DoubleRingCell = styled(RingCell)`
  text-align: center;
  grid-column: 1 / span 2;
`

const Title = styled.span`
  font-size: 8vh;
  line-height: 1.125;
  text-align: center;
  font-family: var(--font-family-artsy);
`;

const PlayerName = styled.p`
  ${Headline6}
  text-align: center;
`

const RingDescription = styled.p`
  text-align: center;
`

const ButtonHover = css`
  transition: box-shadow 250ms ease-out;

  @media (hover: hover) {
    :hover {
      box-shadow: var(--box-shadow-hover);
    }
  }

  :focus-visible {
    box-shadow: var(--box-shadow-hover);
  }
`;

const AvailableAt = styled.h4`
  color: var(--color-primary);
`

const CTA = styled.a`
  text-align: center;
  appearance: none;
  padding: 0 1rem;
  border: 1px solid var(--color-text-default);
  border-radius: 2px;
  background: var(--color-grey-800);
  white-space: nowrap;
  line-height: 1;
  text-transform: uppercase;
  width: max-content;
  min-width: 15rem;
  height: 48px;

  ${Body3Bold};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ButtonHover};

  ${MAX_BREAKPOINT_SM} {
    width: max-content;
    min-width: 8rem;
    height: 48px;
  }

  :disabled {
    pointer-events: none;
    color: var(--color-grey-700);
    border-color: var(--color-grey-700);
    background: var(--color-grey-800);
  }

  transition: background 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out;

  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-highlight);

  :disabled {
    border-color: var(--color-grey-800);
  }
`

interface TextImageSectionProps extends IAlbumContentItem {
  album: IAlbumDetails;
  direction: 'right' | 'left';
  image: string;
  text: string;
  title: string;
}

export const ForeverGalaxyAlbumEpicSection: FC<TextImageSectionProps> = (props) => {
  const { title } = props;
  const getLocalizedString = useGetLocalizedString();

  return (
    <Wrapper>
      <br /><br />
      <Title>{title}</Title>
      <br /><br />
      <WrapperRings>
        <RingCell>
        <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842943/publishers/Frankfurt%20Galaxy/Albums/CommonAlbum/1000x1000_frontal_01.png" alt="Ring #77" />
          <PlayerName>KIEHL</PlayerName>
          <RingDescription>Ring #77<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring5.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-kiehl-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <RingCell>
        <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842558/publishers/Frankfurt%20Galaxy/53_Josiah/53_700x700_02.png" alt="Ring #19" />
          <PlayerName>STRAHMANN</PlayerName>
          <RingDescription>Ring #19<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring6.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-strahmann-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <DoubleRingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842722/publishers/Frankfurt%20Galaxy/99_Maier/99_detail_700x700_05.png" alt="Ring #14" />
          <PlayerName>POZNANSKI</PlayerName>
          <RingDescription>Ring #14<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring7.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-poznanski-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </DoubleRingCell>
      </WrapperRings>
    </Wrapper>
  );
};