import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const CrossedEyeIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.0431 1.5L2.42773 16.5'
      stroke='#ADADAD'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.91849 13.9083C3.58064 13.6771 3.25669 13.4272 2.94816 13.1601C1.97944 12.3214 1.16202 11.3127 0.54254 10.1799C0.15248 9.46667 0.152487 8.60447 0.542558 7.89122C2.36847 4.55251 5.91882 2.28564 9.99997 2.28564C10.9668 2.28564 11.905 2.41304 12.7981 2.65234C13.3338 2.79588 13.853 2.9796 14.3522 3.20004L13.2222 4.35975C12.9569 4.26158 12.6859 4.1752 12.4099 4.10123C11.6423 3.89555 10.8346 3.78564 9.99997 3.78564C6.4879 3.78564 3.43153 5.73483 1.8586 8.61095C1.7138 8.87573 1.7138 9.19543 1.8586 9.46021C2.38561 10.4239 3.07974 11.2842 3.9023 12.002L5.01305 12.7849L3.91849 13.9083ZM17.5236 5.34471C17.227 5.05554 16.7522 5.06154 16.463 5.35811C16.1738 5.65468 16.1798 6.12952 16.4764 6.41869C17.1347 7.06052 17.6974 7.79912 18.1414 8.61108C18.2862 8.87586 18.2862 9.19556 18.1414 9.46034C16.5685 12.3365 13.5121 14.2856 10 14.2856C9.20019 14.2856 8.42506 14.1847 7.68628 13.9953C7.28505 13.8924 6.87638 14.1343 6.7735 14.5355C6.67062 14.9367 6.91249 15.3454 7.31372 15.4483C8.17322 15.6687 9.07349 15.7856 10 15.7856C14.0812 15.7856 17.6315 13.5188 19.4574 10.1801C19.8475 9.46683 19.8475 8.60462 19.4575 7.89137C18.9412 6.94744 18.2876 6.08968 17.5236 5.34471Z'
      fill='currentColor'
    />
    <path
      d='M6.8457 9.03564C6.8457 7.37879 8.15441 6.03564 9.76878 6.03564'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export const EyeIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.20057 7.78442C0.933139 7.2954 0.933144 6.70445 1.20058 6.21544C2.9 3.10803 6.20336 1 9.99997 1C13.7966 1 17.1 3.10809 18.7994 6.21558C19.0669 6.7046 19.0669 7.29555 18.7994 7.78456C17.1 10.892 13.7966 13 10 13C6.20336 13 2.89997 10.8919 1.20057 7.78442Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <circle cx='10' cy='7' r='3' stroke='currentColor' strokeWidth='1.5' />
  </svg>
);
