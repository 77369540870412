import FormWrapper from '../../common/forms/FormWrapper';
import Modal from '../../common/general/Modal';
import { Input } from '../../common/forms/Input';
import { FieldValues, useForm } from 'react-hook-form';
import { FC, useMemo, useState } from 'react';
import { useProfileAPI } from '../../../services/profile';
import { useGetLocalizedString } from '../../../services/localization';
import { ErrorNotification, Indicator, Indicators } from '../registration/SignUp';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { SecondaryButton } from '../../../styles/Buttons';
import { useNavigate } from 'react-router-dom';
import { Body3 } from '../../../styles/FontStyles';

const StyledForm = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledIndicators = styled(Indicators)`
  ${Body3};
  margin-bottom: 1rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 3.875rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    flex-direction: row;
    gap: 2.75rem;
  }
`;


export const ChangePasswordForm: FC = (props) => {
  const form = useForm({ mode: 'onChange' });
  const Change = useProfileAPI();
  const getLocalizedString = useGetLocalizedString();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [errorState, setErrorState] = useState<{ headline: string, text: string } | null>(null);

  const [hasLowerCase, setHasLowerCase] = useState<boolean | undefined>();
  const [hasCapital, setHasCapital] = useState<boolean | undefined>();
  const [hasSymbol, setHasSymbol] = useState<boolean | undefined>();
  const [hasNumber, setHasNumber] = useState<boolean | undefined>();
  const [hasLength, setHasLength] = useState<boolean | undefined>();

  const validatePassword = (password: string) => {
    const hasLowerCase = /[a-z]/g.test(password);
    const hasCapital = /[A-Z]/g.test(password);
    const hasSymbol = /[^\w\d]/g.test(password);
    const hasNumber = /[0-9]/g.test(password);
    const hasLength = /.{8,}$/g.test(password);

    setHasLowerCase(hasLowerCase);
    setHasCapital(hasCapital);
    setHasSymbol(hasSymbol);
    setHasNumber(hasNumber);
    setHasLength(hasLength);

    return (
      hasLowerCase &&
      hasCapital &&
      hasSymbol &&
      hasNumber &&
      hasLength
    );
  };
  const passwordsAreTheSame = (confirmPassword: string, password: string) => confirmPassword === password;

  const handleSubmit = async (e: FieldValues) => {
    try {
      setLoading(true);
      const { oldPassword, newPassword } = e;
      await Change.changePassword({ oldPassword, newPassword });
      setLoading(false);
      setSuccess(true);
    } catch (err: any) {
      setErrorState({
        headline: getLocalizedString('app.v2.change-password.error-headline'),
        text: getLocalizedString('app.v2.change-password.error-text'),
      });
    } finally {
      setLoading(false);
    }
  };

  const isValid = useMemo(() => {
    return form.formState.isValid;
  }, [form.formState.isValid]);

  return (
    <>
      {
        errorState &&
        <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />
      }
      <StyledForm onSubmit={handleSubmit} form={form}>
        <Input
          name={'oldPassword'}
          type={'password'}
          label={getLocalizedString('app.v2.change-password.old-password-label')}
          placeholder={getLocalizedString('app.v2.change-password.old-password-label')}
          options={{
            required: true,
          }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.password.required'),
          }}
        />
        <Input
          name={'newPassword'}
          type={'password'}
          label={getLocalizedString('app.v2.change-password.new-password-label')}
          placeholder={getLocalizedString('app.v2.change-password.new-password-placeholder')}
          options={{
            required: true,
            validate: validatePassword,
            onChange: ({ target }) => {
              !target.value && validatePassword(target.value);
            },
          }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.password.required'),
            validate: getLocalizedString('app.v2.registration.validation.password.invalid'),
          }}
        />
        <Input
          name={'confirmPassword'}
          type={'password'}
          label={getLocalizedString('app.v2.registration.label.confirm-password')}
          placeholder={'Confirm password'}
          options={{
            required: true,
            validate: (confirmPassword) => passwordsAreTheSame(confirmPassword, form.getValues('newPassword')),
          }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.confirm-password.required'),
            validate: getLocalizedString('app.v2.registration.validation.confirm-password.invalid'),
          }}
          onPaste={(e) => e.preventDefault()}
        />
        <StyledIndicators>
          <Indicator
            value={hasLowerCase}>{getLocalizedString('app.v2.registration.password-criteria.lowercase')}</Indicator>
          <Indicator
            value={hasCapital}>{getLocalizedString('app.v2.registration.password-criteria.capital')}</Indicator>
          <Indicator
            value={hasSymbol}>{getLocalizedString('app.v2.registration.password-criteria.symbol')}</Indicator>
          <Indicator
            value={hasNumber}>{getLocalizedString('app.v2.registration.password-criteria.number')}</Indicator>
          <Indicator
            value={hasLength}>{getLocalizedString('app.v2.registration.password-criteria.length')}</Indicator>
        </StyledIndicators>

        <ButtonWrapper>
          <SecondaryButton type={'submit'} disabled={!isValid}>
            {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.change-password.secondary-button.title')}
          </SecondaryButton>
        </ButtonWrapper>
      </StyledForm>
      <Modal
        headline={getLocalizedString('app.v2.change-password.success-headline')}
        text={getLocalizedString('app.v2.change-password.success-text')}
        primaryButton={{
          title: getLocalizedString('app.v2.change-password.success-primary-button'),
          onClick: () => {
            setSuccess(false);
            navigate('/profile');
          },
        }}
        open={success}
        onClose={() => {
          setSuccess(false);
        }}
      />
    </>
  );
};
