export const DeliveryIcon = (props: any) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.668 16.1875V6.8125H18.6059C19.5287 6.8125 20.4002 7.23725 20.9688 7.96414L22.3643 9.7481C22.777 10.2758 23.0013 10.9265 23.0013 11.5965V15.1875C23.0013 15.7398 22.5536 16.1875 22.0013 16.1875H20.068'
      stroke='white' strokeWidth='1.5' />
    <path
      d='M3.75 10.5V14.1875C3.75 15.2921 4.64543 16.1875 5.75 16.1875H6.30357M3.75 7.75V6C3.75 4.89543 4.64543 4 5.75 4H13.6667C14.7712 4 15.6667 4.89543 15.6667 6V16.1875H9.70833'
      stroke='white' strokeWidth='1.5' />
    <ellipse cx='7.8737' cy='16.6562' rx='2.29167' ry='2.34375' stroke='#ADADAD' strokeWidth='1.5' />
    <path
      d='M20.2513 16.6562C20.2513 17.9507 19.2253 19 17.9596 19C16.694 19 15.668 17.9507 15.668 16.6562C15.668 15.3618 16.694 14.3125 17.9596 14.3125C19.2253 14.3125 20.2513 15.3618 20.2513 16.6562Z'
      stroke='#ADADAD' strokeWidth='1.5' />
    <path d='M5.58333 10.5625H1' stroke='#ADADAD' strokeWidth='1.5' strokeLinecap='round' />
    <path d='M7.41797 7.75H1.91797' stroke='#ADADAD' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);
