import styled from "styled-components";
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Headline4 } from '../../../styles/FontStyles';
import SnapchatCamera from './SnapchatCamera';

const Headline = styled.h2`
  ${Headline4};
`;

const Anchor = styled.div`
  scroll-margin-top: 30px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  padding: 0 var(--content-indent);
  
  ${BREAKPOINT_LG} {
    padding: 0;
  }
`;

const Body = styled.div`
  position: relative;
  height: 1000px;
  ${BREAKPOINT_LG}{
    height: 800px;
  }
`;

const SnapchatCameraContainer = ({
}) => {

    return (
        <>
            <Header>
                <Headline><Anchor id='snapchat'>💥 snapchat lenses</Anchor></Headline>
            </Header>
            <Body>
                <SnapchatCamera groupID='0b1261d6-0379-4dda-8aba-d6210c5aaac1'/>
            </Body>
        </>  
    )
}

export default SnapchatCameraContainer;