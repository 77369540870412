import styled from 'styled-components';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';

// Swiper
// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

// Styles
import 'swiper/css';

const Wrapper = styled.div`
  padding: 0 var(--content-indent);

  ${BREAKPOINT_LG} {
    padding: 0;
  }
`;

const PaginationWrapper = styled.div.attrs({ className: 'custom-pagination' })`
  margin-top: 1.5rem;
  display: flex;
  gap: 0.375rem;
  justify-content: center;
  cursor: pointer;

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
  }

  Dot {
    height: 0.375rem;
    width: 0.375rem;
    border-radius: 50%;
    cursor: pointer;
    filter: drop-shadow(0px 1px 9px var(--color-black));
  }

  // ${BREAKPOINT_MD} {
  //   display: none;
  // }
`;

interface ResponsivePublisherItemSliderProps {
  items?: Array<any>;
  children: (item: any) => ReactNode;
  className?: string;
}

const ResponsivePublisherItemSlider: FC<ResponsivePublisherItemSliderProps> = (props) => {
  const { items, children } = props;

  return (
    <Wrapper>
      <Swiper
        modules={[Pagination, A11y]}
        spaceBetween={50}
        slidesPerView={5}
        pagination={{
          clickable: true,
          el: '.custom-pagination',
          renderBullet: function (index, className) {
            return '<Dot class="' + className + '"></Dot>';
            // return '<Dot class="' + className + '"></Dot>';
          },
        }}>
        {items?.map((item, i) => (
          <SwiperSlide key={i}>{children(item)}</SwiperSlide>
        ))}
      </Swiper>
      <PaginationWrapper />
    </Wrapper>
  );
};

export default ResponsivePublisherItemSlider;
