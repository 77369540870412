import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const ArrowIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 17 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 5L16 5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M5 1L1.70711 4.29289C1.31658 4.68342 1.31658 5.31658 1.70711 5.70711L5 9'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);