import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useFavoriteCollectibles } from '../../../hooks/useFavoriteCollectibles';
import { ICollectible, IUserCollectible } from '../../../services/collectible';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS } from '../../../styles/Breakpoints';
import { Colors } from '../../../styles/Colors';
import { Body1Bold, Body2Bold, Body3 } from '../../../styles/FontStyles';
import { FavoriteIcon } from '../../icons/FavoriteIcon';
import { CollectibleImage } from '../collectibles/CollectibleImage';
import { UtilityImage } from '../collectibles/UtilityImage';
import { GlassCard } from '../collectibles/GlassCard';
import { LinkHandler } from '../general/LinkHandler';
import { Marquee } from '../general/Marquee';
import Tags from '../general/Tags';
import Sticker from './Sticker';
import BlockchainInfo from './BlockchainInfo';
import { Nft } from './WalletItems';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  align-self: stretch;
  min-width: 0; // safari fix

  display: flex;
  flex-direction: column;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  display: flex;
  flex-direction: column;

  padding-top: 3rem;

  ${BREAKPOINT_MD} {
    padding-top: 4.5rem;
  }
`;

const MarqueeOverlay = styled(Marquee)`
  position: absolute;
  left: 0;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  flex: 1;

  padding: 0 0.5rem 1.25rem;

  ${BREAKPOINT_LG} {
    padding: 0 1.5rem 2rem;
  }
`;

const ImageWrapperLink = styled(LinkHandler)`
  position: relative;
  padding-top: 100%;
  overflow: hidden;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
`;

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;

  ${BREAKPOINT_MD} {
    gap: 1rem;
  }
`;

const CreatorLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  height: 1.25rem;

  ${BREAKPOINT_SM} {
    gap: 1rem;
  }
`;

const ExtrasWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  gap: 0.375rem;

  ${BREAKPOINT_SM} {
    gap: 0.75rem;
  }
`;

const Creator = styled(LinkHandler)`
  flex: 1;
  min-width: min-content;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 0.75rem;
  }
`;

const CreatorName = styled.div<{ hideCreatorNameOnMobile?: boolean }>`
  display: ${({ hideCreatorNameOnMobile = false }) => (hideCreatorNameOnMobile ? 'none' : '-webkit-box')};
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  ${Body3};
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  ${BREAKPOINT_XS} {
    display: -webkit-box;
  }
`;

const CreatorIcon = styled.img`
  height: 1.375rem;
  width: 1.375rem;
  object-fit: contain;

  ${BREAKPOINT_MD} {
    height: 1.875rem;
    width: 1.875rem;
  }
`;

const FavoriteButton = styled.button.attrs({ type: 'button' })<{ active: boolean }>`
  grid-column: 3;
  justify-self: flex-end;
  align-self: center;
  --size: 1rem;
  z-index: 1;
  color: ${({ active }) => (active ? Colors.primary : Colors.white)};

  ${BREAKPOINT_MD} {
    --size: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled(LinkHandler)`
  ${Body2Bold};
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  height: 2.375rem;
  margin-bottom: auto;

  ${BREAKPOINT_SM} {
    ${Body1Bold};
    height: 3rem;
  }

  ${BREAKPOINT_LG} {
    height: 3.75rem;
  }
`;

const PriceAndTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const PriceInCurrencies = styled.div`
  font-size: 0.8rem;
`;

const Price = styled.p<{ highlight: boolean }>`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  align-self: flex-end;

  color: ${({ highlight }) => (highlight ? 'var(--color-primary)' : 'inherit')};
  font-family: var(--font-family-headline);

  ${BREAKPOINT_LG} {
    font-size: 2rem;
  }
`;

const StyledTags = styled(Tags)`
  align-self: flex-end;
  font-size: 0.75rem;
  flex: 1;

  ${BREAKPOINT_SM} {
    font-size: 0.875rem;
  }
`;

const CollectibleImageWrapper = styled(CollectibleImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface StyleProps {
  active?: boolean;
}

interface ICollectibleItemProps extends IUserCollectible, StyleProps {
  isProfile?: boolean;
  hideCreatorNameOnMobile?: boolean;
  className?: string;
  blockchainData?: Nft;
}

export const CollectibleItem: FC<ICollectibleItemProps> = (props) => {
  const {
    id,
    slug,
    active = false,
    price,
    blockchainData,
    priceInCurrencies,
    publisherId,
    purchasedNr,
    publisherLogoUrl,
    publisherName,
    publisherSlug,
    rarity,
    title,
    isProfile = false,
    isExclusive = false,
    isFreemint = false,
    isBonus = false,
    hideCreatorNameOnMobile = false,
    tags,
    status,
    className,
    sku,
    smartContractAddress,
    ...stickerProps
  } = props;

  const getLocalizedString = useGetLocalizedString();
  const { toggleFavorite } = useFavoriteCollectibles();

  const activeLocale = useActiveLocale();

  // SHOW ONLY OTHER CURRENCIES NOT EUR. For now this is the workaround.
  const pricesInCurrenciesWithoutEUR = priceInCurrencies?.filter((p) => p.currencyShortcode !== 'EUR');

  return (
    <Wrapper className={className}>
      <BackgroundWrapper>
        <MarqueeOverlay rarity={rarity} />
        <GlassCard rarity={rarity} rounded={true} />
      </BackgroundWrapper>

      <Inner>
        <ImageWrapperLink to={`/collectibles/${slug}`}>
          <CollectibleImageWrapper animated collectible={props as never as ICollectible} />
          <UtilityImage collectible={props as never as ICollectible} />
        </ImageWrapperLink>
        <Infos>
          <CreatorLineWrapper>
            <Creator to={`/collectibles/creators/${publisherSlug}`}>
              <CreatorIcon src={publisherLogoUrl} alt={publisherName} />
              <CreatorName hideCreatorNameOnMobile={hideCreatorNameOnMobile} title={publisherName}>
                {publisherName}
              </CreatorName>
            </Creator>
            <ExtrasWrapper>
              <Sticker {...{ ...stickerProps, status }} />
              {/* Damiano Giampaoli 17/05/2023 - Disabling favourites to make space for claimable token tab in profile */}
              {/* <FavoriteButton active={active} onClick={() => toggleFavorite(active, id)}>
                <FavoriteIcon active={active} />
              </FavoriteButton> */}
            </ExtrasWrapper>
          </CreatorLineWrapper>

          <Title to={`/collectibles/${slug}`}>{title}</Title>
          {!isProfile && (
            <>
              <PriceAndTagsWrapper>
                {!isFreemint &&
                  <Price highlight={price === 0}>
                    {isExclusive ? (
                      getLocalizedString('app.v2.general.is-exclusive')
                    ) : price === 0 ? (
                      getLocalizedString('app.v2.general.for-free')
                    ) : (
                      <>{price}€</>
                    )}
                  </Price>
                }
                {isFreemint &&
                  <Price highlight={true}>
                        {getLocalizedString('app.v2.general.freemint-price')}
                  </Price>
                }
                {tags && <StyledTags tags={tags} />}
              </PriceAndTagsWrapper>
              {priceInCurrencies?.length > 1 && (
                <PriceInCurrencies>
                  (
                  {pricesInCurrenciesWithoutEUR?.map((p, i) => {
                    const priceString = p.price.toLocaleString(activeLocale || undefined, {
                      currencyDisplay: 'code',
                      style: 'currency',
                      currency: p.currencyShortcode,
                      minimumFractionDigits: 0,
                    });

                    if (pricesInCurrenciesWithoutEUR.length === i + 1) {
                      return priceString;
                    } else {
                      return priceString + ' / ';
                    }
                  })}
                  )
                </PriceInCurrencies>
              )}
            </>
          )}
          {isProfile && (
            <>
              <BlockchainInfo sku={`${blockchainData?.tokenId}`} initialSupply={stickerProps.initialSupply} smartContractAddress={smartContractAddress}/>
            </>
          )}
        </Infos>
      </Inner>
    </Wrapper>
  );
};
