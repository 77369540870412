import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { IUserCollectible, useCollectiblesAPI } from '../../services/collectible';
import { CollectibleItem } from '../../components/common/special/CollectibleItem';
import { BasicGridWrapper } from '../../components/common/general/Grids';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, MAX_BREAKPOINT_SM } from '../../styles/Breakpoints';
import { LoadingAnimation } from '../../components/common/general/LoadingAnimation';
import { SecondaryButton } from '../../styles/Buttons';
import { CollectiblesFilter } from '../../components/common/collectibles/CollectiblesFilter';
import { useLocation } from 'react-router-dom';
import { PageView } from '../../components/layout/PageLayout';
import { useFavoriteCollectibles } from '../../hooks/useFavoriteCollectibles';
import { PageLoader } from '../../components/common/general/LoadingOverlay';
import { useDataCache } from '../../state/dataCache';
import { CreatorCollectiblesFilter, FilterType } from '../../components/common/collectibles/CreatorCollectiblesFilter';

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  gap: 1.5rem;

  ${BREAKPOINT_MD} {
    gap: 2.5rem;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  ${MAX_BREAKPOINT_SM} {
    flex-direction: column;
  }

  h4 {
    z-index: 3;
  }
`;

const FilterWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  font-size: 0.875rem;

  ${BREAKPOINT_SM} {
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

const StyledBasicGridWrapper = styled(BasicGridWrapper)`
  grid-gap: 1rem 0.5rem;

  ${BREAKPOINT_SM} {
    grid-gap: 1rem;
  }

  ${BREAKPOINT_LG} {
    grid-gap: 2rem;
  }
`;

const LoadMoreButton = styled(SecondaryButton)`
  align-self: center;
  display: flex;
  gap: 1rem;
`;

const ITEMS_PER_PAGE = 9;

export const Collectibles = () => {
  const getLocalizedString = useGetLocalizedString();
  const collectiblesAPI = useCollectiblesAPI();
  const { favoritesIds } = useFavoriteCollectibles();
  const activeLocale = useActiveLocale();

  const [limit, setLimit] = useState(ITEMS_PER_PAGE);
  const [dataCache, setDataCache] = useDataCache();
  const [collectibles, setCollectibles] = useState<IUserCollectible[] | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const { filter } = state || {};
  const [selectedFilter, setSelectedFilter] = useState<FilterType>(filter);
  const [selectedCreator, setSelectedCreator] = useState('');
  const [selectedCreatorTitle, setSelectedCreatorTitle] = useState('');

  const fetchFilteredCollectibles = (limit: number) => {
    switch (selectedFilter) {
      case 'latest':
        return collectiblesAPI.fetchLatestCollectibles(limit - ITEMS_PER_PAGE, ITEMS_PER_PAGE);
      case 'trending':
        return collectiblesAPI.fetchMostWantedCollectibles(limit - ITEMS_PER_PAGE, ITEMS_PER_PAGE);
      case 'upcoming':
        return collectiblesAPI.fetchUpcomingCollectibles(limit - ITEMS_PER_PAGE, ITEMS_PER_PAGE);
      case 'creator':
        return collectiblesAPI.collectiblesByPublisher(selectedCreator, limit - ITEMS_PER_PAGE, ITEMS_PER_PAGE);
      //case 'top':
      //  // TODO add endpoint
      //  setTotalCount(0);
      //  setCollectibles([]);
      //  return collectiblesAPI.fetchAllCollectibles(0, 0);
      default:
        return collectiblesAPI.fetchAllCollectibles(limit - ITEMS_PER_PAGE, ITEMS_PER_PAGE);
    }
  };

  const getDynamicHeadline = () => {
    switch (selectedFilter) {
      case 'latest':
        return getLocalizedString('app.v2.collectibles.filter.latest');
      case 'trending':
        return getLocalizedString('app.v2.collectibles.filter.trending');
      case 'upcoming':
        return getLocalizedString('app.v2.collectibles.filter.upcoming');
      case 'creator':
        return selectedCreatorTitle;
        // return getLocalizedString('app.v2.collectibles.filter.upcoming');
      // case 'top':
      //   return getLocalizedString('app.v2.collectibles.filter.top');
      default:
        return getLocalizedString('app.v2.collectibles.title');
    }
  };

  const updateCollectibles = (replace = false) => {
    const cachedCollectibles = (replace && (dataCache.allCollectibles || {})[filter || 'all']) || null;
    setLoading(!cachedCollectibles);
    if (cachedCollectibles) {
      setCollectibles(cachedCollectibles);
    }
    if (replace) {
      setLimit(ITEMS_PER_PAGE);
      setTotalCount(null);
    }
    fetchFilteredCollectibles(replace ? ITEMS_PER_PAGE : limit)
      .then((res) => {
        if (replace) {
          const collectibles = res.data?.collectibles || [];
          setCollectibles(collectibles);

          // cache collectibles
          setDataCache((state) => ({
            ...state,
            allCollectibles: {
              ...(state.allCollectibles || {}),
              [filter || 'all']: collectibles,
            },
          }));
        } else {
          setCollectibles((state) => [
            ...(state || []),
            ...(res.data?.collectibles || []).filter(({ id }) => !(state || []).some((item) => item.id === id)),
          ]);
        }
        setTotalCount(res.data?.totalRecords || null);
      })
      .catch((err) => {
        setTotalCount(0);
        setCollectibles([]);
        console.error('fetch collectibles error:', selectedFilter, err?.response?.status, err?.response?.data);
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    if (limit > ITEMS_PER_PAGE) {
      updateCollectibles();
    }
  }, [limit]);

  useEffect(() => {
    updateCollectibles(true);
  }, [selectedFilter, selectedCreator, activeLocale]);

  return (
    <PageView>
      <ContentWrapper>
        <PageLoader visible={loading} />

        <HeadWrapper>
          <h4>{getDynamicHeadline()}</h4>
          <FilterWrapper>
            {totalCount !== null && (
              <p>
                {totalCount}{' '}
                {totalCount === 1
                  ? getLocalizedString('app.v2.collectibles.result-count')
                  : getLocalizedString('app.v2.collectibles.results-count')}
              </p>
            )}
            <CreatorCollectiblesFilter
              selectedFilter={selectedFilter}
              setSelectedFilter={(value) => setSelectedFilter(value)}
              selectedCreator={selectedCreator}
              setSelectedCreator={setSelectedCreator}
              setSelectedCreatorTitle={setSelectedCreatorTitle}
            />
          </FilterWrapper>
        </HeadWrapper>

        {collectibles && (
          <StyledBasicGridWrapper gridColumns={'2'} gridColumnsMD={'3'} className='cy-collectibles'>
            {collectibles.map((collectible) => (
              <CollectibleItem
                className='cy-collectible-item'
                key={collectible.id}
                hideCreatorNameOnMobile
                active={favoritesIds?.collectibles?.includes(collectible.id) || false}
                {...collectible}
              />
            ))}
          </StyledBasicGridWrapper>
        )}

        {totalCount !== null && (limit < totalCount || loading) && (
          <LoadMoreButton onClick={() => setLimit(limit + ITEMS_PER_PAGE)}>Load more {loading && <LoadingAnimation />}</LoadMoreButton>
        )}
      </ContentWrapper>
    </PageView>
  );
};
