import { FC } from 'react';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';
import { MAX_BREAKPOINT_LG, MAX_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { SecondaryButton } from '../../../styles/Buttons';
import { LinkHandler } from '../general/LinkHandler';

const Wrapper = styled.div`
  grid-column: 1 / 4;

  margin: 1rem 0 2rem;
  padding: 2rem 2rem;

  background: url(.png),
    radial-gradient(
        90.16% 143.01% at 15.32% 21.04%,
        rgba(24, 95, 91, 0.2) 0%,
        rgba(110, 204, 244, 0.0447917) 77.08%,
        rgba(70, 213, 204, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(36.1905px);

  border-radius: 2px;
  border: 1px solid rgba(8, 232, 222, 0.1);
  box-shadow: 0px 1px 2px rgba(8, 232, 222, 0.1), 0px 0px 10px #08e8de;
  // border: 1px solid;
  // border-image-source: radial-gradient(
  //   70.42% 70.42% at 50% 50%,
  //   #0f0f0f 6.48%,
  //   rgba(8, 232, 222, 0.61) 53.65%,
  //   rgba(255, 255, 255, 0) 100%
  // );

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  padding: 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;

  ${MAX_BREAKPOINT_MD} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    padding: 0;
  }
`;

const ActionButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
  align-items: center;
  min-width: 12rem;

  ${MAX_BREAKPOINT_LG} {
    min-width: 11rem;
  }
  ${MAX_BREAKPOINT_MD} {
    min-width: 10rem;
    width: 100%;
    margin: 1rem;
  }
`;

interface ProfileWelcomeTeaserProps {}

export const ProfileWelcomeTeaser: FC<ProfileWelcomeTeaserProps> = (props) => {
  const t = useGetLocalizedString();

  return (
    <Wrapper>
      <h1>{t('app.v2.profile.welcome-teaser.headline')} 🎉</h1>
      <p>{t('app.v2.profile.welcome-teaser.text1')}</p>
      <ButtonWrapper>
        <ActionButton to={'/collectibles'}>{t('app.v2.profile.welcome-teaser.button1')}</ActionButton>
        <ActionButton to={'/albums'}>{t('app.v2.profile.welcome-teaser.button2')}</ActionButton>
        <ActionButton to={'/collectibles/creators'}>{t('app.v2.profile.welcome-teaser.button3')}</ActionButton>
      </ButtonWrapper>
      <p>{t('app.v2.profile.welcome-teaser.text2')}</p>
      <p>{t('app.v2.profile.welcome-teaser.text3')}</p>
    </Wrapper>
  );
};
