import { FC, useEffect, useState } from 'react';
import { CloudinaryQuality, useCloudinary } from '../../hooks/useCloudinary';

interface FanseaImageProps {
  transformationArgs?: string;
  imageUrl: string;
  altText?: string;
  isJpegConverted?: boolean;
  size?: string;
  quality?: CloudinaryQuality;
}

export const FanseaImage: FC<FanseaImageProps> = (props) => {
  const { transformationArgs, imageUrl, altText = '', isJpegConverted, size, quality } = props;

  const { generateImageUrl } = useCloudinary();
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string>('');

  useEffect(() => {
    const generateUrl = async () => {
      let myImageUrl = await generateImageUrl(imageUrl, transformationArgs, isJpegConverted, size, quality);
      setGeneratedImageUrl(myImageUrl);
    };

    generateUrl();
  }, [imageUrl]);

  return <img src={generatedImageUrl} alt={altText} />;
};
