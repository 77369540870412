import { FC, ReactNode } from 'react';
import { IPublishersListItem } from '../../../services/publisher';
import ItemListWrapper from '../general/ItemListWrapper';
import ResponsivePublisherItemSlider from '../general/ResponsivePublisherItemSlider';
import { PublisherItemMinimal } from '../PublisherItemMinimal';

interface ICreatorItemList {
  creators?: IPublishersListItem[];
  link?: ReactNode;
  headline?: string;
}

const CreatorItemList: FC<ICreatorItemList> = ({ creators, link, headline }) => {
  return (
    <ItemListWrapper headline={headline} link={link}>
      <ResponsivePublisherItemSlider items={creators}>{(creator) => <PublisherItemMinimal {...creator} />}</ResponsivePublisherItemSlider>
    </ItemListWrapper>
  );
};

export default CreatorItemList;
