import { AxiosResponse } from 'axios';
import { ISuccessResponse } from './auth';
import { useAPIInstance } from './index';

interface ICreatePasswordData {
  password: string;
  token: string;
}

interface IResetPasswordData {
  email: string;
}

interface ICreatePasswordResponse {
  success: boolean;
  token: string;
}

interface IResetPasswordResponse {
  success: boolean;
}

export interface IProfile {
  profile: {
    id: string;
    imageUrl: string;
    username: string;
    email: string;
    walletAddress: string;
  };
}

interface IProfilePathData {
  username: string;
  image: string;
}

interface IChangePasswordData {
  oldPassword: string;
  newPassword: string;
}

export interface IProfileAddressData {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  country: string;
  state: string;
}

export interface ICurrentShippingAddressResponse {
  address: IProfileAddressData;
}

export const useProfileAPI = () => {
  const apiInstance = useAPIInstance();

  const createPassword = (createPasswordData: ICreatePasswordData) => {
    return apiInstance.post<ICreatePasswordData, AxiosResponse<ICreatePasswordResponse>>('/profile/create-password', createPasswordData);
  };

  const resetPassword = (resetPasswordData: IResetPasswordData) => {
    return apiInstance.post<ISuccessResponse, AxiosResponse<IResetPasswordResponse>>('/profile/reset-password', resetPasswordData);
  };

  const profile = () => {
    return apiInstance.get<IProfile>('/profile');
  };

  const patchProfile = (profilePathData: FormData) => {
    return apiInstance.patch<IProfilePathData, AxiosResponse<ISuccessResponse>>('/profile', profilePathData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const changePassword = (changePasswordData: IChangePasswordData) => {
    return apiInstance.patch<IChangePasswordData, AxiosResponse<ISuccessResponse>>('/profile/change-password', changePasswordData);
  };

  const getCurrentShippingAddress = () => {
    return apiInstance.get<undefined, AxiosResponse<ICurrentShippingAddressResponse>>('/profile/current-shipping-address');
  };

  return {
    createPassword,
    resetPassword,
    profile,
    patchProfile,
    changePassword,
    getCurrentShippingAddress,
  };
};