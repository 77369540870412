import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_XS } from '../../../styles/Breakpoints';

const StyledSticker = styled.span`
  border-radius: 2px;
  font-size: 0.425rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding: 2px 2px 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  ${BREAKPOINT_XS} {
    font-size: 0.75rem;
  }

  ${BREAKPOINT_LG} {
    font-size: .875rem;
  }
`;

const SoldOutSticker = styled(StyledSticker)`
  background: var(--color-signal-red);
  border: 2px solid var(--color-signal-red);
`;

const DefaultSticker = styled(StyledSticker)`
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  padding: 2px 2px 1px;
  color: var(--color-black-900);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), conic-gradient(from 176.21deg at 50% 50%, rgba(0, 0, 0, 0) -24.76deg, #FFFFFF 0.25deg, rgba(0, 0, 0, 0) 50.63deg, rgba(0, 0, 0, 0) 50.66deg, #FFFFFF 88.12deg, rgba(0, 0, 0, 0) 142.5deg, #FFFFFF 196.87deg, rgba(0, 0, 0, 0) 256.87deg, #FFFFFF 300deg, rgba(0, 0, 0, 0) 335.2deg, rgba(0, 0, 0, 0) 335.24deg, #FFFFFF 360.25deg), conic-gradient(from 176.21deg at 50% 50%, #000000 -24.66deg, #FFFFFF 0.25deg, #000000 50.63deg, rgba(0, 0, 0, 0) 70.31deg, #FFFFFF 88.12deg, #000000 142.5deg, #FFFFFF 196.87deg, #000000 256.87deg, #FFFFFF 300deg, #000000 335.2deg, #000000 335.34deg, #FFFFFF 360.25deg), radial-gradient(95.11% 95.11% at 36.64% 4.89%, #AAFAF7 0%, #FFFFFF 100%);
  background-blend-mode: normal, screen, difference, normal;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 0px 8px #FFFFFF;
`;

interface IStickerProps {
  status: string;
  className?: string;
  publishAt: string;
  isCharityItem: boolean;
  soldOut: boolean;
  currentSupply: number;
  initialSupply: number;
}

const Sticker: FC<IStickerProps> = (props) => {
  const {
    status,
    className,
    publishAt,
    isCharityItem,
    soldOut,
    currentSupply,
    initialSupply,
  } = props;

  const t = useGetLocalizedString();

  const presale = useMemo(() => {
    return status === 'pre-sale';
  }, [status]);

  const isUpcoming = useMemo(() => {
    return status === 'not-buyable';
  }, [status]);

  const isNew = useMemo(() => {
    const nowUTC = new Date(new Date().toUTCString()).getTime();
    return ((nowUTC - new Date(publishAt).getTime()) / 604800000) <= 4;
  }, [publishAt]);

  const isTheNearlySoldOut = useMemo(() => {
    return !soldOut && ((currentSupply * 100 / initialSupply) <= 10);
  }, [soldOut, currentSupply, initialSupply]);

  const StickerWrapper = useMemo(() => {
    return soldOut ? SoldOutSticker : DefaultSticker;
  }, [soldOut]);

  return (
    (isUpcoming || presale || isNew || isTheNearlySoldOut || isTheNearlySoldOut || soldOut || isCharityItem) ? (
      <StickerWrapper className={className}>
        {(() => {
          if (isUpcoming) {
            return t('app.v2.sticker.upcoming');
          } else if (presale) {
            return t('app.v2.sticker.presale');
          } else if (soldOut) {
            return t('app.v2.sticker.sold-out');
          } else if (isTheNearlySoldOut) {
            return t('app.v2.sticker.nearly-sold-out');
          } else if (isCharityItem) {
            return t('app.v2.sticker.charity');
          } else if (isNew) {
            return t('app.v2.sticker.new');
          }
        })()}
      </StickerWrapper>
    ) : (
      <></>
    )
  );
};

export default Sticker;