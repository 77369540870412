import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNewsAPI } from '../../services/news';
import { INotification } from '../../services/news';

const TopBarWrapper = styled.div`
  background-color: var(--color-primary);
  color: black;
  text-align: center;
`;

const NotificationText = styled.p`
  padding: 0.5rem 0;
`;

export const NotificationTopBar = () => {
  const newApi = useNewsAPI();
  const [notification, setNotification] = useState<INotification | null>(null);

  const fetchNotifications = async () => {
    const notificationList = await newApi.notifications();
    const n = notificationList?.data?.notifications[0];

    setNotification(n || null);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  if (!notification) {
    return <></>;
  }

  return (
    <TopBarWrapper>
      <NotificationText>{notification?.translations[0].text}</NotificationText>
    </TopBarWrapper>
  );
};
