import { useCountdown } from "../../../hooks/useCountdown";
import { BREAKPOINT_SM } from '../../../styles/Breakpoints';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';

const CounterContainer = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  font-size: 7vw;
  line-height: 8vw;

  ${BREAKPOINT_SM} {
    font-size: 4vw;
    line-height: 5vw;
    gap: .2vw;
  }
`;

const CampaignDescription = styled.h2`
  text-align: center;
  position: relative;
`;

const FinalClaimDate = styled.h3`
  text-align: center;
  position: relative;
`;

const DateTimeBlock = styled.div`
  text-align: center;
  width: 16vw;
  overflow: hidden;

  ${BREAKPOINT_SM} {
    width: 12vw;
  }
`

export const Countdown = ({description, lastClaimMessage, targetDate} : any) => {
  const getLocalizedString = useGetLocalizedString();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <>
      <CampaignDescription>{description}</CampaignDescription>
      <FinalClaimDate>{lastClaimMessage}</FinalClaimDate>
      {/* <br />
      <CounterContainer>
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          daysLabel={getLocalizedString('app.v2.countdown.days')}
          hoursLabel={getLocalizedString('app.v2.countdown.hours')}
          minutesLabel={getLocalizedString('app.v2.countdown.minutes')}
          secondsLabel={getLocalizedString('app.v2.countdown.seconds')}
        />
      </CounterContainer> */}
    </>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, daysLabel, hoursLabel, minutesLabel, secondsLabel }: any) => {
  return (
      <>
        {days > 0 && <DateTimeDisplay value={days} type={daysLabel} />}
        <DateTimeDisplay value={hours} type={hoursLabel} />
        <DateTimeDisplay value={minutes} type={minutesLabel} />
        <DateTimeDisplay value={seconds} type={secondsLabel} />
      </>
  );
};

const DateTimeDisplay = ({ value, type }: any) => {
  return (
    <DateTimeBlock>
      <h1>{value < 10 ? `0${value}` : value}</h1>
      {type && <span>{type}</span>}
    </DateTimeBlock>
  );
};