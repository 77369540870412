import styled from 'styled-components';
import { useLocalizedString } from '../../../services/localization';
import { FC, useState } from 'react';
import { BREAKPOINT_LG } from '../../../styles/Breakpoints';
import { Input } from '../../common/forms/Input';
import FormWrapper from '../../common/forms/FormWrapper';
import { FieldValues, useForm } from 'react-hook-form';
import { SecondaryButton } from '../../../styles/Buttons';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { useVoucherAPI } from '../../../services/voucher';
import { PageViewHeadline } from '../../../styles/FontStyles';
import { useAddSystemNotification } from '../../../state/systemNotifications';

const Title = styled(PageViewHeadline)`
  display: none;
  text-transform: uppercase;
  line-height: 1;

  ${BREAKPOINT_LG} {
    display: initial;
    position: sticky;
    top: 50%;
    height: fit-content;
    transform: translateY(-50%);
  }
`;

const StyledForm = styled(FormWrapper)`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  ${BREAKPOINT_LG} {
    grid-column: 8 / -1;
    grid-row: 1;

    justify-content: center;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;

  h5 {
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: .5rem;
  }

  p {
    font-size: .875rem;
  }

  ${BREAKPOINT_LG} {
    margin-bottom: 3rem;
  }
`;

const InputWrapper = styled.div`
  width: 100%;

  label {
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: .5rem;
  }

  ${BREAKPOINT_LG} {
    margin-bottom: 4.5rem;
  }
`;

const RedeemButton = styled(SecondaryButton)`
  display: flex;
  gap: 1rem;
`;

interface RedeemViewProps {
  submit: () => void;
}

export const RedeemView: FC<RedeemViewProps> = ({ submit }) => {
    const title = useLocalizedString('app.v2.redeem-voucher.title');
    const headline = useLocalizedString('app.v2.redeem-voucher.headline');
    const text = useLocalizedString('app.v2.redeem-voucher.text');
    const inputLabel = useLocalizedString('app.v2.redeem-voucher.input.label');
    const inputErrorMessage = useLocalizedString('app.v2.redeem-voucher.input.error-message');
    const inputPlaceholder = useLocalizedString('app.v2.redeem-voucher.input.placeholder');
    const buttonLabel = useLocalizedString('app.v2.redeem-voucher.button.label');
    const errorMessage = useLocalizedString('app.v2.redeem-voucher.error-message');

    const { redeemVoucher } = useVoucherAPI();
    const [loading, setLoading] = useState<boolean>(false);
    const form = useForm({ mode: 'onChange' });
    const addNotification = useAddSystemNotification();

    const submitHandler = async (e: FieldValues) => {
      setLoading(true);
      try {
        await redeemVoucher({ voucher: e.voucherCode });
        submit();
      } catch (err: any) {
        console.error('redeem error:', err);
        form.setError('voucherCode', { type: 'validate' });
        addNotification({ type: 'error', headline: errorMessage });
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Title>{title}</Title>
        <StyledForm form={form} onSubmit={submitHandler}>
          <InfoWrapper>
            <h5>{headline}</h5>
            <p>{text}</p>
          </InfoWrapper>

          <InputWrapper>
            <Input
              name={'voucherCode'}
              type={'voucherCode'}
              options={{ required: true }}
              placeholder={inputPlaceholder}
              error={{ required: inputErrorMessage, validate: errorMessage }}
              required={true}
              label={inputLabel}
            />
          </InputWrapper>
          <RedeemButton disabled={!form.formState.isValid}>
            {buttonLabel} {loading && <LoadingAnimation />}
          </RedeemButton>
        </StyledForm>
      </>
    );
  }
;
