import { FC } from 'react';
import { OverlayIcon } from './OverlayIcon';

export const WalletIconOutline: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        d='M2.94336 5.89207L19.207 6.4209L18.1023 2.57648C17.9498 2.04567 17.3958 1.73902 16.865 1.89155L2.94336 5.89207Z'
        fill='currentColor'
      />
      <rect x='2' y='6.38477' width='20' height='16' rx='2' stroke='currentColor' strokeWidth='1.5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M6 17.8848H12H6Z' fill='currentColor' />
      <path d='M6 17.8848H12' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.5 14.3848H17.7' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
);

export const WalletIconFilled: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        d='M2.94336 5.89207L19.207 6.4209L18.1023 2.57648C17.9498 2.04567 17.3958 1.73902 16.865 1.89155L2.94336 5.89207Z'
        fill='currentColor'
      />
      <rect x='2' y='6.38477' width='20' height='16' rx='2' stroke='currentColor' strokeWidth='1.5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M6 17.8848H12H6Z' fill='currentColor' />
      <path d='M6 17.8848H12' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.5 14.3848H17.7' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
);

export const WalletIcon: FC<{ className?: string; active?: boolean }> = (props) => (
  <OverlayIcon {...props}>
    <WalletIconOutline />
    <WalletIconFilled />
  </OverlayIcon>
);
