import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';
import { BREAKPOINT_SM } from '../../../styles/Breakpoints';
import throttle from '../../../utils/throttle';

const TagWrapper = styled.div<{ align: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ align }) => align};
  gap: .25rem;

  overflow: hidden;
  max-height: 2.375rem;

  // prevent show being cut off
  padding: 0.5rem;
  margin: -0.5rem;
  
  color: var(--color-primary);
  font-size: .75rem;

  ${BREAKPOINT_SM} {
    font-size: .875rem;
  }
`;

export const TagInner = styled.ul<{ align: string }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  gap: 1rem .25rem;
  flex-wrap: wrap;
  
  width: 100%;
  max-height: 1.5rem;
`;

export const Tag = styled.span`
  padding: .25rem .375rem;
  border-radius: 5px;
  
  background: var(--color-grey-900);
  box-shadow: 0 0 5px var(--color-primary);
  
  font-size: .75rem;
  line-height: 1;
  font-weight: 700;
  white-space: nowrap;
  
  ${BREAKPOINT_SM} {
    font-size: .875rem;
  }
`;

const BonusTag = styled(Tag)`
  margin-right: -2.5rem;
`;

const Inclusive = styled.li`
  line-height: 1.375rem;
  overflow: hidden;
`;

interface ITagsProps {
  tags?: Array<string>;
  align?: 'flex-start' | 'flex-end';
  className?: string;
}

const Tags: FC<ITagsProps> = (props) => {
  const {
    tags,
    align = 'flex-end',
    className,
  } = props;

  const t = useGetLocalizedString();
  const wrapper = useRef<HTMLUListElement>(null);
  const [hiddenBonus, setHiddenBonus] = useState(0);
  const _tags = (tags && tags[0]) ? tags[0].split(',') : [];

  const checkHiddenBonus = useCallback(() => {
    const wrapperBounds = wrapper.current?.getBoundingClientRect();

    const hiddenBonusCount = Array(_tags.length).fill('').reduce((acc, _, i) => {
      const item = wrapper.current?.children[i + 1];

      if (item) {
        (item as HTMLElement).style.position = '';
        (item as HTMLElement).style.top = '';
        const childBounds = item?.getBoundingClientRect();

        if (
          wrapperBounds &&
          childBounds &&
          wrapperBounds.top + wrapperBounds.height < childBounds.top
        ) {
          (item as HTMLElement).style.position = 'absolute';
          (item as HTMLElement).style.top = '2.75rem';
          return acc + 1;
        }
      }
      return acc;
    }, 0);

    if (wrapper.current) {
      wrapper.current.style.paddingRight = hiddenBonusCount > 0 ? '3rem' : '';    // add additional padding for bonus tag
    }

    setHiddenBonus(hiddenBonusCount);
  }, [setHiddenBonus, _tags]);

  useEffect(() => {
    const listener = throttle(checkHiddenBonus, 50);
    const observer = new ResizeObserver(listener);
    if (wrapper.current) {
      observer.observe(wrapper.current);
      checkHiddenBonus();
    }
    return () => {
      observer.disconnect();
    }
  }, [checkHiddenBonus]);

  return (
    <TagWrapper className={className} align={align}>
      <TagInner ref={wrapper} align={align}>
        {(_tags.length > 0 && _tags[0] !== '') && <Inclusive>{t('app.v2.collectibles.inclusive-short')}</Inclusive>}
        {(_tags.length > 0 && _tags[0] !== '') && _tags.map((tag, i) => (
          <Tag as={'li'} key={i}>{tag}</Tag>
        ))}
        {
          hiddenBonus > 0 &&
          <BonusTag>
            +{hiddenBonus}
          </BonusTag>
        }
      </TagInner>
    </TagWrapper>
  );
};

export default Tags;