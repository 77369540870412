import { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';
import { MAX_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Tile } from './Tile';
import { Tile2Col } from './Tile2Col';

const Wrapper = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${MAX_BREAKPOINT_MD} {
    grid-template-columns: 1fr;
  }
`;

const LeftSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  grid-column: 1 / 1;
  z-index: 1;
  padding: 10px;
  aspect-ratio: 1/1;
`;

const RightSection = styled.div`
  padding: 10px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 0.9fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  
  ${MAX_BREAKPOINT_MD} {
    grid-row-gap: 2rem;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  filter: drop-shadow(0px 0px 10px #08e8de);

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

export const HeroTiles: FC<any> = (props) => {
  const video = 'https://res.cloudinary.com/fanseap/video/upload/v1682006795/1_Teaser/StageVideo_Final_MessiFix-2.mp4';

  // const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const t = useGetLocalizedString();

  // WORKAROUND FOR NOW. NO PLAY BUTTON OVERLAY. ISSUES WITH MOBILE vs DESKTOP and video html 5 controls
  return (
    <Wrapper>
      <LeftSection>
        {/* <PlayButton visible={!isPlaying}><PlayIcon /></PlayButton> */}
        <StyledVideo src={video} ref={videoRef} playsInline muted controls preload='auto' poster='/images/home/fansea_videoPoster.jpeg' />

      </LeftSection>
      <RightSection>
        <Tile link='/roadmap/3d-print' imageURL='/images/home/fansea_3DPrint_v2.jpg' text={t('app.v2.home.hero.tile.3D.headline')} />

        <Tile link='/albums' imageURL='https://res.cloudinary.com/fanseap/image/upload/v1682412426/publishers/Frankfurt%20Galaxy/Albums/CommonAlbum/galaxy_home_teaser.png' text={t('app.v2.home.hero.tile.collect.headline')} />

        <Tile2Col
          link='/collectibles/creators/deutsche-sporthilfe'
          imageURL='https://res.cloudinary.com/fanseap/image/upload/c_limit,h_1000,q_auto:best,w_1000/v1695909775/publishers/Deutsche%20Sporthilfe/fansea_app_sporthilfe.jpg'
          text={t('app.v2.home.hero.tile.sporthilfe.headline')}
        />

        {/* <TextTile text={'app.v2.home.hero.tile.cta.text'} /> */}
      </RightSection>
    </Wrapper>
  );
};
