import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const CloseIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.4375 12.5C5.73327 8.20423 8.14173 5.79577 12.4375 1.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M1.4375 1.5C5.73327 5.79577 8.14173 8.20423 12.4375 12.5'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);