import styled, { css } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XS } from './Breakpoints';
import { FontFamilies } from './Fonts';

export const PageViewHeadline = styled.h1`
  margin-bottom: .5rem;
  font-size: 1.25rem;

  ${BREAKPOINT_XS} {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  ${BREAKPOINT_MD} {
    margin-top: 2rem;
  }

  ${BREAKPOINT_LG} {
    font-size: clamp(4rem, 8vw, 9rem);
    line-height: calc(1em - 1rem);
    text-align: right;
    width: min-content;
    justify-self: flex-end;
    grid-column: 1 / 7;
  }
`;

export const PageHeadline1 = css`
  text-transform: uppercase;
  margin-bottom: .5rem;
  font-size: 1.25rem;
  grid-column: 1 / -1;

  ${BREAKPOINT_XS} {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    grid-column: 2 / -2;
  }

  ${BREAKPOINT_MD} {
    margin-top: 2rem;
    grid-column: 3 / -3;
  }

  ${BREAKPOINT_LG} {
    margin: 0;
    position: sticky;
    top: 50vh;
    height: fit-content;
    transform: translateY(-50%);
    grid-column: 1 / 7;
    font-size: 8.25rem;
    line-height: 7.25rem;
    width: min-content;
    justify-self: end;
    text-align: right;
  }

  ${BREAKPOINT_XL} {
    font-size: 10.75rem;
    line-height: 1;
  }
`;

export const Headline1 = css`
  font-size: 6.25rem;
  line-height: 5.625rem;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 10.75rem;
    line-height: 9.375rem;
  }
`;

export const Headline2 = css`
  font-size: 2rem;
  line-height: 2.375rem;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 9.375rem;
    line-height: 8.75rem;
  }
`;

export const Headline3 = css`
  font-size: 1.5rem;
  line-height: 1.813rem;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 3rem;
    line-height: 3rem;
  }
`;

export const Headline4 = css`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 2rem;
    line-height: 2.375rem;
  }
`;

export const Headline5 = css`
  font-size: 1rem;
  line-height: 1.188em;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

export const Headline6 = css`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;

  ${BREAKPOINT_LG} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

//Todo Body schriften anpassen und auch Bold und Regular achten
export const Body1 = css`
  font-size: 1rem;
  line-height: 1.5rem;

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
`;

export const Body1Bold = css`
  ${Body1};
  font-weight: 700;
`;

export const Body2 = css`
  font-family: ${FontFamilies.default};
  font-size: .875rem;
  line-height: 1.1875rem;

  ${BREAKPOINT_LG} {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

export const Body2Bold = css`
  ${Body2};
  font-weight: 700;
`;

export const Body3 = css`
  font-size: 0.75rem;
  line-height: 1.125rem;

  ${BREAKPOINT_LG} {
    font-size: .875rem;
    line-height: 1.1875rem;
  }
`;

export const Body3Bold = css`
  ${Body3};
  font-weight: 700;
`;

export const Body4 = css`
  font-size: 0.75rem;
  line-height: 1rem;

  ${BREAKPOINT_LG} {
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

export const Body4Bold = css`
  ${Body4};
  font-weight: 700;
`;

export const PageTitle = styled.h1`
  ${Headline3};
  text-transform: uppercase;
`;