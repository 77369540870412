import { AxiosInstance, AxiosResponse } from 'axios';
import { useAPIInstance } from './index';
import { useAuthState } from '../state/auth';
import { useWallet } from '../hooks/useWallet';
import { useSetDataCache } from '../state/dataCache';

export interface ISuccessResponse {
  success: boolean;
}

interface IRegisterUserData {
  username: string;
  email: string;
  password: string;
}

interface IRegisterFinalizeData {
  walletAddress: string;
}

interface ILoginData {
  email: string;
  password: string;
}

export interface ILoginSession {
  userId: string;
  email: string;
  token: string;
  refreshToken: string;
  hasAcceptedLatestTerms: boolean;
}

interface ILoginResponse extends ILoginSession {
  success: boolean;
}

interface IRegisterFinalizeResponse {
  success: boolean;
  userId: string;
}

export interface IUserAuthData {
  id: string;
  email: string;
  name: string;
  username: string;
  role: string;
  twoFactorEnabled: boolean;
  image: string;
  walletAddress: string;
  createdAt: string;
  updatedAt: string;
}

interface IMeResponse {
  success: boolean;
  value: IUserAuthData;
}

interface ITokenData {
  userId: string;
}

interface ITokenResponse {
  success: boolean;
  token?: string;
}

interface IRefreshTokenResponse extends ISuccessResponse {
  refreshToken: string;
}

export const refreshAuthToken = (apiInstance: AxiosInstance, tokenData: ITokenData, refreshToken: string) => {
  return apiInstance.post<ITokenData, AxiosResponse<ITokenResponse>>('/auth/token', tokenData, { headers: { 'refresh-token': refreshToken } });
};

export const checkRefreshToken = (apiInstance: AxiosInstance, tokenData: ITokenData, refreshToken: string) => {
  return apiInstance.get<ITokenData, AxiosResponse<IRefreshTokenResponse>>(`/auth/refresh-token?userId=${tokenData.userId}`, { headers: { 'refresh-token': refreshToken } });
};

export const refreshRefreshToken = (apiInstance: AxiosInstance, tokenData: ITokenData, refreshToken: string) => {
  return apiInstance.post<ITokenData, AxiosResponse<IRefreshTokenResponse>>('/auth/refresh-token', tokenData, { headers: { 'refresh-token': refreshToken } });
};

export const useAuthAPI = () => {
  const apiInstance = useAPIInstance();
  const { removeWallet } = useWallet();
  const [, setAuthState] = useAuthState();
  const setDataCache = useSetDataCache();



  const loginMiddleware: (res: AxiosResponse<ILoginResponse>) => AxiosResponse<ILoginResponse> = (res) => {
    if (!!res?.data?.success) {
      const {
        userId,
        email,
        token,
        refreshToken,
        hasAcceptedLatestTerms
      } = res.data;

      // set new auth state
      setAuthState({
        userId,
        email,
        token,
        refreshToken,
        hasAcceptedLatestTerms
      });
    }
    return res;
  };

  const registerUser = (registerData: IRegisterUserData) => {
    return apiInstance.post<IRegisterUserData, AxiosResponse<ILoginResponse>>('/auth/register/user', registerData)
      .then(loginMiddleware);
  };

  const registerFinalize = (registerData: IRegisterFinalizeData) => {
    return apiInstance.patch<IRegisterFinalizeData, AxiosResponse<IRegisterFinalizeResponse>>('/auth/register/finalize', registerData)
      .then((res) => {
        if (!!res?.data?.success) {
          const { userId } = res.data;

          // set auth state to trigger rerender (re-init /auth/me)
          setAuthState((state: any) => ({
            ...state,
            userId,
          }));
        }
        return res;
      });
  };

  const login = (loginData: ILoginData) => {
    return apiInstance.post<ILoginData, AxiosResponse<ILoginResponse>>('/auth/login', loginData)
      .then(loginMiddleware);
  };

  const checkUsername = (username: string) => {
    return apiInstance.post<{ username: string }>('/auth/check', { username });
  };

  const me = () => {
    return apiInstance.get<IMeResponse>('/auth/me');
  };

  const logout = () => {
    return apiInstance.get<ISuccessResponse>('/auth/logout')
      .then((res) => {
        if (!!res?.data?.success) {
          // reset auth state
          setAuthState(null);
        }
        // remove wallet from localstorage
        removeWallet();
        // reset data cache
        setDataCache((state) => ({
          ...state,
          profileData: null,
          userCollectiblesData: null,
          userAlbums: null,
          userAlbumDetails: {},
        }));
        return res;
      });
  };

  return {
    checkUsername,
    registerUser,
    registerFinalize,
    login,
    me,
    logout,
  };
};
