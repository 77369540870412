import styled, { css } from 'styled-components';
import { FC, InputHTMLAttributes, useMemo, useState } from 'react';
import { CrossedEyeIcon, EyeIcon } from '../../icons/EyeIcon';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { useFormContext } from 'react-hook-form';

const FormItemWrapper = styled.div``;

const InputWrapper = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;

  ${({ disabled }) => disabled && css`
    color: var(--color-grey-700);
  `}
`;

const InputLabel = styled.span`
  font-size: .75rem;
  line-height: 1;
  font-weight: bold;
`;

export const StyledInput = styled.input<{ error?: boolean, hasIcon?: boolean }>`
  padding: .75rem 1rem;
  border: 1px solid var(--color-text-default);
  border-radius: 2px;
  appearance: none;
  caret-color: var(--color-primary);
  font-size: 1rem;
  line-height: 1;
  background: rgba(26, 26, 26, 0.4);
  backdrop-filter: blur(.25rem);
  height: 3rem;

  :focus {
    border-color: var(--color-primary);
  }

  :disabled {
    border-color: var(--color-grey-700);
  }

  ${({ error }) => error && css`
    border-color: var(--color-signal-red);
  `}

  ${({ hasIcon }) => hasIcon && css`
    padding: .75rem 3rem .75rem 1rem;
  `}
`;

const VisibilityButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  bottom: 1.5rem;
  transform: translateY(50%);
  right: 1rem;
  line-height: 1;
`;

const Error = styled.p`
  color: var(--color-signal-red);
  margin-left: 1.25rem;
  font-size: .75rem;
  line-height: .875rem;
  margin-top: .5rem;
`;

const StyledEyeIcon = styled(EyeIcon)`
  font-size: .75rem;
`;

const StyledCrossEyeIcon = styled(CrossedEyeIcon)`
  font-size: .9375rem;
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: string | { [type: string]: string };
  options?: RegisterOptions;
}

export const Input: FC<InputProps> = (props) => {
  const { className, label, name, options, type, error, ...inputProps } = props;
  const { register, formState: { errors } } = useFormContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const currentError = useMemo(() => {
    const errorType = errors[name]?.type;
    if (!(error && errorType)) return;
    if (typeof error === 'string') {
      return error;
    } else {
      return error[errors[name]?.type as string];
    }
  }, [error, errors, name]);

  return (
    <FormItemWrapper>
      <InputWrapper className={className}>
        {label && <InputLabel>{label}</InputLabel>}
        <StyledInput
          {...inputProps}
          {...register(name, options)}
          hasIcon={type === 'password'}
          type={showPassword ? 'text' : type}
          error={!!currentError}
        />
        {type === 'password' && (
          <VisibilityButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <StyledCrossEyeIcon /> : <StyledEyeIcon />}
          </VisibilityButton>
        )}
      </InputWrapper>
      {currentError && (
        <Error>
          {currentError}
        </Error>
      )}
    </FormItemWrapper>
  );
};