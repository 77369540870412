import { IClaimableToken } from '../../../services/claimableToken';
import { FC } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;

  img, object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    pointer-events: none;
    user-select: none;
  }
`;

interface IClaimableTokenImageProps {
  className?: string;
  claimableToken: IClaimableToken;
  tokenURI?: string;
}

export const ClaimableTokenImage: FC<IClaimableTokenImageProps> = (props) => {
  const {
    className,
    claimableToken,
    tokenURI,
  } = props;

  return (
    <ImageWrapper className={className}>
      {
        // image
        tokenURI &&
        <img src={tokenURI} alt={claimableToken?.title || ''} />
      }
      {
        // fallback
        !tokenURI &&
        <object data={'/images/collectible-fallback.svg'} type={'image/svg+xml'} />
      }
    </ImageWrapper>
  );
};