import styled, { css, keyframes } from 'styled-components';
import { IAlbumDetails } from '../../../../services/albums';
import { FC, forwardRef, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { BREAKPOINT_LG } from '../../../../styles/Breakpoints';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import React from 'react';

const AppearAnim = keyframes`
  to {
    opacity: 1;
  }
`;

const CollectiblesTeaserWrapper = styled.div<{ position?: 'top' | 'bottom' }>`
  position: absolute;
  align-self: center;
  width: 150vw;

  ${({ position }) => position === 'top' && css`
    top: 0;
    --translateY: -30%;
  `};

  ${({ position }) => position === 'bottom' && css`
    bottom: 0;
    --translateY: 30%;
  `};

  height: auto;
  max-height: 55%;
  z-index: -1;

  transform: rotate(-10deg) translateY(var(--translateY, 0));

  ${BREAKPOINT_LG} {
    transform: rotate(-10deg) translateY(var(--translateY, 0));
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 200vw;
    background: linear-gradient(${({ position }) => position === 'top' ? 'to bottom' : 'to top'}, #1A1A1A 0%, rgba(26, 26, 26, 0.4) 95%, rgba(26, 26, 26, 0) 100%);
  }

  //will-change: top, bottom;

  // appear anim (fixes bug with page appear animation)
  opacity: 0;
  animation: ${AppearAnim} 2s ease-out .5s forwards;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ position }) => position === 'top' ? 'flex-end' : 'flex-start'};
`;

const MarqueeAnimation = keyframes`
  to {
    transform: translateX(-100%);
  }
`;

const CollectibleTeaserRow = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &:nth-child(odd) > div {
    animation-direction: reverse;
  }
`;

const CollectibleTeaserCol = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  animation: ${MarqueeAnimation} var(--anim-duration, 30s) linear infinite;
`;

const CollectibleTeaserImage = styled.div`
  --size: calc(50vh / 4);
  width: var(--size);
  height: var(--size);

  position: relative;
  flex-shrink: 0;
  margin: 0 1.5rem 1.5rem 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
  }
`;

interface ICollectiblesTeaserProps {
  className?: string;
  ref?: MutableRefObject<HTMLDivElement | null>;
  album: IAlbumDetails;

  position?: 'top' | 'bottom';
  rows?: number;
}

export const CollectiblesTeaserWall: FC<ICollectiblesTeaserProps> = forwardRef((props: ICollectiblesTeaserProps, ref?: any) => {
  const {
    className,
    album,
    position = 'top',
    rows = 4,
  } = props;

  const wrapperRef = useRef(null);
  const wrapper = ref || wrapperRef;

  const { width } = useWindowSize();

  const minItems = useMemo(() => {
    return Math.ceil((width || window.innerWidth) * 1.5 / 140);
  }, [width]);

  const albumItemMultiplier = useMemo(() => {
    return Math.ceil(minItems / (album?.coverCollectibleImages || []).length);
  }, [minItems, album]);

  useEffect(() => {
    const totalItemCountRow = albumItemMultiplier * (album?.coverCollectibleImages || []).length;
    wrapper.current?.style.setProperty('--anim-duration', `${4 * totalItemCountRow}s`);   // set animation duration
  }, [albumItemMultiplier]);

  return (
    <CollectiblesTeaserWrapper ref={wrapper} className={className} position={position}>
      {
        (album?.coverCollectibleImages || []).length > 0 &&
        Array(rows).fill('').map((_, index) => (
          <CollectibleTeaserRow key={index}>
            {
              // two cols for marquee effect
              Array(2).fill('').map((_, index) => (
                <CollectibleTeaserCol key={index}>
                  {
                    Array(albumItemMultiplier).fill('').map((_, index) => (
                      <React.Fragment key={index}>
                        {
                          (album?.coverCollectibleImages || []).map((imageUrl, index) => (
                            <CollectibleTeaserImage key={index}>
                              <img src={imageUrl} alt={''} />
                            </CollectibleTeaserImage>
                          ))
                        }
                      </React.Fragment>
                    ))
                  }
                </CollectibleTeaserCol>
              ))
            }
          </CollectibleTeaserRow>
        ))
      }
    </CollectiblesTeaserWrapper>
  );
}) as any;