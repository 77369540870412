import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const InfoIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='2.99609'
      y='2.5'
      width='18'
      height='19'
      rx='5'
      stroke='white'
      strokeWidth='1.5'
    />
    <path
      d='M12.4688 17.5L12.4688 11.5'
      stroke='#ADADAD'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.574 11.5L12.4688 11.5'
      stroke='#ADADAD'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <ellipse
      cx='12.2311'
      cy='7.75'
      rx='1.18421'
      ry='1.25'
      fill='#ADADAD'
    />
  </svg>
);