import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { INewsList } from '../services/news';
import { IPublishersList } from '../services/publisher';
import { ICollectibleList, IUserCollectible } from '../services/collectible';
import { IProfile } from '../services/profile';
import { IAlbum, IAlbumDetails, IUserAlbum, IUserAlbumDetails } from '../services/albums';
import { IClaimableToken } from 'src/services/claimableToken';
import { ITerms } from './terms';

const { persistAtom } = recoilPersist({
  key: 'data-cache',
  storage: localStorage,
});

interface IDataCache {
  // home
  news: INewsList | null;
  publishers: IPublishersList;
  collectiblesMostWanted: ICollectibleList | null;
  collectiblesUpcoming: ICollectibleList | null;
  collectiblesLatest: ICollectibleList | null;

  // collectibles
  allCollectibles: {
    [type: string]: IUserCollectible[] | null;
  };

  // albums
  allAlbums: IAlbum[] | IUserAlbum[] | null;
  albumDetails: {
    [id: string]: IAlbumDetails | null;
  };

  // claimableTokens
  allClaimableTokens: IClaimableToken[] | null;

  // Terms & Conditions
  termsData: ITerms | null;

  // profile
  profileData: IProfile | null;
  userCollectiblesData: IUserCollectible[] | null;
  userPresaleCollectiblesData: IUserCollectible[] | null;
  userAlbums: IUserAlbum[] | null;
  userAlbumDetails: {
    [id: string]: IUserAlbumDetails | null;
  };
}

const DataCacheState = atom<Partial<IDataCache>>({
  key: 'DataCacheState',
  default: {},
  effects_UNSTABLE: [
    persistAtom
  ],
});

export const useDataCache = () => useRecoilState(DataCacheState);
export const useSetDataCache = () => useSetRecoilState(DataCacheState);
