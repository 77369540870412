import { FC } from 'react';
import { ICollectible, IPriceInCurrency } from '../../../services/collectible';
import styled, { css, keyframes } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Colors } from '../../../styles/Colors';
import { Body1Bold, Body2, Body2Bold, Body3, Body4, Body4Bold } from '../../../styles/FontStyles';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';
import Tags from '../../common/general/Tags';
import { Marquee } from '../../common/general/Marquee';
import { LinkHandler } from '../../common/general/LinkHandler';
import { CollectibleImage } from '../../common/collectibles/CollectibleImage';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
`;

const ImageWrapper = styled(LinkHandler)`
  --size: 6.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;

  margin-bottom: 1.25rem;

  ${BREAKPOINT_MD} {
    --size: 7.5rem;
    margin-bottom: 2.5rem;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const GlassBackground = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 6.25rem;
  height: 6.25rem;

  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(24, 95, 91, 0.6) 0%,
    rgba(110, 204, 244, 0.134375) 77.08%,
    rgba(70, 213, 204, 0) 100%
  );
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(20px);

  border-radius: 2px;

  ${BREAKPOINT_MD} {
    width: 100%;
    height: calc(100% - 2.5rem);
  }
`;

const StyledMarquee = styled(Marquee)`
  transform: translateY(-50%);
`;

const ContentWrapper = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  ${BREAKPOINT_MD} {
    margin-top: 4rem;
    padding: 0 1rem 0.5rem 0;
  }
`;

const Title = styled(LinkHandler)`
  ${Body2Bold};
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-bottom: 0.5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1rem;
  }

  ${BREAKPOINT_LG} {
    font-size: 1rem;
  }
`;

const ContentBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0.75rem;
  margin-top: auto;
`;

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${BREAKPOINT_MD} {
    gap: 0.75rem;
  }
`;

const StyledTags = styled(Tags).attrs({ align: 'flex-start' })`
  //
`;

const CreatorWrapper = styled(LinkHandler)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;

  ${Body3};
  color: var(--color-grey-600);

  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
    pointer-events: none;
    user-select: none;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${BREAKPOINT_MD} {
    gap: 0.75rem;

    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

const PriceInCurrencies = styled.div`
  font-size: 0.8rem;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  width: min-content;
`;

const Price = styled.p<{ highlight: boolean }>`
  ${Body1Bold};

  ${({ highlight = false }) =>
    highlight &&
    css`
      color: ${Colors.primary};
    `};
`;

const TaxesIncludedHint = styled.span`
  ${Body4};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IReviewArticleViewProps {
  collectible: ICollectible;
  selectedCurrency?: IPriceInCurrency;
  discountedPrice?: number;
}

export const ReviewArticleView: FC<IReviewArticleViewProps> = (props) => {
  const { collectible } = props;

  const activeLocale = useActiveLocale();

  const getLocalizedString = useGetLocalizedString();

  const finalPrice = props.discountedPrice || (props?.selectedCurrency || collectible)?.price;

  return (
    <Wrapper>
      <GlassBackground>
        <StyledMarquee rarity={collectible.rarity} />
      </GlassBackground>
      <ImageWrapper target={'_blank'} to={`/collectibles/${collectible.id}`}>
        <CollectibleImage collectible={collectible} />
      </ImageWrapper>
      <ContentWrapper>
        <Title target={'_blank'} to={`/collectibles/${collectible.id}`}>
          {collectible.title}
        </Title>
        <ContentBottom>
          <InfoWrapper>
            {collectible.tags?.length > 0 && <StyledTags tags={collectible.tags} />}
            <CreatorWrapper target={'_blank'} to={`/collectibles/creators/${collectible.publisherId}`}>
              <img src={collectible.publisherLogoUrl} alt={collectible.publisherName} />
              <span>{collectible.publisherName}</span>
            </CreatorWrapper>
          </InfoWrapper>
          <PriceWrapper>
            <Price highlight={collectible.price === 0}>
              {collectible.price === 0
                ? getLocalizedString('app.v2.general.for-free')
                : `${finalPrice.toLocaleString(
                    activeLocale || undefined,
                    {
                      currencyDisplay: 'code',
                      style: 'currency',
                      currency: props.selectedCurrency?.currencyShortcode,
                      minimumFractionDigits: Number.isInteger(collectible.price) ? 0 : 2,
                      useGrouping: true,
                    },
                  )}`}
            </Price>
            {/* {collectible.priceInCurrencies && (
              <PriceInCurrencies>
                (
                {collectible.priceInCurrencies?.map((p, i) => {
                  if (collectible.priceInCurrencies.length === i + 1) {
                    return (
                      <>
                        {p.price.toLocaleString(undefined, {
                          style: 'currency',
                          currency: p.currencyShortcode,
                          minimumFractionDigits: 0,
                        })}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {collectible.price.toLocaleString(undefined, {
                          style: 'currency',
                          currency: p.currencyShortcode,
                          minimumFractionDigits: 0,
                        })}{' '}
                        /{' '}
                      </>
                    );
                  }
                })}
                )
              </PriceInCurrencies>
            )} */}
            <TaxesIncludedHint>{getLocalizedString('app.v2.checkout.review.article.taxes-included')}</TaxesIncludedHint>
          </PriceWrapper>
        </ContentBottom>
      </ContentWrapper>
    </Wrapper>
  );
};
