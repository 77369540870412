import { FC, MouseEventHandler, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useIsAuthenticated } from '../../services';
import { useActiveLocale, useAvailableLocales, useGetLocalizedString, useLocalizedString } from '../../services/localization';
import { ActiveLocaleState } from '../../state/activeLocale';
import { LanguageSwitchState } from '../../state/languageSwitch';
import { LogoutDialogOpenState } from '../../state/logout';
import { MenuOpenState } from '../../state/menu';
import { NotificationOpenState } from '../../state/notification';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, DIMEN_BREAKPOINT_MD, MAX_BREAKPOINT_MD } from '../../styles/Breakpoints';
import { PrimaryButton } from '../../styles/Buttons';
import { Colors } from '../../styles/Colors';
import { LinkHandler } from '../common/general/LinkHandler';
import { ArrowIcon } from '../icons/ArrowIcon';
import { ChevronIcon } from '../icons/ChevronIcon';
import { DiscordIcon } from '../icons/socialMedia/DiscordIcon';
import { InstagramIcon } from '../icons/socialMedia/InstagramIcon';
import { LinkedInIcon } from '../icons/socialMedia/LinkedInIcon';
import { TwitterIcon } from '../icons/socialMedia/TwitterIcon';
import { HeaderButton, HeaderButtons } from './Header';
import { useAuthAPI } from '../../services/auth';

export const MenuWrapper = styled.nav<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  pointer-events: all;

  display: flex;
  flex-direction: column;
  padding: var(--nav-height) var(--content-indent) 0;

  && {
    grid-column: 1 / -1;
  }

  transition: opacity 450ms ease-out, transform 450ms ease-out, visibility 450ms ease-out;
  opacity: ${({ open }) => open ? 1 : .4};
  transform: translateX(${({ open }) => open ? '0' : '-100%'});
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  overflow: ${({ open }) => open ? 'hidden' : 'auto'};



  ${BREAKPOINT_MD} {
    position: fixed;
    z-index: 12;
    top: 0;
    right: 0;
    left: initial;
    width: 26rem;
    height: 100%;
    padding: 1.5rem var(--content-indent) 0;
    transform: translateX(${({ open }) => (open ? '0' : '100%')});
    background: linear-gradient(167.66deg, rgba(24, 95, 91, 0.95) 0%, rgba(26, 26, 26, 0.7) 99.31%);
    backdrop-filter: blur(1rem);
  }

  ${BREAKPOINT_XL} {
    width: 32rem;
    padding: 1.5rem var(--nav-padding) 2.5rem 3.375rem;
  }
`;

const OverflowWrapper = styled.div<{ open: boolean }>`
  display: grid;
  height: 100%;
  overflow-x: hidden;
  margin-right: calc(-1 * var(--nav-padding));
  padding-right: var(--nav-padding);
  padding-bottom: 2.5rem;

  > * {
    grid-row: 1;
    grid-column: 1;
    transition: transform 450ms ease;
  }

  > :first-child {
    transform: translateX(${({ open }) => (open ? 'calc(-1 * (100% + var(--nav-padding)))' : 0)});
  }

  > :last-child {
    transform: translateX(${({ open }) => (open ? 0 : 'calc(100% + var(--nav-padding))')});
  }

  ${BREAKPOINT_MD} {
    padding-top: 4.125rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${BREAKPOINT_MD} {
    justify-content: space-between;
    gap: 3rem;
  }
`;

const LanguageSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationWrapper = styled.div`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
`;

export const BackButton = styled(HeaderButton)<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: opacity 450ms ease;
`;

const Headline = styled.h3`
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 2.25rem;
  }
`;

const MenuItemStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: min-content;
  width: 100%;
  white-space: nowrap;

  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  transition: color 250ms ease-out, border-color 250ms ease-out;

  @media (hover: hover) {
    :hover {
      color: ${Colors.primary};
      border-color: rgba(var(--color-primary-rgb), 0.67);
    }
  }

  :focus-visible {
    color: ${Colors.primary};
    border-color: rgba(var(--color-primary-rgb), 0.67);
  }

  svg {
    font-size: 1.5rem;
  }
`;

const MenuItem = styled(LinkHandler)`
  ${MenuItemStyles};
`;

const LanguageSwitchButton = styled.button<{ selected?: boolean }>`
  ${MenuItemStyles};

  ${({ selected = false }) =>
    selected &&
    css`
      font-weight: bold;
      pointer-events: none;

      @media (hover: hover) {
        :hover {
          color: inherit;
          border-color: inherit;
        }
      }

      :focus {
        color: inherit;
        border-color: inherit;
      }
    `};
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  ${BREAKPOINT_MD} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 1.25rem;
  margin: 0 1rem;
  justify-content: space-between;

  ${BREAKPOINT_MD} {
    margin: 0;
    align-items: flex-start;
    gap: 3rem;
  }
`;

const LegalTexts = styled.div`
  ${BREAKPOINT_MD} {
    display: flex;
    gap: 1rem 3rem;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

const LegalTextMenuItem = styled(MenuItem)`
  width: 100%;

  ${BREAKPOINT_MD} {
    border: none;
    width: min-content;

    padding: 0;

    svg {
      display: none;
      visibility: hidden;
    }
  }
`;

const SocialMediaLink = styled(LinkHandler)`
  @media (hover: hover) {
    :hover {
      text-decoration: underline;
      color: var(--color-primary);
    }
  }
`;

const ButtonWrapper = styled.div`
  align-self: center;
`;

const LogoutButton = styled(PrimaryButton)`
  align-self: center;
  margin-top: 0rem;

  ${MAX_BREAKPOINT_MD} {
    margin: 2rem 0;
  }
`;

const LoginButton = styled(LogoutButton).attrs({ as: LinkHandler })<{ to: string }>``;

interface MenuItemProps {
  title: string;
  to: string;
  onClick?: MouseEventHandler;
}

const LEGAL_PAGES: MenuItemProps[] = [
  {
    title: 'app.v2.menu.link.privacy-policy',
    to: 'https://fansea.io/privacy-policy',
  },
  {
    title: 'app.v2.menu.link.imprint',
    to: 'https://fansea.io/imprint',
  },
];

export const SOCIAL_MEDIA_LINKS = {
  discord: 'https://discord.com/invite/f8PB4kCJcS',
  instagram: 'https://www.instagram.com/fansea',
  linkedIn: 'https://de.linkedin.com/company/fansea-io',
  twitter: 'https://twitter.com/fansea_io',
};

export const Menu: FC = () => {
  const menuService = useLocalizedString('app.v2.menu.service');
  const menuLogout = useLocalizedString('app.v2.menu.logout');
  const menuLogin = useLocalizedString('app.v2.menu.login');
  const languageLabel = useLocalizedString('app.v2.menu.language');
  const getLocalizedString = useGetLocalizedString();
  const activeLocale = useActiveLocale();
  const availableLocales = useAvailableLocales();
  const setActiveLocaleState = useSetRecoilState(ActiveLocaleState);
  const [languageSwitchOpen, setLanguageSwitchOpen] = useRecoilState(LanguageSwitchState);

  const [menuOpen, setMenuOpen] = useRecoilState(MenuOpenState);
  const setNotificationOpen = useSetRecoilState(NotificationOpenState);
  const setLogoutDialogOpen = useSetRecoilState(LogoutDialogOpenState);
  const location = useLocation();

  const authAPI = useAuthAPI();
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  // add menu items here
  const MENU_ITEMS: MenuItemProps[] = ([
    {
      title: 'app.v2.menu.link.3d-print',
      to: '/roadmap/3d-print',
    },
    {
      title: 'app.v2.menu.link.about',
      to: 'https://fansea.io/about',
    },
    {
      title: 'app.v2.menu.link.contact',
      to: 'https://fansea.io/contact',
    },
    isAuthenticated &&
    {
      title: 'app.v2.menu.link.change-password',
      to: '/profile/change-password',
    },
  ] as MenuItemProps[]).filter(Boolean) as MenuItemProps[];

  useEffect(() => {
    setMenuOpen(false);
  }, [location, setMenuOpen]);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useMediaQuery(DIMEN_BREAKPOINT_MD);

  useEffect(() => {
    const mainTag = document.querySelector('main');
    if (mainTag) {
      if (menuOpen && !isDesktop) {
        mainTag.style.height = `${menuRef.current?.scrollHeight}px`;
        mainTag.style.overflow = 'hidden';
      } else {
        mainTag.style.height = '';
        mainTag.style.overflow = '';
      }
    }

    document.body.classList.toggle('menu-open', menuOpen);
    if (menuOpen) {
      setNotificationOpen(false);
    }
  }, [menuOpen, isDesktop]);

  const changeLocale = (locale: string) => {
    setActiveLocaleState(locale);
    setMenuOpen(false);
    setTimeout(() => setLanguageSwitchOpen(false), 450);
  };

  const logout = useCallback(
    (redirectToHome = true) => {
      // TODO add loading spinner and error messages
      authAPI.logout().then(() => {
        if (redirectToHome) {
          navigate('/');
        }
      });
    },
    [authAPI, navigate],
  );

  return (
    <MenuWrapper open={menuOpen} ref={menuRef}>
      <NavigationWrapper>
        <BackButton onClick={() => setLanguageSwitchOpen(false)} visible={languageSwitchOpen}>
          <ArrowIcon />
        </BackButton>
        <HeaderButtons />
      </NavigationWrapper>

      <OverflowWrapper open={languageSwitchOpen}>
        <ContentWrapper>
          <div>
            {availableLocales.length > 1 && (
              <LanguageSwitchButton onClick={() => setLanguageSwitchOpen(true)}>
                {languageLabel} <ChevronIcon />
              </LanguageSwitchButton>
            )}
            {MENU_ITEMS.map((menuItem, index) => (
              <MenuItem key={index} to={menuItem.to} onClick={menuItem.onClick}>
                {getLocalizedString(menuItem.title)}
                <ChevronIcon />
              </MenuItem>
            ))}
          </div>
          {
            isAuthenticated &&
            <LogoutButton onClick={() => logout()}>{menuLogout}</LogoutButton>
          }
          {
            !isAuthenticated && location.pathname !== '/login' &&
            <LoginButton to={'/login'}>{menuLogin}</LoginButton>
          }
          <FooterWrapper>
            <LegalTexts>
              {LEGAL_PAGES.map((menuItem, index) => (
                <LegalTextMenuItem key={index} to={menuItem.to}>
                  {getLocalizedString(menuItem.title)}
                  <ChevronIcon />
                </LegalTextMenuItem>
              ))}
            </LegalTexts>
            <IconWrapper>
              <SocialMediaLink to={SOCIAL_MEDIA_LINKS.discord}>
                {' '}
                <DiscordIcon />
              </SocialMediaLink>
              <SocialMediaLink to={SOCIAL_MEDIA_LINKS.instagram}>
                {' '}
                <InstagramIcon />
              </SocialMediaLink>
              <SocialMediaLink to={SOCIAL_MEDIA_LINKS.linkedIn}>
                {' '}
                <LinkedInIcon />
              </SocialMediaLink>
              <SocialMediaLink to={SOCIAL_MEDIA_LINKS.twitter}>
                {' '}
                <TwitterIcon />
              </SocialMediaLink>
            </IconWrapper>
          </FooterWrapper>
        </ContentWrapper>

        <LanguageSwitchWrapper>
          <Headline>{languageLabel}</Headline>
          {availableLocales.map((locale, index) => (
            <LanguageSwitchButton
              key={index}
              onClick={() => locale !== activeLocale && changeLocale(locale)}
              selected={locale === activeLocale}>
              {getLocalizedString(`app.v2.localization.${locale}`)}
            </LanguageSwitchButton>
          ))}
        </LanguageSwitchWrapper>
      </OverflowWrapper>
    </MenuWrapper>
  );
};
