import styled from 'styled-components';
import { IAlbumContentItem, IAlbumDetails } from '../../../../services/albums';
import { FC, useEffect, useRef } from 'react';
import { CollectiblesTeaserWall } from './CollectiblesTeaserWall';
import { SecondaryButton } from '../../../../styles/Buttons';
import { LinkHandler } from '../../general/LinkHandler';
import { useGetLocalizedString } from '../../../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { ArrowDownIcon } from '../../../icons/ArrowDownIcon';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
  
  transition: none;
`;

const CollectionButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
  align-self: center;
  margin-bottom: 3rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 10vh;
  }
`;

const Title = styled.h2`
  text-align: center;
  max-width: 30rem;

  margin-bottom: 2rem;
  font-size: 2rem;
  line-height: 1;

  ${BREAKPOINT_LG} {
    font-size: 3rem;
    margin-bottom: 4.5rem;
  }

  transform: translateY(var(--translate-y));
  opacity: var(--animated-opacity);
  transition: inherit;
  
  pointer-events: none;
  user-select: none;
`;

const SvgWrapper = styled.div`
  align-self: center;
  margin-bottom: 20vh;

  transform: translateY(var(--translate-y));
  opacity: var(--animated-opacity);
  transition: inherit;

  pointer-events: none;
  user-select: none;

  svg {
    height: 2rem;

    ${BREAKPOINT_MD} {
      height: 3rem;
    }
  }

  ${BREAKPOINT_LG} {
    margin-bottom: 15vh;
  }
`;

interface IFooterProps extends IAlbumContentItem {
  title: string;
  album: IAlbumDetails;
}

export const Footer: FC<IFooterProps> = (props) => {
  const { type, title, album } = props;
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const sectionHeight = sectionRef.current.scrollHeight;

        const useCSSTransition = true;

        const sectionCenter = top + ((bottom - top) / 2);
        const scrollDelta = Math.max(0, Math.min(2, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2)))) / 2;

        let translateX = 0;
        let translateY = 0;
        let opacity = 0;

        if (bottom < sectionHeight + screenHeight && bottom > 0) {
          const progress = Math.min(Math.max((100 + (screenHeight - bottom) / (sectionHeight / 100)), 0), 100);
          opacity = Math.max(0, (progress - 50) * 2);
          translateX = 100 - progress;
          translateY = -(150 - progress * 1.5);
        }

        if (useCSSTransition) {
          if (scrollDelta > .15) {
            translateX = 0;
            translateY = 0;
            opacity = 100;
          } else {
            translateX = 100;
            translateY = -150;
            opacity = 0;
          }
        }

        sectionRef.current.style.transition = useCSSTransition ? 'transform .45s ease-out, opacity .45s ease-out' : 'none';
        sectionRef.current.style.setProperty('--translate-x', `${translateX}vw`);
        sectionRef.current.style.setProperty('--translate-y', `${translateY}vh`);
        sectionRef.current.style.setProperty('--animated-opacity', `${opacity}`);
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    scrollHandler();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const getLocalizedString = useGetLocalizedString();

  return (
    <Wrapper data-type={type} ref={sectionRef}>
      <Title>{title}</Title>
      <SvgWrapper><ArrowDownIcon /></SvgWrapper>
      <CollectionButton to={`/albums/${album.id}/collectibles`}>
        {getLocalizedString('app.v2.album-details.button.my-collection')}
      </CollectionButton>
      <CollectiblesTeaserWall
        position={'bottom'}
        album={album}
      />
    </Wrapper>
  );
};