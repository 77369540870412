import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const LinkedInIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 18 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.13018 5.66981H4.49407V16.9988H1.13018V5.66981V5.66981ZM2.81181 0.0385742C3.88712 0.0385742 4.76033 0.953594 4.76033 2.08092C4.76033 3.20824 3.88648 4.12192 2.81181 4.12192C1.73713 4.12192 0.863281 3.2069 0.863281 2.08092C0.863281 0.954937 1.73457 0.0385742 2.81181 0.0385742Z'
      fill='currentColor'
    />
    <path
      d='M6.60352 5.6698H9.82881V7.21904H9.87501C10.3241 6.3282 11.4213 5.38965 13.0567 5.38965C16.4616 5.38965 17.0904 7.735 17.0904 10.7858V16.9995H13.7291V11.4905C13.7291 10.1764 13.7073 8.48677 11.982 8.48677C10.2568 8.48677 9.96483 9.9191 9.96483 11.3964V17.0001H6.60352V5.67115V5.6698Z'
      fill='currentColor'
    />
  </svg>

);