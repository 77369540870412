// General Imports
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode"
import styled from "styled-components";
import QRCode from "../../icons/QRCode";
import CameraIcon from "../../icons/CameraIcon";
import { Button } from "./WalletBackup";
import { useGetLocalizedString } from "../../../services/localization";
import { Html5QrcodeCameraScanConfig } from "html5-qrcode/esm/html5-qrcode";

// Styles
const FileInput = styled.input`
  display: none;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 1rem;
`;

const ScannerWrapperOverlay = styled.div<{ scanType: TScanType }>`
  ${({ scanType }) => scanType === 'file' ? (`
    display: none;
  `) : (`
    display: block;
    position: fixed !important;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .3);
  `)}
`;

const ScannerWrapper = styled.div`
  canvas, video {
    width: min(500px, 80vmin) !important;
    height: min(500px, 80vmin) !important;
  }
`;

const ScannerButton = styled(Button)`
  width: 100%;
`;

const StyledQRIcon = styled(QRCode)`
  font-size: 1rem;
`;

const StyledCameraIcon = styled(CameraIcon)`
  font-size: 1.25rem;
`;

type TScanType = 'file' | 'camera' 

interface IQRCodeScannerProps {
  onScanSuccess: (value: string) => void;
  onScanFailure?: (err: string) => void;
  
}

const QRCodeScanner: FC<IQRCodeScannerProps> = ({
  onScanSuccess,
  onScanFailure = () => {},

}) => {
  const getLocalizedString = useGetLocalizedString();
  const scanner = useRef<Html5Qrcode | undefined>();
  const upload = useRef<HTMLInputElement | null>(null);
  const [scanType, setScanType] = useState<TScanType>('file');

  /**
   * Fetch the media input devices of the client
   * @returns Primary media input device id
   */
  const getPrimaryCamera = async () => {
    try {
      const devices = await Html5Qrcode.getCameras();
      return devices?.[0]?.id;
    } catch (err) {
      console.error(`Error getting cameras. Reason: ${err}`);
    }
  }

  /**
   * Stop scanner
   */
  const stopScanner = () => {
    try {
      return scanner.current?.stop();
    } catch (err) {
      console.error(`Error failed to stop scanning. Reason: ${err}`);
    }
  }

  const closeScanner = async () => {
    await stopScanner();
    setScanType('file');
  }

  /**
   * Function being called on succesful scan that triggers the
   * callback provided via props and closes the scanning process
   */
  const onScan = useCallback(async (value: string) => {
    await closeScanner();
    onScanSuccess(value);
  }, [scanner, onScanSuccess]);    // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Camera scan function that checks wether the user has a backcamera,
   * if not automatically opens the primary media input device to scan
   * the qr code
   */
  const scan = async () => {
    const config: Html5QrcodeCameraScanConfig = {  fps: 10, qrbox: { height: 200, width: 200 }, aspectRatio: 1  };
    
    try {
      scanner.current?.start({ facingMode: "environment" }, config, onScan, onScanFailure);
    } catch {
      try {
        const cameraId = await getPrimaryCamera();
        if (!cameraId) return;
        scanner.current?.start(cameraId, config, onScan, onScanFailure);
      } catch (err) {
        console.error(`Error staring to scan. Reason: ${err}`);
      }
    } finally {
      setScanType('camera');
    }
  }

  /**
   * Scan QR code from file uploaded
   * @param e File input event
   */
  const scanFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;
    
    try {
      setScanType('file');
      const imageFile = files[0];
      const content = await scanner.current?.scanFile(imageFile, true);
      if (!content) throw Error('Error reading QR Code content');
      onScanSuccess(content);
    } catch (err) {
      console.log(`Error scanning file. Reason: ${err}`);
      onScanFailure(err as unknown as string);
    }
  }

  const selectFile = () => {
    upload.current?.click();
  }

  useEffect(() => {
    scanner.current = new Html5Qrcode('scanner');
  }, []);

  return (
    <Wrapper>
      <ScannerWrapperOverlay scanType={scanType} onClick={closeScanner}>
        <ScannerWrapper id="scanner" />
      </ScannerWrapperOverlay>
      <ScannerButton onClick={selectFile}>
        <StyledQRIcon />
        {getLocalizedString('app.v2.wallet-import.import-from-file')}
      </ScannerButton>
      <FileInput ref={upload} type="file" accept="image/*" onChange={scanFile} aria-hidden="true" />
      <ScannerButton onClick={scan}>
        <StyledCameraIcon />
        {getLocalizedString('app.v2.wallet-import.import-from-camera')}
      </ScannerButton>
    </Wrapper>
  ) 
}

export default QRCodeScanner;