import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ActiveLocaleState } from '../state/activeLocale';

interface ILocalizationContext {
  locale: string;
  availableLocales: string[];
  translations: {
    [locale: string]: {
      [key: string]: string;
    }
  };
  countries: {
    [locale: string]: { alpha2: string, name: string }[];
  };
}

const LocalizationContext = createContext<ILocalizationContext>({
  locale: 'en',
  availableLocales: [],
  translations: {},
  countries: {},
});

export const useGetLocalizedString = (locale?: string) => {
  const localizationContext = useContext(LocalizationContext);
  const _locale = locale || localizationContext.locale;

  return useCallback((key: string) => {
    if (_locale && localizationContext.availableLocales.includes(_locale)) {
      return (localizationContext.translations[_locale] || {})[key] || '';
    }
    return '';
  }, [localizationContext, _locale]);
}

export const useLocalizedString = (key: string, locale?: string) => {
  const getLocalizedString = useGetLocalizedString(locale);
  return getLocalizedString(key);
}

export const useCountries = (locale?: string): { alpha2: string, name: string }[] => {
  const localizationContext = useContext(LocalizationContext);
  const _locale = locale || localizationContext.locale;

  if (_locale && localizationContext.availableLocales.includes(_locale)) {
    return localizationContext.countries[_locale] || localizationContext.countries['en'] || [];
  }
  return [];
}

export const useAvailableLocales = () => useContext(LocalizationContext).availableLocales;
export const useActiveLocale = () => useRecoilValue(ActiveLocaleState);

export const LocalizationProvider: FC<PropsWithChildren<{ availableLocales: string[] }>> = (props) => {
  const {
    availableLocales,
    children,
  } = props;

  const [activeLocale, setActiveLocale] = useRecoilState(ActiveLocaleState);
  const [translations, setTranslations] = useState<{ [locale: string]: { [key: string]: string } }>({});
  const [countries, setCountries] = useState<{ [locale: string]: { alpha2: string, name: string }[] }>({});

  useEffect(() => {
    availableLocales.forEach((locale) => {
      try {
        const json = require(`../../public/translations/${locale}.json`);
        setTranslations((state) => ({
          ...state,
          [locale]: json,
        }))
      } catch (err) {
        console.error('LOCALIZATION', 'could not get translations for locale', locale);
      }

      // iso code translations
      try {
        const json = require(`../../public/translations/countries-${locale}.json`);
        setCountries((state) => ({
          ...state,
          [locale]: json,
        }))
      } catch (err) {
        console.error('LOCALIZATION', 'could not get country translations for locale', locale);
      }
    });
  }, [availableLocales]);

  useEffect(() => {
    if (availableLocales && activeLocale === null) {
      const language = window?.navigator?.language?.substring(0, 2) || '';
      if (language.length === 2 && availableLocales.includes(language)) {
        setActiveLocale(language);    // browser language
      } else {
        setActiveLocale('en');    // default fallback
      }
    }
  }, [activeLocale, availableLocales]);

  const context: ILocalizationContext = {
    locale: activeLocale || 'en',
    availableLocales,
    translations,
    countries,
  };

  return (
    <LocalizationContext.Provider value={context}>
      {children}
    </LocalizationContext.Provider>
  );
}
