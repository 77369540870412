import { FC, useEffect, useState } from 'react';
import { CloseIcon } from '../../icons/CloseIcon';
import { AlbumCollectibleItem } from './AlbumCollectibleItem';
import styled, { css } from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_XL, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { GridLayout } from '../../../styles/Layout';
import { SecondaryButton } from '../../../styles/Buttons';
import { LinkHandler } from '../general/LinkHandler';
import { IAlbum, IAlbumCollectible, IUserAlbum, IUserAlbumCollectible } from '../../../services/albums';
import { useGetLocalizedString } from '../../../services/localization';
import { Colors } from '../../../styles/Colors';
import { ShareIcon } from '../../icons/ShareIcon';
import { useLocation } from 'react-router-dom';
import { ArrowIcon } from '../../icons/ArrowIcon';
import { AlbumShareMenu } from './AlbumShareMenu';

const DetailsModal = styled.div<{ active?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 15;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem;

  ${BREAKPOINT_MD} {
    padding: 3rem;
  }

  background: rgba(0, 0, 0, .4);
  backdrop-filter: blur(21px);

  transition: opacity 300ms ease-out, visibility 300ms ease-out;

  opacity: ${({ active = false }) => active ? 1 : 0};
  visibility: ${({ active = false }) => active ? 'visible' : 'hidden'};

  ${({ active = false }) => active && css`
    transition-delay: .75s;
  `};
`;

const BoxedGrid = styled.div`
  ${GridLayout};
`;

const NavigationBar = styled.div`
  position: relative;
  padding: 1rem 3rem;
`;

const BackButton = styled.button<{ open: boolean }>`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${({ open }) => open ? 1 : 0};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  transition: opacity 450ms ease, visibility 450ms ease;
  display: flex;
  align-items: center;
  gap: 1rem;
  text-transform: uppercase;

  svg {
    margin-bottom: .125rem;
  }

  p {
    display: none;

    ${BREAKPOINT_MD} {
      display: initial;
    }
  }
`;

const CollectibleNumberView = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;

  span {
    color: ${Colors.primary};
  }
`;

const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.5rem;
  height: 1.5rem;

  @media (hover: hover) {
    :hover {
      color: var(--hover-color);
    }
  }
`;

const ShareWrapper = styled.div<{ open: boolean }>`
  position: relative;
  transform: ${({ open }) => open ? 'scale(0.8) translateY(-5vh)' : 'scale(1) translateY(0)'};
  transition: transform 450ms ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${BREAKPOINT_XL} {
    transform: none;
    width: min-content;
    margin: 0 auto;
  }
`;

const ButtonWrapper = styled.div<{ open: boolean }>`
  position: relative;
  align-self: center;
  opacity: ${({ open }) => open ? 0 : 1};
  visibility: ${({ open }) => open ? 'hidden' : 'visible'};
  transition: opacity 450ms, visibility 450ms ease;
`;

const ShareButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(calc(100% + .75rem), -50%);
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid var(--color-text-default);
  border-radius: 1.5rem;
  display: flex;
  gap: .5rem;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1;
  padding-bottom: .25rem;

  p {
    display: none;
    font-size: 1rem;
  }

  ${BREAKPOINT_XL} {
    width: auto;
    padding: 1rem;
    transform: translate(calc(100% + 2rem), -50%);

    p {
      display: initial;
    }
  }
`;

const CollectibleWrapper = styled.div`
  perspective: 1000px;
`;

const ViewCollectibleButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
  align-self: center;
`;

export interface IAlbumCollectibleDetailsProps {
  collectible?: IAlbumCollectible | IUserAlbumCollectible;
  view?: HTMLDivElement | null;
}

interface IAlbumCollectibleDetailsModalProps extends IAlbumCollectibleDetailsProps {
  onCloseButtonClick: () => void;
  album: IAlbum | IUserAlbum;
}

export const AlbumCollectibleDetailsModal: FC<IAlbumCollectibleDetailsModalProps> = (props) => {
  const {
    collectible,
    view,
    onCloseButtonClick,
  } = props;

  const isOwned = (collectible as IUserAlbumCollectible)?.isOwned || false;
  const getLocalizedString = useGetLocalizedString();
  const [openShareMenu, setOpenShareMenu] = useState<boolean>(false);
  const [supportsNavigator, setSupportsNavigator] = useState<boolean>(false);
  const [collectibleUrl, setCollectibleUrl] = useState<string>('');
  const location = useLocation();

  const hasTouchSupport = 'ontouchstart' in window;

  useEffect(() => {
    document.body.classList.toggle('no-scroll', !!collectible);

    if (collectible?.id) {
      setCollectibleUrl(`${window.location.origin.toString()}/collectibles/${collectible?.id}`);
    }
  }, [collectible]);

  useEffect(() => {
    // init
    if (!!navigator?.canShare) {
      setSupportsNavigator(true);
    }
    // cleanup
    return () => {
      document.body.classList.toggle('no-scroll', false);
    };
  }, []);

  const handleShareButtonClick = async (e: any) => {
    e.stopPropagation();
    if (supportsNavigator) {
      const shareData = {
        text: collectible?.title,
        url: location.pathname,
      };

      try {
        await navigator.share(shareData);
      } catch {
        console.log('navigator share error!');
      }
    } else {
      setOpenShareMenu(true);
    }
  };

  return (
    <DetailsModal
      active={!!collectible && !!view}
      onClick={() => {
        if (!hasTouchSupport || window.innerWidth >= DIMEN_BREAKPOINT_MD) {
          onCloseButtonClick();
          setOpenShareMenu(false);
        }
      }}
    >
      <BoxedGrid>
        <NavigationBar>
          <BackButton open={openShareMenu} onClick={(e) => {
            e.stopPropagation();
            setOpenShareMenu(false);
          }}>
            <ArrowIcon /><p>{getLocalizedString('app.v2.general.back')}</p>
          </BackButton>
          {
            collectible &&
            <CollectibleNumberView>
              {
                openShareMenu
                  ? <p><ShareIcon /> {getLocalizedString('app.v2.general.share')}</p>
                  : isOwned
                    ?
                    <>
                      <span>{(collectible as IUserAlbumCollectible)?.collectibleIndex || 0}</span>
                      /{(collectible as IUserAlbumCollectible)?.totalCollectibleSupply || 0}
                    </>
                    :
                    <p>{`${getLocalizedString('app.v2.album-collectibles.label.quantity')} ${(collectible as IUserAlbumCollectible)?.totalCollectibleSupply || 0}`}</p>
              }
            </CollectibleNumberView>
          }
          <CloseButton onClick={() => {
            onCloseButtonClick();
            setOpenShareMenu(false);
          }} aria-label='Close'>
            <CloseIcon />
          </CloseButton>
        </NavigationBar>
      </BoxedGrid>

      <ShareWrapper open={openShareMenu}>
        <CollectibleWrapper
          onClick={(e) => e.stopPropagation()}
          style={
            view ? {
              width: view.offsetWidth,
              height: view.offsetHeight,
              transform: `scale(var(--scale, 1))`,
              '--scale': view.style.getPropertyValue('--scale'),
              '--rotation-multiplier': hasTouchSupport ? 5.5 : 2.5,
            } as any : {}
          }
        >
          {
            collectible &&
            <AlbumCollectibleItem
              {...collectible}
              withBorder={false}
              fullscreen={true}
            />
          }
        </CollectibleWrapper>

        <AlbumShareMenu open={openShareMenu} url={collectibleUrl} title={collectible?.title || ''} />
      </ShareWrapper>

      {collectible && <ButtonWrapper open={openShareMenu}>
        <ViewCollectibleButton to={`/collectibles/${collectible?.slug}`}>
          {getLocalizedString('app.v2.album-collectibles.button.view-collectible')}
        </ViewCollectibleButton>

        <ShareButton onClick={handleShareButtonClick}>
          <p>{getLocalizedString('app.v2.general.share')}</p>
          <ShareIcon />
        </ShareButton>
      </ButtonWrapper>}
    </DetailsModal>
  );
};
