/**
 * Download the content of a canvas as png file
 * 
 * This is the easiest way to trigger native downloads
 * on all devices
 */
  const downloadCanvas = (canvas: HTMLCanvasElement, fileName: string) => {
  const data = canvas.toDataURL();
  const downloadLink = document.createElement('a');
  downloadLink.href = data;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export default downloadCanvas;