import { ReactNode } from 'react';
import BackupWallet from '../components/views/profile/BackupWallet';
import { ChangePassword } from '../pages/ChangePassword';
import ConnectWallet from '../pages/ConnectWallet';
import { EditProfile } from '../pages/EditProfile';
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { News } from '../pages/News';
import { PageNotFound } from '../pages/PageNotFound';
import { Profile } from '../pages/Profile';
import { RedeemVoucher } from '../pages/RedeemVoucher';
import { Registration } from '../pages/Registration';
import { ResetPassword } from '../pages/ResetPassword';
import { Terms } from '../pages/Terms';
import { Wallet } from '../pages/Wallet';
import { AlbumCollectibles } from '../pages/albums/AlbumCollectibles';
import { AlbumDetails } from '../pages/albums/AlbumDetails';
import { Albums } from '../pages/albums/Albums';
import { CampaignDetails } from '../pages/campaigns/CampaignDetails';
import { Collectible3DStage } from '../pages/collectibles/Collectible3DStage';
import { CollectibleDetail } from '../pages/collectibles/CollectibleDetail';
import { Collectibles } from '../pages/collectibles/Collectibles';
import { ClaimableTokenCheckout } from '../pages/collectibles/checkout/ClaimableTokenCheckout';
import { CollectibleCheckout } from '../pages/collectibles/checkout/CollectibleCheckout';
import { SuccessPage } from '../pages/collectibles/checkout/SuccessPage';
import { Publishers } from '../pages/publishers/Publishers';
import { PublishersDetail } from '../pages/publishers/PublishersDetail';
import { ThreeDPrint } from '../pages/roadmap/3dPrint';
import { SnapchatCameraSandbox } from 'src/pages/SnapchatCameraSandbox';

interface createRouteConfig {
  authRequired?: boolean;
  hideNavBar?: boolean;
  hideHeader?: boolean;
  hideSwirl?: boolean;
  hideBlobs?: boolean;
  showCampaignBackground?: boolean;
  showNotificationTopBar?: boolean;
  withBackButton?: boolean;
}

const createRoute = (path: string, element: ReactNode, config: createRouteConfig | undefined = undefined) => ({
  path,
  element,
  config,
});

export const ROUTES = [
  createRoute('/', <Home />, {
    showNotificationTopBar: true,
  }),
  createRoute('/news/:id', <News />),
  createRoute('/collectibles/creators/:id', <PublishersDetail />),
  createRoute('/collectibles/creators', <Publishers />, {
    withBackButton: true,
  }),
  createRoute('/collectibles/:id/purchase', <CollectibleCheckout />),
  createRoute('/collectibles/:id', <CollectibleDetail />, {
    hideNavBar: true,
    hideHeader: true,
    hideSwirl: true,
    hideBlobs: true,
  }),
  createRoute('/collectibles/3d-stage/:id', <Collectible3DStage />, {
    hideNavBar: true,
    hideHeader: true,
    hideSwirl: true,
    hideBlobs: true,
  }),
  createRoute('/collectibles', <Collectibles />),
  createRoute('/collectibles/:id/claim/:sku', <ClaimableTokenCheckout />, {
    authRequired: true,
  }),
  createRoute('/albums/:id/collectibles', <AlbumCollectibles />, {
    hideSwirl: true,
    hideBlobs: true,
  }),
  createRoute('/albums/:id', <AlbumDetails />, {
    hideSwirl: true,
    hideBlobs: true,
  }),
  createRoute('/albums', <Albums />, {
    hideSwirl: true,
    hideBlobs: true,
  }),
  createRoute('/campaigns/:id', <CampaignDetails />, {
    hideSwirl: true,
    hideBlobs: true,
    showCampaignBackground: true,
  }),
  createRoute('/profile/albums/:id/collectibles', <AlbumCollectibles inProfile />, {
    authRequired: true,
    hideSwirl: true,
    hideBlobs: true,
  }),
  createRoute('/profile/change-password', <ChangePassword />, {
    authRequired: true,
    hideNavBar: false,
  }),
  createRoute('/profile/reset-password', <ResetPassword />),
  createRoute('/profile/edit', <EditProfile />, {
    authRequired: true,
    hideNavBar: false,
    withBackButton: true,
  }),
  createRoute('/profile', <Profile />, {
    authRequired: true,
  }),
  createRoute('/login', <Login />, {
    hideNavBar: false,
  }),
  createRoute('/wallet', <Wallet />, {}),
  createRoute('/registration', <Registration />, {
    hideNavBar: false,
  }),
  createRoute('/backup-wallet', <BackupWallet />, {
    hideNavBar: true,
  }),
  createRoute('/backup-wallet', <BackupWallet />, {
    hideNavBar: false,
  }),
  createRoute('/connect-wallet', <ConnectWallet />, {
    hideNavBar: false,
  }),
  createRoute('/redeem-voucher', <RedeemVoucher />, {
    authRequired: true,
    hideNavBar: false,
    withBackButton: true,
  }),
  createRoute('/roadmap/3d-print', <ThreeDPrint />, {
    hideNavBar: true,
  }),
  createRoute('/404', <PageNotFound />, {
    hideNavBar: true,
  }),
  createRoute('/purchase/success', <SuccessPage />, {
    authRequired: true,
  }),
  createRoute('/purchase/error', <SuccessPage asErrorPage />, {
    authRequired: true,
  }),
  createRoute('/accept-terms', <Terms />, {
    authRequired: true,
    hideNavBar: true,
    hideHeader: true,
  }),
  createRoute('/terms', <Terms />, {
    withBackButton: true,
  }),
  createRoute('/sandbox', <SnapchatCameraSandbox />, {
    withBackButton: false,
    hideNavBar: true,
    hideHeader: true,
    hideSwirl: true,
    hideBlobs: true,
  }),
];
