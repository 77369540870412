import styled from 'styled-components';
import { FC } from 'react';
import { IAlbumContentItem, IAlbumDetails } from '../../../../services/albums';
import Text from '../../general/Text';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { BasicGridWrapper } from '../../general/Grids';

const Wrapper = styled(BasicGridWrapper).attrs({ as: 'section' })`
  gap: 1rem;

  ${BREAKPOINT_MD} {
    gap: 2rem;
  }

  > * {
    grid-column: 2 / -2;

    ${BREAKPOINT_MD} {
      grid-column: 6 / -2;
    }
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.125;

  ${BREAKPOINT_MD} {
    font-size: 3rem;
  }
`;

interface TextSectionProps extends IAlbumContentItem {
  title: string;
  text: string;
  album: IAlbumDetails[];
}

export const TextSection: FC<TextSectionProps> = (props) => {
  const { type, title, text } = props;

  return (
    <Wrapper data-type={type}>
      <Title>{title}</Title>
      <Text content={text} />
    </Wrapper>
  );
};