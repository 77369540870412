import { useAPIInstance } from './index';
import { AxiosResponse } from 'axios';

interface ISubCollectible {
  id: string;
  type: string;
  name: string;
  description: string;
  imageUrl: string;
  price: number;
  actionText: string;
  consumed: boolean;
}

export interface ICollectibleAlbum {
  collectiblesInAlbum: number;
  coverImage: string;
  description: string;
  slug: string;
  id: string;
  isAvailable: true;
  ownedCollectibles: number;
  publisherId: string;
  publisherLogoUrl: string;
  publisherName: string;
  subTitle: string;
  title: string;
}

export interface ICollectibleContentItem {
  type: string;
  order: number;
}

export interface ICollectibleBackgrounds {
  name: string;
  backgroundTextureUrl: string;
  _id: string;
}

// TODO Merge with UserCollectible
export interface ICollectible {
  type: string;
  slug: string;
  backgrounds?: ICollectibleBackgrounds[];
  category: string;
  albums: ICollectibleAlbum[];
  id: string;
  title: string;
  imageUrl: string;
  imageAnimatedUrl: string;
  imageStaticUrl: string;
  image3dUrl: string;
  rawImage3dUrl: string;
  marketingType: string;
  status: string;
  publisherId: string;
  publisherLogoUrl: string;
  publisherName: string;
  publisherSlug: string;
  priceInCurrencies: IPriceInCurrency[];
  price: number;
  rarity: number;
  edition: string;
  purchasedNr: number;
  favoriteNr: number;
  sku: string;
  hasPhysicalProduct: boolean;
  acquiredAt: string;
  subCollectibles: ISubCollectible[];
  tags: string[];
  publishAt: string;
  soldOut: boolean;
  initialSupply: number;
  currentSupply: number;
  isCharityItem: boolean;
  isExclusive: boolean;
  isFreemint: boolean;
  isBonus: boolean;
  smartContractAddress: string;
  ownedCollectiblesCount: number;
  content: ICollectibleContentItem[];
}

export interface ICollectibles {
  collectibles: ICollectible[];
}

export interface ICollectibleList {
  collectibles: IUserCollectible[];
  totalRecords: number;
}

interface ICollectibleResponse {
  collectible: ICollectible;
}

export interface IUserCollectible {
  type: string;
  id: string;
  slug: string;
  title: string;
  imageUrl: string;
  imageAnimatedUrl: string;
  imageStaticUrl: string;
  status: string;
  priceInCurrencies: IPriceInCurrency[];
  publisherId: string;
  publisherLogoUrl: string;
  publisherName: string;
  publisherSlug: string;
  price: number;
  rarity: number;
  edition: string;
  sku: string;
  purchasedNr?: number;
  subCollectibles: string[];
  tags: string[];
  publishAt: string;
  soldOut: boolean;
  initialSupply: number;
  currentSupply: number;
  isCharityItem: boolean;
  hasPhysicalProduct: boolean;
  isExclusive: boolean;
  isFreemint: boolean;
  isBonus: boolean;
  smartContractAddress: string;
}

interface IUserCollectibles {
  collectibles: string[];
}

export interface IUserCollectiblesDetails {
  collectibles: IUserCollectible[];
}

export interface ICollectibleList {
  collectibles: IUserCollectible[];
  totalRecords: number;
}

export interface IPriceInCurrency {
  currencySymbol: string;
  currencyShortcode: string;
  price: number;
}

export const useCollectiblesAPI = () => {
  const apiInstance = useAPIInstance();

  const collectibleDetailsMiddleware = (res: AxiosResponse<ICollectibleResponse>): AxiosResponse<ICollectibleResponse> => {
    const collectible = res?.data?.collectible;
    if (collectible && !collectible.price) {
      collectible.price = collectible.subCollectibles?.find(({ type }) => type === 'base')?.price as number; // use price from sub collectible of type base
    }
    return res;
  };

  const userCollectibles = () => {
    return apiInstance.get<IUserCollectibles>('/collectibles/collection/list');
  };

  const userPresaleCollectibles = () => {
    return apiInstance.get<IUserCollectible[]>('/mint-orders/mine', { baseURL: process.env.REACT_APP_API_PAYMENT_BASE_URL });
  };

  const fetchAllCollectibles = (offset: number, limit: number) => {
    return apiInstance.get<ICollectibleList>(`/collectibles/all?offset=${offset}&limit=${limit}`);
  };

  const fetchLatestCollectibles = (offset: number, limit: number) => {
    return apiInstance.get<ICollectibleList>(`/collectibles/latest?offset=${offset}&limit=${limit}`);
  };

  const fetchMostWantedCollectibles = (offset: number, limit: number) => {
    return apiInstance.get<ICollectibleList>(`/collectibles/most-wanted?offset=${offset}&limit=${limit}`);
  };

  const fetchUpcomingCollectibles = (offset: number, limit: number) => {
    return apiInstance.get<ICollectibleList>(`/collectibles/upcoming?offset=${offset}&limit=${limit}`);
  };

  const userCollectiblesDetails = () => {
    return apiInstance.get<IUserCollectiblesDetails>('/collectibles/collection/details');
  };

  const collectiblesDetails = (collectibleId: string, sku: string) => {
    return apiInstance.get<ICollectibles>(`/collectibles/collection/details/${collectibleId}/${sku}`);
  };

  const userWalletCollectibleDetails = (smartContractAddresses: string[]) => {
    return apiInstance.get<IUserCollectible[]>(`/collectibles/smartcontract/${smartContractAddresses.join(',')}`);
  };

  const collectible = (collectibleId: string) => {
    // return apiInstance.get<ICollectibleResponse>(`/collectibles/${collectibleId}`, { headers: { 'next': true } })
    return apiInstance.get<ICollectibleResponse>(`/collectibles/${collectibleId}`).then(collectibleDetailsMiddleware);
  };

  const collectiblesByPublisher = (publisherId: string, offset: number, limit: number) => {
    return apiInstance.get<ICollectibleList>(`/collectibles/publishers/${publisherId}?offset=${offset}&limit=${limit}`);
  };

  return {
    userCollectibles,
    userCollectiblesDetails,
    collectible,
    collectiblesDetails,
    collectiblesByPublisher,
    fetchAllCollectibles,
    fetchLatestCollectibles,
    fetchMostWantedCollectibles,
    fetchUpcomingCollectibles,
    userPresaleCollectibles,
    userWalletCollectibleDetails,
  };
};
