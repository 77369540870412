import { useAPIInstance } from './index';
import { useUserId } from '../state/auth';

export interface IAlbum {
  id: string;
  slug: string;
  title: string;
  subTitle?: string;
  description: string;
  coverImage: string;
  publisherId: string;
  publisherName: string;
  publisherLogoUrl: string;
  collectiblesInAlbum: number;
  isAvailable: boolean;
}

export interface IAlbumCollectible {
  id: string;
  slug: string;
  title: string;
  description: string;
  image: string;
  price: number;
  utilities: string[];
  rarity: number;
  order: number;
  isBonus: boolean;
}

export interface IUserAlbum extends IAlbum {
  ownedCollectibles: number;
}

export interface IUserAlbumCollectible extends IAlbumCollectible {
  isOwned: boolean;
  collectibleIndex: number;
  totalCollectibleSupply: number;
}

export interface IAlbumContentItem {
  type: string;
  order: number;
}

export interface IAlbumDetails extends IAlbum {
  collectibles: IAlbumCollectible[];
  content: IAlbumContentItem[];
  coverCollectibleImages?: string[];
}

export interface IUserAlbumDetails extends IUserAlbum {
  collectibles: IUserAlbumCollectible[];
}

export const useAlbumsAPI = () => {
  const apiInstance = useAPIInstance();
  const userId = useUserId();

  const fetchAlbums = () => {
    return apiInstance.get<IAlbum[]>('/albums');
  };

  const fetchAlbumDetails = (id: string) => {
    return apiInstance.get<IAlbumDetails>(`/albums/${id}`);
  };

  const fetchUserAlbums = () => {
    return apiInstance.get<IUserAlbum[]>(`/users/${userId}/albums`);
  };

  const fetchUserAlbumDetails = (id: string) => {
    return apiInstance.get<IUserAlbumDetails>(`/users/${userId}/albums/${id}`);
  };

  return {
    fetchAlbums,
    fetchAlbumDetails,
    fetchUserAlbums,
    fetchUserAlbumDetails,
  };
};
