import { BrowserRouter } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { CurrencyContextProvider } from './hooks/useCurrency';
import { FavoriteContextProvider } from './hooks/useFavoriteCollectibles';
import { FanseaRouter } from './router/Router';
import { APIProvider } from './services';
import { LocalizationProvider } from './services/localization';
import { client } from './web3/client';
import { PrivacyBanner } from "./components/common/PrivacyBanner"

function App() {
  return (
    <LocalizationProvider
      availableLocales={
        (process.env.REACT_APP_AVAILABLE_LOCALES || '')?.length > 0 ? (process.env.REACT_APP_AVAILABLE_LOCALES || '').split(',') : ['en']
      }>
      <APIProvider>
        <BrowserRouter>
          <WagmiConfig client={client}>
            <CurrencyContextProvider>
              <FavoriteContextProvider>
                <FanseaRouter />
                <PrivacyBanner />
              </FavoriteContextProvider>
            </CurrencyContextProvider>
          </WagmiConfig>
        </BrowserRouter>
      </APIProvider>
    </LocalizationProvider>
  );
}

export default App;
