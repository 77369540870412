import { useEffect, useState } from 'react';

export const useMediaQuery = (breakpoint: number) => {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const listener = (e: any) => {
      setMatches(e.matches);
    };

    const mediaQuery = window.matchMedia(`(min-width:${breakpoint}px)`);
    mediaQuery.addEventListener('change', listener);

    listener(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, []);

  return matches;
};