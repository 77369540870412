import { FC, ReactNode } from 'react';
import { FormProvider, SubmitHandler, FieldValues, UseFormReturn } from 'react-hook-form';

interface IFormWrapperProps {
  children: ReactNode;
  form: UseFormReturn;
  className?: string;
  onSubmit?: SubmitHandler<FieldValues>;
  onChange?: (e: any) => void;
}

const FormWrapper: FC<IFormWrapperProps> = (props) => {
  const { children, className, onSubmit, onChange, form } = props;

  return (
    <FormProvider {...form} >
      <form
        onSubmit={onSubmit && form.handleSubmit(onSubmit)}
        onChange={onChange}
        className={className}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default FormWrapper;