import { FC } from 'react';

export const PlayIcon: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox='0 0 36 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.5' filter='url(#filter0_d_2672_20852)'>
      <path d='M27 20L9 30.3923L9 9.60769L27 20Z' fill='white' />
    </g>
    <defs>
      <filter id='filter0_d_2672_20852' filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                       result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='4.5' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2672_20852' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2672_20852' result='shape' />
      </filter>
    </defs>
  </svg>

);