import { atom } from "recoil";

export const NotificationOpenState = atom<boolean>({
  key: 'NotificationOpenState',
  default: false,
});

export const NewNotificationsState = atom<boolean>({
  key: 'NewNotificationsState',
  default: false,
});
