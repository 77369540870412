import { configureChains, createClient } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { BLOCKCHAIN_NETWORKS } from './types';
import { InjectedConnector } from '@wagmi/core';

const REACT_APP_BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

export let chainId: number = polygonMumbai.id;
export let chainList: any[] = [polygonMumbai];

if (REACT_APP_BLOCKCHAIN_NETWORK === BLOCKCHAIN_NETWORKS.POLYGON) {
  chainList = [polygon];
  chainId = polygon.id;
}

const { chains, provider, webSocketProvider } = configureChains(chainList, [
  alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY || '' }),
  publicProvider(),
]);

export const metaMaskConnector = new MetaMaskConnector({
  chains,
});

export const injectedConnector = new InjectedConnector({ chains });

export const client = createClient({
  autoConnect: true,
  connectors: [metaMaskConnector, injectedConnector],
  provider,
  webSocketProvider,
});
