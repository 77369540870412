import styled, { css } from 'styled-components';
import { FC, useState } from 'react';
import Text from '../../general/Text';
import { Body2, Headline3 } from '../../../../styles/FontStyles';
import { BasicGridWrapper } from '../../general/Grids';
import { BREAKPOINT_MD, BREAKPOINT_SM, MAX_BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { ICollectibleContentItem, IUserCollectible } from '../../../../services/collectible';
import { FanseaImage } from '../../FanseaImage';
import { CloudinaryQuality } from '../../../../hooks/useCloudinary';
import { useFavoriteCollectibles } from '../../../../hooks/useFavoriteCollectibles';
import { useActiveLocale, useGetLocalizedString } from '../../../../services/localization';

const Wrapper = styled(BasicGridWrapper).attrs({ as: 'section' })`
  grid-row-gap: 2rem;
  margin-bottom: 3rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 8.75rem;
  }
`;

const TextWrapper = styled.div<{ imageLeft: boolean }>`
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  flex-direction: column;

  ${BREAKPOINT_MD} {
    grid-column: ${({ imageLeft }) => (imageLeft ? '7 / -1' : '1 / 7')};
    justify-content: center;
  }
`;

const TextWrapperCentered = styled.div`
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  flex-direction: column;

  ${BREAKPOINT_MD} {
    grid-column: '1 / -1';
    justify-content: center;
  }
`;

const SubHeadline = styled.h5`
  ${Body2};
`;

const Headline = styled.h3`
  ${Headline3};
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1.5rem;
  }
`;

const ImagesWrapper = styled.div<{ stacked: boolean; imageLeft: boolean }>`
  position: relative;
  grid-column: 1 / -1;
  grid-row: 2;
  border-radius: 0.25rem;
  margin: 0 1rem;

  :before {
    content: '';
    display: block;
    padding-top: calc(120% + 1rem);

    ${BREAKPOINT_MD} {
      padding-top: calc(120% + 1.5rem);
    }
  }

  ${BREAKPOINT_SM} {
    margin: 0 5rem;
  }

  ${BREAKPOINT_MD} {
    grid-row: 1;
    grid-column: ${({ imageLeft }) => (imageLeft ? '1 / 6' : '8 / -1')};
    margin: 0;
  }

  > * {
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.6));
  }

  ${({ stacked }) =>
    stacked &&
    css`
      border: 1px solid var(--color-primary);
    `};
`;

const ImageCard = styled.div<{ stacked: boolean; active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 750ms ease;
  z-index: ${({ active }) => (active ? 2 : 1)};


  ${({ stacked }) =>
    stacked &&
    css`
      &:nth-child(5n + 0) {
        transform: scale(97.5%) rotate(3deg) translateY(-0.25rem);
      }

      &:nth-child(5n + 1) {
        transform: scale(97.5%) rotate(-3deg) translateY(-0.25rem);
      }

      &:nth-child(5n + 2) {
        transform: scale(97.5%) rotate(5deg) translateY(-0.25rem);
      }

      &:nth-child(5n + 3) {
        transform: scale(97.5%) rotate(2deg) translateY(-0.25rem);
      }

      &:nth-child(5n + 4) {
        transform: scale(97.5%) rotate(-5deg) translateY(-0.25rem);
      }
    `};

  p {
    background: var(--color-grey-900);
    color: var(--color-primary);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: end;
    padding: 0 0.25rem;
    opacity: ${({ active, stacked }) => (active || !stacked ? 1 : 0)};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

interface TextImageSectionProps extends ICollectibleContentItem {
  headline: string;
  title?: string;
  text: string;
  images: { title?: string; url: string }[];
  imageLeft: boolean;
  collectible?: IUserCollectible;
}

export const TextImageSection: FC<TextImageSectionProps> = (props) => {
  const { headline, title, text, images, imageLeft, collectible } = props;
  const { favoritesIds } = useFavoriteCollectibles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const activeLocale = useActiveLocale();
  const getLocalizedString = useGetLocalizedString();

  const translatedText = getLocalizedString(text);
  const translatedTopHeadline = getLocalizedString(title || '');
  const translatedHeadline = getLocalizedString(headline);

  const imagesLength = (images) ? images.length : 0;

  const clickHandler = () => {
    if (currentIndex < images.length - 1) setCurrentIndex((state) => state + 1);
    else setCurrentIndex(0);
  };

  return (
    <Wrapper>
      {(imagesLength > 0) && (
        <TextWrapper imageLeft={imageLeft}>
          {title && <SubHeadline>{translatedTopHeadline}</SubHeadline>}
          <Headline>{translatedHeadline}</Headline>
          <Text content={translatedText || ""} />
        </TextWrapper>
      )}
      {(imagesLength == 0) && (
        <TextWrapperCentered>
          {title && <SubHeadline>{translatedTopHeadline}</SubHeadline>}
          <Headline>{translatedHeadline}</Headline>
          <Text content={translatedText || ""} />
        </TextWrapperCentered>
      )}
      {(imagesLength == 1) && (
        <ImagesWrapper stacked={imagesLength > 2} imageLeft={imageLeft}>
          {images.map(({ title, url }, index) => (
            <ImageCard stacked={false} key={index} onClick={clickHandler} active={index === currentIndex}>
              {title && <p>{title}</p>}
              <FanseaImage altText={title} imageUrl={url} isJpegConverted={false} size='700' quality={CloudinaryQuality.GOOD} />
            </ImageCard>
          ))}
        </ImagesWrapper>
      )}
      {(imagesLength > 1) && (
        <ImagesWrapper stacked={imagesLength > 2} imageLeft={imageLeft}>
          {images.map(({ title, url }, index) => (
            <ImageCard stacked={imagesLength > 2} key={index} onClick={clickHandler} active={index === currentIndex}>
              {title && <p>{title}</p>}
              <FanseaImage altText={title} imageUrl={url} isJpegConverted={false} size='700' quality={CloudinaryQuality.GOOD} />
            </ImageCard>
          ))}
        </ImagesWrapper>
      )}
    </Wrapper>
  );
};
