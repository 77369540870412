import styled, { css } from 'styled-components';
import { CSSProperties, FC, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import { HoloCard } from './HoloCard';
import { interpolate } from '../../../utils/interpolation';
import { BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { LinkHandler } from '../general/LinkHandler';

// Example
// --rx: 0deg;
// --ry: 0deg;
// --tx: 0px;
// --ty: 0px;
//
// --mx: 50%;
// --my: 50%;
// --s: 1;
// --posx: 50%;
// --posy: 50%;
// --hyp: 0;
// --o: 0;

// Our
// --mx: 50%;
// --my: 50%;
// --hover: 0;
// --posx: 0%;
// --posy: 0%;
// --hyp: 0;
// --o: 0;

const HOLO_OPACITY_DEFAULT = .35;

const Wrapper = styled.div<{ withBorder: boolean }>`
  position: relative;
  padding-top: 154%;

  display: flex;
  flex-direction: column;

  border-radius: 2px;
  overflow: hidden;

  transition: background .75s ease-out, box-shadow .75s ease-out;

  ${({ withBorder = false }) => withBorder && css`
    background: #1E1E1E; // special color for album collectible tile
    box-shadow: inset -4px -3px 5px #343434, inset 4px 4px 3px #101010;
    background-blend-mode: overlay, normal;
  `};
`;

const Button = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;

  perspective: 1000px;

  z-index: calc((max(1 - var(--scale, 1), calc(-1 * (1 - var(--scale, 1)))) * 100) + var(--z-index, 0));
`;

const CardWrapper = styled.div<{ to?: string, opacityDefault?: number }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  --posx: 50%;
  --posy: 50%;
  --hyp: 0;
  --o: ${({ opacityDefault = 0 }) => opacityDefault};
  --mx: 50%;
  --my: 50%;

  transform-style: preserve-3d;
`;

const RotationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  transform-style: preserve-3d;
  transform: rotateX(calc(var(--rx, 0) * var(--rotation-multiplier, 1))) rotateY(calc(var(--ry, 0) * var(--rotation-multiplier, 1)));
`;

const ScaleWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  transform-style: preserve-3d;
  transition: transform .75s ease-in-out;

  & > ${Wrapper} {
    transform-style: preserve-3d;
    transition: transform .75s ease-in-out;
  }

  ${({ active = false }) => active && css`
    transform: scale(var(--scale)) translate(var(--x), var(--y));

    & > ${Wrapper} {
      transform: rotateY(360deg);
    }
  `};
`;

const CardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  background: #1E1E1E; // special color for album collectible tile

  pointer-events: none;
  user-select: none;
`;

const CardBackgroundImage = styled(CardBackground).attrs({ as: 'img' })<{ src: string, alt: string }>`
  object-fit: fill;
  object-position: center;
  background: none;
`;

const InnerWrapper = styled.div<{ rarity: number, withBorder: boolean }>`
  position: absolute;
  top: .25rem;
  left: .25rem;
  width: calc(100% - .5rem);
  height: calc(100% - .5rem);

  ${BREAKPOINT_MD} {
    top: .5rem;
    left: .5rem;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
  }

  display: flex;
  flex-direction: column;

  // glass border
  :before {
    display: flex;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(-2px, -2px);
    inset: 0;
    border: 2px solid transparent;
    border-radius: 2px;
    background: ${({ rarity }) => rarity !== 3 ?
            'radial-gradient(rgba(8, 232, 222, .8) 10%, rgba(255, 255, 255, 0) 100%) border-box;'
            : 'radial-gradient(rgba(15, 15, 15, .8) 2.5%, rgba(255, 255, 255, 1) 55%, rgba(102, 102, 102, .8) 100%) border-box;'};
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    visibility: hidden;
    opacity: 0;
    transition: visibility .75s ease-out, opacity .75s ease-out;

    ${({ withBorder = false, rarity }) => !withBorder && rarity < 3 && css`
      visibility: visible;
      opacity: 1;
    `};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  user-select: none;

  padding: .25rem .5rem .75rem;
  overflow: hidden;
  z-index: 0;

  ${BREAKPOINT_MD} {
    padding: .25rem 1.75rem 1.25rem;
    gap: 1rem;
  }
`;

const Edition = styled.p`
  margin-left: auto;
  margin-bottom: .375rem;
  font-size: .75rem;
  line-height: 1.5;

  span {
    color: var(--color-primary);
  }

  p {
    color: var(--color-grey-700);
  }

  ${BREAKPOINT_MD} {
    font-size: .875rem;
  }
`;

const CARD_BACKGROUND: { [rarity: number]: { mobileSrc: string, desktopSrc: string } } = {
  1: {
    desktopSrc: '/images/album_common_card.png',
    mobileSrc: '/images/mobile_album_common_card.png',
  },
  2: {
    desktopSrc: '/images/album_epic_card.png',
    mobileSrc: '/images/mobile_album_epic_card.png',
  },
  3: {
    desktopSrc: '/images/album_legendary_card.png',
    mobileSrc: '/images/mobile_album_legendary_card.png',
  },
};

export interface IAlbumCardItemProps {
  className?: string;
  style?: CSSProperties;
  active?: boolean;   // detail view
  holoEffect?: boolean;   // for bonus card
  fillBackground?: boolean;    // owned or bonus card
  withBorder?: boolean;   // border effect for album overview
  staticCard?: boolean;   // no movement on hover
  rarity?: number;
  onClick?: (view: HTMLDivElement | null) => void;
  to?: string;
  fullscreen?: boolean;
  renderTitle?: () => ReactNode;
}

export const AlbumCardItem: FC<PropsWithChildren<IAlbumCardItemProps>> = (props) => {
  const {
    style,
    active = false,
    holoEffect = false,
    fillBackground = false,
    withBorder = !active,
    staticCard = false,
    rarity = 1,
    onClick,
    to,
    fullscreen,
    renderTitle,
    children,
  } = props;

  const wrapper = useRef<HTMLDivElement | null>(null);
  const wrapperHoverAction = useRef(0);

  const hasTouchSupport = 'ontouchstart' in window;

  useEffect(() => {
    if (active) {
      wrapper.current?.parentElement?.style.setProperty('--z-index', '1');
      wrapper.current?.setAttribute('active', '');
    } else {
      wrapper.current?.removeAttribute('active');
    }
  }, [active]);

  const onTouchMove = (clientX: number, clientY: number) => {
    const wrapperDiv = wrapper.current;
    if (wrapperDiv) {
      const deltaX = Math.max(0, Math.min(1, (clientX - wrapperDiv.getBoundingClientRect().x) / wrapperDiv.getBoundingClientRect().width));
      const deltaY = Math.max(0, Math.min(1, (clientY - wrapperDiv.getBoundingClientRect().y) / wrapperDiv.getBoundingClientRect().height));
      const absPosX = Math.max(0, Math.min(100, deltaX * 100));
      const absPosY = Math.max(0, Math.min(100, deltaY * 100));

      const deltaXCenter = (deltaX - .5) * 2;
      const deltaYCenter = (deltaY - .5) * 2;

      const posX = .5 + .2 * deltaXCenter;
      const posY = .5 + .2 * deltaYCenter;

      const mx = absPosX;
      const my = absPosY;

      const rx = 4 * -deltaYCenter;
      const ry = 6 * deltaXCenter;

      const hyp = Math.max(0, Math.min(.5, (Math.abs(deltaXCenter / 2) - .75) / .25 + (Math.abs(deltaYCenter) - .75) / .25));
      const opacity = Math.max(0, Math.min(.5, Math.max(Math.abs(deltaXCenter / 2), Math.abs(deltaYCenter)) * 1.25));

      wrapperDiv.style.setProperty('--hover', '1');
      wrapperDiv.style.setProperty('--posx', `${posX * 100}%`);
      wrapperDiv.style.setProperty('--posy', `${posY * 100}%`);
      wrapperDiv.style.setProperty('--hyp', `${hyp}`);
      wrapperDiv.style.setProperty('--o', `${opacity}`);
      wrapperDiv.style.setProperty('--mx', `${mx}%`);
      wrapperDiv.style.setProperty('--my', `${my}%`);
      wrapperDiv.style.setProperty('--rx', `${rx}deg`);
      wrapperDiv.style.setProperty('--ry', `${ry}deg`);
    }
  };

  const onTouchEnd = () => {
    const wrapperDiv = wrapper.current;
    if (wrapperDiv && wrapperDiv.style.getPropertyValue('--hover') === '1') {
      wrapperDiv.style.setProperty('--hover', '0');

      const opacityDefault = holoEffect ? HOLO_OPACITY_DEFAULT : 0;
      const opacityStart = +(wrapperDiv.style.getPropertyValue('--o') || opacityDefault);
      const hypStart = +(wrapperDiv.style.getPropertyValue('--hyp') || 0);
      const posXStart = +(wrapperDiv.style.getPropertyValue('--posx') || '50%').replace('%', '');
      const posYStart = +(wrapperDiv.style.getPropertyValue('--posy') || '50%').replace('%', '');
      const mxStart = +(wrapperDiv.style.getPropertyValue('--mx') || '50%').replace('%', '');
      const myStart = +(wrapperDiv.style.getPropertyValue('--my') || '50%').replace('%', '');
      const rxStart = +(wrapperDiv.style.getPropertyValue('--rx') || '0deg').replace('deg', '');
      const ryStart = +(wrapperDiv.style.getPropertyValue('--ry') || '0deg').replace('deg', '');

      const hoverAction = ++wrapperHoverAction.current;
      interpolate((delta) => {
        // console.log("interpolate", delta, opacityStart - opacityStart * delta);
        if (wrapperDiv && wrapperDiv.style.getPropertyValue('--hover') === '0' && hoverAction === wrapperHoverAction.current) {
          wrapperDiv.style.setProperty('--o', `${opacityStart + (opacityDefault - opacityStart) * delta}`);
          wrapperDiv.style.setProperty('--hyp', `${hypStart - hypStart * delta}`);
          wrapperDiv.style.setProperty('--posx', `${posXStart + (50 - posXStart) * delta}%`);
          wrapperDiv.style.setProperty('--posy', `${posYStart + (50 - posYStart) * delta}%`);
          wrapperDiv.style.setProperty('--mx', `${mxStart + (50 - mxStart) * delta}%`);
          wrapperDiv.style.setProperty('--my', `${myStart + (50 - myStart) * delta}%`);
          wrapperDiv.style.setProperty('--rx', `${rxStart - (rxStart * delta)}deg`);
          wrapperDiv.style.setProperty('--ry', `${ryStart - (ryStart * delta)}deg`);
        }
      }, 500);
    }
  };

  return (
    <OuterWrapper>
      {
        !fullscreen &&
        <Edition>
          { renderTitle?.() }
        </Edition>
      }
      <CardWrapper
        style={style}
        ref={wrapper}
        opacityDefault={holoEffect ? HOLO_OPACITY_DEFAULT : 0}
        as={onClick ? Button : to ? LinkHandler : undefined}
        to={to}
        onClick={(e: any) => {
          if (onClick) {
            e.preventDefault();
            e.stopPropagation();

            if (!active) {
              // calculation for animation
              if (wrapper.current) {
                const { x, y, width, height } = wrapper.current.getBoundingClientRect();

                const scale = Math.min((window.innerHeight * .75) / height, (window.innerWidth * .85) / width);
                const offsetXScaled = (window.innerWidth / 2) - ((width * scale) / 2);
                const offsetYScaled = (window.innerHeight / 2) - ((height * scale) / 2);

                const currentX = x - (((width * scale) / 2) - (width / 2));
                const currentY = y - (((height * scale) / 2) - (height / 2));

                wrapper.current.style.setProperty('--x', `${Math.round((offsetXScaled - currentX) / scale)}px`);
                wrapper.current.style.setProperty('--y', `${Math.round((offsetYScaled - currentY) / scale)}px`);
                wrapper.current.style.setProperty('--scale', `${scale}`);
                wrapper.current.parentElement?.style.setProperty('--scale', `${scale}`);
                wrapper.current.parentElement?.style.setProperty('--z-index', '1');
              }

              // on click
              onClick(wrapper.current);
            }
          }
        }}
        onTransitionEnd={() => {
          if (!wrapper.current?.hasAttribute('active')) {
            wrapper.current?.style.setProperty('--scale', '1');
            wrapper.current?.parentElement?.style.setProperty('--scale', '1');
            wrapper.current?.parentElement?.style.setProperty('--z-index', null);
          }
        }}
        onTouchMove={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (hasTouchSupport && !staticCard) {
            onTouchMove(e.touches[0].clientX, e.touches[0].clientY);
          }
        }}
        onTouchEnd={() => {
          if (hasTouchSupport && !staticCard) {
            onTouchEnd();
          }
        }}
        onMouseMove={(e: any) => {
          if (!hasTouchSupport && !staticCard) {
            onTouchMove(e.clientX, e.clientY);
          }
        }}
        onMouseOut={(e: any) => {
          if (!hasTouchSupport && !staticCard) {
            onTouchEnd();
          }
        }}
      >
        <RotationWrapper>
          <ScaleWrapper active={active}>
            <Wrapper withBorder={withBorder}>
              <InnerWrapper rarity={rarity} withBorder={withBorder}>
                <ContentWrapper
                  as={fillBackground ? HoloCard : undefined}
                  shineOnly={!holoEffect}
                >
                  {
                    // show rarity based background image if collectible is owned
                    fillBackground &&
                    <picture>
                      <source media={`(min-width: ${DIMEN_BREAKPOINT_MD}px)`}
                              srcSet={CARD_BACKGROUND[rarity].desktopSrc} />
                      <CardBackgroundImage src={CARD_BACKGROUND[rarity].mobileSrc} alt={''} />
                    </picture>
                  }
                  {
                    !fillBackground &&
                    <CardBackground />
                  }
                  {
                    // card content
                    children
                  }
                </ContentWrapper>
              </InnerWrapper>
            </Wrapper>
          </ScaleWrapper>
        </RotationWrapper>
      </CardWrapper>
    </OuterWrapper>
  );
};