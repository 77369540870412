import { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';

const RarityBackgroundWrapper = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`;

interface RarityBackgroundProps {
  className?: string;
  rarity: number;
  children?: ReactNode;
}

export const RarityBackground: FC<RarityBackgroundProps> = (props) => {
  const { rarity, className, children } = props;

  const { mobileSrc, desktopSrc } = useMemo(() => {
    switch (rarity) {
      case 3:
        return {
          desktopSrc: '/images/background-legendary-desktop.jpg',
          mobileSrc: '/images/background-legendary-desktop.jpg',
          // mobileSrc: '/images/background-legendary-mobile.jpg',
        }
      case 2:
        return {
          desktopSrc: '/images/background-epic-desktop.jpg',
          mobileSrc: '/images/background-epic-desktop.jpg',
          // mobileSrc: '/images/background-epic-mobile.jpg',
        }
      case 1:
      default:
        return {
          desktopSrc: '/images/background-common-desktop.jpg',
          mobileSrc: '/images/background-common-desktop.jpg',
          // mobileSrc: '/images/background-common-mobile.jpg',
        }
    }
  }, [rarity]);

  return (
    <RarityBackgroundWrapper className={className}>
      <picture>
        <source media={`(min-width: ${DIMEN_BREAKPOINT_MD}px)`} srcSet={desktopSrc} />
        <img aria-hidden='true' src={mobileSrc} alt={'metallic background'} />
      </picture>
      {children}
    </RarityBackgroundWrapper>
  );
};
