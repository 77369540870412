import styled from 'styled-components';
import { PageView } from '../../components/layout/PageLayout';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { BasicGridWrapper } from '../../components/common/general/Grids';
import { BREAKPOINT_MD } from '../../styles/Breakpoints';
import { useEffect, useMemo, useState } from 'react';
import { Body3 } from '../../styles/FontStyles';
import { PageLoader } from '../../components/common/general/LoadingOverlay';
import { Link } from 'react-router-dom';
import { useDataCache } from '../../state/dataCache';
import { useAlbumsAPI } from '../../services/albums';
import { AlbumItem } from '../../components/common/albums/AlbumItem';

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; // TODO
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3rem;
  }
`;

const ResultsText = styled.p`
  ${Body3};
`;

const ItemList = styled(BasicGridWrapper)`
  grid-gap: 1rem .5rem;

  ${BREAKPOINT_MD} {
    grid-gap: 2rem;
  }
`;

const BrowseCollectiblesWrapper = styled.div`
  text-align: center;
  margin-top: 10rem;

  p {
    font-size: .875rem;
    line-height: 1.1875rem;
    margin: .5625rem 0 1.5rem 0;
    color: var(--color-grey-600);
  }
`;

const BrowseCollectibles = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: .875rem;
  font-weight: bold;

  svg {
    width: 1.5rem;
    height: auto;
  }

  span {
    margin-left: .625rem;
  }
`;

export const Albums = () => {
  const getLocalizedString = useGetLocalizedString();

  const activeLocale = useActiveLocale();

  const albumsAPI = useAlbumsAPI();
  const [dataCache, setDataCache] = useDataCache();
  const [loading, setLoading] = useState(false);

  const { allAlbums = [] } = dataCache || {};

  useEffect(() => {
    // make api call
    setLoading((allAlbums || []).length === 0);
    albumsAPI.fetchAlbums()
      .then((res) => {
        setDataCache((state) => ({
          ...state,
          allAlbums: res.data || [],
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.error('failed to load albums', err);
        setLoading(false);
      });
  }, [activeLocale]);

  const albumsCount = useMemo(() => {
    return allAlbums ? allAlbums.filter((album) => album.isAvailable).length : 0;
  }, [allAlbums]);

  return (
    <PageView>
      <ContentWrapper>
        <PageLoader visible={loading} />

        {
          !loading &&
          <>
            <HeadWrapper>
              <h4>{getLocalizedString('app.v2.albums.headline')}</h4>
              {
                allAlbums &&
                <ResultsText>{albumsCount} {getLocalizedString(albumsCount === 1 ? 'app.v2.albums.label.count-singular' : 'app.v2.albums.label.count')}</ResultsText>
              }
            </HeadWrapper>
            {
              allAlbums &&
              <ItemList gridColumns={'1'} gridColumnsMD={'2'}>
                {
                  allAlbums.filter(({ isAvailable }) => isAvailable).reverse().map((album) => (
                    <AlbumItem
                      key={album.id}
                      linkTo={`/albums/${album.slug}`}
                      {...album}
                    />
                  ))
                }
              </ItemList>
            }
          </>
        }
      </ContentWrapper>
    </PageView>
  );
};
