import { AxiosResponse } from 'axios';
import { useAPIInstance } from './index';
import { useTrackingSource } from '../state/tracking';

interface IPurchaseCollectibleData {
  userId: string;
  productId: string;
  currency?: string;
  walletAddress?: string;
  promoCode?: string;
}

interface IPurchaseCollectibleResponse {
  success: boolean;
  url: string;
}

interface IOrderResponse {
  order: {
    id: string;
    status: string;
  };
}

export const usePurchaseAPI = () => {
  const apiInstance = useAPIInstance();
  const trackingSource = useTrackingSource();

  const purchaseCollectible = (paymentMethod: 'stripe' | 'paypal', purchaseCollectibleData: IPurchaseCollectibleData) => {
    const query = trackingSource ? `?referrer=${trackingSource}` : '';
    const url = `/${paymentMethod}/pay${query}`;
    return apiInstance.post<IPurchaseCollectibleData, AxiosResponse<IPurchaseCollectibleResponse>>(url, purchaseCollectibleData, { baseURL: process.env.REACT_APP_API_PAYMENT_BASE_URL });
  };

  const getOrder = (orderId: string) => {
    return apiInstance.get<undefined, AxiosResponse<IOrderResponse>>(`/order/${orderId}`);
  };

  const validatePromoCode = (promoCode: string) => {
    return apiInstance.get<undefined, AxiosResponse<boolean>>(`/promo-codes/${promoCode}/validate`, { baseURL: process.env.REACT_APP_API_PAYMENT_BASE_URL });
  };

  const applyDiscount = (promoCode: string, originalPrice: number) => {
    return apiInstance.get<number, AxiosResponse<number>>(`/promo-codes/${promoCode}/original-price/${originalPrice}/apply`, { baseURL: process.env.REACT_APP_API_PAYMENT_BASE_URL });
  };

  return {
    purchaseCollectible,
    getOrder,
    validatePromoCode,
    applyDiscount,
  };
};