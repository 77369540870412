import styled from 'styled-components';
import { FC, useMemo } from 'react';

const GlassCardWrapper = styled.div<{ rarity?: number }>`
  position: relative;
  overflow: hidden;
  flex: 1;
  z-index: -1;
  border-radius: 2px;
  backdrop-filter: blur(4px);
  
  // glass border
  :before {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    border-radius: 2px;
    background: ${({ rarity }) => rarity !== 3 ?
            'radial-gradient(rgba(8, 232, 222, .8) 10%, rgba(255, 255, 255, 0) 100%) border-box;'
            : 'radial-gradient(rgba(15, 15, 15, .8) 2.5%, rgba(255, 255, 255, 1) 55%, rgba(102, 102, 102, .8) 100%) border-box;'};
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    object-position: top center;
    object-fit: cover;
  }
`;

interface Props {
  className?: string;
  rounded?: boolean;
  rarity?: number;
}

export const GlassCard: FC<Props> = (props) => {
  const {
    className,
    rarity = 1,
  } = props;

  const imageSrc = useMemo(() => {
    switch (rarity) {
      case 3:
        return '/images/card-legendary.png';
      case 2:
        return '/images/card-epic.png';
      default:
      case 1:
        return '/images/card-common.png';
    }
  }, [rarity]);

  return (
    <GlassCardWrapper rarity={rarity} className={className}>
      <img src={imageSrc} alt={''} />
    </GlassCardWrapper>
  );
};
