import styled, {css} from 'styled-components';
import { FC, useRef , useState} from 'react';
import { IAlbumContentItem, IAlbumDetails } from '../../../services/albums';
import { MAX_BREAKPOINT_SM} from '../../../styles/Breakpoints';
import { Headline6, Body3Bold } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';

const Wrapper = styled.div.attrs({ as: 'section' })`
  text-align: center;
`
const WrapperRings = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 49% 49%;
  gap: 8% 2%;
`

const RingCell = styled.div`
  text-align: center;
`

const RingImage = styled.img`
  width: 20vw;
`

const Title = styled.span`
  font-size: 8vh;
  line-height: 1.125;
  text-align: center;
  font-family: var(--font-family-artsy);
`;

const PlayerName = styled.p`
  ${Headline6}
  text-align: center;
`

const RingDescription = styled.p`
  text-align: center;
`

const ButtonHover = css`
  transition: box-shadow 250ms ease-out;

  @media (hover: hover) {
    :hover {
      box-shadow: var(--box-shadow-hover);
    }
  }

  :focus-visible {
    box-shadow: var(--box-shadow-hover);
  }
`;

const CTA = styled.a`
  text-align: center;
  appearance: none;
  padding: 0 1rem;
  border: 1px solid var(--color-text-default);
  border-radius: 2px;
  background: var(--color-grey-800);
  white-space: nowrap;
  line-height: 1;
  text-transform: uppercase;
  width: max-content;
  min-width: 15rem;
  height: 48px;

  ${Body3Bold};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ButtonHover};

  ${MAX_BREAKPOINT_SM} {
    width: max-content;
    min-width: 8rem;
    height: 48px;
  }

  :disabled {
    pointer-events: none;
    color: var(--color-grey-700);
    border-color: var(--color-grey-700);
    background: var(--color-grey-800);
  }

  transition: background 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out;

  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-highlight);

  :disabled {
    border-color: var(--color-grey-800);
  }
`

interface TextImageSectionProps extends IAlbumContentItem {
  album: IAlbumDetails;
  direction: 'right' | 'left';
  image: string;
  text: string;
  title: string;
}

export const ForeverGalaxyAlbumCommonSection: FC<TextImageSectionProps> = (props) => {
  const { title } = props;
  const getLocalizedString = useGetLocalizedString();

  return (
    <Wrapper>
      <Title>{title}</Title>
      <br /><br />
      <WrapperRings>
        <RingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842557/publishers/Frankfurt%20Galaxy/53_Josiah/53_700x700_04.png" alt="Ring #53" />
          <PlayerName>JOSIAH</PlayerName>
          <RingDescription>Ring #53<br/>{getLocalizedString('app.v2.forevergalaxy.campaign.ring1.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/josiah-ring-forevergalaxy">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <RingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842726/publishers/Frankfurt%20Galaxy/99_Maier/099_still_1000x1000_dof_02.png" alt="Ring #99" />
          <PlayerName>MAIER</PlayerName>
          <RingDescription>Ring #99<br/>{getLocalizedString('app.v2.forevergalaxy.campaign.ring2.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-maier-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <RingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842818/publishers/Frankfurt%20Galaxy/71_Atunlese/071_still_700x700_004.png" alt="Ring #71" />
          <PlayerName>ATUNLESE</PlayerName>
          <RingDescription>Ring #71<br/>{getLocalizedString('app.v2.forevergalaxy.campaign.ring3.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-atunlese-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <RingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1679842852/publishers/Frankfurt%20Galaxy/13_Rutsch/013_still_700x700_003.png" alt="Ring #13" />
          <PlayerName>RUTSCH</PlayerName>
          <RingDescription>Ring #13<br/>{getLocalizedString('app.v2.forevergalaxy.campaign.ring4.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-rutsch-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
      </WrapperRings>
    </Wrapper>
  );
};