import styled from 'styled-components';
import { INews, useNewsAPI } from '../services/news';
import { useEffect, useState } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../styles/Breakpoints';
import { useNavigate, useParams } from 'react-router-dom';
import AspectRatio from '../components/common/general/AspectRatio';
import { Headline2, Headline3 } from '../styles/FontStyles';
import { BackButton } from '../styles/Buttons';
import { Wysiwyg } from '../components/common/general/Wysiwyg';
import { PageView } from '../components/layout/PageLayout';
import { useActiveLocale } from '../services/localization';
import { LoadingOverlay } from '../components/common/general/LoadingOverlay';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  && {
    grid-column: 1 / -1;
    padding: 0 var(--content-indent);

    ${BREAKPOINT_LG} {
      grid-column: 3;
      padding: 0;
    }
  }
`;

const BackButtonCustom = styled(BackButton)`
  margin-bottom: .5rem;
  align-self: flex-start;
`;

const ImageWrapper = styled(AspectRatio)`
  --aspect--ratio: 2 / 1;
  position: relative;
  margin: 0 calc(-1 * var(--content-indent)) 2.5rem;
  max-height: 60vh;
  min-width: 100%;

  ${BREAKPOINT_MD} {
    margin: 0 0 2.5rem;
  }
`;

const Image = styled.img`
  border-radius: 2px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.4));
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
`;

const Title = styled.h1`
  ${Headline2};
  margin-bottom: 1.5rem;
  
  ${BREAKPOINT_MD} {
    ${Headline3};
  }
`;

export const News = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const activeLocale = useActiveLocale();
  const newsService = useNewsAPI();

  const [news, setNews] = useState<INews | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchNews = async (id: string) => {
    return await newsService.newsById(id);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchNews(id)
        .then((response) => {
          setLoading(false);
          setNews(response.data);
        })
        .catch((err) => {
          setLoading(false);

          // navigate to 404 page
          navigate('/404', { replace: true });
        });
    } else {
      // navigate to 404 page
      navigate('/404', { replace: true });
    }
  }, [id, activeLocale]);

  return (
    <PageView>
      {news?.value && (
        <Wrapper>
          <BackButtonCustom />
          <ImageWrapper>
            <Image src={news?.value.imageUrl} alt={news?.value.title} />
          </ImageWrapper>
          <ContentWrapper>
            <Title>
              {news?.value.title}
            </Title>
            <Wysiwyg dangerouslySetInnerHTML={{ __html: news?.value.content || '' }} />
          </ContentWrapper>
        </Wrapper>
      )}
      {
        loading &&
        <LoadingOverlay visible inline />
      }
    </PageView>
  );
};
