import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const EditIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
  >
    <path
      stroke='currentColor'
      opacity='.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='m12.992 5.632 5.045 5.045'
    />
    <path
      stroke='currentColor'
      strokeWidth='1.5'
      d='M15.173 3.279a2 2 0 0 1 2.829-.001l2.595 2.595a2 2 0 0 1 .015 2.811l-9.47 9.701a3.002 3.002 0 0 1-2.148.905H6.132a1.5 1.5 0 0 1-1.498-1.563l.125-2.917a3.002 3.002 0 0 1 .877-1.994l9.537-9.537Z'
    />
  </svg>
);
