import { ICollectible } from '../../../services/collectible';
import { FC } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  
  img, object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    pointer-events: none;
    user-select: none;
  }
`;

interface ICollectibleImageProps {
  className?: string;
  collectible: ICollectible;
  animated?: boolean;
}

export const CollectibleImage: FC<ICollectibleImageProps> = (props) => {
  const {
    className,
    collectible,
    animated = false,
  } = props;

  // get image url (animated or not)
  const url = animated && collectible?.imageAnimatedUrl || collectible?.imageStaticUrl || null;

  return (
    <ImageWrapper className={className}>
      {
        // image
        url &&
        <img src={url} alt={collectible?.title || ''} />
      }
      {
        // fallback
        !url &&
        <object data={'/images/collectible-fallback.svg'} type={'image/svg+xml'} />
      }
    </ImageWrapper>
  );
};