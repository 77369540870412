import { Wallet } from 'ethers';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { PrimaryButton } from '../../../styles/Buttons';
import { useAuthAPI } from '../../../services/auth';
import { useNavigate } from 'react-router-dom';
import WalletBackup from '../../common/special/WalletBackup';
import ConfirmPassword from '../settings/ConfirmPassword';
import { useGetLocalizedString } from '../../../services/localization';
import { PageViewHeadline } from '../../../styles/FontStyles';
import { useWallet } from '../../../hooks/useWallet';
import { PageView } from '../../layout/PageLayout';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  align-self: center;
  
  ${BREAKPOINT_LG} {
    align-items: center;
    flex-direction: row;
    gap: 8.75rem
  }
`;

const ContentWrapper = styled.div`
  font-size: .875rem;
  line-height: 1.25rem;
  grid-column-start: 2;
  grid-column-end: 4;
`;

const RedirectButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 3.5rem;
  
  ${BREAKPOINT_SM} {
    gap: 2rem;
  }

  ${BREAKPOINT_LG} {
    flex-direction: row;
    margin-top: 6.25rem;

    button {
      min-width: unset;
      flex: 1;
    }
  }
`;

// TODO Backup to own view
// TODO Move Logout Process to pages
const BackupWallet = () => {
  const getLocalizedString = useGetLocalizedString();
  const navigate = useNavigate();
  const authAPI = useAuthAPI();
  const [wallet, setWallet] = useState<Wallet | undefined>();
  const { decryptWallet } = useWallet();

  const onPasswordInput = async (password: string) => {
    const decryptedWallet = await decryptWallet(password);
    setWallet(decryptedWallet);
  };

  const logout = useCallback(() => {
    authAPI.logout().then(() => {
      navigate('/');
    });
  }, [authAPI, navigate]);

  return wallet ? (
    <PageView>
      <Wrapper>
        <PageViewHeadline>{getLocalizedString('app.v2.wallet-backup.headline')}</PageViewHeadline>
        <ContentWrapper>
          <WalletBackup wallet={wallet} showCopyPrivateKey={true}>
            {({ saved }) => (
              <RedirectButtonWrapper>
                <PrimaryButton onClick={() => navigate('/')}>
                  {getLocalizedString('app.v2.wallet-backup.back-button-label')}
                </PrimaryButton>
                <PrimaryButton onClick={logout} disabled={!saved}>
                  {getLocalizedString('app.v2.wallet-decrypt.logout-button-label')}
                </PrimaryButton>
              </RedirectButtonWrapper>
            )}
          </WalletBackup>
        </ContentWrapper>
      </Wrapper>
    </PageView>
  ) : (
    <ConfirmPassword onSuccess={onPasswordInput} title={getLocalizedString('app.v2.wallet-decrypt.description')} />
  );
};

export default BackupWallet;