import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveLocale, useGetLocalizedString } from '../services/localization';

interface ICurrencyContextProps {
  currencies: IPriceInCurrency[] | null;
  selectedCurrency: IPriceInCurrency | null;
}

interface IPriceInCurrency {
  currencySymbol: string;
  currencyShortcode: string;
}

const CurrencyContext = createContext<Partial<ICurrencyContextProps>>({});

export const CurrencyContextProvider: FC<PropsWithChildren> = (props) => {
  const getLocalizedString = useGetLocalizedString();
  const activeLocale = useActiveLocale();
  const navigate = useNavigate();

  const [currencies, setCurrencies] = useState<IPriceInCurrency[] | null>([
    {
      currencySymbol: '€',
      currencyShortcode: 'EUR',
    },
    {
      currencySymbol: 'arg$',
      currencyShortcode: 'ARS',
    },
  ]);
  const [selectedCurrency, setSelectedCurrency] = useState<IPriceInCurrency | null>({
    currencySymbol: '€',
    currencyShortcode: 'EUR',
  });

  const context = {
    currencies,
    selectedCurrency,
  };

  return <CurrencyContext.Provider value={context}>{props.children}</CurrencyContext.Provider>;
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);

  return {
    ...(context as ICurrencyContextProps),
  };
};
