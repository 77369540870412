import { useAPIInstance } from './index';

export interface ITermList {
  terms: ITermListItem[];
}

export interface ITermListItem {
  _id: string;
  language: string;
  content: string;
}

export interface IAcceptTermsData {
  termsId: string;
}

export interface IAcceptTermsResponse {
  success: boolean;
}

export const useTermsAPI = () => {
  const apiInstance = useAPIInstance();

  const latestTerms = async () => {
    return await apiInstance.get<ITermList>(`/terms/latest`);
  };

  const acceptTerms = (acceptTermsData: IAcceptTermsData) => {
    return apiInstance.post<IAcceptTermsResponse>('/terms/accept', acceptTermsData);
  };

  return {
    latestTerms,
    acceptTerms
  };
};
