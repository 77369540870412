import styled from 'styled-components';
import { FC, useEffect, useRef, useState } from 'react';
import { BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { PlayIcon } from '../../../icons/PlayIcon';
import { ICollectibleContentItem } from '../../../../services/collectible';
import { useActiveLocale } from '../../../../services/localization';

const Wrapper = styled.section`
  position: relative;
  margin: 0 calc(-1 * var(--content-indent));
  width: calc(2 * var(--content-indent) + 100%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: flex-end;

  ${BREAKPOINT_MD} {
    margin: 0;
    width: 100%;
  }
`;

const StyledVideo = styled.video`
  height: auto;
  width: 100%;
  object-fit: contain;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

const PlayButton = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  border-radius: 100%;
  background: linear-gradient(144.32deg, rgba(255, 255, 255, 0.24) 11.51%, rgba(255, 255, 255, 0) 94.95%), rgba(75, 75, 75, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  font-size: 3rem;
  pointer-events: none;
  opacity: ${({ visible }) => visible ? 1 : 0};
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  transition: opacity 450ms ease, visibility 450ms ease;

  svg {
    transform: translateX(.125rem);
  }
`;

const Title = styled.p`
  color: var(--color-primary);
  margin-right: var(--content-indent);
  margin-bottom: 2.5rem;

  ${BREAKPOINT_MD} {
    margin-right: 0;
    margin-bottom: 9rem;
  }
`;

interface VideoSectionProps extends ICollectibleContentItem {
  videoUrl: string;
  title?: string;
}

export const VideoSection: FC<VideoSectionProps> = (props) => {
  const { type, videoUrl, title } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  const activeLocale = useActiveLocale();

  const translatedTitle = Array.isArray(title) ? title.find(t => t.language === activeLocale)?.description : title

  const handlePlayPauseVideo = (e: any) => {
    if (e.currentTarget.paused) {
      e.currentTarget.play();
      setVideoPlaying(true);
    } else {
      e.currentTarget.pause();
      setVideoPlaying(false);
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.75,
    };

    const intersectionHandler = (e: any) => {
      if (e[0].isIntersecting) {
        setVideoPlaying(true);
        videoRef.current?.play();
      } else {
        setVideoPlaying(false);
        videoRef.current?.pause();
      }
    };

    const observer = new IntersectionObserver(intersectionHandler, observerOptions);
    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  return (
    <Wrapper data-type={type}>
      <StyledVideo src={videoUrl} ref={videoRef} onClick={handlePlayPauseVideo} playsInline muted loop />
      <PlayButton visible={!videoPlaying}><PlayIcon /></PlayButton>
      {translatedTitle && <Title>{translatedTitle}</Title>}
    </Wrapper>
  );
};