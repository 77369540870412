import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'wallet',
  storage: localStorage,
});

/*
 * THIS STATE ISN'T MEANT TO BE USED INSIDE OF COMPONENTS
 * 
 * The wallet state only contains the encrypted Wallet as JSON.
 * To use the wallet use the decrpyt function inside of the useWallet hook.
 * The unencrypted wallet or mnemonic shall never be saved in any persitent
 * kind of way.
 */
export const WalletState = atom<string | undefined>({
  key: 'Wallet',
  default: undefined,
  effects_UNSTABLE: [
    persistAtom
  ],
});