import { FC } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import { useGetLocalizedString } from '../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from '../../styles/Breakpoints';
import { SecondaryButton } from '../../styles/Buttons';
import { SOCIAL_MEDIA_LINKS } from '../layout/Menu';
import { LinkHandler } from './general/LinkHandler';

const Wrapper = styled.section`
  margin: 10px 0 50px 0;

  && {
    grid-column: 1 / -1;

    ${BREAKPOINT_LG} {
      grid-column: 3;
    }
  }
`;

const Container = styled.article`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0;
  width: 100%;
  height: auto;

  ${BREAKPOINT_MD} {
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  grid-column: 1 / -1;
  order: 1;
  display: block;
  z-index: 1;
  padding: 0 10px;

  ${BREAKPOINT_MD} {
    grid-column: 1 / 6;
    order: 1;
    height: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  grid-column: 1 / -1;
  order: 2;
  padding: 0 10px;

  ${BREAKPOINT_MD} {
    order: 2;
    grid-column: 6 / -1;
  }
`;

const RoadMapHeadline = styled.h4`
  margin: 2rem 0;
`;

const RoadMapContentParagraph = styled.p`
  margin-bottom: 1rem;
`;

const DiscordButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
  align-self: center;
  margin-top: 3rem;
`;

export const Roadmap: FC<any> = (props) => {
  const desktopSrc = 'images/utility_map_desktop.png';
  const mobileSrc = 'images/utility_map_mobile.png';

  const getLocalizedString = useGetLocalizedString();

  const paragraphOne = getLocalizedString('app.v2.home.utilities.text.one');
  const paragraphTwo = getLocalizedString('app.v2.home.utilities.text.two');
  const paragraphThree = getLocalizedString('app.v2.home.utilities.text.three');

  return (
    <Wrapper>
      {/* <Header>
        <Headline>{getLocalizedString('app.v2.home.utilities')}</Headline>
      </Header> */}

      <Container>
        <Content>
          {paragraphOne && <RoadMapHeadline>💡{paragraphOne}</RoadMapHeadline>}
          {paragraphTwo && <RoadMapContentParagraph>{paragraphTwo}</RoadMapContentParagraph>}
          {paragraphThree && <RoadMapContentParagraph>{paragraphThree}</RoadMapContentParagraph>}

          {/* <DiscordButton to={SOCIAL_MEDIA_LINKS.discord}>{getLocalizedString('app.v2.home.utilities.text.join-discord')}</DiscordButton> */}
        </Content>
        <ImageWrapper>
          <picture>
            <source media={`(min-width: ${DIMEN_BREAKPOINT_MD}px)`} srcSet={desktopSrc} />
            <img aria-hidden='true' src={mobileSrc} alt={getLocalizedString('app.v2.home.utilities')} />
          </picture>
        </ImageWrapper>
      </Container>
    </Wrapper>
  );
};
