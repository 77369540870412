import { Link, LinkProps } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';
import * as React from 'react';

export type TLink = PropsWithChildren<LinkProps & React.RefAttributes<HTMLAnchorElement>>;

export const LinkHandler: FC<TLink> = (props) => {
  if (typeof props.to === 'string' && (props.to.startsWith('http') || props.to.startsWith('https'))) {
    const { to, children, ...aProps } = props;
    return <a {...aProps} target={'_blank'} rel={'noreferrer noopener'} href={to}>{children}</a>;
  }

  return (
    <Link {...props} />
  );
};