import styled, { css } from 'styled-components';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useGetLocalizedString, useLocalizedString } from '../../../services/localization';
import { ChevronIcon } from '../../icons/ChevronIcon';
import { SecondaryButton } from '../../../styles/Buttons';
import { CloseIcon } from '../../icons/CloseIcon';
import { BREAKPOINT_MD, BREAKPOINT_SM, MAX_BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { useDataCache } from '../../../state/dataCache';
import { usePublisherAPI } from '../../../services/publisher';
import { Dropdown } from '../forms/Dropdown';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { useFormContext } from 'react-hook-form';

const ResetIconWrapper = styled.div``;

const SelectIcon = styled(ChevronDownIcon)`
  position: absolute;
  width: 1rem;
  height: 1rem;
  bottom: 1.5rem;
  transform: translateY(50%);
  right: 1rem;
  line-height: 1;
  pointer-events: none;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
  width: 250px;

  ${MAX_BREAKPOINT_SM} {
  }
`;

const ResetButton = styled.button`
  background-color: grey;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  font-weight: bold;
`;

export const StyledSelect = styled.select<{ error?: boolean }>`
  padding: 0.75rem 3rem 0.75rem 1rem;
  border: 1px solid var(--color-text-default);
  border-radius: 2px;
  appearance: none;
  caret-color: var(--color-primary);
  font-size: 1rem;
  line-height: 1;
  background: rgba(26, 26, 26, 0.4);
  backdrop-filter: blur(0.25rem);
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-overflow: ellipsis;

  :focus {
    border-color: var(--color-primary);
  }

  :disabled {
    border-color: var(--color-grey-700);
  }

  ${({ error }) =>
    error &&
    css`
      border-color: var(--color-signal-red);
    `}
`;

export type FilterType = 'trending' | 'top' | 'latest' | 'upcoming' | 'creator' | null;

interface CreatorCollectiblesFilterProps {
  selectedCreator: string;
  setSelectedCreator: (value: string) => void;
  setSelectedCreatorTitle: (value: string) => void;
  selectedFilter: FilterType;
  setSelectedFilter: (value: FilterType) => void;
}

export const CreatorCollectiblesFilter: FC<CreatorCollectiblesFilterProps> = (props) => {
  const getLocalizedString = useGetLocalizedString();
  const { selectedCreator, setSelectedFilter, setSelectedCreator, setSelectedCreatorTitle } = props;

  const { allPublishers } = usePublisherAPI();

  const [dataCache, setDataCache] = useDataCache();
  const { publishers = null } = dataCache;

  const fetchData = async () => {
    const publishers = await allPublishers(0, 15).catch(() => null);
    setDataCache((state) => ({
      ...state,
      publishers: publishers?.data || undefined,
    }));
  };

  const resetFilter = () => {
    setSelectedCreator('');
    setSelectedFilter(null);
  };

  useEffect(() => {
    if (!publishers) {
      fetchData();
    }
  }, []);

  const onChange = (event: any) => {
    const value = event.target.value;

    const c = publishers?.publishers.find((p) => p.id === value);
    if (c) {
      setSelectedCreatorTitle(c.name);
    }

    if (value) {
      setSelectedCreator(value);
      setSelectedFilter('creator');
    } else {
      setSelectedCreator('');
      setSelectedFilter(null);
    }
  };

  return (
    <>
      <SelectWrapper className='select-wrapper'>
        <StyledSelect value={selectedCreator} onChange={onChange}>
          <option key={0} value={''}>
            {getLocalizedString('app.v2.collectibles.filter.creator')}
          </option>
          {publishers?.publishers
            .filter((p) => p.name)
            .map(({ id, name }, index) => (
              <option key={index + 1} value={id}>
                {name}
              </option>
            ))}
        </StyledSelect>
        <SelectIcon />
      </SelectWrapper>
      <ResetIconWrapper>
        {selectedCreator && (
          <ResetButton type='button' onClick={resetFilter}>
            X
          </ResetButton>
        )}
      </ResetIconWrapper>
    </>
  );
};
