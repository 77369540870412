import styled, { css } from 'styled-components';
import { BasicGridWrapper } from '../../general/Grids';
import { FC, useEffect, useRef } from 'react';
import { IAlbumContentItem, IAlbumDetails } from '../../../../services/albums';
import Text from '../../general/Text';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { Body1 } from '../../../../styles/FontStyles';
import { SecondaryButton } from 'src/styles/Buttons';
import { LinkHandler } from '../../general/LinkHandler';
import { useGetLocalizedString } from '../../../../services/localization';

const Wrapper = styled(BasicGridWrapper).attrs({ as: 'section' })<{ imgLeft: boolean }>`
  grid-template-rows: 1fr 20%;

  ${BREAKPOINT_MD} {
    grid-template-rows: 50% 50%;
  }

  --translate-x: 0vw;
  transition: none;
`;

const ImageWrapper = styled.div<{ imgLeft: boolean }>`
  z-index: -1;
  grid-column: 1/ -1;
  grid-row: 1 / 3;
  max-height: min(42rem, 50vh);
  margin-right: ${({ imgLeft }) => imgLeft ? '0' : '-50vw'};
  margin-left: ${({ imgLeft }) => imgLeft ? '-50vw' : '0'};
  padding: 0 ${({ imgLeft }) => imgLeft ? '5vw 0 0' : '0 0 5vw'};
  transform: translateX(${({ imgLeft }) => imgLeft ? 'var(--translate-x)' : 'calc(var(--translate-x) * -1)'});
  transition: inherit;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  ${BREAKPOINT_MD} {
    grid-column: ${({ imgLeft }) => imgLeft ? '1 / 7' : '7 / -1'};
    margin: 0;
    padding: 0;
  }
`;

const Title = styled.h5<{ imgLeft: boolean }>`
  grid-column: 2 / -2;
  grid-row: 1;
  align-self: flex-end;
  font-size: 2rem;
  line-height: 1.125;
  text-align: ${({ imgLeft }) => imgLeft ? 'end' : 'start'};
  transform: translateX(${({ imgLeft }) => imgLeft ? 'calc(var(--translate-x) * -1)' : 'var(--translate-x)'});
  transition: inherit;

  ${BREAKPOINT_MD} {
    grid-column: ${({ imgLeft }) => imgLeft ? '7 / -2' : '2 / 7'};
  }

  ${BREAKPOINT_LG} {
    font-size: 3rem;
  }
`;

const CTAWrapper = styled.div<{ imgLeft: boolean }>`
  ${Body1};
  text-align: ${({ imgLeft }) => imgLeft ? 'end' : 'start'};
  grid-column: 2 / -2;
  grid-row: 2;
  transform: translateX(${({ imgLeft }) => imgLeft ? 'calc(var(--translate-x) * -1)' : 'var(--translate-x)'});
  transition: inherit;

  ${BREAKPOINT_MD} {
    grid-column: ${({ imgLeft }) => imgLeft ? '7 / -2' : '2 / 7'};
  }
`;

const StyledText = styled(Text)`
`;

const CTAButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string, imgLeft: boolean }>`
  position: absolute;
  right: ${({ imgLeft }) => imgLeft ? 0 : 'auto'};;
`;

interface TextImageSectionProps extends IAlbumContentItem {
  album: IAlbumDetails;
  direction: 'right' | 'left';
  image: string;
  text: string;
  title: string;
  ctaLink: string;
}

export const TextImageSection: FC<TextImageSectionProps> = (props) => {
  const { type, image, title, text, direction, ctaLink } = props;

  const sectionRef = useRef<HTMLDivElement | null>(null);
  const getLocalizedString = useGetLocalizedString();

  useEffect(() => {
    const scrollHandler = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const sectionHeight = sectionRef.current.scrollHeight;

        const useCSSTransition = true;

        const sectionCenter = top + ((bottom - top) / 2);
        const scrollDelta = Math.max(0, Math.min(2, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2)))) / 2;

        let translateX = 0;

        if (bottom < sectionHeight + screenHeight && bottom > 0) {
          const progress = Math.min(Math.max((screenHeight + sectionHeight - bottom) / ((screenHeight + sectionHeight) / 100), 0), 100);
          if (progress < 40) {
            translateX = -50 + progress * 1.25;
          } else if (progress > 40 && progress < 60) {
            translateX = 0;
          } else if (progress > 60) {
            translateX = (progress - 60) * 1.25;
          }
        }

        if (useCSSTransition) {
          if (scrollDelta < .15) {
            translateX = -50;
          } else if (scrollDelta > .75) {
            translateX = 50;
          } else {
            translateX = 0;
          }
        }

        sectionRef.current.style.transition = useCSSTransition ? 'transform .5s ease-out' : 'none';
        sectionRef.current.style.setProperty('--translate-x', `${translateX}vw`);
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    scrollHandler();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);
  
  return (
    <Wrapper imgLeft={direction === 'left'} data-type={type} data-direction={direction} ref={sectionRef}>
      <ImageWrapper imgLeft={direction === 'left'}><img src={image} alt={title} /></ImageWrapper>
      <Title imgLeft={direction === 'left'}> {title}</Title>
      <CTAWrapper imgLeft={direction === 'left'}>
        <StyledText content={text}/>
        <br />
        { ctaLink &&
          <CTAButton to={ctaLink} imgLeft={direction === 'left'}>{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTAButton>
        }
      </CTAWrapper>
    </Wrapper>
  );
};