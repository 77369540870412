import styled, { keyframes } from 'styled-components';
import { useRecoilValue } from 'recoil';
import { SystemNotificationState } from '../../state/systemNotifications';
import { Notification } from '../common/general/Notification';

const SlideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  20% {
    opacity: 1;
    transform: translateX(0);
  }

  80% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 20;
  bottom: var(--content-indent);
  right: var(--content-indent);

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  gap: 1rem;

  transition: height .5s ease;
`;

const StyledNotification = styled(Notification)`
  animation: ${SlideInAnimation} 7s ease;
  max-width: calc(100% - 2 * var(--content-indent));
`;

export const SystemNotifications = () => {
  const notifications = useRecoilValue(SystemNotificationState);

  return (
    <Wrapper>
      {
        notifications.map(({ type, headline, text, id }) => (
          <StyledNotification key={id} type={type} headline={headline} text={text} />
        ))
      }
    </Wrapper>
  );
};