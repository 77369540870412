import { FC } from 'react';
import { IconProps } from '../../types/Icon';
import styled from 'styled-components';

const SVG = styled.svg`
  transform: ${({ direction = false }) => direction === 'right' ? 'rotate(-90deg)' : ''};
  transform: ${({ direction = false }) => direction === 'right' ? '' : 'translateY(5px)'};
  margin-left: 5px;
  .background {
    fill: white;
    width: 2px;
  }
`;

export const TrianglePointer: FC<IconProps> = ({ className, direction }) => (
  <SVG direction={direction} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" overflow="visible">
    <polygon className={'background'} points="530.874,0.5 265.87,459.5 0.866,0.5 " />
  </SVG>
);