import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const CameraIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 9C3 7.89543 3.89543 7 5 7H6.20683C6.99385 7 7.70771 6.53843 8.03067 5.82073L8.31933 5.17927C8.64229 4.46157 9.35615 4 10.1432 4H12H13.8568C14.6439 4 15.3577 4.46157 15.6807 5.17927L15.9693 5.82073C16.2923 6.53843 17.0061 7 17.7932 7H19C20.1046 7 21 7.89543 21 9V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9Z" stroke="white" strokeWidth="1.5"/>
    <circle r="3" transform="matrix(-1 0 0 1 12 12)" stroke="#ADADAD" strokeWidth="1.5"/>
  </svg>
);

export default CameraIcon;
