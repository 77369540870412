// General Imports
import React, { useImperativeHandle } from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// Utils
import generateQRCode from "../../../utils/generateQRCode";

// Styles
const QRCodeContainer = styled.div<{ isLoading: boolean, hidden: boolean }>`
  ${({ isLoading, hidden }) => isLoading || hidden ? (`
      position: absolute;
      z-index: 0;
      opacity: 0;
      pointer-events: none;
      width: 0;
      height: 0;
    `) : (`
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      justify-content: center;
      align-items: center;
  `)}
`;

interface IQRCodeProps {
  content: string;
  showCode?: boolean;
  width?: number;
}

const QRCode = React.forwardRef<HTMLCanvasElement, IQRCodeProps>(({
  content,
  showCode = false,
  width = 500,
}, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const canvas = useRef<HTMLCanvasElement>(null);

  useImperativeHandle(ref, () => canvas.current as HTMLCanvasElement);

  /**
   * Generate the qr code to be displayed
   */
  const generate = async () => {
    if (!canvas.current) return;
    await generateQRCode(content, canvas.current, width);
    setIsLoading(false);
  }

  useEffect(() => {
    generate();
  }, [canvas]);   // eslint-disable-line react-hooks/exhaustive-deps
  
 return (
   <QRCodeContainer isLoading={isLoading} hidden={!showCode}>
    <canvas ref={canvas} />
   </QRCodeContainer>
 ) 
});

export default QRCode;