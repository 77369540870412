import styled from 'styled-components';
import { FC, useEffect, useRef } from 'react';
import { useGetLocalizedString } from '../../../services/localization';
import throttle from '../../../utils/throttle';
import { BREAKPOINT_LG, BREAKPOINT_XL, BREAKPOINT_XS } from '../../../styles/Breakpoints';
import { Body2Bold } from '../../../styles/FontStyles';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  border-radius: 2rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(75, 75, 75, 0.2);
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1px);
  width: 100%;
  justify-content: space-between;

  ${BREAKPOINT_XS} {
    width: auto;
    justify-content: center;
    gap: 1rem;
  }
`;

const Item = styled.button<{ active: boolean }>`
  ${Body2Bold};
  line-height: 1;
  z-index: 1;
  padding: .325rem .875rem;
  color: var(--color-${({ active }) => active ? 'text-highlight' : 'grey-600'});
  transition: color 450ms ease;

  ${BREAKPOINT_LG} {
    line-height: 1;
    padding: .75rem 2rem;
  }

  ${BREAKPOINT_XL} {
    font-size: 1.25rem;
  }
`;

const Indicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-text-default);
  border-radius: 2rem;
  height: 100%;
  width: 10%;
  transition: width 450ms ease, left 450ms ease;
`;

interface ProfileNavigationProps {
  selected: number;
  setSelected: (value: number) => void;
}

export const ProfileNavigation: FC<ProfileNavigationProps> = (props) => {
  const { selected, setSelected } = props;
  const getLocalizedString = useGetLocalizedString();
  const indicatorRef = useRef<HTMLSpanElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (e: any, index: number) => {
    setSelected(index);
    if (indicatorRef.current) {
      indicatorRef.current.style.left = `${e.currentTarget.offsetLeft}px`;
      indicatorRef.current.style.width = `${e.currentTarget.getBoundingClientRect().width}px`;
    }
  };

  const resizeHandler = () => {
    if (indicatorRef.current && wrapperRef.current) {
      indicatorRef.current.style.left = `${(wrapperRef.current?.children.item(selected) as HTMLElement).offsetLeft}px`;
      indicatorRef.current.style.width = `${(wrapperRef.current?.children.item(selected) as HTMLElement).getBoundingClientRect().width}px`;
    }
  };

  useEffect(() => {
    const listener = throttle(resizeHandler, 50);
    const observer = new ResizeObserver(listener);
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
      resizeHandler();
    }
    return () => {
      observer.disconnect();
    };
  }, [resizeHandler]);

  return (
    <Wrapper ref={wrapperRef}>
      <Indicator ref={indicatorRef} />
      <Item active={selected === 1} onClick={(e) => handleClick(e, 1)}>
        {getLocalizedString('app.v2.profile.category.collectibles')}
      </Item>
      <Item active={selected === 2} onClick={(e) => handleClick(e, 2)}>
        {getLocalizedString('app.v2.profile.category.albums')}
      </Item>
      {/* Damiano Giampaoli 17/05/2023 - Disabling favourites to make space for claimable token tab in profile */}
      {/* <Item active={selected === 3} onClick={(e) => handleClick(e, 3)}>
        {getLocalizedString('app.v2.profile.category.favorites')}
      </Item> */}
      <Item active={selected === 3} onClick={(e) => handleClick(e, 3)}>
        {getLocalizedString('app.v2.profile.category.claimabletokens')}
      </Item>
    </Wrapper>
  );
};