import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WalletImport from "../components/views/login/WalletImport";
import ConfirmPassword from "../components/views/settings/ConfirmPassword";
import { useGetLocalizedString } from "../services/localization";
import { useAddSystemNotification } from '../state/systemNotifications';

/**
 * Page that handles the wallet connect process including
 * request user password and importing the wallet
 */
const ConnectWallet = () => {
  const getLocalizedString = useGetLocalizedString();
  const navigate = useNavigate();
  const location = useLocation();
  const { redirect = '/profile', password = null } = location.state || {};
  const [_password, setPassword] = useState(password);
  const addSystemNotification = useAddSystemNotification();

  const onPasswordInput = async (password: string) => {
    setPassword(password)
  };

  const onImport = () => {
    navigate(redirect);
    addSystemNotification({
      type: 'success',
      headline: getLocalizedString('app.v2.verify-wallet.notification.success.title'),
      text: getLocalizedString('app.v2.verify-wallet.notification.success.text'),
    });
  }

  return _password ? (
    <WalletImport 
      onImport={onImport} 
      password={_password} 
    />
  ) : (
    <ConfirmPassword 
      onSuccess={onPasswordInput} 
      title={getLocalizedString('app.v2.wallet-import.password-input-title')} 
    />
  );
}

export default ConnectWallet;
