import { atom, useSetRecoilState } from 'recoil';

interface NotificationProps {
  id: number;
  type: 'info' | 'success' | 'alert' | 'error',
  headline: string;
  text?: string;
}

export const SystemNotificationState = atom<NotificationProps[]>({
  key: 'SystemNotifications',
  default: [],
});

export const useAddSystemNotification = () => {
  const setNotifications = useSetRecoilState(SystemNotificationState);

  return (notification: Omit<NotificationProps, 'id'>, timeout: number = 7000) => {
    const time = new Date().getTime();

    setNotifications((state) => [
      ...state,
      { ...notification, id: time },
    ]);

    setTimeout(() => {
      setNotifications((state) => state.filter(({ id }) => id !== time));
    }, timeout);
  };
};