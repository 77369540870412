import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const SpinArrowIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.1737 13C20.1158 12.5 22 11.5 22 10C22 8.5 19.1737 6 12.5791 6C6.26598 6 -0.139283 8.50014 2.68703 11.5001C5.04231 14.0001 10.5579 14 11.5 14'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M12 11L14.2929 13.2929C14.6834 13.6834 14.6834 14.3166 14.2929 14.7071L13.75 15.25L12 17'
      stroke='currentColor'
      opacity={.75}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);