import styled, { css } from 'styled-components';
import { FC } from 'react';
import { EditIcon } from '../../icons/EditIcon';
import { PolygonIcon } from '../../icons/PolygonIcon';
import CopyIcon from '../../icons/CopyIcon';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { IProfile } from '../../../services/profile';
import { useGetLocalizedString, useLocalizedString } from '../../../services/localization';
import { useWallet } from '../../../hooks/useWallet';
import { LinkHandler } from '../general/LinkHandler';
import { useAddSystemNotification } from '../../../state/systemNotifications';
import { FanseaImage } from '../FanseaImage';
import { CloudinaryQuality } from '../../../hooks/useCloudinary';
import { useAccount } from 'wagmi';

const Wrapper = styled.div<Props>`
  height: ${({ positionHeader }) => (positionHeader ? '0' : 'auto')};

  ${({ positionHeader }) =>
    positionHeader &&
    css`
      display: none;

      ${BREAKPOINT_MD} {
        display: block;
      }
    `};
`;

const ProfileWrapper = styled.div<Props>`
  display: flex;
  flex-direction: ${({ positionHeader }) => (positionHeader ? 'row-reverse' : 'column')};
  align-items: ${({ positionHeader }) => (positionHeader ? 'flex-start' : 'center')};
`;

const ImageWrapper = styled.div<Props>`
  display: inline-block;
  position: relative;
  margin-bottom: 0.375rem;
  margin-left: ${({ positionHeader }) => (positionHeader ? '1.25rem' : '0')};
  line-height: 0;
  flex-shrink: 0;

  ${BREAKPOINT_MD} {
    margin-bottom: ${({ positionHeader }) => (positionHeader ? '0' : '2rem')};
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
`;

const EditIconWrapper = styled.div<Props>`
  display: none;
  position: absolute;
  top: 0;
  left: ${({ collectiblesAvailable }) => (collectiblesAvailable ? '-.5rem' : '0')};
  display: flex;
  opacity: ${({ positionHeader }) => (positionHeader ? '0' : '1')};
  width: 1rem;
  height: 1rem;

  svg {
    width: 100%;
    height: auto;
  }

  ${BREAKPOINT_MD} {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${({ positionHeader }) =>
    positionHeader &&
    css`
      align-items: center;
      justify-content: center;

      height: auto;
      width: auto;
      top: 0.125rem;
      right: 0.125rem;
      bottom: 0.125rem;
      left: 0.125rem;
      border-radius: 50%;

      background-blend-mode: overlay, normal;
      backdrop-filter: blur(5px);

      transition: opacity 0.2s;

      a {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      :hover {
        opacity: 1;
      }
    `};
`;

const Image = styled.picture<Props>`
  img {
    height: 3.375rem;
    width: 3.375rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    background-color: var(--color-grey-800);
    border: ${({ positionHeader }) => (positionHeader ? '.125rem solid var(--color-primary)' : 'none')};

    ${({ positionHeader }) =>
      positionHeader &&
      css`
        ${BREAKPOINT_MD} {
          height: 4.5rem;
          width: 4.5rem;
        }
      `};

    ${({ positionHeader, collectiblesAvailable }) =>
      !positionHeader &&
      css`
        ${BREAKPOINT_MD} {
          height: ${collectiblesAvailable ? '8rem' : '11.75rem'};
          width: ${collectiblesAvailable ? '8rem' : '11.75rem'};
          transition: width 450ms ease, height 450ms ease;
        }
      `};
  }
`;

const ProfileName = styled.div<Props>`
  text-align: ${({ positionHeader }) => (positionHeader ? 'right' : 'center')};
  font-size: ${({ positionHeader }) => (positionHeader ? '.875rem' : '1rem')};
  font-weight: bold;
  margin-bottom: ${({ positionHeader }) => (positionHeader ? '0' : '.375rem')};
`;

const ConnectedWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 0.875rem;
`;

const WalletInfoWrapper = styled.div<{ positionHeader: boolean }>`
  width: 100%;
  display: ${({ positionHeader }) => (positionHeader ? 'flex' : 'grid')};
  grid-template-columns: 1fr min-content 1fr;
  gap: 0.5rem;
`;

const ConnectWallet = styled.button`
  display: flex;
  justify-self: flex-end;
  color: var(--color-signal-orange);
  text-decoration: underline;
`;

const WalletConnected = styled.span`
  display: flex;
  justify-self: flex-end;
  color: var(--color-signal-green);
  white-space: nowrap;
`;

const WalletNotConnected = styled.span`
  display: flex;
  justify-self: flex-end;
  color: var(--color-signal-orange);
  white-space: nowrap;
`;

const WalletID = styled.span`
  justify-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CopyIconWrapper = styled.div`
  height: 1.125rem;
  width: 1.125rem;
  margin-top: -0.25rem;
  cursor: pointer;

  svg {
    width: auto;
    height: 100%;
  }
`;

interface Props {
  collectiblesAvailable?: boolean;
  positionHeader?: boolean;
  userImage?: string;
}

interface IProfileTopProps extends Props {
  onConnect?: () => any;
  className?: string;
  profileData: IProfile | null;
}

export const ProfileTop: FC<IProfileTopProps> = (props) => {
  const { collectiblesAvailable, positionHeader = false, className, profileData } = props;

  const getLocalizedString = useGetLocalizedString();
  const navigate = useNavigate();
  const location = useLocation();

  const { encryptedWallet } = useWallet();

  const { address, isConnected } = useAccount();

  const userImageAlt = useLocalizedString('app.v2.profile.user.image.alt');
  const userWalletConnected = useLocalizedString('app.v2.profile.user.wallet.connected');
  const userWalletConnect = useLocalizedString('app.v2.profile.user.wallet.connect');
  const userWalletPolygonscanLink = useLocalizedString('app.v2.profile.user.wallet.userWalletPolygonscanLink');

  const addSystemNotification = useAddSystemNotification();

  const copyWalletAddress = () => {
    if (profileData?.profile?.walletAddress) {
      navigator.clipboard.writeText(profileData?.profile?.walletAddress);
      addSystemNotification({
        type: 'success',
        headline: getLocalizedString('app.v2.create-wallet.notification.copied-public-key.title'),
        text: getLocalizedString('app.v2.create-wallet.notification.copied-public-key.text'),
      });
    }
  };

  const walletID = profileData?.profile?.walletAddress;
  // Shorten Wallet ID
  const shortedWalletID =
    profileData?.profile?.walletAddress?.slice(0, 4) + '...' + profileData?.profile?.walletAddress?.slice(-4) || 'xxxx...xxxx';
  return (
    <Wrapper positionHeader={positionHeader} className={className}>
      <ProfileWrapper positionHeader={positionHeader}>
        <ImageWrapper positionHeader={positionHeader}>
          <EditIconWrapper collectiblesAvailable={collectiblesAvailable} positionHeader={positionHeader}>
            <LinkHandler to={'/profile/edit'}>
              <EditIcon />
            </LinkHandler>
          </EditIconWrapper>
          <LinkHandler to={'/profile/edit'}>
            <Image collectiblesAvailable={collectiblesAvailable} positionHeader={positionHeader}>
              <FanseaImage
                altText={userImageAlt}
                imageUrl={profileData?.profile.imageUrl ? profileData?.profile.imageUrl : 'images/UserImageFallback.png'}
                isJpegConverted
                size='256'
                quality={CloudinaryQuality.GOOD}
              />
            </Image>
          </LinkHandler>
        </ImageWrapper>
        <InfoWrapper>
          <ProfileName positionHeader={positionHeader}>{profileData?.profile.username}</ProfileName>
          <ConnectedWrapper>
            <WalletInfoWrapper positionHeader={positionHeader}>
              {typeof encryptedWallet === 'string' ? (
                <WalletConnected>{userWalletConnected}</WalletConnected>
              ) : (
                <ConnectWallet onClick={() => navigate('/connect-wallet', { state: { redirect: location } })}>
                  {userWalletConnect}
                </ConnectWallet>
              )}
              <WalletID>
                <span>|</span>
                {shortedWalletID}
                <CopyIconWrapper onClick={copyWalletAddress}>
                  <CopyIcon />
                </CopyIconWrapper>
                <span>|</span>
              </WalletID>
              <LinkHandler to={process.env.REACT_APP_POLYGONSCAN_BASE_URL + '/address/' + walletID + '/#tokentxnsErc721'}>
                {userWalletPolygonscanLink}&nbsp;
                <PolygonIcon style={{ width: '18px', height: '18px' }} />
              </LinkHandler>
            </WalletInfoWrapper>
          </ConnectedWrapper>
        </InfoWrapper>
      </ProfileWrapper>
    </Wrapper>
  );
};
