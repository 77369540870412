import styled, { css } from 'styled-components';
import { BasicGridWrapper } from '../general/Grids';
import { FC, useEffect, useRef } from 'react';
import { IAlbumContentItem, IAlbumDetails } from '../../../services/albums';
import Text from '../general/Text';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Body1, Headline4, Headline5, Headline6 } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';

const Wrapper = styled(BasicGridWrapper).attrs({ as: 'section' })`
  grid-template-rows: 1fr 20%;

  ${BREAKPOINT_MD} {
    grid-template-rows: 50% 50%;
  }

  --translate-x: 0vw;
  transition: none;
  height: 80vh;
  text-align: middle;
`;

const ImageWrapper = styled.div`
  z-index: -1;
  grid-column: 1/ -1;
  grid-row: 1 / 8;
  max-height: 30vh;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const TokenTitle = styled.p`
  ${Headline6}
  text-align: center;
`

const Description = styled.p`
  text-align: center;
`

const StyledText = styled(Text)<{ imgLeft: boolean }>`
  ${Body1};
  text-align: 'center'};
  grid-column: 2 / -2;
  grid-row: 2;
  transition: inherit;

  ${({ imgLeft }) => !imgLeft && css`

  `};

  ${BREAKPOINT_MD} {
    grid-column: ${({ imgLeft }) => imgLeft ? '7 / -2' : '2 / 7'};
  }
`;

const Title = styled.span`
  font-size: 8vh;
  line-height: 1.125;
  text-align: center;
  font-family: var(--font-family-artsy);
`;

interface TextImageSectionProps extends IAlbumContentItem {
  album: IAlbumDetails;
  direction: 'right' | 'left';
  image: string;
  text: string;
  title: string;
}

export const ForeverGalaxyTokenSection: FC<TextImageSectionProps> = (props) => {
  const { type, image, title, text, direction } = props;

  const sectionRef = useRef<HTMLDivElement | null>(null);
  const getLocalizedString = useGetLocalizedString();

  useEffect(() => {
    const scrollHandler = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const sectionHeight = sectionRef.current.scrollHeight;

        const useCSSTransition = true;

        const sectionCenter = top + ((bottom - top) / 2);
        const scrollDelta = Math.max(0, Math.min(2, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2)))) / 2;

        let translateX = 0;

        if (bottom < sectionHeight + screenHeight && bottom > 0) {
          const progress = Math.min(Math.max((screenHeight + sectionHeight - bottom) / ((screenHeight + sectionHeight) / 100), 0), 100);
          if (progress < 40) {
            translateX = -50 + progress * 1.25;
          } else if (progress > 40 && progress < 60) {
            translateX = 0;
          } else if (progress > 60) {
            translateX = (progress - 60) * 1.25;
          }
        }

        if (useCSSTransition) {
          if (scrollDelta < .15) {
            translateX = -50;
          } else if (scrollDelta > .75) {
            translateX = 50;
          } else {
            translateX = 0;
          }
        }

        sectionRef.current.style.transition = useCSSTransition ? 'transform .5s ease-out' : 'none';
        sectionRef.current.style.setProperty('--translate-x', `${translateX}vw`);
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    scrollHandler();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <>
    <br />
    <Title>Forever Galaxy</Title>
    <Wrapper>
      <ImageWrapper>
        <img src={image} alt={title} />
        <br /><br /><br />
        <TokenTitle>FOREVER GALAXY TOKEN</TokenTitle>
        <Description>
          {getLocalizedString('app.v2.forevergalaxy.campaign.forevergalaxytoken.title')}<br />
          {getLocalizedString('app.v2.forevergalaxy.campaign.forevergalaxytoken.utility1')}<br />
          {getLocalizedString('app.v2.forevergalaxy.campaign.forevergalaxytoken.utility2')}<br />
          {getLocalizedString('app.v2.forevergalaxy.campaign.forevergalaxytoken.utility3')}
        </Description>
      </ImageWrapper>
    </Wrapper>
    </>
  );
};