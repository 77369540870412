import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../../styles/Buttons';
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { CloseIcon } from '../../icons/CloseIcon';
import { Colors } from '../../../styles/Colors';

const Background = styled.div<{ open: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 15;

  display: flex;
  color: ${Colors.textDefault};

  transition: opacity 300ms ease-out, transform 300ms ease-out, visibility 300ms ease-out;
  opacity: ${({ open }) => open ? 1 : 0};
  transform: ${({ open }) => open ? 'none' : 'scale(.95)'};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--color-black-900);
    opacity: .95;
  }
  
  ${BREAKPOINT_MD}{
    padding-left: 5rem;
  }
`;

const Dialog = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;

  border: 2px solid;
  border-radius: 2px;
  border-image-slice: 1;
  border-image-source: linear-gradient(-15deg, rgba(8, 232, 222, .5) 2%, rgba(8, 232, 222, .05) 53%, rgba(8, 232, 222, .5));

  width: calc(100% - 2 * var(--content-indent));
  max-width: 44rem;
  max-height: 100%;
  overflow: auto;

  background: var(--gradient-linear-100);
  backdrop-filter: blur(12px);

  ${BREAKPOINT_MD} {
    min-height: 22.75rem;
  }
`;

const Wrapper = styled.div`
  padding: 1.625rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  ${BREAKPOINT_MD} {
    padding: 5rem;
  }
`;

const Button = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  justify-content: flex-end;
  color: ${Colors.textDefault};
`;

const ButtonWrapper = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  ${BREAKPOINT_SM} {
    justify-content: ${({ center = false }) => center ? 'center' : 'space-between'};
    gap: .75rem;
  }
`;

const Headline = styled.h3`
  margin-bottom: .5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1rem;
  }
`;

const Text = styled.p`
  margin-bottom: 2rem;
`;

interface IButtonProps {
  title: string | ReactNode;
  onClick?: MouseEventHandler;
  type?: 'button' | 'submit';
  disabled?: boolean;
}

interface IModalProps {
  open: boolean;
  onClose: () => void;
  headline?: string | ReactNode;
  text?: string | ReactNode;
  primaryButton?: IButtonProps;
  secondaryButton?: IButtonProps;
}

const Modal: FC<PropsWithChildren<IModalProps>> = (props) => {
  const { children, open, text, headline, onClose, primaryButton, secondaryButton } = props;
  return (
    <>
      <Background open={open}>
        <Dialog>
          <Button onClick={onClose} aria-label='Close'>
            <CloseIcon />
          </Button>
          <Wrapper>
            {
              headline &&
              <Headline>
                {headline}
              </Headline>
            }

            {
              text &&
              <Text>
                {text}
              </Text>
            }

            {children}

            {
              (primaryButton || secondaryButton) &&
              <ButtonWrapper center={!(primaryButton && secondaryButton)}>
                {
                  primaryButton &&
                  <PrimaryButton
                    type={primaryButton.type || 'button'}
                    disabled={primaryButton.disabled}
                    onClick={(e) => {
                      if ((primaryButton.type || 'button') === 'button') {
                        primaryButton.onClick?.(e);
                        if (!e.isDefaultPrevented()) {
                          onClose();
                        }
                      }
                    }}>
                    {primaryButton.title}
                  </PrimaryButton>
                }
                {
                  secondaryButton &&
                  <SecondaryButton
                    type={secondaryButton.type || 'button'}
                    disabled={secondaryButton.disabled}
                    onClick={(e) => {
                      if ((secondaryButton.type || 'button') === 'button') {
                        secondaryButton.onClick?.(e);
                        if (!e.isDefaultPrevented()) {
                          onClose();
                        }
                      }
                    }}
                  >
                    {secondaryButton.title}
                  </SecondaryButton>
                }
              </ButtonWrapper>
            }
          </Wrapper>
        </Dialog>
      </Background>
    </>
  );
};

export default Modal;