import styled from 'styled-components';
import { Accordion } from '../../general/Accordion';
import { FC, useState } from 'react';
import { useActiveLocale, useGetLocalizedString } from '../../../../services/localization';

const AccordionSet = styled.div``;

interface FAQItem {
  head: string;
  body: string;
}

interface IFaqSectionCustomProps {
  title?: string;
  faqs: FAQItem[];
}

export const FaqSectionCustom: FC<IFaqSectionCustomProps> = (props) => {

  console.log(props)

  const { faqs, title = "" } = props;

  const toggleAccordion = (index: number) => {
    setOpenItem(index === openItem ? -1 : index);
  };

  const [openItem, setOpenItem] = useState<number>(-1);
  const getLocalizedString = useGetLocalizedString();

  if(faqs?.length === 0) {
    return <></>
  }

  return (
    <AccordionSet>
      {title && <h1>{title}</h1>}
      <br />
      {faqs &&
        faqs?.map((f, i) => {
          return (
            <Accordion
              head={getLocalizedString(f.head)}
              body={getLocalizedString(f.body)}
              key={i}
              open={1 === openItem}
              onClick={() => toggleAccordion(1)}
            />
          );
        })}
    </AccordionSet>
  );
};
