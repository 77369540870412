import { useAPIInstance } from './index';
import { IUserCollectiblesDetails } from './collectible';
import { AxiosResponse } from 'axios';

export interface IFavoritesList {
  collectibles: string[];
}

interface IAddFavoriteData {
  productId: string;
  productType: string;
}

interface IDeleteFavoriteResponse {
  success: boolean;
}

export const useFavoritesAPI = () => {
  const apiInstance = useAPIInstance();

  const favorites = () => {
    return apiInstance.get<IFavoritesList>(`/favorites/list?productType=collectibles`);
  };

  const favoritesDetails = () => {
    return apiInstance.get<IUserCollectiblesDetails>(`/favorites/details?productType=collectibles`);
  };

  const addFavorite = (collectibleId: string) => {
    return apiInstance.put<IAddFavoriteData, AxiosResponse<{}>>(`/favorites`, {
      productId: collectibleId,
      productType: 'collectibles',
    });
  };

  const deleteFavorite = (collectibleId: string) => {
    return apiInstance.delete<{}, AxiosResponse<IDeleteFavoriteResponse>>(`/favorites/collectibles/${collectibleId}`);
  };

  return {
    favorites,
    favoritesDetails,
    addFavorite,
    deleteFavorite,
  };
};
