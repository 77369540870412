import styled from 'styled-components';
import { PageView } from '../../components/layout/PageLayout';
import { useActiveLocale, useGetLocalizedString, useLocalizedString } from '../../services/localization';
import { useEffect, useState } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../styles/Breakpoints';
import { IPublishersListItem, usePublisherAPI } from '../../services/publisher';
import { PublisherItem } from '../../components/common/PublisherItem';
import { CircleArrowButton } from '../../styles/Buttons';
import { useNavigate } from 'react-router-dom';

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Results = styled.p`
  font-size: .875rem;

  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

export const PublisherWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(30%, 250px), 1fr));
  gap: 2rem;
  margin-top: 3.5rem;

  ${BREAKPOINT_LG} {
    margin-top: 6.5rem;
  }
`;

const StyledCircleArrowButton = styled(CircleArrowButton)`
  margin-top: 2rem;
`;

const PAGE_SIZE = 9;

export const Publishers = () => {
  const getLocalizedString = useGetLocalizedString();
  const navigate = useNavigate();

  const publishersService = usePublisherAPI();
  const resultsTitle = useLocalizedString('app.v2.publisher.results');
  const activeLocale = useActiveLocale();

  const [publishers, setPublishers] = useState<IPublishersListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const initPublishers = async (replace: boolean = true) => {
    try {
      // reset data on replace
      if (replace) {
        setPublishers([]);
        setTotalCount(null);
      }
      setLoading(true);

      const publishersResponse = (await publishersService.allPublishers(limit - PAGE_SIZE, PAGE_SIZE)).data;
      setPublishers((state) => ([
        ...(replace ? [] : (state || [])),
        ...(publishersResponse?.publishers || []),
      ]));
      setTotalCount(publishersResponse?.totalRecords || 0);
    } catch {
      // navigate to 404 page
      navigate('/404', { replace: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (limit === PAGE_SIZE) {
      initPublishers();
    } else {
      setLimit(PAGE_SIZE);
    }
  }, [activeLocale]);

  useEffect(() => {
    initPublishers(limit === PAGE_SIZE);
  }, [limit]);

  const showMore = () => {
    setLimit((limit) => limit + PAGE_SIZE);
  }

  return (
    <PageView>
      <Section>
        <TitleWrapper>
          <h1>{useLocalizedString('app.v2.publisher.title')}</h1>
          {
            totalCount &&
            <Results>{totalCount} {resultsTitle}</Results>
          }
        </TitleWrapper>
        <PublisherWrapper>
          {
            publishers?.map((item, index) => (
              <PublisherItem
                key={index}
                {...item}
              />
            ))
          }
        </PublisherWrapper>
        {
          (totalCount || 0) > (publishers?.length || 0) &&
          <StyledCircleArrowButton onClick={showMore}>{getLocalizedString('app.v2.general.load-more')}</StyledCircleArrowButton>
        }
      </Section>
    </PageView>
  );
};
