import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XL,
  DIMEN_BOXED_CONTENT_WIDTH,
  DIMEN_BREAKPOINT_LG,
} from '../../styles/Breakpoints';
import { Header } from './Header';
import { GridLayout } from '../../styles/Layout';
import { Colors } from '../../styles/Colors';
import { Menu } from './Menu';
import { PageAppear } from '../../styles/PageTransitions';
import { NavBar } from './NavBar';
import { SystemNotifications } from './SystemNotifications';
import { BuildVersion } from './BuildVersion';
import { NotificationSideBar } from './NotificationSideBar';
import { DiscordButton } from './DiscordButton';
import { NotificationTopBar } from './NotificationTopBar';

const PageWrapper = styled.div<IPageLayoutProps>`
  --content-max-width: ${DIMEN_BOXED_CONTENT_WIDTH}px;
  --content-indent: 1rem;
  --gutter-width: .75rem;
  --nav-height: ${({ hideHeader }) => !hideHeader ? '5.25rem' : '0'};
  --nav-padding: 1rem;

  ${BREAKPOINT_MD} {
    --content-indent: 2rem;
    --gutter-width: 1.5rem;
    --nav-height: ${({ hideHeader }) => !hideHeader ? '5.5rem' : '0'};
    --nav-padding: 2rem;
  }

  ${BREAKPOINT_LG} {
    --content-indent: ${({ hideNavBar }) => !hideNavBar ? '3.875rem' : '2rem'};
    --gutter-width: 2rem;
  }

  ${BREAKPOINT_XL} {
    --nav-padding: 6.5rem;
  }

  --page-grid: minmax(var(--content-indent), 1fr) minmax(0, var(--content-max-width)) minmax(var(--content-indent), 1fr);

  ${GridLayout};
  grid-template-rows: 1fr min-content;

  min-height: 100%;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${Colors.background};
    background-image: url(${({ showCampaignBackground }) => showCampaignBackground ? 'https://res.cloudinary.com/fanseap/image/upload/v1679653641/publishers/Frankfurt%20Galaxy/Albums/CampaignPage/02_background_stars.jpg': ''});
    z-index: -2;
  }

  & > * {
    grid-column: 1 / -1;
  }

  ${BREAKPOINT_LG} {
    --page-grid: min-content minmax(var(--content-indent), 1fr) minmax(0, var(--content-max-width)) minmax(var(--content-indent), 1fr);

    & > * {
      grid-column: 2 / -1;
    }
  }

  & > main {
    position: relative;
    align-self: stretch;
    ${GridLayout};
    grid-template-rows: minmax(min-content, 1fr);
    padding-bottom: 4.5rem;
    overflow: hidden;
    grid-row: 1;
    padding-top: var(--nav-height, 5.5rem);

    // page transition
    ${PageAppear};
  }
`;

const BackgroundWrapper = styled.div`
  pointer-events: none;
  user-select: none;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;

    ${BREAKPOINT_LG} {
      object-fit: cover;
    }
  }
`;

const BlurredBlobOne = styled.div`
  position: absolute;
  width: 71rem;
  height: 71rem;
  bottom: 0;
  right: 0;
  transform: translate(75%, 75%);
  background: var(--color-primary);
  opacity: 0.1;
  filter: blur(8rem);
`;

const BlurredBlobTwo = styled.div`
  position: absolute;
  width: 32rem;
  height: 32rem;
  left: 0;
  top: 0;
  transform: translate(-75%, -50%);
  background: var(--color-primary);
  opacity: 0.1;
  filter: blur(8rem);
`;

const NofiticationTopBar = styled.div`
  background-color: var(--color-primary);
  color: black;
  text-align: center;
`

interface IPageLayoutProps {
  withBackButton?: boolean;
  hideNavBar?: boolean;
  hideSwirl?: boolean;
  hideBlobs?: boolean;
  hideHeader?: boolean;
  showNotificationTopBar?: boolean;
  showCampaignBackground?: boolean;
}

export const PageLayout: FC<PropsWithChildren<IPageLayoutProps>> = ({ children, ...props }) => {
  const {
    withBackButton = false,
    hideNavBar = false,
    hideHeader = false,
    hideSwirl = false,
    hideBlobs = false,
    showCampaignBackground = false,
    showNotificationTopBar = false,
  } = props;

  return (
    <>
    {showNotificationTopBar && <NotificationTopBar />}
    <PageWrapper hideNavBar={hideNavBar} hideHeader={hideHeader} showCampaignBackground={showCampaignBackground}>
      {!hideHeader && <Header showBackButton={withBackButton} />}

      {children}

      {!hideNavBar && <NavBar />}

      {/* <Footer /> */}

      <BackgroundWrapper>
        {
          !hideSwirl &&
          <picture>
            <source media={`(min-width: ${DIMEN_BREAKPOINT_LG}px)`} srcSet='/images/swirl_desktop.png' />
            <img aria-hidden='true' src={'/images/swirl_mobile.png'} alt={'fansea background graphic'} />
          </picture>
        }
        {
          !hideBlobs &&
          <><BlurredBlobOne /><BlurredBlobTwo /></>
        }
      </BackgroundWrapper>

      <SystemNotifications />
      <BuildVersion />
    </PageWrapper>
    </>
  );
};



export const PageView: FC<PropsWithChildren> = ({ children }) => (
  <main>
    <DiscordButton />
    {children}
    <Menu />
    <NotificationSideBar />
  </main>
);
