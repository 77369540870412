import { useAPIInstance } from './index';
import { AxiosResponse } from 'axios';

interface INotifications {
  notifications: INotification[];
}

export type TNotificationType = 'upcoming' | 'reveal' | 'last-chance' | 'new-release' | 'news' | 'collectibles';

export interface INotification {
  edition: string;
  publisherName: string;
  rarity: number;
  type: TNotificationType;
  tags: Array<string>;
  commons: {
    createdAt: string;
    headline: string;
    id: string;
    image: string;
    item: string;
    publisherLogoUrl: string;
    read: boolean;
  },
}

interface IReadNotificationResponse {
  success: boolean;
}

interface IUnreadNotificationResponse {
  hasUnread: boolean;
}

export const useNotificationAPI = () => {
  const apiInstance = useAPIInstance();

  const notifications = () => {
    return apiInstance.get<INotifications>(`/notifications-user`);
  };

  const unreadNotification = () => {
    return apiInstance.get<IUnreadNotificationResponse>(`/notifications-user/unread`);
  };

  const readNotification = (userNotificationId: string) => {
    return apiInstance.patch<{}, AxiosResponse<IReadNotificationResponse>>(`/notifications-user/${userNotificationId}/read`);
  };

  return {
    notifications,
    readNotification,
    unreadNotification,
  };
};
