import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const InstagramIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 19 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.26 9C12.26 10.5903 11.024 11.88 9.5 11.88C7.97596 11.88 6.74 10.5903 6.74 9C6.74 7.4097 7.97596 6.12 9.5 6.12C11.0232 6.1218 12.2583 7.4106 12.26 9ZM18.125 5.04V12.96C18.1216 15.7419 15.961 17.9964 13.295 18H5.705C3.03901 17.9964 0.87845 15.7419 0.875 12.96V5.04C0.87845 2.2581 3.03901 0.0036 5.705 0H13.295C15.961 0.0036 18.1216 2.2581 18.125 5.04ZM13.64 9C13.64 6.6141 11.7865 4.68 9.5 4.68C7.21351 4.68 5.36 6.6141 5.36 9C5.36 11.3859 7.21351 13.32 9.5 13.32C11.7856 13.3173 13.6374 11.385 13.64 9ZM15.02 4.32C15.02 3.7233 14.5568 3.24 13.985 3.24C13.4132 3.24 12.95 3.7233 12.95 4.32C12.95 4.9167 13.4132 5.4 13.985 5.4C14.5568 5.4 15.02 4.9167 15.02 4.32Z'
      fill='currentColor'
    />
  </svg>

);