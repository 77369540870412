import { useLocation, useNavigate } from 'react-router-dom';
import { useGetLocalizedString } from '../../../services/localization';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { PageView } from '../../../components/layout/PageLayout';
import styled from 'styled-components';
import { BREAKPOINT_LG } from '../../../styles/Breakpoints';
import { Body2, Body3, Headline4, Headline5 } from '../../../styles/FontStyles';
import { SecondaryButton, PrimaryButton } from '../../../styles/Buttons';
import { LinkHandler } from '../../../components/common/general/LinkHandler';
import { ICollectible, useCollectiblesAPI } from '../../../services/collectible';
import { PageAppear } from '../../../styles/PageTransitions';
import { SadSmiley } from '../../../components/icons/SadSmiley';
import { LoadingOverlay } from '../../../components/common/general/LoadingOverlay';
import { CollectibleImage } from '../../../components/common/collectibles/CollectibleImage';
import { useTrackingState } from '../../../state/tracking';
import { FanseaConfetti } from '../../../components/common/FanseaConfetti';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  align-self: center;
  min-height: 100%;

  & > * {
    flex-shrink: 0;
  }

  ${BREAKPOINT_LG} {
    flex-direction: row;
    justify-content: center;
  }

  // transition
  ${PageAppear};
`;

const ImageWrapper = styled.div`
  align-self: stretch;
  position: relative;
  flex: 1;

  ${BREAKPOINT_LG} {
    flex: 3;
  }
`;

// const Glow = styled.img.attrs({ src: '/images/collectible-glow.png', alt: '' })`
const GlowUnderlay = styled.object.attrs({ data: '/images/glow-underlay.svg', type: 'image/svg+xml', alt: '' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const GlowOverlay = styled(GlowUnderlay).attrs({ data: '/images/glow-overlay.svg', type: 'image/svg+xml', alt: '' })``;

const GlowGalaxyUnderlay = styled.object.attrs({ data: '/images/glow-underlay-galaxy.svg', type: 'image/svg+xml', alt: '' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const GlowGalaxyOverlay = styled(GlowUnderlay).attrs({ data: '/images/glow-overlay-galaxy.svg', type: 'image/svg+xml', alt: '' })``;

const CollectibleImageWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
`;

const ErrorImage = styled(SadSmiley)`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  object-fit: contain;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${BREAKPOINT_LG} {
    flex: 2;
  }
`;

const ContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Headline = styled.h1`
  ${Headline4};
  text-align: center;
  margin-bottom: 0.5rem;

  ${BREAKPOINT_LG} {
    ${Headline5};
  }
`;

const Text = styled.p`
  ${Body2};
  text-align: center;
  margin-bottom: 1rem;

  ${BREAKPOINT_LG} {
    ${Body3};
    margin-bottom: 2.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${BREAKPOINT_LG} {
    gap: 1rem;
  }
`;

const SecondaryButtonLink = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string; isGalaxy?: boolean }>`
  ${({ isGalaxy }) =>
    isGalaxy &&
    `
  background-color: #362550;
  border-color: #362550;
  color: white;
  `}
`;
const PrimaryButtonLink = styled(PrimaryButton).attrs({ as: LinkHandler })<{ to: string }>``;

interface ISuccessPageProps {
  asErrorPage?: boolean;
}

export const SuccessPage: FC<ISuccessPageProps> = (props) => {
  const { asErrorPage = false } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [trackingState, setTrackingState] = useTrackingState();
  const conversionEventSent = useRef(false);

  const collectibleId = new URLSearchParams(location.search).get('product');
  const isClaimableToken = new URLSearchParams(location.search).get('type') == 'claimableToken';
  // const orderId = new URLSearchParams(search).get('order');

  const getLocalizedString = useGetLocalizedString();

  const collectiblesAPI = useCollectiblesAPI();
  const [collectible, setCollectible] = useState<ICollectible | null>(null);
  const [loading, setLoading] = useState(false);

  const isPresaleItem = useMemo(() => {
    return collectible?.status === 'pre-sale';
  }, [collectible]);

  const isGalaxyCollectible = useMemo(() => {
    return ["63cebdd31d7ed027dc84c0ba", "63aef8b4f83d2eb31d50d94d"].includes(collectible?.publisherId || "");
  }, [collectible]);

  useEffect(() => {
    if (collectibleId) {
      setLoading(true);
      collectiblesAPI
        .collectible(collectibleId)
        .then(async (res) => {
          setLoading(false);
          setCollectible(res.data.collectible || null);
        })
        .catch((err) => {
          setLoading(false);
          setCollectible(null);
          console.error('CHECKOUT LP', collectibleId, err?.response?.status, err?.response?.data);

          // navigate to 404 page
          navigate('/404', { replace: true });
        });
    } else {
      // navigate to 404 page
      navigate('/404', { replace: true });
    }
  }, [collectibleId]);

  useEffect(() => {
    if (trackingState?.trackEvent && trackingState?.source && collectible) {
      if (!conversionEventSent.current) {
        conversionEventSent.current = true;

        // send SA event
        trackingState.trackEvent('conversion', { source: trackingState.source, product: collectibleId });

        // reset url parameter
        const { pathname, search } = location;
        const searchParams = new URLSearchParams(search);
        searchParams.delete('referrer');
        navigate(`${pathname}?${searchParams.toString()}`, { replace: true });

        // reset tracking source
        setTrackingState((state) => ({
          ...(state || {}),
          source: undefined,
        }));
      }
    }
  }, [trackingState, collectible, location]);

  
  var successText = getLocalizedString('app.v2.checkout.success.text');
  if(isPresaleItem){
    successText = getLocalizedString('app.v2.checkout.presale.success.text');
  }
  if(isClaimableToken){
    successText = getLocalizedString('app.v2.claimabletokens.success.message');
  }

  var successCTA = getLocalizedString('app.v2.checkout.success.button-collectibles');
  if(isPresaleItem){
    successCTA = getLocalizedString('app.v2.checkout.presale.success.button-collectibles');
  }
  if(isClaimableToken){
    successCTA = getLocalizedString('app.v2.claimabletokens.success.cta');
  }

  return (
    <>
      {isGalaxyCollectible && <FanseaConfetti />}
      <PageView>
        {collectible && !asErrorPage && (
          <Layout>
            <ImageWrapper>
              {isGalaxyCollectible ? <GlowGalaxyUnderlay /> : <GlowUnderlay />}
              <CollectibleImageWrapper>
                <CollectibleImage animated collectible={collectible} />
              </CollectibleImageWrapper>
              {isGalaxyCollectible ? <GlowGalaxyOverlay /> : <GlowOverlay />}
            </ImageWrapper>
            <ContentWrapper>
              <ContentInnerWrapper>
                <Headline>{getLocalizedString('app.v2.checkout.success.headline')}</Headline>
                <Text>
                  {successText}
                </Text>
                {collectible.hasPhysicalProduct && <Text>{getLocalizedString('app.v2.checkout.success.mini-print.text')}</Text>}
                <ButtonsWrapper>
                  <SecondaryButtonLink to={`/profile`} isGalaxy={isGalaxyCollectible}>
                    {successCTA}
                  </SecondaryButtonLink>
                </ButtonsWrapper>
              </ContentInnerWrapper>
            </ContentWrapper>
          </Layout>
        )}

        {!loading && asErrorPage && (
          <Layout>
            <ImageWrapper>
              <ErrorImage />
            </ImageWrapper>
            <ContentWrapper>
              <ContentInnerWrapper>
                <Headline>{getLocalizedString('app.v2.checkout.error.headline')}</Headline>
                <Text>{getLocalizedString('app.v2.checkout.error.text')}</Text>
                <ButtonsWrapper>
                  <SecondaryButtonLink to={collectibleId ? `/collectibles/${collectibleId}/purchase` : '/collectibles'}>
                    {getLocalizedString('app.v2.checkout.error.button-back-to-payment')}
                  </SecondaryButtonLink>
                  <PrimaryButtonLink to={collectibleId ? `/collectibles/${collectibleId}` : '/collectibles'}>
                    {getLocalizedString('app.v2.checkout.error.button-collectibles')}
                  </PrimaryButtonLink>
                </ButtonsWrapper>
              </ContentInnerWrapper>
            </ContentWrapper>
          </Layout>
        )}

        {loading && <LoadingOverlay visible inline />}
      </PageView>
    </>
  );
};
