import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const ShareIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M15 5L12.7071 2.70711C12.3166 2.31658 11.6834 2.31658 11.2929 2.70711L9 5'
          stroke='#ADADAD'
          strokeWidth='1.5'
          strokeLinecap='round'
    />
    <path
      d='M12 3L12 15'
      stroke='#ADADAD'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M7.2 9L6 9C4.89543 9 4 9.89543 4 11L4 20C4 21.1046 4.89543 22 6 22L18 22C19.1046 22 20 21.1046 20 20L20 11C20 9.89543 19.1046 9 18 9L16.8 9'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);