import { useState } from 'react';
import styled, { css } from 'styled-components';
import { BREAKPOINT_LG } from '../styles/Breakpoints';
import { RedeemView } from '../components/views/redeemVoucher/RedeemView';
import { SuccessView } from '../components/views/redeemVoucher/SuccessView';
import { useWallet } from '../hooks/useWallet';
import { Navigate } from 'react-router-dom';
import { PageView } from '../components/layout/PageLayout';

const Section = styled.section<{ submitted: boolean }>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;

  ${BREAKPOINT_LG} {
    gap: 2rem;
  }

  ${({ submitted }) => submitted && css`
    grid-template-rows: min-content;
    place-content: center;
  `}
`;

export const RedeemVoucher = () => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { encryptedWallet } = useWallet();

  return typeof encryptedWallet === 'string' ? (
    <PageView>
      <Section submitted={submitted}>
        {submitted
          ? <SuccessView reset={() => setSubmitted(false)} />
          : <RedeemView submit={() => setSubmitted(true)} />
        }
      </Section>
    </PageView>
  ) : (
    <Navigate
      state={{ redirect: '/redeem-voucher' }}
      to={'/connect-wallet'}
    />
  );
};
