import { useAPIInstance } from './index';

export interface INewsList {
  news: INewsListItem[];
  totalRecords: number;
  headline?: string;
}

export interface INewsListItem {
  id: string;
  slug: string;
  createdAt: string;
  imageUrl: string;
  title: string;
  shortDescription: string;
  type: string;
  commentCount: number;
  publisherName: string;
  publisherLogoUrl: string;
}

export interface INews {
  success: boolean;
  value: {
    id: string;
    slug: string;
    createdAt: string;
    imageUrl: string;
    title: string;
    shortDescription: string;
    content: string;
    type: string;
    commentCount?: number;
    publisherName: string;
    publisherLogoUrl: string;
  };
}
interface ITranslatedNotification {
  language: string;
  text: string;
}

export interface INotification {
    ranking: number;
    createdAt: string;
    translations: ITranslatedNotification[];
}
export interface INotifications {
  success: boolean;
  notifications: INotification[];
}

export const useNewsAPI = () => {
  const apiInstance = useAPIInstance();

  const allNews = (offset: number, limit: number) => {
    return apiInstance.get<INewsList>(`/news?offset=${offset}&limit=${limit}`);
  };

  const newsById = (id: string) => {
    return apiInstance.get<INews>(`/news/${id}`);
  };

  const similarNews = (id: string) => {
    return apiInstance.get<INewsList>(`/news/similar/${id}`);
  };

  const notifications = () => {
    return apiInstance.get<INotifications>(`/news/notifications`);
  };

  return {
    allNews,
    newsById,
    similarNews,
    notifications
  };
};
