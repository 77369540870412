import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'locale',
  storage: localStorage,
});

export const ActiveLocaleState = atom<string | null>({
  key: 'ActiveLocaleState',
  default: null,
  effects_UNSTABLE: [
    persistAtom
  ],
});