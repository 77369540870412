import { FC } from "react";
import { useGetLocalizedString } from '../../../services/localization';
import WalletDescriptionModal from "../../common/special/WalletDescriptionModal";
import Text from "../../common/general/Text";
import styled from "styled-components";
import { Body3, Body3Bold, Headline4 } from "../../../styles/FontStyles";
import { BlurredButton } from "../../../styles/Buttons";
import { DownloadIcon } from "../../icons/DownloadIcon";
import { ArrowIcon } from "../../icons/ArrowIcon";
import { BREAKPOINT_LG, BREAKPOINT_XL } from "../../../styles/Breakpoints";
import { useRecoilValue } from "recoil";
import { UserState } from "../../../state/user";
import { PageView } from "../../layout/PageLayout";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: auto;
  max-width: 21.5rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 0;
    grid-column: 8 / -2;
  }
`;

const Section = styled.section`
  display: grid;
  place-self: center;
  justify-items: center;

  ${BREAKPOINT_LG} {
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
  }
`;

const ConnectImage = styled.img`
  max-width: max(180px, 25vw);
  margin-top: auto;

  ${BREAKPOINT_LG} {
    margin-top: 0;
    max-width: unset;
    grid-column: 2 / 6;
  }

  ${BREAKPOINT_XL} {
    grid-column: 3 / 7;
  }
`;

const Headline = styled.h1`
  ${Headline4}
`;

const StyledText= styled(Text)`
  ${Body3}
`;

const StyledBlurredButton = styled(BlurredButton)`
  width: 100%;
  padding: 3rem 0;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const WalletImportIcon = styled(DownloadIcon)`
  font-size: 1.25rem;
  margin-bottom: .125rem;
`;

const SkipButton = styled.button`
  ${Body3Bold}
  display: flex;
  align-items: center;
  gap: .375rem;
  text-transform: uppercase;
  width: max-content;
  margin-left: auto;
`;

const Arrow = styled(ArrowIcon)`
  font-size: .5rem;
  transform: rotate(180deg);
`;

interface IWelcomeBackProps {
  onStartImport: () => void;
  onSkipImport: () => void;
}

/**
 * Welcome screen after successful Login, where you can
 * optionally start the wallet import
 */
const WelcomeBack: FC<IWelcomeBackProps> = ({
  onStartImport,
  onSkipImport,
}) => {
  const getLocalizedString = useGetLocalizedString();
  const userData = useRecoilValue(UserState);

  return (
    <PageView>
      <Section>
        <ConnectImage aria-hidden="true" src="/images/connect.png" alt="" />
        <Wrapper>
          <Headline>{getLocalizedString('app.v2.welcome.welcome-back')}, {userData?.username}</Headline>
          <StyledText content={getLocalizedString('app.v2.welcome.text')} />

          <WalletDescriptionModal />

          <StyledBlurredButton onClick={onStartImport}>
            <WalletImportIcon />
            {getLocalizedString('app.v2.welcome.connect-wallet')}
          </StyledBlurredButton>
          <SkipButton onClick={onSkipImport}>
            {getLocalizedString('app.v2.welcome.skip')}
            <Arrow />
          </SkipButton>
        </Wrapper>
      </Section>
    </PageView>
  );
}

export default WelcomeBack;