import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const CopyIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='11' y='4' width='15' height='10' rx='2' transform='rotate(90 11 4)' stroke='currentColor' strokeWidth='1.5' />
    <path d='M5.5 1H12.5C13.6046 1 14.5 1.89543 14.5 3V16' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);

export default CopyIcon;