import styled, { css } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';

interface BasicGridProps {
  gridColumns?: string;
  gridColumnsSM?: string;
  gridColumnsMD?: string;
  gridColumnsLG?: string;
}

export const BasicGridWrapper = styled.div<BasicGridProps>`
  display: grid;
  grid-template-columns: repeat(${({ gridColumns = 12 }) => gridColumns}, 1fr);
  align-content: flex-start;
  gap: 1rem;

  ${({ gridColumnsSM }) => gridColumnsSM && css`
    ${BREAKPOINT_SM} {
      grid-template-columns: repeat(${gridColumnsSM}, 1fr);
    }
  `};

  ${({ gridColumnsMD }) => gridColumnsMD && css`
    ${BREAKPOINT_MD} {
      grid-template-columns: repeat(${gridColumnsMD}, 1fr);
    }
  `};

  ${({ gridColumnsLG }) => gridColumnsLG && css`
    ${BREAKPOINT_LG} {
      grid-template-columns: repeat(${gridColumnsLG}, 1fr);
    }
  `};
`;
