import styled from 'styled-components';
import { ErrorNotification } from '../registration/SignUp';
import { FC, useState } from 'react';
import { useCountries, useGetLocalizedString } from '../../../services/localization';
import { BackButton } from '../../common/general/BackButton';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { SecondaryButton } from '../../../styles/Buttons';
import { Colors } from '../../../styles/Colors';
import { VisaIcon } from '../../icons/VisaIcon';
import { MastercardIcon } from '../../icons/MastercardIcon';
import { AmexIcon } from '../../icons/AmexIcon';
import { PayPalIcon } from '../../icons/PayPalIcon';
import { ICollectible } from '../../../services/collectible';
import { ReviewArticleView } from './ReviewArticleView';
import { usePurchaseState } from '../../../state/purchase';
import { usePurchaseAPI } from '../../../services/purchase';
import { useUserId } from '../../../state/auth';
import { Body2, Body3 } from '../../../styles/FontStyles';
import { SwipeButton } from '../../common/forms/SwipeButton';
import { PageAppear } from '../../../styles/PageTransitions';
import { Checkbox } from '../../common/forms/Checkbox';
import FormWrapper from '../../common/forms/FormWrapper';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  // page transition
  ${PageAppear};
`;

const BackBtn = styled(BackButton)`
  align-self: flex-start;
  margin: 1.25rem 0 1.5rem;

  ${BREAKPOINT_LG} {
    margin: 0 0 1.5rem;
  }
`;

const DescriptionText = styled.p`
  ${Body2};
  margin-bottom: 2.5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3rem;
  }

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const PaymentIconsWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 1.5rem;
  }

  p {
    padding-left: 0.5rem;
  }
`;

const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-bottom: 3.25rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3rem;
  }
`;

const ReviewContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Separator = styled.div`
  height: 0.5px;
  background-color: ${Colors.textDefault};
`;

const ReviewContentTitle = styled.h6`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
`;

const EditButton = styled.button.attrs({ type: 'button' })`
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;

  ${Body2};
  font-weight: 600;
  color: ${Colors.primary};
  text-decoration: underline;
  text-transform: capitalize;

  ${BREAKPOINT_LG} {
    ${Body3};
    font-weight: 600;
  }
`;

const ReviewContentText = styled.p`
  ${Body2};

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const ContinueButton = styled(SecondaryButton)`
  align-self: center;
  margin-top: 1.5rem;

  @media (hover: none) {
    display: none;
  }
`;

const ContinueButtonTouch = styled(SwipeButton)`
  align-self: center;
  display: none;
  margin-top: 1.5rem;

  @media (hover: none) {
    display: flex;
  }
`;

const StyledFormWrapper = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    gap: 1.5rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  ${Body3};
`;

const StyledErrorNotification = styled(ErrorNotification).attrs({ autoScroll: true })`
  margin-bottom: 1.5rem;
`;

interface IPaymentMethodProps {
  collectible: ICollectible;
  onBackButtonClick?: () => void;
  onEditButtonClick?: (type: 'shipping-address' | 'payment') => void;
  onDeleteButtonClick?: () => void;
  onSubmit: () => void;
}

export const Review: FC<IPaymentMethodProps> = (props) => {
  const { collectible, onBackButtonClick, onEditButtonClick, onDeleteButtonClick, onSubmit } = props;

  const getLocalizedString = useGetLocalizedString();
  const countries = useCountries();

  const purchaseAPI = usePurchaseAPI();
  const form = useForm({ mode: 'onChange' });

  const userId = useUserId()!;
  const [purchaseState, setPurchaseState] = usePurchaseState();

  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const [buttonTriggered, setButtonTriggered] = useState(false);

  const handleSubmit = async () => {
    // purchase   TODO separate stripe and paypal payments
    setLoading(true);
    setErrorState(null);
    purchaseAPI
      .purchaseCollectible(purchaseState?.paymentMethod || 'stripe', {
        userId,
        productId: collectible.id,
        currency: purchaseState?.currency?.currencyShortcode,
        promoCode: purchaseState?.promoCode,
      })
      .then((res) => {
        setLoading(false);

        if (res.data.success && res.data.url) {
          window.location.href = res.data.url; // redirect to payment provider
        }

        onSubmit?.();
      })
      .catch((err) => {
        setLoading(false);
        setButtonTriggered(false);
        console.error('PAYMENT', err?.response?.status, err?.response?.data);

        // show error message
        setErrorState({
          headline: getLocalizedString('app.v2.checkout.review.payment-error.headline'),
          text: getLocalizedString('app.v2.checkout.review.payment-error.text'),
        });
      });
  };

  return (
    <Wrapper>
      <BackBtn onClick={onBackButtonClick} />

      <DescriptionText>{getLocalizedString('app.v2.checkout.review.description-text')}</DescriptionText>

      {errorState && <StyledErrorNotification {...errorState} onClose={() => setErrorState(null)} />}
      <ReviewContentWrapper>
        {/* shipping address */}
        {collectible.hasPhysicalProduct && (
          <>
            <ReviewContentBox>
              <ReviewContentTitle>
                {getLocalizedString('app.v2.checkout.review.shipping-address')}
                <EditButton onClick={() => onEditButtonClick?.('shipping-address')}>{getLocalizedString('app.v2.general.edit')}</EditButton>
              </ReviewContentTitle>
              <ReviewContentText>
                {purchaseState?.shippingAddress?.firstName} {purchaseState?.shippingAddress?.lastName}
                <br />
                {purchaseState?.shippingAddress?.address1}
                <br />
                {purchaseState?.shippingAddress?.postcode} {purchaseState?.shippingAddress?.city}
                <br />
                {countries.find(({ alpha2 }) => alpha2 === purchaseState?.shippingAddress?.country)?.name}
                <br />
              </ReviewContentText>
            </ReviewContentBox>

            <Separator />
          </>
        )}

        {/* payment */}
        <ReviewContentBox>
          <ReviewContentTitle>
            {getLocalizedString('app.v2.checkout.review.payment')} in {purchaseState?.currency?.currencyShortcode}
            <EditButton onClick={() => onEditButtonClick?.('payment')}>{getLocalizedString('app.v2.general.edit')}</EditButton>
          </ReviewContentTitle>
          <PaymentIconsWrapper>
            {purchaseState?.paymentMethod === 'stripe' && (
              <>
                <MastercardIcon />
                <VisaIcon />
                <AmexIcon />
              </>
            )}
            {purchaseState?.paymentMethod === 'paypal' && <PayPalIcon />}
            <ReviewContentText>
              {purchaseState?.paymentMethod === 'stripe' && getLocalizedString('app.v2.checkout.payment.method.stripe')}
              {purchaseState?.paymentMethod === 'paypal' && getLocalizedString('app.v2.checkout.payment.method.paypal')}
            </ReviewContentText>
          </PaymentIconsWrapper>
        </ReviewContentBox>

        <Separator />

        {/* article */}
        <ReviewContentBox>
          <ReviewContentTitle>
            {getLocalizedString('app.v2.checkout.review.article')}
            <EditButton onClick={() => onDeleteButtonClick?.()}>{getLocalizedString('app.v2.general.delete')}</EditButton>
          </ReviewContentTitle>
          <ReviewArticleView collectible={collectible} selectedCurrency={purchaseState?.currency} discountedPrice={purchaseState?.discountedPrice} />
        </ReviewContentBox>
      </ReviewContentWrapper>

      <StyledFormWrapper form={form} onSubmit={handleSubmit}>
        <StyledCheckbox required name={'tos'} label={getLocalizedString('app.v2.checkout.review.place-order-agreement')} />

        <StyledCheckbox required name={'risk'} label={getLocalizedString('app.v2.checkout.review.risk-information-agreement')} />

        <ContinueButton className='cy-button-buy-now' disabled={loading || !form.formState.isValid} type={'submit'}>
          {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.checkout.review.place-order')}
        </ContinueButton>

        <ContinueButtonTouch
          loading={loading}
          disabled={!form.formState.isValid}
          buttonTriggered={buttonTriggered}
          setButtonTriggered={setButtonTriggered}
          type={'submit'}
        />
      </StyledFormWrapper>
    </Wrapper>
  );
};
