import styled, { css, keyframes } from 'styled-components';
import { PageView } from '../../components/layout/PageLayout';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { BasicGridWrapper } from '../../components/common/general/Grids';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../styles/Breakpoints';
import { FC, useEffect, useState } from 'react';
import { Body2, Body2Bold, Body3 } from '../../styles/FontStyles';
import { PageLoader } from '../../components/common/general/LoadingOverlay';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDataCache } from '../../state/dataCache';
import { IUserAlbumDetails, useAlbumsAPI } from '../../services/albums';
import { BackButton } from '../../components/common/general/BackButton';
import { Colors } from '../../styles/Colors';
import { AlbumCollectibleItem } from '../../components/common/albums/AlbumCollectibleItem';
import {
  AlbumCollectibleDetailsModal,
  IAlbumCollectibleDetailsProps,
} from '../../components/common/albums/AlbumCollectibleDetailsModal';
import { useIsAuthenticated } from '../../services';
import { CheckIconBold } from '../../components/icons/CheckIcon';
import { AlbumExplanationCardItem } from '../../components/common/albums/AlbumExplanationCardItem';
import { ShareIcon } from '../../components/icons/ShareIcon';
import { ShareAlbumPopUp } from '../../components/common/albums/ShareAlbumPopUp';

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledBackButton = styled(BackButton)`
  margin-bottom: .5rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 1rem;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1.5rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .25rem;

  ${BREAKPOINT_LG} {
    margin-bottom: .5rem;
  }
`;

const ShareWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  white-space: nowrap;
`;

const ShareButton = styled.button`
  display: flex;
  gap: .5rem;
  align-items: center;
  font-size: 1rem;

  ${BREAKPOINT_LG} {
    font-size: 1.125rem;
  }

  svg {
    font-size: 1.5rem;
    margin-bottom: .25rem;
  }

  p {
    display: none;
  }

  ${BREAKPOINT_MD} {
    border: 1px solid var(--color-text-default);
    padding: .5rem 1rem;
    border-radius: 1.5rem;

    p {
      display: initial;
    }
  }
`;

const Title = styled.h4`
  line-height: 1;
`;

const SubTitle = styled.h5`
  ${Body2};
  font-weight: normal;
`;

const CollectiblesCount = styled.p`
  ${Body3};
`;

const PulseAnim = keyframes`
  0% {
    box-shadow: inset 0 0 8px ${Colors.primary};
  }
  25% {
    box-shadow: inset 0 0 24px ${Colors.primary};
  }
  50% {
    box-shadow: inset 0 0 8px ${Colors.primary};
  }
  100% {
    box-shadow: inset 0 0 8px ${Colors.primary};
  }
`;

const AlbumCompletedWrapper = styled.div`
  position: relative;
  margin: -1rem 0 0;
  padding: 2rem 0;

  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 125%;
    z-index: -1;

    background: url('/images/album-completed-rect.png') repeat-x center;
    background-size: auto 100%;
  }
`;

const AlbumCompleted = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding: .625rem .75rem .5rem;
  border-radius: 19px;
  overflow: hidden;

  background: url('/images/album-completed-frame.png') no-repeat center;
  background-size: cover;

  ${Body2Bold};
  text-transform: uppercase;
  color: ${Colors.textHighlight};

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  && {
    line-height: 1;

    span {
      line-height: 1;
    }
  }
`;

const OwnedCollectiblesCount = styled.span<{ completed?: boolean }>`
  ${Body2};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  align-self: flex-start;
  flex-shrink: 0;

  background: rgba(8, 232, 222, 0.08);
  box-shadow: inset 0 0 8px ${Colors.primary};
  border: 1px solid ${Colors.primary};
  border-radius: 19px;
  overflow: hidden;

  padding: .625rem 1.5rem .5rem;

  animation: ${PulseAnim} 5s ease-in-out infinite;

  span {
    ${Body2Bold};
    color: ${Colors.primary};
  }

  ${({ completed = false }) => completed && css`
    padding: .625rem .75rem .5rem;

    ${Body2Bold};
    border-color: transparent;
    box-shadow: none;
    animation: none;

    &, & span {
      color: ${Colors.textHighlight};
    }

    background: url('/images/album-completed-mark.png') no-repeat center;
    background-size: cover;
  `};

  && {
    line-height: 1;

    span {
      line-height: 1;
    }
  }
`;

const CompletedCheckmark = styled(CheckIconBold)`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: .375rem;
`;

const ItemList = styled(BasicGridWrapper)`
  grid-gap: 1rem .5rem;
  perspective: 1000px;

  ${BREAKPOINT_MD} {
    grid-gap: 2rem;
  }
`;

const BrowseCollectiblesWrapper = styled.div`
  text-align: center;
  margin-top: 10rem;

  p {
    font-size: .875rem;
    line-height: 1.1875rem;
    margin: .5625rem 0 1.5rem 0;
    color: var(--color-grey-600);
  }
`;

const BrowseCollectibles = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: .875rem;
  font-weight: bold;

  svg {
    width: 1.5rem;
    height: auto;
  }

  span {
    margin-left: .625rem;
  }
`;

interface IAlbumCollectiblesProps {
  inProfile?: boolean;
}

export const AlbumCollectibles: FC<IAlbumCollectiblesProps> = (props) => {
  const {
    inProfile = false,
  } = props;

  const { id } = useParams();
  const getLocalizedString = useGetLocalizedString();

  const isAuthenticated = useIsAuthenticated();
  const activeLocale = useActiveLocale();
  const location = useLocation();

  const albumsAPI = useAlbumsAPI();
  const [dataCache, setDataCache] = useDataCache();
  const [loading, setLoading] = useState(false);
  const [openShareMenu, setOpenShareMenu] = useState(false);
  const [supportsNavigator, setSupportsNavigator] = useState<boolean>(false);

  const [showCollectibleDetails, setShowCollectibleDetails] = useState<IAlbumCollectibleDetailsProps | null>(null);

  const album = inProfile
    ? (dataCache?.userAlbumDetails || {})[id || ''] || null
    : (dataCache?.albumDetails || {})[id || ''] || null;

  const fetchAlbumDetails = (id: string) => {
    setLoading(!album);
    albumsAPI.fetchAlbumDetails(id)
      .then((res) => {
        setDataCache((state) => ({
          ...state,
          albumDetails: {
            ...(state?.albumDetails || {}),
            [id]: res.data || null,
          },
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.error('failed to load album details', err);
        setLoading(false);
      });
  };

  const fetchUserAlbumDetails = (id: string) => {
    setLoading(!album);
    albumsAPI.fetchUserAlbumDetails(id)
      .then((res) => {
        setDataCache((state) => ({
          ...state,
          userAlbumDetails: {
            ...(state?.userAlbumDetails || {}),
            [id]: res.data || null,
          },
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.error('failed to load user album details', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    // make api call
    if (id) {
      if (inProfile) {
        if (isAuthenticated) {
          fetchUserAlbumDetails(id);
        }
      } else {
        fetchAlbumDetails(id);
      }
    }
  }, [id, inProfile, activeLocale, isAuthenticated]);

  useEffect(() => {
    if (!!navigator?.canShare) {
      setSupportsNavigator(true);
    }
  }, []);

  const handleShareButtonClick = async (e: any) => {
    e.stopPropagation();
    if (supportsNavigator) {
      const shareData = {
        text: album?.title,
        url: location.pathname,
      };

      try {
        await navigator.share(shareData);
      } catch {
        console.log('navigator share error!');
      }
    } else {
      setOpenShareMenu(true);
    }
  };

  const ownedCollectibles = ((album as IUserAlbumDetails)?.ownedCollectibles || 0);

  return (
    <PageView>
      <ContentWrapper>
        <PageLoader visible={loading} />

        {
          !loading && album &&
          <>
            <StyledBackButton />
            {
              ownedCollectibles === album.collectiblesInAlbum &&
              <AlbumCompletedWrapper>
                <AlbumCompleted>
                  <span>{getLocalizedString('app.v2.album-collectibles.label.album-complete')}</span>
                  <div>
                    <span>{ownedCollectibles}</span>/{album.collectiblesInAlbum}
                    {
                      ownedCollectibles === album.collectiblesInAlbum &&
                      <CompletedCheckmark />
                    }
                  </div>
                </AlbumCompleted>
              </AlbumCompletedWrapper>
            }
            <HeadWrapper>
              <TitleWrapper>
                <Title>{album.title}</Title>
                {
                  <ShareWrapper>
                    {
                      ownedCollectibles > 0 && ownedCollectibles < album.collectiblesInAlbum &&
                      <OwnedCollectiblesCount completed={ownedCollectibles === album.collectiblesInAlbum}>
                        <span>{ownedCollectibles}</span>/{album.collectiblesInAlbum}
                        {
                          ownedCollectibles === album.collectiblesInAlbum &&
                          <CompletedCheckmark />
                        }
                      </OwnedCollectiblesCount>
                    }
                    {
                      ownedCollectibles === 0 &&
                      <CollectiblesCount>
                        {album.collectiblesInAlbum} {getLocalizedString(album.collectiblesInAlbum === 1 ? 'app.v2.albums.label.collectibles-count-singular' : 'app.v2.albums.label.collectibles-count')}
                      </CollectiblesCount>
                    }

                    {/* <ShareButton onClick={handleShareButtonClick}>
                      <p>{getLocalizedString('app.v2.general.share')}</p>
                      <ShareIcon />
                    </ShareButton> */}
                  </ShareWrapper>
                }
              </TitleWrapper>
              {
                // album.subTitle &&
                // <SubTitle dangerouslySetInnerHTML={{ __html: album.subTitle }} />
              }
            </HeadWrapper>
            {
              album.collectibles &&
              <ItemList gridColumns={'2'} gridColumnsMD={'3'}>
                {
                  album.subTitle &&
                  <AlbumExplanationCardItem
                    text={album.subTitle}
                  />
                }
                {
                  album.collectibles.map((albumCollectible) => (
                    <AlbumCollectibleItem
                      key={albumCollectible.id}
                      {...albumCollectible}
                      active={showCollectibleDetails?.collectible?.id === albumCollectible.id || undefined}
                      onClick={(view) => setShowCollectibleDetails({ collectible: albumCollectible, view })}
                      // active={(albumCollectible as IUserAlbumCollectible).isOwned && showCollectibleDetails?.collectible?.id === albumCollectible.id || undefined}
                      // onClick={(albumCollectible as IUserAlbumCollectible).isOwned && ((view) => setShowCollectibleDetails({ collectible: albumCollectible, view }))  || undefined}
                      // to={!(albumCollectible as IUserAlbumCollectible).isOwned && `/collectibles/${albumCollectible.id}` || undefined}
                    />
                  ))
                }
              </ItemList>
            }
            {
              // detail view for owned items
              <AlbumCollectibleDetailsModal
                {...showCollectibleDetails}
                album={album}
                onCloseButtonClick={() => setShowCollectibleDetails(null)}
              />
            }
          </>
        }
      </ContentWrapper>

      <ShareAlbumPopUp
        initiallyOpen
        open={openShareMenu}
        setOpen={setOpenShareMenu}
        album={album}
      />
    </PageView>
  );
};
