import { useState, FC } from 'react';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../services/localization';

const PrivacyBannerWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #08E8DE;
    color: black;
    padding: 10px;
    text-align: center;
    z-index: 100;
`;

export const PrivacyBanner: FC = () => {
  const [accepted, setAccepted] = useState<boolean>(localStorage.getItem('acceptedCookies') === 'true');
  const getLocalizedString = useGetLocalizedString();
  const message = getLocalizedString("app.v2.privacy.banner.message");
  const actionText = getLocalizedString("app.v2.privacy.banner.action");
  const privacyLink = getLocalizedString("app.v2.privacy.banner.link");

  const acceptCookies = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <PrivacyBannerWrapper>
      {message} [<a target="blank" href="https://fansea.io/privacy-policy/">{privacyLink}</a>] <button onClick={acceptCookies}><strong>&nbsp;&nbsp;{actionText}</strong></button>
    </PrivacyBannerWrapper>
  );
};