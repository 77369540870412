export const CheckIcon = (props: any) => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M6 13L10.5 17.5L20 8.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);

export const CheckIconBold = (props: any) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.39844 4L4.24538 7L10.2556 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
