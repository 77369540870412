import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { Location, matchPath, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PageLayout } from '../components/layout/PageLayout';
import { useAuthToken } from '../state/auth';
import { LastLocationState } from '../state/lastLocation';
import { useTrackingState } from '../state/tracking';
import { ROUTES } from './Routes';

interface createRouteConfig {
  authRequired?: boolean;
  hideNavBar?: boolean;
  hideHeader?: boolean;
  hideSwirl?: boolean;
  hideBlobs?: boolean;
  showCampaignBackground?: boolean;
  showNotificationTopBar?: boolean;
  withBackButton?: boolean;
}

interface CreateRouteProps {
  path: string;
  element: ReactNode;
  config: createRouteConfig | undefined;
}

export const FanseaRouter: FC<{ location?: Location }> = (props) => {
  const authToken = useAuthToken();
  const currentLocation = useLocation();
  const location = props.location || currentLocation;
  const [trackingState, setTrackingState] = useTrackingState();

  const lastLocation = useRef<Location | null>(null);
  const setLastLocationState = useSetRecoilState(LastLocationState);

  const availableRoutes = useMemo(
    () =>
      ROUTES.map((route) => ({
        ...route,
        element:
          !route.config?.authRequired || !!authToken ? ( // check authRequired
            route.element
          ) : (
            <Navigate replace to={'/login'} state={{ redirect: `${location.pathname}${location.search}` }} />
          ), // redirect to login page
      })),
    [authToken, location],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });

    // handle last location state
    if (lastLocation.current?.key !== location.key) {
      if (lastLocation.current) {
        setLastLocationState(lastLocation.current);
      }
      lastLocation.current = location;
    }
  }, [location]);

  useEffect(() => {
    if (!!trackingState?.trackEvent) {
      // tracking parameter
      const trackingSource = new URLSearchParams(location.search).get('referrer') || null;
      if (trackingSource && trackingState?.source !== trackingSource) {
        setTrackingState((state) => ({
          ...(state || {}),
          source: trackingSource,
        }));

        const excludedPaths = ['/purchase/success', '/purchase/error'];
        if (!excludedPaths.some((path) => location.pathname === path)) {
          // send SA event
          trackingState?.trackEvent('lead', { source: trackingSource });
        }
      }
    }
  }, [location, trackingState?.trackEvent]);

  return (
    <Routes location={location}>
      <Route
        path={'/'}
        element={
          <PageLayout
            hideNavBar={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.hideNavBar}
            hideHeader={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.hideHeader}
            hideSwirl={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.hideSwirl}
            hideBlobs={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.hideBlobs}
            showNotificationTopBar={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.showNotificationTopBar}
            showCampaignBackground={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.showCampaignBackground}
            withBackButton={availableRoutes.find(({ path }) => matchPath(path, location?.pathname || ''))?.config?.withBackButton}>
            <Outlet />
          </PageLayout>
        }>
        {availableRoutes.map((route: CreateRouteProps) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        {/* fallback route */}
        <Route path={'*'} element={<Navigate replace to={'/404'} />} />
      </Route>
    </Routes>
  );
};
