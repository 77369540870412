import { ChangeEvent, FC, useEffect, useState } from "react";
import styled from "styled-components";
import { StyledInput } from "../forms/Input";

const Wrapper = styled.fieldset`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
`;

const InputWrapper = styled.div`
  display: grid;
  align-items: center;
`;

const Number = styled.span`
  grid-area: 1 / 1;
  z-index: 1;
  font-weight: 700;
  margin-left: 1rem;
  font-size: 1rem;
  width: max-content;
`;

const Input = styled(StyledInput)`
  grid-area: 1 / 1;
  padding-left: 2.75rem;
  width: 100%;
`;

interface ISeedPhraseProps {
  value: string;
  onChange?: (seedPhrase: string) => void;
  disabled?: boolean;
}

const SeedPhraseInput: FC<ISeedPhraseProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const [seedPhrase, setSeedPhrase] = useState<Array<string>>(Array(12).fill(''));

  useEffect(() => {
    setSeedPhrase(value.split(' ').slice(0, 12))
  }, [value])

  const handleInput = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const _seedPhrase = [...seedPhrase];
    _seedPhrase[i] = e.target.value.trim() || '';
    onChange?.(_seedPhrase.join(' ').toLowerCase());
  }

  return (
    <form>
      <Wrapper disabled={disabled}>
        {Array(12).fill('').map((_, i) => (
          <InputWrapper key={i}>
            <Number>{(i + 1 + '').padStart(2, '0')}</Number>
            <Input onChange={(e) => handleInput(e, i)} value={seedPhrase[i] || ''} autoComplete="off"  />
          </InputWrapper>
        ))}
      </Wrapper>
    </form>
  )
}

export default SeedPhraseInput

