import { ArrowIcon } from '../../icons/ArrowIcon';
import { SpinArrowIcon } from '../../icons/SpinArrowIcon';
import { FavoriteIcon } from '../../icons/FavoriteIcon';
import styled, { css, keyframes } from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { FC, useEffect, useState } from 'react';
import { ButtonHover } from '../../../styles/Buttons';
import { LinkHandler } from '../general/LinkHandler';
import { useGetLocalizedString } from '../../../services/localization';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { LastLocationState } from '../../../state/lastLocation';
import { ICollectible } from '../../../services/collectible';
import { ShareIcon } from '../../icons/ShareIcon';
import { CloseIcon } from '../../icons/CloseIcon';
import { CollectibleItem } from '../special/CollectibleItem';
import { useFavoriteCollectibles } from '../../../hooks/useFavoriteCollectibles';

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.75rem 0 1.125rem;

  ${BREAKPOINT_MD} {
    margin-bottom: .625rem;
  }
`;

const FavoriteButton = styled.button<{ active: boolean }>`
  border: none;
  background: none;
  appearance: none;
  color: ${({ active }) => active ? 'var(--color-primary)' : 'inherit'};

  @media (hover: hover) {
    :hover {
      color: var(--color-primary);
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const BackArrowStyles = css`
  background: none;
  border: none;
  appearance: none;
  display: flex;
  align-items: center;
  gap: .5rem;

  svg {
    font-size: 1rem;
  }

  span {
    display: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .875rem;
    margin-top: .25rem;

    ${BREAKPOINT_MD} {
      display: initial;
    }
  }

  @media (hover: hover) {
    :hover {
      color: var(--color-primary);
      text-decoration: none;
    }
  }
`;

const BackButton = styled.button`
  ${BackArrowStyles};
`;

const BackLink = styled(LinkHandler)`
  ${BackArrowStyles};
`;

const ShareButton = styled.button`
  font-size: 1.5rem;
`;

const ShareCollectiblePopUp = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  opacity: ${({ open }) => open ? 1 : 0};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  transform: scale(${({ open }) => open ? 1 : .8});
  transition: opacity 450ms ease, visibility 450ms ease, transform 450ms ease;

  background: rgba(0, 0, 0, .4);
  backdrop-filter: blur(21px);

  padding: 1rem;

  ${BREAKPOINT_MD} {
    padding: 3rem;
  }
`;

const PopUpWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: var(--page-grid);
  grid-template-rows: max-content 1fr max-content;
  gap: var(--content-indent);
  place-items: center;

  > * {
    grid-column: 2;

    ${BREAKPOINT_LG} {
      grid-column: 3;
    }
  }
`;

const PopUpHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1rem var(--content-indent);
  width: 100%;
`;

const PopUpHeadline = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: 1.5rem;
  font-weight: bold;

  svg {
    margin-bottom: .25rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const PopUpBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 2 * var(--content-indent) - 4rem - 9rem);
  max-width: calc((100vh - 2 * var(--content-indent) - 4rem - 9rem) / 1.4);

  ${BREAKPOINT_MD} {
    max-height: calc(100vh - 2 * var(--content-indent) - 4rem - 9rem - 2 * 3rem);
    max-width: calc((100vh - 2 * var(--content-indent) - 4rem - 9rem - 2 * 3rem) / 1.4);
  }

  ${BREAKPOINT_XL} {
    max-height: calc(100vh - 2 * var(--content-indent) - 4rem - 4rem - 2 * 3rem);
    max-width: 700px;
    grid-row: 2;
  }
`;

interface CollectibleDetailNavigationProps {
  isFavorite: boolean;
  toggleFavorite: () => void;
  toggleOverlay: () => void;
  openOverlay?: boolean;
  backAction?: () => void;
  show3DButton?: boolean;
  collectible: ICollectible;
}

export const CollectibleDetailNavigation: FC<CollectibleDetailNavigationProps> = (props) => {
  const {
    isFavorite,
    toggleFavorite,
    openOverlay = false,
    toggleOverlay,
    backAction,
    show3DButton = false,
    collectible,
  } = props;
  const getLocalizedString = useGetLocalizedString();

  const { state } = useLocation();
  const lastLocation = useRecoilValue(LastLocationState);
  const lastLocationHref = `${lastLocation?.pathname || ''}${lastLocation?.search ? `?${lastLocation.search}` : ''}`;
  const { backTo = lastLocationHref || '/collectibles' } = state || {};

  const location = useLocation();
  const [openShareMenu, setOpenShareMenu] = useState(false);
  const [supportsNavigator, setSupportsNavigator] = useState<boolean>(false);
  const [collectibleUrl, setCollectibleUrl] = useState<string>('');
  const { favoritesIds } = useFavoriteCollectibles();

  useEffect(() => {
    if (collectible?.id) {
      setCollectibleUrl(`${window.location.origin.toString()}/collectibles/${collectible?.id}`);
    }
  }, [collectible]);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', openShareMenu);
  }, [openShareMenu]);

  useEffect(() => {
    // init
    if (!!navigator?.canShare) {
      setSupportsNavigator(true);
    }
    // cleanup
    return () => {
      document.body.classList.toggle('no-scroll', false);
    };
  }, []);

  const handleShareButtonClick = async (e: any) => {
    e.stopPropagation();
    if (supportsNavigator) {
      const shareData = {
        text: collectible?.title,
        url: location.pathname,
      };

      try {
        await navigator.share(shareData);
      } catch {
        console.log('navigator share error!');
      }
    } else {
      setOpenShareMenu(true);
    }
  };

  return (
    <>
      <NavigationWrapper>
        {backAction
          ? <BackButton onClick={backAction}>
            <ArrowIcon />
            <span>{getLocalizedString('app.v2.general.back')}</span>
          </BackButton>
          : <BackLink to={backTo}>
            <ArrowIcon />
            <span>{getLocalizedString('app.v2.general.back')}</span>
          </BackLink>
        }
        <ButtonWrapper>
        
          <ShareButton onClick={handleShareButtonClick}>
            <ShareIcon />
          </ShareButton>

          <FavoriteButton active={isFavorite} onClick={toggleFavorite}>
            <FavoriteIcon active={isFavorite} />
          </FavoriteButton>
        </ButtonWrapper>
      </NavigationWrapper>

      <ShareCollectiblePopUp open={openShareMenu} onClick={() => {
        if (window.innerWidth >= DIMEN_BREAKPOINT_MD) {
          setOpenShareMenu(false);
        }
      }}>
        <PopUpWrapper>
          <PopUpHeader>
            <PopUpHeadline>
              <ShareIcon /> {getLocalizedString('app.v2.general.share')}
            </PopUpHeadline>

            <CloseButton onClick={() => setOpenShareMenu(false)}>
              <CloseIcon />
            </CloseButton>
          </PopUpHeader>

          <PopUpBody>
            <CollectibleItem
              key={collectible.id}
              hideCreatorNameOnMobile
              active={favoritesIds?.collectibles?.includes(collectible.id) || false}
              {...collectible}
              subCollectibles={collectible.subCollectibles.map(item => item.id)}
            />
          </PopUpBody>
        </PopUpWrapper>
      </ShareCollectiblePopUp>
    </>
  );
};