import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const OptionsIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 6 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <circle cx='2.88477' cy='3' r='2.5' fill='currentColor' />
    <circle cx='2.88477' cy='11' r='2.5' fill='currentColor' opacity='.3' />
    <circle cx='2.88477' cy='19' r='2.5' fill='currentColor' />
  </svg>
);