import { FC } from 'react';
import { OverlayIcon } from './OverlayIcon';

export const HomeIconOutline: FC<{ className?: string }> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M2.5 10.4692C2.5 9.85711 2.78029 9.27872 3.26071 8.89944L10.7607 2.97838C11.4873 2.40474 12.5127 2.40474 13.2393 2.97839L20.7393 8.89944C21.2197 9.27872 21.5 9.85711 21.5 10.4692V20C21.5 21.1046 20.6046 22 19.5 22H17C15.8954 22 15 21.1046 15 20V18C15 16.8954 14.1046 16 13 16H11C9.89543 16 9 16.8954 9 18V20C9 21.1046 8.10457 22 7 22H4.5C3.39543 22 2.5 21.1046 2.5 20L2.5 10.4692Z" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export const HomeIconFilled: FC<{ className?: string }> = ({ className }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M3.4978 10.4692C3.4978 9.85711 3.77809 9.27872 4.25851 8.89944L11.7585 2.97838C12.4851 2.40474 13.5105 2.40474 14.2371 2.97839L21.7371 8.89944C22.2175 9.27872 22.4978 9.85711 22.4978 10.4692V20C22.4978 21.1046 21.6024 22 20.4978 22H17.9978C16.8932 22 15.9978 21.1046 15.9978 20V18C15.9978 16.8954 15.1024 16 13.9978 16H11.9978C10.8932 16 9.9978 16.8954 9.9978 18V20C9.9978 21.1046 9.10237 22 7.9978 22H5.4978C4.39323 22 3.4978 21.1046 3.4978 20L3.4978 10.4692Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
  </svg>
);

export const HomeIcon: FC<{ className?: string, active?: boolean }> = (props) => (
  <OverlayIcon {...props}>
    <HomeIconOutline />
    <HomeIconFilled />
  </OverlayIcon>
);
