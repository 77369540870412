import styled, { css } from 'styled-components';
import { ConnectorNotFoundError, useAccount, useConnect, useDisconnect } from 'wagmi';

import { useGetLocalizedString } from '../../../services/localization';
import { useAddSystemNotification } from '../../../state/systemNotifications';
import { truncateWalletAddress } from '../../../utils/truncate';
import { chainId, metaMaskConnector, injectedConnector } from '../../../web3/client';

const ConnectWalletButton = styled.button<{ address?: string; isConnected: boolean }>`
  padding: 0 2rem 0 1rem;

  position: relative;
  color: white;

  height: 30px;
  line-height: 30px;

  font-size: 0.75rem;
  font-weight: normal;
  text-transform: none;

  background: rgba(217, 217, 217, 0.1);

  // border: 1px solid #c5c5c5;
  border-radius: 11px;

  ${(props) => {
    switch (props.isConnected) {
      case true:
        return css`
          border: 1px solid #08e8de;
        `;
      default:
        return css`
          border: 1px solid #c5c5c5;
        `;
    }
  }}

  &:before {
    position: absolute;
    content: '';
    right: 10px;
    top: 7px;

    width: 12px;
    height: 12px;
    border: 1px solid #c5c5c5;

    border-radius: 50%;
    box-shadow: 0 0 4px #1c1c17;
    transition: all 0.3s ease;
    ${(props) => {
      switch (props.isConnected) {
        case true:
          return css`
            background: #08e8de;
            border: 1px solid #ffffff;
          `;
        default:
          return css`
            border: 1px solid #c5c5c5;
          `;
      }
    }}
  }
`;

// USED IN HEADER
const ConnectButton = () => {
  const t = useGetLocalizedString();
  const { address, isConnected, connector: activeConnector } = useAccount();
  const addSystemNotification = useAddSystemNotification();

  const { disconnect } = useDisconnect();
  const { connect } = useConnect({
    connector: injectedConnector,
    chainId: chainId,
    onError(error) {
      if (error instanceof ConnectorNotFoundError) {
        addSystemNotification({
          type: 'error',
          headline: t('app.v2.connect-wallet.error.no-provider.title'),
          text: t('app.v2.connect-wallet.error.no-provider.text'),
        });
      }
    },
  });

  const toggleConnect = () => {
    if (address) {
      disconnect();
    } else {
      connect();
    }
  };

  return (
    <ConnectWalletButton disabled={!window.ethereum} isConnected={isConnected} address={address} type='button' onClick={() => toggleConnect()}>
      {isConnected && truncateWalletAddress(address)}
      {!isConnected && t('app.v2.connect-wallet.connect.button')}
    </ConnectWalletButton>
  );
};

export default ConnectButton;
