import { FC } from 'react';
import { OverlayIcon } from './OverlayIcon';

export const CollectiblesIconOutline: FC<{ className?: string }> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="0.957694" y="0.395576" width="11.6449" height="17.4717" rx="1.25" transform="matrix(0.914503 -0.404579 0.362423 0.932014 -0.0614857 6.23272)" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M18.5916 11.4068L14.8733 1.4917H17.5916C18.1439 1.4917 18.5916 1.93941 18.5916 2.4917V11.4068Z" fill="#ADADAD"/>
    <path d="M20.3319 13.9621L20.4004 3.47461L23.0969 4.75686C23.5957 4.99403 23.8273 5.59939 23.6143 6.10895L20.3319 13.9621Z" fill="#ADADAD"/>
  </svg>
);

export const CollectiblesIconFilled: FC<{ className?: string }> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="0.957694" y="0.395576" width="11.6449" height="17.4717" rx="1.25" transform="matrix(0.914503 -0.404579 0.362423 0.932014 -0.061974 6.23272)" fill="currentColor" stroke="currentColor" strokeWidth="1.5"/>
    <path d="M18.5911 11.4068L14.8728 1.4917H17.5911C18.1434 1.4917 18.5911 1.93941 18.5911 2.4917V11.4068Z" fill="currentColor"/>
    <path d="M20.3314 13.9621L20.3999 3.47461L23.0964 4.75686C23.5952 4.99403 23.8268 5.59939 23.6139 6.10895L20.3314 13.9621Z" fill="currentColor"/>
  </svg>
);

export const CollectiblesIcon: FC<{ className?: string, active?: boolean }> = (props) => (
  <OverlayIcon {...props}>
    <CollectiblesIconOutline />
    <CollectiblesIconFilled />
  </OverlayIcon>
);
