export const PaymentIcon = (props: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.5 15.5' {...props}>
    <g>
      <g opacity={.91}>
        <path fill={'#fff'}
              d='M2.75,1.5H20.75V0H2.75V1.5Zm19.25,1.25V12.75h1.5V2.75h-1.5Zm-1.25,11.25H2.75v1.5H20.75v-1.5ZM1.5,12.75V2.75H0V12.75H1.5Zm1.25,1.25c-.69,0-1.25-.56-1.25-1.25H0c0,1.52,1.23,2.75,2.75,2.75v-1.5Zm19.25-1.25c0,.69-.56,1.25-1.25,1.25v1.5c1.52,0,2.75-1.23,2.75-2.75h-1.5Zm-1.25-11.25c.69,0,1.25,.56,1.25,1.25h1.5c0-1.52-1.23-2.75-2.75-2.75V1.5ZM2.75,0C1.23,0,0,1.23,0,2.75H1.5c0-.69,.56-1.25,1.25-1.25V0Z' />
        <path fill={'#adadad'}
              d='M14.75,9.19c0,1.24-.73,1.95-2.18,2.14v.64c0,.44-.35,.79-.79,.79s-.79-.35-.79-.79v-.65c-.76-.12-1.32-.39-1.68-.82-.21-.25-.36-.53-.45-.83-.12-.4,.24-.76,.66-.76,.4,0,.7,.33,.91,.68,.06,.11,.14,.2,.23,.29,.27,.24,.66,.35,1.18,.35,.48,0,.83-.08,1.04-.23,.22-.16,.32-.39,.32-.68,0-.25-.07-.45-.22-.6-.15-.15-.39-.24-.73-.3l-1.53-.22c-.59-.09-1.04-.3-1.35-.63-.3-.33-.45-.76-.45-1.31,0-.57,.18-1.04,.54-1.4,.37-.36,.88-.59,1.53-.69v-.62c0-.44,.35-.79,.79-.79s.79,.35,.79,.79v.64c.62,.11,1.11,.34,1.46,.71,.18,.18,.32,.38,.42,.61,.18,.41-.19,.81-.64,.81-.4,0-.7-.33-.95-.64-.04-.05-.09-.1-.14-.14-.22-.19-.54-.29-.97-.29s-.76,.08-.98,.24c-.22,.15-.34,.36-.34,.63,0,.44,.28,.69,.84,.78l1.48,.2c.72,.1,1.23,.33,1.53,.68,.31,.34,.47,.82,.47,1.42Z' />
      </g>
    </g>
  </svg>
);
