import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'tracking',
  storage: sessionStorage,
});

interface ITrackingSession {
  source?: string;
  trackEvent?: (name: string, data?: any) => void;
}

const TrackingState = atom<ITrackingSession | null>({
  key: 'TrackingState',
  default: null,
  effects_UNSTABLE: [
    persistAtom
  ],
});

export const useTrackingState = () => useRecoilState(TrackingState);
export const useSetTrackingState = () => useSetRecoilState(TrackingState);
export const useTrackingSource = () => useRecoilValue(TrackingState)?.source;
export const useTrackEvent = () => useRecoilValue(TrackingState)?.trackEvent;
