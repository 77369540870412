import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const ErrorIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.26562 17.5227C2.26562 16.9317 2.41623 16.3505 2.70322 15.834L8.72195 5.00024C9.25546 4.03993 10.1837 3.36232 11.261 3.14688V3.14688C11.7458 3.04992 12.245 3.04992 12.7297 3.14688V3.14688C13.807 3.36232 14.7353 4.03993 15.2688 5.00024L21.2875 15.834C21.5745 16.3505 21.7251 16.9317 21.7251 17.5227V17.5227C21.7251 19.4432 20.1682 21 18.2478 21H5.74294C3.82247 21 2.26562 19.4432 2.26562 17.5227V17.5227Z'
      stroke='#E20A4B'
      strokeWidth='1.5'
    />
    <path
      d='M12.2656 9L12.2656 13'
      stroke='#ADADAD'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.2656 16L12.2656 16.5'
      stroke='#ADADAD'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);