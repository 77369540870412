import { Wallet } from 'ethers';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useWallet } from '../../../hooks/useWallet';
import { PrimaryButton } from '../../../styles/Buttons';
import { Checkbox } from '../../common/forms/Checkbox';
import FormWrapper from '../../common/forms/FormWrapper';
import { useAuthAPI } from '../../../services/auth';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { useGetLocalizedString } from '../../../services/localization';
import WalletBackup from '../../common/special/WalletBackup';
import { useRecoilState } from 'recoil';
import { UserState } from '../../../state/user';

const StyledCheckbox = styled(Checkbox)`
  color: var(--color-signal-yellow);
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const NextButton = styled(PrimaryButton)`
  margin: 0.75rem auto 0;
`;

const StyledFormWrapper = styled(FormWrapper)`
  display: grid;
  gap: 1rem;
`;

interface IWalletCreationProps {
  password: string | null;
  onSubmit: () => void;
  userWallet: string;
}

const WalletCreation: FC<IWalletCreationProps> = ({ password, onSubmit, userWallet }) => {
  const { createWallet, storeWallet } = useWallet();
  const { registerFinalize } = useAuthAPI();
  const getLocalizedString = useGetLocalizedString();

  const [wallet, setWallet] = useState<Wallet | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);

  const [userState, setUserState] = useRecoilState(UserState);

  const form = useForm({
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    
    setLoading(true);
    try {
      if ((wallet?.address || userWallet) && password) {
        const walletAddress = userWallet || wallet?.address || '';
        // finalize user
        await registerFinalize({ walletAddress: walletAddress });

        // save wallet address in user state
        if (userState) {
          setUserState({
            ...userState,
            walletAddress: walletAddress,
          });
        }

        // save encrypted wallet
        await saveWallet(password);

        // success
        setLoading(false);
        onSubmit();
      } else {
        // show error message
        setErrorState({
          headline: getLocalizedString('app.v2.create-wallet.error401.title'),
          text: getLocalizedString('app.v2.create-wallet.error401.text'),
        });
      }
    } catch (err: any) {
      console.error('register finalize', err);
      switch (err?.response?.status || 500) {
        default:
        case 401:
          setErrorState({
            headline: getLocalizedString('app.v2.create-wallet.error401.title'),
            text: getLocalizedString('app.v2.create-wallet.error401.text'),
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const initializeWallet = useCallback(() => {
    const wallet = createWallet();
    setWallet(wallet);
  }, [createWallet]);

  const saveWallet = useCallback(
    async (password: string) => {
      if (wallet) {
        await storeWallet(wallet, password);
      }
    },
    [wallet, storeWallet],
  );

  useEffect(() => {
    initializeWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WalletBackup wallet={wallet} errorState={errorState} setErrorState={setErrorState}>
      {({ saved }) => (
        <StyledFormWrapper form={form} onSubmit={handleSubmit}>
          <StyledCheckbox
            label={getLocalizedString('app.v2.create-wallet.checkbox.access-information')}
            name='accessInformation'
            required={true}
          />
          <StyledCheckbox
            label={getLocalizedString('app.v2.create-wallet.checkbox.seedphrase-information')}
            name='seedPhraseInformation'
            required={true}
          />
          <NextButton className='cy-button-next' disabled={!(form.formState.isValid && saved) && (userWallet === '' || !form.formState.isValid)}>
            {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.general.continue')}
          </NextButton>
        </StyledFormWrapper>
      )}
    </WalletBackup>
  );
};

export default WalletCreation;
