import styled from 'styled-components';
import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XS } from '../../../../styles/Breakpoints';
import { ChevronIcon } from '../../../icons/ChevronIcon';
import { LinkHandler } from '../../general/LinkHandler';
import { useIsAuthenticated } from '../../../../services';
import { ICollectibleAlbum } from '../../../../services/collectible';
import { Headline3 } from '../../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../../services/localization';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 3.75rem;

  ${BREAKPOINT_MD} {
    gap: 1.5rem;
    margin-bottom: 9rem;
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  margin: 0 calc(-1 * var(--content-indent));

  ${BREAKPOINT_LG} {
    margin: 0 auto;
    width: 100%;
    max-width: 64rem;
  }

  .swiper {
    padding: 0 var(--content-indent);
    width: 100%;

    ${BREAKPOINT_XS} {
      padding: 0 calc(var(--content-indent) * 1.5);
    }

    ${BREAKPOINT_MD} {
      padding: 0 calc(var(--content-indent));
    }

    ${BREAKPOINT_LG} {
      padding: 0;
    }
  }
`;

const Pagination = styled.div<{ items: number }>`
  display: ${({ items }) => items > 1 ? 'flex' : 'none'};
  justify-content: center;
  gap: .375rem;

  ${BREAKPOINT_XS} {
    display: ${({ items }) => items > 2 ? 'flex' : 'none'};
  }

  ${BREAKPOINT_MD} {
    display: ${({ items }) => items > 3 ? 'flex' : 'none'};
  }
`;

const Dot = styled.span<{ activeIndexDiff: number }>`
  height: .375rem;
  width: .375rem;
  border-radius: 100%;
  background: var(--color-text-default);
  transition: opacity 450ms ease;
  opacity: ${({ activeIndexDiff }) => activeIndexDiff === 0 ? 1 : .5};

  ${BREAKPOINT_XS} {
    opacity: ${({ activeIndexDiff }) => activeIndexDiff >= 0 && activeIndexDiff < 2 ? 1 : .5};
  }

  ${BREAKPOINT_MD} {
    opacity: ${({ activeIndexDiff }) => activeIndexDiff >= 0 && activeIndexDiff < 3 ? 1 : .5};
  }
`;

const PaginationButton = styled.button<{ active: boolean }>`
  border: none;
  appearance: none;
  background: none;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  opacity: ${({ active }) => active ? 1 : .5};
  pointer-events: ${({ active }) => active ? 'auto' : 'none'};
  display: none;

  ${BREAKPOINT_LG} {
    display: initial;
  }

  &:first-child {
    transform: rotate(180deg);
  }
`;

const AlbumCard = styled(LinkHandler)`
  position: relative;
  display: block;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  border-radius: 1.25rem;
  overflow: hidden;

  :before {
    content: '';
    display: block;
    padding-top: 120%;
  }

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 100%);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const MetaDataWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  padding: 1rem;
`;

const Title = styled.h6`
  font-size: 2rem;
  line-height: 2.375rem;
`;

const CreatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  font-size: .75rem;
  line-height: 1.125;
  gap: .375rem;

  img {
    width: 1.25rem;
    height: 1.25rem;
    object-fit: contain;
  }
`;

const CollectibleAmount = styled.p`
  font-size: .75rem;
  white-space: nowrap;
`;

const AlbumsHeadline = styled.h3`
  ${Headline3};
  text-align: center;
  margin-bottom: 2.5rem;
`;

interface RelatedAlbumsSectionProps {
  albums: ICollectibleAlbum[];
}

export const RelatedAlbumsSection: FC<RelatedAlbumsSectionProps> = (props) => {
  const { albums } = props;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>(null);
  const isAuthenticated = useIsAuthenticated();
  const getLocalizedString = useGetLocalizedString();

  const onSlideChange = (e: any) => {
    setActiveIndex(e.realIndex);
  };

  return (
    <Wrapper>
      <AlbumsHeadline>{getLocalizedString('app.v2.collectible.details.albums.headline')}</AlbumsHeadline>
      <SwiperWrapper>
        {
          albums.length > 2 &&
          <PaginationButton onClick={() => swiper.slidePrev()} active={activeIndex > 0}>
            <ChevronIcon />
          </PaginationButton>
        }

        <Swiper
          onSwiper={setSwiper}
          onSlideChange={onSlideChange}
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 8 },
            480: { slidesPerView: 2, spaceBetween: 16 },
            768: { slidesPerView: 3, spaceBetween: 16 },
            992: { slidesPerView: 3, spaceBetween: 24 },
          }}>
          {
            albums.map((album, index) => (
              <SwiperSlide key={index}>
                <AlbumCard to={`/albums/${album.id}`}>
                  <ImageWrapper><img src={album.coverImage} alt={album.title} /></ImageWrapper>

                  <MetaDataWrapper>
                    <Title>{album.title}</Title>

                    <CreatorWrapper>
                      <Creator>
                        <img src={album.publisherLogoUrl} alt={album.publisherName} />
                        <p>{album.publisherName}</p>
                      </Creator>

                      <CollectibleAmount>{isAuthenticated && `${album.ownedCollectibles}/`}{album.collectiblesInAlbum} {album.collectiblesInAlbum > 1 ? getLocalizedString('app.v2.albums.label.collectibles-count') : getLocalizedString('app.v2.albums.label.collectibles-count-singular')}</CollectibleAmount>
                    </CreatorWrapper>
                  </MetaDataWrapper>
                </AlbumCard>
              </SwiperSlide>
            ))
          }
        </Swiper>

        {
          albums.length > 2 &&
          <PaginationButton onClick={() => swiper.slideNext()} active={activeIndex + 3 < albums.length}>
            <ChevronIcon />
          </PaginationButton>
        }
      </SwiperWrapper>

      <Pagination items={albums.length}>
        {albums.map((i, index) => <Dot key={index} activeIndexDiff={index - activeIndex} />)}
      </Pagination>
    </Wrapper>
  );
};