import { FC } from 'react';
import 'swiper/css';
import Confetti from 'react-confetti';
import { useWindowSize } from '../../hooks/useWindowSize';

const colors = [
  '#A3781F',
  '#A8934C',
  '#EBDFA8',
  '#A8934C',
  '#362550',
];

function random(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const FanseaConfetti: FC<any> = (props) => {
  const { width, height } = useWindowSize();

  return (
    <Confetti
      tweenDuration={3000}
      opacity={0.7}
      width={width}
      height={height}
      colors={colors}
      gravity={0.06}
      friction={random(0.99, 0.98)}
      numberOfPieces={200}
      recycle={true}
    />
  );
};
