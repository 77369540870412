import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import { Wizard } from '../components/common/special/Wizard';
import { RegistrationIcon } from '../components/icons/RegistrationIcon';
import { WalletIcon } from '../components/icons/WalletIcon';
import { PageView } from '../components/layout/PageLayout';
import Intro from '../components/views/registration/Intro';
import SignUp from '../components/views/registration/SignUp';
import WalletCreation from '../components/views/registration/WalletCreation';
import { useWallet } from '../hooks/useWallet';
import { useAuthAPI } from '../services/auth';
import { useGetLocalizedString, useLocalizedString } from '../services/localization';
import { UserState } from '../state/user';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XS } from '../styles/Breakpoints';
import { PageHeadline1 } from '../styles/FontStyles';

const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-content: center;

  ${BREAKPOINT_LG} {
    align-content: stretch;
    gap: 2rem;
  }
`;

const StyledWizard = styled(Wizard)`
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3rem;
  }
`;

const Headline = styled.h1`
  ${PageHeadline1};
`;

const Content = styled.div`
  grid-column: 1 / -1;
  align-self: center;

  ${BREAKPOINT_XS} {
    grid-column: 2 / -2;
  }

  ${BREAKPOINT_MD} {
    grid-column: 3 / -3;
  }

  ${BREAKPOINT_LG} {
    grid-column: 8 / -1;
  }
`;

const BoxedContent = styled(Content)`
  ${BREAKPOINT_XL} {
    max-width: 66.6%;
  }
`;

export const Registration = () => {
  const getLocalizedString = useGetLocalizedString();
  const signUpTitle = useLocalizedString('app.v2.register.sign-up-title');
  const walletCreateTitle = useLocalizedString('app.v2.register.wallet-create-title.v2');

  const navigate = useNavigate();
  const location = useLocation();

  const { step: initialStep = 1, password = null } = location.state || {};
  const [step, setStep] = useState(initialStep);

  const [cachedPassword, setCachedPassword] = useState<string | null>(password);

  const loggedOutOnError = useRef(false);
  const authAPI = useAuthAPI();
  // const setUserState = useSetRecoilState(UserState);
  const [userState, setUserState] = useRecoilState(UserState);

  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);
  const { storeWallet } = useWallet();
  const { address, isConnected } = useAccount();
  const [userWallet, setUserWallet] = useState('');

  const navigateToApp = useCallback(() => {
    const { redirect = '/' } = location.state || {};
    navigate(redirect);
  }, [location, navigate]);

  const redirectToLogin = useCallback(() => {
    if (!loggedOutOnError.current) {
      loggedOutOnError.current = true;
      authAPI.logout().then(() => {
        navigate('/login', {
          state: {
            error: {
              headline: getLocalizedString('app.v2.login.error.incomplete-registration.title'),
              text: getLocalizedString('app.v2.login.error.incomplete-registration.text'),
            },
          },
        });
      });
    }
  }, [setUserState, authAPI, navigate, getLocalizedString]);

  useEffect(() => {
    if (address) {
      setUserWallet(address);
    }
  }, [address]);

  useEffect(() => {
    // redirect to login if no cached password available on step 2
    if (step === 2 && !cachedPassword) {
      redirectToLogin();
    } else {
      loggedOutOnError.current = false;
    }
  }, [step, cachedPassword, redirectToLogin]);

  const handleStep1WithConnectedWallet = async (password: string) => {
    try {
      if (address && password) {
        // finalize user
        await authAPI.registerFinalize({ walletAddress: address });

        // save wallet address in user state
        if (userState) {
          setUserState({
            ...userState,
            walletAddress: address,
          });
        }

        // save encrypted wallet
        // await saveWallet(password);

        navigateToApp();
      } else {
        // show error message
        setErrorState({
          headline: getLocalizedString('app.v2.create-wallet.error401.title'),
          text: getLocalizedString('app.v2.create-wallet.error401.text'),
        });
      }
    } catch (err: any) {
      console.error('register finalize', err);
      switch (err?.response?.status || 500) {
        default:
        case 401:
          setErrorState({
            headline: getLocalizedString('app.v2.create-wallet.error401.title'),
            text: getLocalizedString('app.v2.create-wallet.error401.text'),
          });
          break;
      }
    }
  };

  const handleSignUp = async (password: any) => {
    setCachedPassword(password);
    if (!address) {
      setStep(2);
    } else {
      await handleStep1WithConnectedWallet(password);
    }
  };

  const signUpView = useMemo(
    () => (
      <>
        <Headline>{signUpTitle}</Headline>
        <BoxedContent>
          <SignUp onSubmit={(password) => handleSignUp(password)} />
        </BoxedContent>
      </>
    ),
    [signUpTitle, setStep, setCachedPassword],
  );

  const walletCreationView = useMemo(
    () => (
      <>
        <Headline>{walletCreateTitle}</Headline>
        <Content>
          <WalletCreation userWallet={userWallet} password={cachedPassword} onSubmit={navigateToApp} />
        </Content>
      </>
    ),
    [walletCreateTitle, navigateToApp, cachedPassword, userWallet],
  );

  const body = useMemo(() => {
    switch (step) {
      case 0: // TODO add tutorial screen
        return <Intro />;
      case 1:
        return signUpView;
      case 2:
        return walletCreationView;
    }
    return undefined;
  }, [step, signUpView, walletCreationView]);

  return (
    <PageView>
      <Section>
        <StyledWizard active={step - 1}>
          <RegistrationIcon />
          <WalletIcon />
        </StyledWizard>
        {body}
      </Section>
    </PageView>
  );
};
