import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import HiddenArea from "../general/HiddenArea";

const Wrapper = styled.form`
  padding: 2.5rem 3.5rem;
  background: var(--gradient-linear-100);
  border-radius: 2px;
  border: 1px solid;
  // TODO Fix
  border-image-source: var(--gradient-radial-900);
`;

const Inner = styled.fieldset`
  display: grid;
  grid-template-columns: repeat(auto-fit, 10ch);
  gap: 1.25rem 1.5rem;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  display: flex;
  gap: .875rem;
`;

const Number = styled.span`
  font-weight: 700;
  display: flex;
  gap: .875rem;
`;

interface ISeedPhraseProps {
  seedPhrase?: string;
  onShow?: () => any;
}

const SeedPhrase: FC<ISeedPhraseProps> = ({
  seedPhrase,
  onShow,
}) => {
  const [seedPhraseValues, setSeedPhraseValues] = useState<Array<string>>(Array(12).fill(''));

  useEffect(() => {
    if (seedPhrase) {
      setSeedPhraseValues(seedPhrase.split(' '))
    }
  }, [seedPhrase])

  return (
    <HiddenArea onShow={onShow}>
      <Wrapper>
        <Inner disabled>
          {seedPhraseValues.map((val, i) => (
            <InputWrapper key={i}>
              <Number>{(i + 1 + '').padStart(2, '0')}</Number>
              {val}
            </InputWrapper>
          ))}
        </Inner>
      </Wrapper>
    </HiddenArea>
  )
};

export default SeedPhrase;