import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CollectibleDetailNavigation } from '../../components/common/collectibles/CollectibleDetailNavigation';
import { TextImageSection } from '../../components/common/collectibles/content/TextImageSection';
import { VideoSection } from '../../components/common/collectibles/content/VideoSection';
import { RarityBackground } from '../../components/common/collectibles/RarityBackground';
import Text from '../../components/common/general/Text';
import { ChevronIcon } from '../../components/icons/ChevronIcon';
import { PageView } from '../../components/layout/PageLayout';
import { useFavoriteCollectibles } from '../../hooks/useFavoriteCollectibles';
import { Marquee } from '../../components/common/general/Marquee';
import { Tag } from '../../components/common/general/Tags';
import { useIsAPIInitialized } from '../../services';
import { useTrackingState } from '../../state/tracking';
import { IAlbumContentItem } from '../../services/albums';
import { ICollectible, useCollectiblesAPI } from '../../services/collectible';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD, MAX_BREAKPOINT_MD, MAX_BREAKPOINT_SM } from '../../styles/Breakpoints';
import { Body2, Body3, Headline3 } from '../../styles/FontStyles';
import { CollectiblesStickyPurchase } from '../../components/common/collectibles/CollectiblesStickyPurchase';
import { BuyButton } from '../../components/common/collectibles/BuyButton';
import { LoadingOverlay } from '../../components/common/general/LoadingOverlay';
import styled, { css } from 'styled-components';
import { OwnedMark } from '../../components/common/albums/AlbumCollectibleItem';
import { Colors } from '../../styles/Colors';
import { CollectibleImage } from '../../components/common/collectibles/CollectibleImage';
import { RelatedAlbumsSection } from '../../components/common/collectibles/content/RelatedAlbumsSection';
import { FaqSection } from '../../components/common/campaigns/FaqSection';
import { FaqSectionCustom } from '../../components/common/albums/content/FaqSectionCustom';

const CollectibleStage = styled.section`
  margin-top: 5rem;
  display: grid;
  grid-template-rows: max-content 1fr repeat(4, max-content);
  height: 100vh;
  height: min(100vh, 100 svh); // ios safari fix

  ${BREAKPOINT_MD} {
    grid-template-rows: repeat(2, max-content) 1fr repeat(3, max-content);
  }
`;

const BackgroundGradient = styled.div`
  z-index: -1;
  position: absolute;
  left: 0;
  top: 70vh;
  height: 30vh;
  width: 100%;
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, var(--color-grey-900) 100%);
`;

const StyledMarquee = styled(Marquee)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  background: none;
  font-size: 4.875rem;
  line-height: 1;
  height: auto;
  z-index: 1;

  --animation-duration: 90s;

  ${BREAKPOINT_MD} {
    font-size: 7.375rem;
  }

  span {
    text-transform: uppercase;
    color: rgba(24, 95, 91, 0.1);
    filter: drop-shadow(0 0 5px #08e8de);
    -webkit-text-stroke: 1px ${({ rarity }) => (rarity > 2 ? 'var(--color-text-default)' : 'var(--color-primary)')};
  }
`;

const CollectibleContent = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  ${BREAKPOINT_LG} {
    margin-top: 6.75rem;
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  ${BREAKPOINT_MD} {
    grid-row: 3;
  }
`;

const Title = styled.h1`
  ${Headline3};
  margin-bottom: 0.75rem;
  font-size: 1.25rem;

  ${BREAKPOINT_MD} {
    grid-row: 2;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  ${BREAKPOINT_MD} {
    grid-row: 5;
    margin-bottom: 1.5rem;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.375rem;
  color: var(--color-primary);
  font-size: 0.875rem;
  line-height: 1;
  justify-content: flex-end;
  margin-bottom: 1.375rem;

  ${BREAKPOINT_MD} {
    grid-row: 4;
    margin-bottom: 1rem;
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.875rem;

  ${BREAKPOINT_MD} {
    font-size: 1.125rem;
  }

  ${BREAKPOINT_LG} {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const AlreadyOwnedWrapper = styled.div`
  justify-self: flex-end;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin: 0 0 0.5rem;

  ${BREAKPOINT_MD} {
    margin: -0.75rem 0 0;
    // margin: -0.75rem 0 1rem;
  }

  ${OwnedMark} {
    width: 1.5rem;
    height: 1.5rem;

    ${BREAKPOINT_LG} {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const Price = styled.p<{ highlight: boolean }>`
  font-size: 0.875rem;

  ${BREAKPOINT_MD} {
    font-size: 1.125rem;
  }

  ${({ highlight = false }) =>
    highlight &&
    css`
      color: ${Colors.primary};
    `};
`;

const CreatorNameWrapper = styled.span`
  ${MAX_BREAKPOINT_SM} {
    display: none;
  }
`;

const CreatorImageWrapper = styled.div`
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  margin: -0.75rem 0;
  backdrop-filter: blur(5px);
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${BREAKPOINT_MD} {
    width: 2.5rem;
    height: 2.5rem;
    margin: -1.25rem 0;
  }

  ${BREAKPOINT_LG} {
    width: 4rem;
    height: 4rem;
    margin: -2rem 0;
  }
`;

const ReadMoreButton = styled.button<{ visible: boolean }>`
  ${Body3};
  border: none;
  background: none;
  appearance: none;
  display: flex;
  gap: 0.75rem;
  place-self: center;
  margin-bottom: 1rem;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: opacity 450ms ease, visibility 450ms ease;
  grid-row: -1;

  svg {
    font-size: 1rem;
    transform: rotate(90deg);
  }

  @media (hover: hover) {
    :hover {
      color: var(--color-primary);
    }
  }
`;

const MetaDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr min-content 1fr;
  place-items: center;
  margin-bottom: 2.5rem;

  span {
    height: 1.75rem;
    width: 1px;
    background-color: var(--color-grey-600);
    margin: 0 1rem;
  }

  ${BREAKPOINT_MD} {
    grid-template-columns: minmax(1fr, max-content) min-content minmax(1fr, max-content) min-content minmax(1fr, max-content);

    span {
      margin: 0 3.75rem;
    }

    > :first-child {
      justify-self: flex-end;
    }

    > :last-child {
      justify-self: flex-start;
    }
  }

  ${BREAKPOINT_LG} {
    margin-bottom: 7.75rem;
  }
`;

const MetaDataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1;
  text-align: center;
  word-break: break-word;

  ${BREAKPOINT_MD} {
    font-size: 1.125rem;
  }

  b {
    color: var(--color-primary);

    ${BREAKPOINT_MD} {
      font-size: 1.5rem;
    }
  }
`;

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2rem;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  ${BREAKPOINT_MD} {
    grid-column: 1 / 7;
    margin-bottom: 7.5rem;
  }
`;

const DescriptionSubHeadline = styled.p`
  ${Body2};
  margin-bottom: 0.25rem;
`;

const DescriptionHeadline = styled.h3`
  font-size: 2rem;
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1.5rem;
  }

  ${BREAKPOINT_LG} {
    font-size: 3rem;
  }
`;

const BuyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  align-items: center;
`;

const BuyHeadline = styled.h3`
  font-size: 2rem;
  margin-bottom: 0.25rem;

  ${BREAKPOINT_LG} {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
`;

const Catchphrase = styled.p`
  ${Body2};
  margin-bottom: 2.5rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
`;

const StyledText = styled(Text)`
  ${BREAKPOINT_MD} {
    font-size: 1rem;
  }
`;

const ColletibleNr = styled.span`
  font-size: 1rem;
`;

const RelatedAlbumsSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  grid-column: 1 / -1;
`;

const CONTENT_SECTIONS: { [type: string]: FC<IAlbumContentItem & any> } = {
  'text-image-section': TextImageSection,
  'video-section': VideoSection,
  'faq-custom-section': FaqSectionCustom,
};

export const CollectibleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getLocalizedString = useGetLocalizedString();

  const apiInitialized = useIsAPIInitialized();
  const activeLocale = useActiveLocale();
  const collectiblesAPI = useCollectiblesAPI();
  const { toggleFavorite, favoritesIds } = useFavoriteCollectibles();

  const [collectible, setCollectible] = useState<ICollectible | null>(null);
  const [loading, setLoading] = useState(false);

  const [mintLoadingText, setMintLoadingText] = useState('');

  const [visibleReadMore, setVisibleReadMore] = useState(true);
  const stageRef = useRef(null);
  const [trackingState] = useTrackingState();

  const isFavorite = favoritesIds?.collectibles.includes(collectible?.id || '') || false;
  const description = collectible?.subCollectibles.find((item) => item.type === 'base')?.description || '';

  const tags = useMemo(() => {
    return (collectible?.tags[0]?.split(', ') || []).filter(Boolean);
  }, [collectible]);

  useEffect(() => {
    if (apiInitialized) {
      if (id) {
        setLoading(true);
        collectiblesAPI
          .collectible(id)
          .then((response) => {
            if (response.data?.collectible.id) setCollectible(response.data?.collectible || null);
            else navigate('/404');
          })
          .catch((error) => {
            setCollectible(null);
            console.error('fetch collectible error:', id, error?.response?.status, error?.response?.data);

            // navigate to 404 page
            navigate('/404', { replace: true });
          })
          .finally(() => setLoading(false));
      } else {
        // navigate to 404 page
        navigate('/404', { replace: true });
      }
    }
  }, [id, activeLocale, apiInitialized]);

  const scrollDown = () => {
    const screenHeight = document.documentElement.clientHeight;
    window.scrollBy({ top: screenHeight, behavior: 'smooth' });
  };

  const getRarityString = () => {
    switch (collectible?.rarity) {
      case 1:
        return getLocalizedString('app.v2.collectible.rarity.1');
      case 2:
        return getLocalizedString('app.v2.collectible.rarity.2');
      case 3:
        return getLocalizedString('app.v2.collectible.rarity.3');
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };

    const toggleReadMore = (e: any) => {
      setVisibleReadMore(e[0].isIntersecting);
    };

    const observer = new IntersectionObserver(toggleReadMore, observerOptions);

    if (stageRef.current) observer.observe(stageRef.current);

    return () => {
      if (stageRef.current) observer.unobserve(stageRef.current);
    };
  }, [stageRef.current]);

  const handleOpenStage = () => {
    if (trackingState?.trackEvent && collectible) {
      trackingState?.trackEvent('click_3d_stage', { product: collectible?.id });
    }
    navigate(`/collectibles/3d-stage/${collectible?.slug}`);
  };

  return (
    <PageView>
      {collectible && (
        <CollectiblesStickyPurchase
          show3DButton={!!collectible.rawImage3dUrl}
          toggleOverlay={handleOpenStage}
          collectible={collectible}
          activeLocale={activeLocale || ''}
          setLoadingCallback={setLoading}
          setMintLoadingText={setMintLoadingText}
        />
      )}

      {collectible && !loading ? (
        <>
          <CollectibleStage ref={stageRef}>
            <RarityBackground rarity={collectible.rarity}>
              <StyledMarquee rarity={collectible.rarity} />
            </RarityBackground>
            <BackgroundGradient />

            <CollectibleDetailNavigation
              toggleOverlay={handleOpenStage}
              backAction={() => {
                navigate('/collectibles');
              }}
              isFavorite={isFavorite}
              toggleFavorite={() => toggleFavorite(isFavorite, collectible.id)}
              collectible={collectible}
            />

            <ImageWrapper>
              <CollectibleImage collectible={collectible} />
            </ImageWrapper>

            <Title>{collectible.title}</Title>

            <InfoWrapper>
              <AuthorWrapper>
                <CreatorImageWrapper>
                  <img src={collectible.publisherLogoUrl} alt={collectible.publisherName} />
                </CreatorImageWrapper>
                <CreatorNameWrapper>{collectible.publisherName}</CreatorNameWrapper>
              </AuthorWrapper>
              <BuyButton
                collectible={collectible}
                activeLocale={activeLocale || ''}
                setLoadingCallback={setLoading}
                setMintLoadingText={setMintLoadingText}
              />
            </InfoWrapper>

            {(collectible?.ownedCollectiblesCount || 0) > 0 && (
              <AlreadyOwnedWrapper>
                <p>
                  {getLocalizedString('app.v2.general.already-bought')}{' '}
                  {collectible.ownedCollectiblesCount > 1 && `(x${collectible.ownedCollectiblesCount})`}
                </p>
                <OwnedMark />
              </AlreadyOwnedWrapper>
            )}

            {!!tags.length && (
              <TagWrapper>
                <span>{getLocalizedString('app.v2.general.incl')}</span>
                {tags.map((tag, index) => (
                  <Tag key={index}>{tag}</Tag>
                ))}
              </TagWrapper>
            )}

            <ReadMoreButton onClick={scrollDown} visible={visibleReadMore}>
              {getLocalizedString('app.v2.general.read-more')}
              <ChevronIcon />
            </ReadMoreButton>
          </CollectibleStage>

          <CollectibleContent>
            <MetaDataWrapper>
              <MetaDataItem>
                {getLocalizedString('app.v2.collectible.details.category-label')}
                <b>{getLocalizedString(collectible.category || '') || collectible.category}</b>
              </MetaDataItem>
              <span />
              <MetaDataItem>
                {getLocalizedString('app.v2.collectible.details.rarity-label')}
                <b>{getRarityString()}</b>
              </MetaDataItem>
              <span />
              <MetaDataItem>
                {getLocalizedString('app.v2.collectible.details.class-label')}
                <b>{getLocalizedString(collectible.marketingType || '') || collectible.marketingType}</b>
              </MetaDataItem>
            </MetaDataWrapper>

            {!(collectible.content && collectible?.content?.length > 0) && (
              <GridWrapper>
                <DescriptionWrapper>
                  <DescriptionSubHeadline>{collectible.title}</DescriptionSubHeadline>
                  <DescriptionHeadline>{getLocalizedString('app.v2.collectible.details.story-headline')}</DescriptionHeadline>
                  <StyledText content={description} />
                </DescriptionWrapper>
              </GridWrapper>
            )}

            {collectible.content &&
              collectible.content?.map((section, index) => {
                const Section = CONTENT_SECTIONS[section.type];
                if (Section) return <Section key={index} {...section} />;
                else return <></>;
              })}
            <GridWrapper>
              {collectible.albums.length > 0 && (
                <RelatedAlbumsSectionWrapper>
                  <RelatedAlbumsSection albums={collectible.albums} />
                </RelatedAlbumsSectionWrapper>
              )}
            </GridWrapper>
            {collectible.isExclusive ? (
              <Price highlight={true}>{getLocalizedString('app.v2.general.is-exclusive')}</Price>
            ) : (
              collectible.price !== 0 && (
                <BuyWrapper>
                  <BuyHeadline>{getLocalizedString('app.v2.collectible.details.buy.headline')}</BuyHeadline>
                  <Catchphrase>{getLocalizedString('app.v2.collectible.details.buy.catchphrase')}</Catchphrase>
                  <BuyButton
                    collectible={collectible}
                    activeLocale={activeLocale || ''}
                    setLoadingCallback={setLoading}
                    setMintLoadingText={setMintLoadingText}
                  />
                </BuyWrapper>
              )
            )}
          </CollectibleContent>
        </>
      ) : (
        <LoadingOverlay visible text={mintLoadingText} />
      )}
    </PageView>
  );
};
