import styled, { css } from 'styled-components';
import { FC, InputHTMLAttributes, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { Body3Bold } from '../../../styles/FontStyles';

const Wrapper = styled.div`
  display: grid;
  gap: .125rem
`;

const FormItemWrapper = styled.label<{ error: boolean, disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: .75rem;
  cursor: pointer;

  ${({ error }) => error && css`
    color: var(--color-signal-red);
  `}

  ${({ disabled }) => disabled && css`
    color: var(--color-grey-700);
    pointer-events: none;
  `}
`;

const RadioInputBox = styled.input.attrs({ type: 'radio' })<{ error: boolean }>`
  position: relative;
  height: 1.125rem;
  width: 1.125rem;
  flex-shrink: 0;
  --border-color: ${({ error }) => error ? 'var(--color-signal-red)' : 'var(--color-text-default)'};
  border: 1px solid var(--border-color);
  border-radius: 50%;
  appearance: none;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :disabled {
    --border-color: var(--color-grey-700);
  }

  :after {
    content: '';
    position: absolute;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: var(--border-color);

    opacity: 0;
    transform: scale(.75);
    transition: opacity 25ms ease-out, transform 250ms ease-out;
  }

  :checked {
    :after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const Label = styled.p`
  ${Body3Bold};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
`;

interface IRadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name: string;
  value: string;
  label: string | ReactNode;
  required?: boolean;
  defaultChecked?: boolean;
  error?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export const RadioInput: FC<IRadioInputProps> = (props) => {
  const { id, label, name, value, error, disabled = false, required = false, className, onClick, defaultChecked = false } = props;
  const { register } = useFormContext();

  return (
    <Wrapper className={className}>
      <FormItemWrapper error={!!error} disabled={disabled}>
        <RadioInputBox
          defaultChecked={defaultChecked}
          id={id}
          value={value}
          error={!!error}
          disabled={disabled}
          required={required}
          onClick={onClick}
          {...register(name, { required })} 
        />
        <Label>{label}</Label>
      </FormItemWrapper>
      {error && error}
    </Wrapper>
  );
};