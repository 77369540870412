import { useAPIInstance } from './index';

export interface IPublishersList {
  publishers: IPublishersListItem[],
  totalRecords: number;
}

export interface IPublishersListItem {
  id: string;
  name: string;
  slug: string;
  type: string;
  coverImageUrl: string;
  logoUrl: string;
  title: string;
  category: string;
  releases: number;
}

export interface IPublisherContentItem {
  type: string;
  order: number;
}

export interface IPublisher {
  id: string;
  slug: string;
  name: string;
  title: string;
  coverImageUrl: string;
  logoUrl: string;
  website: string;
  description: string;
  totalReleases: number;
  tags: string[];
  translations: [ITranslation]
  content: IPublisherContentItem[];
}

interface IPublisherResponse {
  success: true,
  value: IPublisher
}

interface ITranslation {
  language: string;
  description: string;

}

export const usePublisherAPI = () => {
  const apiInstance = useAPIInstance();

  const allPublishers = (offset: number, limit: number) => {
    return apiInstance.get<IPublishersList>(`/publishers?offset=${offset}&limit=${limit}`);
  };

  const publishersById = (id: string) => {
    return apiInstance.get<IPublisherResponse>(`/publishers/${id}`);
  };

  return {
    allPublishers,
    publishersById,
  };
};
