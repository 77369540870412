import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { TertiaryButton } from '../../../styles/Buttons';
import downloadCanvas from '../../../utils/downloadCanvas';
import printCanvas from '../../../utils/printCanvas';
import Text from '../general/Text';
import QRCode from './QRCode';
import SeedPhrase from './SeedPhrase';
import CopyIcon from '../../icons/CopyIcon';
import PrinterIcon from '../../icons/PrinterIcon';
import QRCodeIcon from '../../icons/QRCode';
import { Notification } from '../general/Notification';
import { useGetLocalizedString } from '../../../services/localization';
import { Wallet } from 'ethers';
import { Body2 } from '../../../styles/FontStyles';
import WalletDescriptionModal from './WalletDescriptionModal';
import { useRecoilValue } from 'recoil';
import { UserState } from '../../../state/user';

const Headline = styled.h2`
  ${Body2};
  font-weight: 700;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
`;

const ButtonWrapper = styled.div`
  margin-top: .5rem;
  display: grid;
  gap: .5rem;
`;

export const Button = styled(TertiaryButton)<{ highlight?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  line-height: 1;
  gap: 1rem;

  ${BREAKPOINT_MD} {
    padding: 0 2.75rem;
  }

  ${({ highlight }) => highlight && `
    color: var(--color-grey-900);
    background: #FFA800;
  `}
`;

const HighlightText = styled.p`
  color: #FFA800
`;

export const Divider = styled.span`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: center;
  font-size: .875rem;
  line-height: 1.25rem;

  &::after,
  &::before {
    content: '';
    display: block;
    height: 1px;
    flex: 1;
    background: #fff;
    max-width: 20%;
  }
`;

const StyledCopyIcon = styled(CopyIcon)`
  font-size: 1.0625rem;
`;

const StyledPrinterIcon = styled(PrinterIcon)`
  font-size: 1.125rem;
`;

const ErrorNotification = styled(Notification).attrs({ type: 'error' })`
  //
`;

const AlertNotification = styled(Notification).attrs({ type: 'alert' })`
  margin-bottom: .5rem;
`;

const InfoNotification = styled(Notification).attrs({ type: 'info' })`
  margin-bottom: .5rem;
`;

interface IWalletBackupErrorState { 
  headline: string; 
  text: string; 
}

interface IWalletBackupChildrenProps {
  saved: boolean;
}

interface IWalletBackupProps {
  wallet?: Wallet;
  children: (props: IWalletBackupChildrenProps) => ReactNode;
  errorState?: IWalletBackupErrorState | null;
  setErrorState?: React.Dispatch<React.SetStateAction<IWalletBackupErrorState | null>>;
  showCopyPrivateKey?: boolean;
}

const WalletBackup: FC<IWalletBackupProps> = ({
  wallet,
  children,
  errorState,
  setErrorState,
  showCopyPrivateKey = true,
}) => {
  const getLocalizedString = useGetLocalizedString();
  const userData = useRecoilValue(UserState);
  
  const [saved, setSaved] = useState<boolean>(false);
  const [seedPhraseVisible, setSeedPhraseVisible] = useState<boolean>(false);
  const [alertState, setAlertState] = useState<{ headline: string, text: string } | null>(null);
  const qrCode = useRef<HTMLCanvasElement>(null);
  const [showCopiedSeedPhraseToClipboardHint, setShowCopiedSeedPhraseToClipboardHint] = useState<{ headline: string, text: string } | null>(null);
  const [showCopiedPrivateKeyToClipboardHint, setShowCopiedPrivateKeyToClipboardHint] = useState<{ headline: string, text: string } | null>(null);

  const seedPhrase = useMemo(() => {
    return wallet?.mnemonic?.phrase;
  }, [wallet]);

  const qrContent = useMemo(() => {
    if (seedPhrase || wallet?.privateKey) {
      return seedPhrase || JSON.stringify({ myfsqr: wallet?.privateKey, userEmail: userData?.email });
    }
  }, [seedPhrase, userData, wallet])

  const onShowSeedPhrase = () => {
    setSaved(true);
    setSeedPhraseVisible(true);
  };

  const copy = () => {
    setSaved(true);
    wallet && navigator.clipboard.writeText(wallet?.mnemonic.phrase);

    setShowCopiedSeedPhraseToClipboardHint({
      headline: getLocalizedString('app.v2.create-wallet.notification.copied-seedphrase.title'),
      text: getLocalizedString('app.v2.create-wallet.notification.copied-seedphrase.text'),
    });
    setTimeout(() => setShowCopiedSeedPhraseToClipboardHint(null), 5000);
  };

  const copyPrivateKey = () => {
    setSaved(true);
    wallet && navigator.clipboard.writeText(wallet?.privateKey);

    setShowCopiedPrivateKeyToClipboardHint({
      headline: getLocalizedString('app.v2.create-wallet.notification.copied-private-key.title'),
      text: getLocalizedString('app.v2.create-wallet.notification.copied-private-key.text'),
    });
    setTimeout(() => setShowCopiedPrivateKeyToClipboardHint(null), 5000);
  };

  const download = () => {
    setSaved(true);
    qrCode.current && downloadCanvas(qrCode.current, 'wallet');
  };

  const print = () => {
    setSaved(true);
    qrCode.current && printCanvas(qrCode.current);
  };

  useEffect(() => {
    if (!seedPhrase) {
      setAlertState({
        headline: getLocalizedString('app.v2.wallet-backup.old-wallet.headline'),
        text: getLocalizedString('app.v2.wallet-backup.old-wallet.text'),
      })
    } else {
      setAlertState(null);
    }
  }, [seedPhrase])

  return (
    <Wrapper>
      <Headline>{getLocalizedString('app.v2.create-wallet.headline.v2')}</Headline>
      <Text
        content={getLocalizedString('app.v2.create-wallet.intro-text')}
      />

      <WalletDescriptionModal />

      { alertState && (
        <AlertNotification {...alertState} onClose={() => setAlertState(null)} />
      )}

      {errorState && setErrorState && (
        <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />
      )}

      {seedPhrase && (
        <>
          <SeedPhrase seedPhrase={seedPhrase} onShow={onShowSeedPhrase} />
          <Text
            content={getLocalizedString('app.v2.create-wallet.security-text')}
          />
        </>
      )}

      {/* TODO If wallet has private key */}
      {qrContent && (
        <QRCode ref={qrCode} content={qrContent} />
      )}
          
      <ButtonWrapper>
        {seedPhrase && (
          <>
            {showCopiedSeedPhraseToClipboardHint && (
              <InfoNotification {...showCopiedSeedPhraseToClipboardHint} />
            )}

            <Button onClick={copy}>
              <StyledCopyIcon />
              {getLocalizedString('app.v2.create-wallet.copy-seedphrase')}
            </Button>

            <Divider>{getLocalizedString('app.v2.general.or')}</Divider>
          </>
        )}

        {seedPhraseVisible && (
          <HighlightText>{getLocalizedString('app.v2.create-wallet.recommendation-seedphrase')}</HighlightText>
        )}

        <Button onClick={download} highlight={seedPhraseVisible}>
          <QRCodeIcon />
          {getLocalizedString('app.v2.create-wallet.save-qr-code')}
        </Button>

        <Divider>{getLocalizedString('app.v2.general.or')}</Divider>

        <Button onClick={print}>
          <StyledPrinterIcon />
          {getLocalizedString('app.v2.create-wallet.print-qr-code')}
        </Button>

        {
          showCopyPrivateKey &&
          <>
            <Divider>{getLocalizedString('app.v2.wallet-backup.copy-private-key-separator')}</Divider>
            {
              showCopiedPrivateKeyToClipboardHint &&
              <InfoNotification {...showCopiedPrivateKeyToClipboardHint} />
            }
            <Button onClick={copyPrivateKey}>{getLocalizedString('app.v2.create-wallet.copy-private-key')}</Button>
          </>
        }
      </ButtonWrapper>

      {children({saved})}

    </Wrapper>
  );
};

export default WalletBackup;
