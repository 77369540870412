import { useAPIInstance } from './index';

interface IAPIResponse {
  success: boolean;
}

export const useNewsletterAPI = () => {
  const apiInstance = useAPIInstance();

  const subscribe = (email: string) => {
    return apiInstance.post<IAPIResponse>(`/newsletter`, { email });
  };

  return {
    subscribe,
  };
};
