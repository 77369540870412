import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useScrollPage = (scrollAnchor: number | MutableRefObject<HTMLDivElement | null> = 0) => {
  const scrollUp = useCallback(() => {
    if (timeoutHandleRef.current) {
      clearTimeout(timeoutHandleRef.current);
    }
    timeoutHandleRef.current = setTimeout(() => {
      if (typeof scrollAnchor === 'number') {
        window.scrollTo({ top: scrollAnchor, left: 0, behavior: 'smooth' });
      } else {
        scrollAnchor.current?.scrollIntoView({ behavior: 'smooth' });
      }
      scrollToErrorWhenKeyboardClosesRef.current = false;
      timeoutHandleRef.current = null;
    }, 50);
  }, [scrollAnchor]);

  const isKeyboardOpen = useCallback(() => {
    const MIN_KEYBOARD_HEIGHT = 300; // N.B.! this might not always be correct
    const isMobile = window.innerWidth < 768;
    return isMobile && window.screen.height - MIN_KEYBOARD_HEIGHT > (window.visualViewport?.height || 0);
  }, []);

  const scrollToErrorWhenKeyboardClosesRef = useRef(false);
  const timeoutHandleRef = useRef<any>(null);

  // fix keyboard close animation / scroll bug
  useEffect(() => {
    const listener = () => {
      if (scrollToErrorWhenKeyboardClosesRef.current && !isKeyboardOpen()) {
        scrollUp();
      }
    };

    window.visualViewport?.addEventListener('resize', listener);

    return () => window.visualViewport?.removeEventListener('resize', listener);
  }, [scrollUp, isKeyboardOpen]);

  return useCallback(() => {
    if (isKeyboardOpen()) {
      scrollToErrorWhenKeyboardClosesRef.current = true;
    } else {
      scrollUp();
    }
  }, [isKeyboardOpen, scrollUp]);
};