import styled from 'styled-components';
import { Accordion } from '../general/Accordion';
import { FC, useState } from 'react';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';

const AccordionSet = styled.div`
`;

export const FaqSection: FC<void> = () => {

  const toggleAccordion = (index: number) => {
    setOpenItem(index === openItem ? -1 : index);
  };

  const [openItem, setOpenItem] = useState<number>(-1);
  const getLocalizedString = useGetLocalizedString();

  return(
    <AccordionSet>
      <h1>FAQ</h1>
      <br />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q1')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a1')}
        key='1'
        open={1 === openItem}
        onClick={() => toggleAccordion(1)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q2')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a2')}
        key='2'
        open={2 === openItem}
        onClick={() => toggleAccordion(2)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q3')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a3')}
        key='3'
        open={3 === openItem}
        onClick={() => toggleAccordion(3)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q4')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a4')}
        key='4'
        open={4 === openItem}
        onClick={() => toggleAccordion(4)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q5')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a5')}
        key='5'
        open={5 === openItem}
        onClick={() => toggleAccordion(5)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q6')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a6')}
        key='6'
        open={6 === openItem}
        onClick={() => toggleAccordion(6)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q7')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a7')}
        key='7'
        open={7 === openItem}
        onClick={() => toggleAccordion(7)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q8')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a8')}
        key='8'
        open={8 === openItem}
        onClick={() => toggleAccordion(8)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q9')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a9')}
        key='9'
        open={9 === openItem}
        onClick={() => toggleAccordion(9)}
      />
      <Accordion
        head={getLocalizedString('app.v2.forevergalaxy.faq.q10')}
        body={getLocalizedString('app.v2.forevergalaxy.faq.a10')}
        key='10'
        open={10 === openItem}
        onClick={() => toggleAccordion(10)}
      />
    </AccordionSet>
  );
}