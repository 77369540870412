import styled from 'styled-components';
import { FC } from 'react';
import { IPublishersListItem } from '../../services/publisher';
import AspectRatio from './general/AspectRatio';
import { Body2 } from '../../styles/FontStyles';
import { useGetLocalizedString } from '../../services/localization';
import { BREAKPOINT_MD } from '../../styles/Breakpoints';
import { LinkHandler } from './general/LinkHandler';

const Wrapper = styled(LinkHandler)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  padding: .75rem;
  border: .125rem solid var(--color-primary);

  :hover {
    text-decoration: none;
  }
`;

const ImageWrapper = styled(AspectRatio)`
  display: flex;
  justify-content: center;
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: visible;
`;

const Img = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LogoWrapper = styled(LinkHandler)`
  position: absolute;
  bottom: -1rem;
  margin: 0 auto;
  height: 6rem;
  width: 6rem;
  
  img {
    object-fit: contain;
    filter: drop-shadow(0px 4px 30px var(--color-primary-dark));
  }
  
  ${BREAKPOINT_MD} {
    bottom: -1.25rem;
    height: min(7.5rem, 35%);
    width: min(7.5rem, 35%);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.75rem;
`;

const Title = styled.h2`
  ${Body2};
  text-transform: none;
  width: 70%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Releases = styled.div`
  width: 30%;
  text-align: right;
  font-size: .875rem;
  color: var(--color-grey-600);
`;


export const PublisherItem: FC<IPublishersListItem> = (props) => {
  const {
    id,
    slug,
    type,
    coverImageUrl,
    logoUrl,
    title,
    category,
    releases,
  } = props

  const getLocalizedString = useGetLocalizedString();

  return (
    <Wrapper to={`/collectibles/creators/${slug}`}>
      <ImageWrapper>
        <Img src={coverImageUrl ? coverImageUrl : 'images/UserImageFallback.png'} alt={title} />
        <LogoWrapper to={`/collectibles/creators/${slug}`}>
          <Img src={logoUrl ? logoUrl : 'images/UserImageFallback.png'} alt={title} />
        </LogoWrapper>
      </ImageWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Releases>&#127881; {releases} {getLocalizedString('app.v2.publisher.releases')}</Releases>
      </ContentWrapper>
    </Wrapper>
  );
};
