import styled from 'styled-components';
import { FC, useEffect, useRef } from 'react';
import { Body3 } from '../../../../styles/FontStyles';
import { BasicGridWrapper } from '../../general/Grids';
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../../../styles/Breakpoints';

const Wrapper = styled(BasicGridWrapper).attrs({ as: 'section' })`
  --translate-x: 0vw;
  transition: none;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  align-items: center;

  ${BREAKPOINT_SM} {
    grid-column: 2 / -2;
  }

  ${BREAKPOINT_MD} {
    grid-column: 3 / 8;
  }

  transform: translateX(var(--translate-x));
  transition: inherit;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: flex-end;
`;

const Title = styled.p`
  ${Body3};
  color: var(--color-primary);
  font-weight: bold;
  width: max-content;
`;

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  max-height: 60vh;
  width: max-content;
`;

interface ImageSectionProps {
  type: string;
  title: string;
  image: string;
}

export const ImageSection: FC<ImageSectionProps> = (props) => {
  const { title, image } = props;
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const sectionHeight = sectionRef.current.scrollHeight;

        const useCSSTransition = true;

        const sectionCenter = top + ((bottom - top) / 2);
        const scrollDelta = Math.max(0, Math.min(2, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2)))) / 2;

        let translateX = 0;

        if (bottom < sectionHeight + screenHeight && bottom > 0) {
          const progress = Math.min(Math.max((screenHeight + sectionHeight - bottom) / ((screenHeight + sectionHeight) / 100), 0), 100);
          if (progress < 40) {
            translateX = -50 + progress * 1.25;
          } else if (progress > 40 && progress < 60) {
            translateX = 0;
          } else if (progress > 60) {
            translateX = (progress - 60) * 1.25;
          }
        }

        if (useCSSTransition) {
          if (scrollDelta < .15) {
            translateX = -50;
          } else if (scrollDelta > .75) {
            translateX = 50;
          } else {
            translateX = 0;
          }
        }

        sectionRef.current.style.transition = useCSSTransition ? 'transform .5s ease-out' : 'none';
        sectionRef.current.style.setProperty('--translate-x', `${translateX}vw`);
      }
    };

    window.addEventListener('scroll', scrollHandler);
    scrollHandler();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <Wrapper ref={sectionRef}>
      <Item>
        <ContentWrapper>
          <Title>{title}</Title>
          <Image src={image} alt={title} />
        </ContentWrapper>
      </Item>
    </Wrapper>
  );
};