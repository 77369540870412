import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { CheckIcon } from '../../icons/CheckIcon';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 4.5rem;
  
  ${BREAKPOINT_SM} {
    gap: 7.75rem;
  }

  ${BREAKPOINT_MD} {
    gap: 16.5rem;
  }
`;

const AnimatedProgress = styled.span<{ progress: number }>`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background: var(--color-grey-800);
  overflow: hidden;

  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: ${({ progress }) => progress}%;
    height: 2px;
    background: linear-gradient(90deg, var(--color-primary) 75%, transparent 100%);
    transition: width .5s ease-out;
  }
`;

const WizardItem = styled.div<{ active: boolean }>`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), linear-gradient(152.97deg, rgba(255, 255, 255, 0.235) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(4px);
  position: relative;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    box-shadow: inset 0 0 6px #08E8DE;
    opacity: ${({ active }) => active ? '1' : '.2'};
    filter: grayscale(${({ active }) => active ? '0' : '1'});
    transition: opacity .5s ease-out .25s;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    opacity: ${({ active }) => active ? '1' : '.2'};
    transition: opacity .5s ease-out .25s;
  }
`;

interface WizardProps {
  className?: string;
  children: ReactNode[];
  active: number;
}

export const Wizard: FC<WizardProps> = (props) => {
  const { active, className, children } = props;

  return (
    <Wrapper className={className}>
      <ItemWrapper>
        {
          children.map((child, index) => (
            <WizardItem key={index} active={index <= active}>
              {
                index < active
                  ? <CheckIcon />
                  : child
              }
            </WizardItem>
          ))
        }
        <AnimatedProgress progress={100 / (children.length - 1) * (active + .5)} />
      </ItemWrapper>
    </Wrapper>
  );
};