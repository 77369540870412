import axios, { AxiosInstance } from 'axios';
import React, { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useAuthState, useAuthToken } from '../state/auth';
import { ILoginSession, refreshAuthToken } from './auth';
import { useActiveLocale } from './localization';

interface IAPIContextProps {
  apiInstance: AxiosInstance;
  apiInterceptorsReady: boolean;
}

const APIContext = React.createContext<IAPIContextProps>({} as any);

export const useAPIInstance = () => useContext(APIContext).apiInstance;
export const useIsAPIInitialized = () => {
  return useContext(APIContext).apiInterceptorsReady;
}
export const useIsAuthenticated = () => {
  const interceptorsReady = useIsAPIInitialized();
  const authTokenAvailable = !!useAuthToken();
  return interceptorsReady && authTokenAvailable;
}

export const APIProvider: FC<PropsWithChildren> = ({ children }) => {
  const [authState, setAuthState] = useAuthState();
  const {
    token,
    refreshToken = '',
    userId = '',
  } = authState || {};

  const activeLocale = useActiveLocale();

  const authToken = useRef(token);
  const locale = useRef(activeLocale);

  const [context, setContext] = useState<IAPIContextProps>({
    apiInstance: axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        'x-api-key': process.env.REACT_APP_X_API_KEY || '',
        'Accept-Language': locale.current || 'en',
      },
    }),
    apiInterceptorsReady: false,
  });

  useEffect(() => {
    // add auth interceptor
    context.apiInstance.interceptors.request.use((config) => {
      // add localization config
      config.headers = {
        ...(config.headers || {}),
        'Accept-Language': locale.current || 'en',
      };

      // add auth token if available
      if (authToken.current) {
        config.headers = {
          ...(config.headers || {}),
          'Authorization': `Bearer ${authToken.current}`,
        };
      }
      return config;
    }, undefined);

    // add response interceptor (auth token refresh)
    context.apiInstance.interceptors.response.use(undefined, (error) => {
      if (
        // check if token has expired
        error?.response?.status === 401 && authToken.current &&
        // check url to prevent endless loop
        error?.config?.url &&
        ![
          '/auth/token',
          '/auth/login',
          '/auth/register',
        ].includes(error.config.url)
      ) {
        return refreshAuthToken(context.apiInstance, { userId }, refreshToken)
          .then((res) => {
            if (res.data?.success) {
              authToken.current = res.data.token;   // set new auth token
              setAuthState((state) => ({
                ...state,
                token: authToken.current,
              }) as ILoginSession);

              // retry request with new auth token
              return axios({
                ...(error.config || {}),
                headers: {
                  ...(error.config?.headers || {}),
                  'Authorization': `Bearer ${authToken.current}`,
                },
              });
            }
            return Promise.reject(error);
          })
          .catch((err) => {
            console.error('could not refresh auth token -> logging out');
            setAuthState(null);
            return Promise.reject(error);
          });
      }
      return Promise.reject(error);
    });
    setContext({
      ...context,
      apiInterceptorsReady: true,
    });
  }, []);    // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    authToken.current = token;
  }, [token]);    // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    locale.current = activeLocale;
  }, [activeLocale]);

  return (
    <APIContext.Provider value={context}>
      {children}
    </APIContext.Provider>
  );
};