import styled, { css } from 'styled-components';
import { FC } from 'react';
import { ICollectible, IUserCollectible } from '../../../services/collectible';
import { FavoriteIcon } from '../../icons/FavoriteIcon';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS } from '../../../styles/Breakpoints';
import { LinkHandler } from '../general/LinkHandler';
import { Body2, Body3 } from '../../../styles/FontStyles';
import { Marquee } from '../general/Marquee';
import { useGetLocalizedString } from '../../../services/localization';
import { Colors } from '../../../styles/Colors';
import { useFavoriteCollectibles } from '../../../hooks/useFavoriteCollectibles';
import Tags from '../general/Tags';
import Sticker from './Sticker';
import { CollectibleImage } from '../collectibles/CollectibleImage';

const Wrapper = styled.div`
  --tile-size: 6.25rem;

  display: grid;
  grid-template-rows: 1.75rem .75rem calc(var(--tile-size) - .75rem);
  grid-template-columns: var(--tile-size) 1fr;
  gap: 0 .5rem;
  z-index: 0;

  &:hover {
    text-decoration: none;
  }

  ${BREAKPOINT_XS} {
    grid-template-rows: 2.5rem .75rem calc(var(--tile-size) - .75rem);
  }

  ${BREAKPOINT_SM} {
    --tile-size: 9rem;
  }
`;

const Favorite = styled(FavoriteIcon)`
  --size: 1.25rem;
  justify-self: end;
  color: ${({ active }) => active ? Colors.primary : Colors.white};

  ${BREAKPOINT_LG} {
    --size: 1.5rem
  }

  &:hover {
    cursor: pointer;
  }
`;

const Background = styled.div`
  grid-area: 2 / 1 / 4 / 2;
  height: auto;
  width: auto;
  overflow: hidden;
  background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(24, 95, 91, 0.6) 0%, rgba(110, 204, 244, 0.134375) 77.08%, rgba(70, 213, 204, 0) 100%);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(20px);
  border-radius: 2px;
  position: relative;

  &:before {
    z-index: 0;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url('/images/noise.png');
    background-repeat: repeat;
    background-size: 60%;
    opacity: .08;
  }

  ${BREAKPOINT_MD} {
    grid-area: 2 / 1 / 4 / -1;
  }
`;

const CollectibleImageLink = styled(LinkHandler)`
  grid-area: 1 / 1 / -1 / 2;
  z-index: 2;
  position: relative;
  width: var(--tile-size);
  height: var(--tile-size);

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledMarquee = styled(Marquee)`
  grid-area: 2 / 1 / 3 / 2;
  z-index: 1;
  height: .825rem;
  font-size: .75rem;
  line-height: 1;

  ${BREAKPOINT_MD} {
    grid-area: 2 / 1 / 3 / -1;
  }
`;

const Inner = styled.div`
  grid-area: 3 / 2 / 4 / 3;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: .25rem .75rem;
  z-index: 1;

  ${BREAKPOINT_SM} {
    padding: .25rem 0;
  }

  ${BREAKPOINT_MD} {
    grid-area: 3 / 2 / 4 / 3;
    padding: .75rem;
  }
`;

const CreatorWrapper = styled(LinkHandler)`
  align-self: center;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  color: var(--color-grey-600);

  ${BREAKPOINT_MD} {
    gap: .75rem;
  }
`;

const CreatorIcon = styled.img`
  --size: 1rem;
  height: var(--size);
  width: var(--size);
  object-fit: contain;

  ${BREAKPOINT_MD} {
    --size: 1.25rem;
  }
`;

const CreatorName = styled.span`
  ${Body2};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const Label = styled.div`
  min-height: 2.275rem;

  ${BREAKPOINT_LG} {
    margin-top: 2px;
    min-height: 3rem;
  }
`;

const Title = styled.p`
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  ${BREAKPOINT_LG} {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const StyledSticker = styled(Sticker)`
  grid-area: 3 / 1 / -1 / 2;
  z-index: 2;
  align-self: end;
  justify-self: start;
  margin: .125rem;
`;

const Price = styled.span<{ highlight: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  align-self: end;
  
  ${({ highlight = false }) => highlight && css`
    color: ${Colors.primary};
  `};
`;

const StyledTags = styled(Tags)`
  justify-content: start;
  grid-column: 1 / 3;
`;

interface ICollectibleItemTeaserProps extends IUserCollectible {
  active?: boolean;
}

const CollectibleItemTeaser: FC<ICollectibleItemTeaserProps> = (props) => {
  const {
    status,
    id,
    slug,
    price,
    publisherId,
    publisherLogoUrl,
    publisherName,
    publisherSlug,
    title,
    rarity,
    active,
    tags,
    isFreemint,
    isExclusive,
    ...stickerProps
  } = props;
  const getLocalizedString = useGetLocalizedString();
  const { toggleFavorite } = useFavoriteCollectibles();

  return (
    <Wrapper>
      <CollectibleImageLink to={`/collectibles/${slug}`}>
        <CollectibleImage collectible={props as never as ICollectible} />
      </CollectibleImageLink>
      <StyledSticker {...{ ...stickerProps, status }} />
      <Background />
      {rarity > 1 && (
        <StyledMarquee rarity={rarity} />
      )}
      <Inner>
        <Label>
          <LinkHandler to={`/collectibles/${slug}`}>
            <Title>{title}</Title>
          </LinkHandler>
        </Label>
        <Favorite active={active || false} onClick={() => {
          toggleFavorite(active || false, id);
        }} />
        {tags && <StyledTags tags={tags} />}
        <CreatorWrapper to={`/collectibles/creators/${publisherSlug}`}>
          <Creator>
            <CreatorIcon src={publisherLogoUrl} alt={publisherName} />
            <CreatorName>{publisherName}</CreatorName>
          </Creator>
        </CreatorWrapper>
        <Price highlight={price === 0}>
          {!isFreemint &&
            <Price highlight={price === 0}>
              {isExclusive ? (
                getLocalizedString('app.v2.general.is-exclusive')
              ) : price === 0 ? (
                getLocalizedString('app.v2.general.for-free')
              ) : (
                <>{price}€</>
              )}
            </Price>
          }
          {isFreemint &&
            <Price highlight={true}>
                  {getLocalizedString('app.v2.general.freemint-price')}
            </Price>
          }
        </Price>
      </Inner>
    </Wrapper>
  );
};

export default CollectibleItemTeaser;
