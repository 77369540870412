import styled from 'styled-components';
import { PrimaryButton } from '../../../styles/Buttons';
import { FC, ReactNode, useState, useMemo } from 'react';
import { Input } from '../../common/forms/Input';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import FormWrapper from '../../common/forms/FormWrapper';
import { FieldValues, useForm } from 'react-hook-form';
import { isEmail } from '../../../utils/validators';
import { Checkbox } from '../../common/forms/Checkbox';
import { useAuthAPI } from '../../../services/auth';
import { Notification } from '../../common/general/Notification';
import { Body2, Body3 } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';
import WalletConnectButton from '../../common/special/WalletConnectButton';

const Section = styled.section`
  ${Body3};

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const WalletIntroText = styled.p`
${Body2};
  margin-bottom: 1rem;
`;

const WalletWarningText = styled.p`
${Body2};
  margin-bottom: 1rem;
`;

const IntroText = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const NextButton = styled(PrimaryButton)`
  margin: 0.75rem auto 0;
`;

export const Indicators = styled.ul`
  margin-left: 1.25rem;
  margin-top: -0.5rem;
`;

const IndicatorWrapper = styled.li<{ valid: boolean | undefined }>`
  color: ${({ valid }) => (valid === undefined ? 'inherit' : valid ? 'var(--color-signal-green)' : 'var(--color-signal-red)')};
`;

export const ErrorNotification = styled(Notification).attrs({ type: 'error' })`
  margin-bottom: 1rem;
`;

export const WalletConnectWrapper = styled.div``;
export const WalletConnectDescription = styled.div``;

const Divider = styled.span`
  display: flex;
  align-items: center;
  // gap: 1.5rem;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  // margin: 3rem 0 3rem 0;

  &::after,
  &::before {
    content: '';
    display: block;
    height: 1px;
    flex: 1;
    background: #fff;
    max-width: 20%;
  }
`;
interface IIndicatorProps {
  children: ReactNode;
  value: boolean | undefined;
}

export const Indicator: FC<IIndicatorProps> = ({ value, children }) => <IndicatorWrapper valid={value}>{children}</IndicatorWrapper>;

interface ISignupProps {
  onSubmit: (password: string) => void;
}

// TODO Implement Username check
const passwordsAreTheSame = (confirmPassword: string, password: string) => confirmPassword === password;

const SignUp: FC<ISignupProps> = ({ onSubmit }) => {
  const getLocalizedString = useGetLocalizedString();

  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm({ mode: 'onChange' });
  const { registerUser, checkUsername } = useAuthAPI();

  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);

  const isValid = useMemo(() => {
    return form.formState.isValid;
  }, [form.formState.isValid]);

  const [hasLowerCase, setHasLowerCase] = useState<boolean | undefined>();
  const [hasCapital, setHasCapital] = useState<boolean | undefined>();
  const [hasSymbol, setHasSymbol] = useState<boolean | undefined>();
  const [hasNumber, setHasNumber] = useState<boolean | undefined>();
  const [hasLength, setHasLength] = useState<boolean | undefined>();

  const validatePassword = (password: string) => {
    const hasLowerCase = /[a-z]/g.test(password);
    const hasCapital = /[A-Z]/g.test(password);
    const hasSymbol = /[^\w\d]/g.test(password);
    const hasNumber = /[0-9]/g.test(password);
    const hasLength = /.{8,}$/g.test(password);

    setHasLowerCase(hasLowerCase);
    setHasCapital(hasCapital);
    setHasSymbol(hasSymbol);
    setHasNumber(hasNumber);
    setHasLength(hasLength);

    return hasLowerCase && hasCapital && hasSymbol && hasNumber && hasLength;
  };

  const handleSubmit = async (e: FieldValues) => {
    setLoading(true);
    try {
      const { username, email, password } = e;
      await registerUser({ username, email, password });
      setLoading(false);
      onSubmit(password);
    } catch (err: any) {
      console.error('register', err);
      switch (err?.response?.status || 500) {
        case 403:
          setErrorState({
            headline: getLocalizedString('app.v2.registration.error403.title'),
            text: getLocalizedString('app.v2.registration.error403.text'),
          });
          break;
        default:
        case 401:
          setErrorState({
            headline: getLocalizedString('app.v2.registration.error401.title'),
            text: getLocalizedString('app.v2.registration.error401.text'),
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const checkName = async (username: string) => {
    try {
      const valid = await checkUsername(username);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <Section>
      {!window.ethereum && (
        <>
          <WalletWarningText>{getLocalizedString('app.v2.registration.wallet-connect.no-wallet')}</WalletWarningText>
          <WalletIntroText>{getLocalizedString('app.v2.registration.wallet-connect.create-wallet')}</WalletIntroText>
        </>
      )}

      {window.ethereum && (
        <>
          <WalletIntroText>{getLocalizedString('app.v2.registration.wallet-connect.description')}</WalletIntroText>
          <div>
            <WalletConnectButton />
          </div>
        </>
      )}

      <Divider />
      <IntroText>{getLocalizedString('app.v2.registration.title')}</IntroText>
      {errorState && <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />}
      <FormWrapper onSubmit={handleSubmit} form={form}>
        <Input
          name={'username'}
          label={getLocalizedString('app.v2.registration.label.username')}
          placeholder={getLocalizedString('app.v2.registration.placeholder.username')}
          autoComplete={'nickname'}
          options={{
            required: true,
            validate: checkName,
          }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.username.required'),
            validate: getLocalizedString('app.v2.registration.validation.username.invalid'),
          }}
        />
        <Input
          name={'email'}
          label={getLocalizedString('app.v2.registration.label.email')}
          placeholder={getLocalizedString('app.v2.registration.placeholder.email')}
          type={'email'}
          autoComplete={'username'}
          options={{ required: true, validate: isEmail }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.email.required'),
            validate: getLocalizedString('app.v2.registration.validation.email.invalid'),
          }}
        />
        <Input
          name={'password'}
          label={getLocalizedString('app.v2.registration.label.password')}
          placeholder={getLocalizedString('app.v2.registration.placeholder.password')}
          type={'password'}
          autoComplete={'new-password'}
          options={{
            required: true,
            validate: validatePassword,
            onChange: ({ target }) => {
              !target.value && validatePassword(target.value);
            },
          }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.password.required'),
            validate: getLocalizedString('app.v2.registration.validation.password.invalid'),
          }}
        />
        <Input
          name={'confirmPassword'}
          label={getLocalizedString('app.v2.registration.label.confirm-password')}
          placeholder={getLocalizedString('app.v2.registration.placeholder.confirm-password')}
          type={'password'}
          autoComplete={'new-password'}
          options={{
            required: true,
            validate: (confirmPassword) => passwordsAreTheSame(confirmPassword, form.getValues('password')),
          }}
          error={{
            required: getLocalizedString('app.v2.registration.validation.confirm-password.required'),
            validate: getLocalizedString('app.v2.registration.validation.confirm-password.invalid'),
          }}
          onPaste={(e) => e.preventDefault()}
        />
        <Indicators>
          <Indicator value={hasLowerCase}>{getLocalizedString('app.v2.registration.password-criteria.lowercase')}</Indicator>
          <Indicator value={hasCapital}>{getLocalizedString('app.v2.registration.password-criteria.capital')}</Indicator>
          <Indicator value={hasSymbol}>{getLocalizedString('app.v2.registration.password-criteria.symbol')}</Indicator>
          <Indicator value={hasNumber}>{getLocalizedString('app.v2.registration.password-criteria.number')}</Indicator>
          <Indicator value={hasLength}>{getLocalizedString('app.v2.registration.password-criteria.length')}</Indicator>
        </Indicators>
        {/* TODO add links to t&c and privacy */}
        <Checkbox label={getLocalizedString('app.v2.registration.accept-terms')} name='termsAndConditions' required={true} />
        <Checkbox label={getLocalizedString('app.v2.registration.accept-mail')} name='mail' />
        <Divider />
        <NextButton className='cy-button-next' disabled={!isValid || loading}>
          {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.general.continue')}
        </NextButton>
      </FormWrapper>
    </Section>
  );
};

export default SignUp;
