import styled from 'styled-components';
import { Input } from '../components/common/forms/Input';
import { useLocalizedString } from '../services/localization';
import { PrimaryButton, SecondaryButton } from '../styles/Buttons';
import FormWrapper from '../components/common/forms/FormWrapper';
import { FieldValues, useForm } from 'react-hook-form';
import { Headline3 } from '../styles/FontStyles';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../styles/Breakpoints';
import { EditIcon } from '../components/icons/EditIcon';
import { useProfileAPI } from '../services/profile';
import { useEffect, useState } from 'react';
import { LoadingAnimation } from '../components/common/general/LoadingAnimation';
import { useRecoilState } from 'recoil';
import { UserState } from '../state/user';
import { IUserAuthData, useAuthAPI } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import { useAddSystemNotification } from '../state/systemNotifications';
import { PageView } from '../components/layout/PageLayout';

const Section = styled.section`
  grid-column: 1/ -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Headline = styled.h1`
  ${Headline3};
  text-transform: uppercase;
  margin-bottom: 1.25rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 5rem;
  }
`;

const StyledForm = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 16.5rem;
  width: 16.5rem;
  margin-bottom: 1.5rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  ${BREAKPOINT_MD} {
    margin-bottom: 4rem;

    :before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: var(--color-primary);
      opacity: 0.1;
      filter: blur(130px);
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  max-width: 21.25rem;
  margin-bottom: 2rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3.125rem;
  }

  ${BREAKPOINT_XL} {
    margin-bottom: 6.25rem;
  }
`;

const EditButton = styled.label`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.625rem;
  width: 2.625rem;
  border: 1px solid var(--color-text-default);
  border-radius: 50%;
  background: var(--color-grey-800);

  bottom: 7.5%;
  right: 7.5%;
  font-size: 1.5rem;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 3.75rem;

  ${PrimaryButton} {
    display: none;

    ${BREAKPOINT_MD} {
      display: initial;
    }
  }

  ${SecondaryButton} {
    display: flex;
    gap: 1rem;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;

  input {
    padding-right: 3rem;
  }

  label {
    text-transform: uppercase;
  }
`;

const ImageInput = styled(Input)`
  display: none;
`;

const IconWrapper = styled.div`
  position: absolute;
  bottom: .75rem;
  right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
`;

export const EditProfile = () => {
  const headline = useLocalizedString('app.v2.edit-profile.headline');
  const inputLabel = useLocalizedString('app.v2.edit-profile.input.label');
  const inputPlaceholder = useLocalizedString('app.v2.edit-profile.input.placeholder');
  const cancelButtonLabel = useLocalizedString('app.v2.edit-profile.button.cancel');
  const saveButtonLabel = useLocalizedString('app.v2.edit-profile.input.save');
  const successMessage = useLocalizedString('app.v2.edit-profile.success-message');
  const genericErrorMessage = useLocalizedString('app.v2.edit-profile.error-message');
  const fileSizeErrorMessage = useLocalizedString('app.v2.edit-profile.file-size.error-message');

  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string>('');

  const { patchProfile } = useProfileAPI();
  const [userState, setUserState] = useRecoilState<IUserAuthData | null>(UserState);
  const form = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const authAPI = useAuthAPI();
  const addNotification = useAddSystemNotification();

  const checkName = async (username: string) => {
    try {
      const valid = await authAPI.checkUsername(username);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const submitHandler = async (e: FieldValues) => {
    setLoading(true);
    let data = new FormData();

    if (userState?.username !== e.username) {
      data.append('username', e.username);
    }

    if (e.userImage.length === 1) {
      const file = e.userImage[0];
      data.append('image', file as Blob);
    }

    try {
      await patchProfile(data);
      const { value } = (await authAPI.me()).data;
      setUserState(value);
      addNotification({ type: 'success', headline: successMessage });
      setDisabled(true);
      navigate('/profile');
    } catch (error: any) {
      console.log('patch profile error:', error);
      if (error.response.status === 413) {
        addNotification({ type: 'error', headline: fileSizeErrorMessage });
      } else {
        addNotification({ type: 'error', headline: genericErrorMessage });
      }
    } finally {
      setLoading(false);
    }
  };

  const changeHandler = (e: any) => {
    const usernameChanges = userState?.username === form.getValues('username');
    const imageChanged = form.getValues('userImage')?.length === 0;

    if (e.target.id === 'userImage') {
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }

    setDisabled(usernameChanges && imageChanged);
  };

  useEffect(() => {
    form.setValue('username', userState?.username);
  }, [userState]);

  return (
    <PageView>
      <Section>
        <StyledForm form={form} onSubmit={submitHandler} onChange={changeHandler}>
          <Headline>{headline}</Headline>
          <ImageWrapper>
            <picture>
              <source srcSet={imageUrl} />
              <source srcSet={userState?.image} />
              <img src={'/images/profile-avatar.png'} alt={'Avatar'} />
            </picture>
            <EditButton htmlFor={'userImage'}><EditIcon /></EditButton>
            <ImageInput
              name={'userImage'}
              type={'file'}
              id={'userImage'}
              accept={'image/png, image/jpeg'}
              multiple={false}
            />
          </ImageWrapper>
          <InputWrapper>
            <StyledInput
              name={'username'}
              label={inputLabel}
              placeholder={inputPlaceholder}
            />
            <IconWrapper><EditIcon /></IconWrapper>
          </InputWrapper>
          <ButtonWrapper>
            <PrimaryButton type={'button'} onClick={() => navigate('/profile')}>{cancelButtonLabel}</PrimaryButton>
            <SecondaryButton type={'submit'} disabled={disabled}>
              {saveButtonLabel}{loading && <LoadingAnimation />}
            </SecondaryButton>
          </ButtonWrapper>
        </StyledForm>
      </Section>
    </PageView>
  );
};
