import styled, {css} from 'styled-components';
import { FC, useRef , useState} from 'react';
import { IAlbumContentItem, IAlbumDetails } from '../../../services/albums';
import { MAX_BREAKPOINT_SM} from '../../../styles/Breakpoints';
import { Headline6, Body3Bold } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';

const Wrapper = styled.div.attrs({ as: 'section' })`
  text-align: center;
`
const WrapperRings = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 49% 49%;
  gap: 8% 2%;
`

const RingCell = styled.div`
  text-align: center;
`

const DoubleRingCell = styled(RingCell)`
  text-align: center;
  grid-column: 1 / span 2;
`

const RingImage = styled.img`
  width: 20vw;
`

const Title = styled.span`
  font-size: 8vh;
  line-height: 1.125;
  text-align: center;
  font-family: var(--font-family-artsy);
`;

const PlayerName = styled.p`
  ${Headline6}
  text-align: center;
`

const RingDescription = styled.p`
  text-align: center;
`

const ButtonHover = css`
  transition: box-shadow 250ms ease-out;

  @media (hover: hover) {
    :hover {
      box-shadow: var(--box-shadow-hover);
    }
  }

  :focus-visible {
    box-shadow: var(--box-shadow-hover);
  }
`;

const CTA = styled.a`
  text-align: center;
  appearance: none;
  padding: 0 1rem;
  border: 1px solid var(--color-text-default);
  border-radius: 2px;
  background: var(--color-grey-800);
  white-space: nowrap;
  line-height: 1;
  text-transform: uppercase;
  width: max-content;
  min-width: 15rem;
  height: 48px;

  ${Body3Bold};

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ButtonHover};

  ${MAX_BREAKPOINT_SM} {
    width: max-content;
    min-width: 8rem;
    height: 48px;
  }

  :disabled {
    pointer-events: none;
    color: var(--color-grey-700);
    border-color: var(--color-grey-700);
    background: var(--color-grey-800);
  }

  transition: background 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out;

  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-highlight);

  :disabled {
    border-color: var(--color-grey-800);
  }
`
const AvailableAt = styled.h4`
  color: var(--color-primary);
`

interface TextImageSectionProps extends IAlbumContentItem {
  album: IAlbumDetails;
  direction: 'right' | 'left';
  image: string;
  text: string;
  title: string;
}

export const ForeverGalaxyAlbumLegendSection: FC<TextImageSectionProps> = (props) => {
  const { title } = props;

  const sectionRef = useRef<HTMLDivElement | null>(null);
  const getLocalizedString = useGetLocalizedString();

  return (
    <Wrapper>
      <br /><br />
      <Title>{title}</Title>
      <br /><br />
      <WrapperRings>
        <RingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1684140634/publishers/Frankfurt%20Galaxy/56_Gomez/56_gomez_still.png" alt="Ring #56" />
          <PlayerName>SILVA GOMEZ</PlayerName>
          <RingDescription>Ring #56<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring8.utility1')}<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring8.utility2')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-silvagomez-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <RingCell>
        <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1684159209/publishers/Frankfurt%20Galaxy/86_Regler/86_regler_still.png" alt="Ring #86" />
          <PlayerName>REGLER</PlayerName>
          <RingDescription>Ring #86<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring9.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-regler-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </RingCell>
        <DoubleRingCell>
          <RingImage src="https://res.cloudinary.com/fanseap/image/upload/v1683906423/publishers/Frankfurt%20Galaxy/7_Sullivan/7_Sullivan_still.png" alt="Ring #7" />
          <PlayerName>SULLIVAN</PlayerName>
          <RingDescription>Ring #7<br />{getLocalizedString('app.v2.forevergalaxy.campaign.ring10.utility')}</RingDescription>
          <br />
          <CTA href="../collectibles/fg-jakebsullivanlegendary-forever-galaxy-ring-2021">{getLocalizedString('app.v2.collectible.cta.learnmore')}</CTA>
        </DoubleRingCell>
      </WrapperRings>
    </Wrapper>
  );
};
