import path from 'path';

export enum CloudinaryQuality {
  GOOD = "good",
  BEST = "best"
}

export const useCloudinary = () => {
  const DEFAULT_TRANSFORMATION = 'c_limit,h_<SIZE>,q_auto:<QUALITY>,w_<SIZE>';

  const getTranformationArgs = (size: string, quality: string) => {
    return DEFAULT_TRANSFORMATION.replaceAll('<SIZE>', size).replace('<QUALITY>', quality);
  };

  const generateImageUrl = async (
    imageUrl: string | null,
    transformationArgs: string = 'c_limit,h_1000,q_auto:good,w_1000',
    isJpegConverted = false,
    size = '1000',
    quality: CloudinaryQuality = CloudinaryQuality.BEST,
  ) => {
    if (!imageUrl) {
      return '';
    }

    if (!imageUrl.startsWith('https://res.cloudinary.com')) {
      return imageUrl;
    }

    const urlParts = imageUrl.split('upload');
    let finalImageUrl = urlParts[0] + `upload/${getTranformationArgs(size, quality)}` + urlParts.slice(1).join('upload');

    if (isJpegConverted) {
      finalImageUrl = finalImageUrl.replace(/\.[^.]+$/, '.jpeg');
    }

    return finalImageUrl;
  };

  return { generateImageUrl };
};

//https://res.cloudinary.com/fanseap/image/upload/v1676038027/publishers/Frankfurt%20Galaxy/Banner/210926_uploadELF_CHAMPIONS/upload_505_ohne_ips3se.jpg
