export const SadSmiley = (props: any) => (
  <svg width="479" height="498" viewBox="0 0 479 498" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.3">
      <path d="M264.618 32.0426L264.619 32.0427C356.016 43.3945 433.578 112.206 455.789 201.922L455.791 201.933L455.794 201.943C459.719 215.249 461.041 235.997 461.336 253.778C461.631 271.545 460.9 286.257 460.747 287.495C439.972 447.591 263.204 535.319 122.035 455.513C40.397 409.365 -3.89476 317.471 9.65758 223.121L9.16265 223.05L9.65759 223.121C23.3183 127.9 92.5155 61.7975 180.095 36.7716C215.604 26.6389 262.103 31.7282 264.618 32.0426ZM235.698 69.2033C131.389 69.1131 46.5504 152.953 46.2798 256.394C46.0093 361.244 130.008 446.195 234.123 446.307C338.41 446.42 423.271 362.557 423.541 259.094C423.834 154.244 339.79 69.2935 235.698 69.2033Z" stroke="#08E8DE"/>
      <path d="M343.966 337.851L343.966 337.851C351.938 345.824 351.253 357.626 343.65 365.251C339.727 369.185 335.161 371.198 330.481 371.214C325.799 371.231 320.942 369.249 316.441 365.08C298.458 348.402 279.195 333.799 254.413 328.734L254.412 328.734C217.02 321.115 186.13 334.696 158.781 358.843L158.779 358.845C157.651 359.848 156.598 360.929 155.56 361.998L155.507 362.053C154.483 363.107 153.471 364.149 152.392 365.12L152.39 365.121C147.978 369.113 143.163 371.091 138.465 371.116C133.77 371.14 129.136 369.214 125.08 365.29L125.08 365.289C121.174 361.516 119.096 356.892 118.967 352.122C118.839 347.352 120.66 342.391 124.633 337.943C140.225 320.512 182.416 284.821 234.144 284.821C283.749 284.821 313.78 307.643 343.966 337.851Z" stroke="#08E8DE"/>
      <path d="M143.714 205.969L143.714 205.969C143.958 189.086 158.034 175.302 174.798 175.545L174.798 175.545C191.682 175.789 205.465 189.866 205.222 206.607C204.979 223.49 190.902 237.274 174.138 237.031C157.254 236.787 143.471 222.71 143.714 205.969Z" stroke="#08E8DE"/>
      <path d="M297.863 175.545L297.864 175.545C314.747 175.744 328.575 189.821 328.354 206.562C328.133 223.446 314.078 237.251 297.337 237.03H297.337C280.431 236.809 266.647 222.776 266.846 206.013C267.067 189.129 281.1 175.323 297.863 175.545Z" stroke="#08E8DE"/>
    </g>
    <g opacity="0.1">
      <path d="M257.876 43.2851L257.877 43.2851C349.274 54.6369 426.836 123.448 449.046 213.164L449.049 213.175L449.052 213.186C452.977 226.492 454.299 247.239 454.594 265.021C454.889 282.787 454.158 297.499 454.005 298.737C433.23 458.833 256.462 546.561 115.293 466.755C33.6549 420.608 -10.6369 328.714 2.91539 234.363L2.42047 234.292L2.9154 234.363C16.5761 139.142 85.7733 73.04 173.353 48.014C208.862 37.8813 255.361 42.9706 257.876 43.2851ZM228.956 80.4457C124.646 80.3555 39.8082 164.196 39.5376 267.636C39.2671 372.486 123.266 457.437 227.381 457.55C331.668 457.662 416.529 373.799 416.799 270.337C417.092 165.487 333.048 80.5359 228.956 80.4457Z" stroke="#08E8DE"/>
      <path d="M337.223 349.094L337.224 349.094C345.196 357.066 344.511 368.868 336.908 376.493C332.985 380.428 328.419 382.44 323.739 382.456C319.057 382.473 314.2 380.492 309.698 376.322C291.716 359.645 272.452 345.041 247.67 339.976L247.67 339.976C210.278 332.357 179.388 345.938 152.038 370.086L152.037 370.087C150.908 371.09 149.856 372.171 148.818 373.24L148.765 373.295C147.741 374.35 146.729 375.391 145.649 376.362L145.648 376.363C141.236 380.355 136.421 382.334 131.723 382.358C127.028 382.383 122.394 380.456 118.338 376.532L118.338 376.532C114.432 372.758 112.353 368.135 112.225 363.364C112.097 358.594 113.918 353.633 117.891 349.186C133.483 331.754 175.674 296.063 227.401 296.063C277.007 296.063 307.038 318.886 337.223 349.094Z" stroke="#08E8DE"/>
      <path d="M136.972 217.211L136.972 217.211C137.215 200.328 151.292 186.544 168.056 186.787L168.056 186.787C184.94 187.031 198.723 201.108 198.48 217.849C198.236 234.732 184.16 248.516 167.396 248.273C150.512 248.029 136.729 233.952 136.972 217.211Z" stroke="#08E8DE"/>
      <path d="M291.121 186.787L291.122 186.787C308.005 186.986 321.833 201.063 321.612 217.804C321.39 234.688 307.335 248.493 290.594 248.272H290.594C273.688 248.051 259.905 234.019 260.104 217.255C260.325 200.371 274.358 186.566 291.121 186.787Z" stroke="#08E8DE"/>
    </g>
    <g opacity="0.5">
      <path d="M271.368 18.5519L271.369 18.552C362.766 29.9037 440.328 98.715 462.539 188.431L462.541 188.442L462.544 188.452C466.469 201.759 467.791 222.506 468.086 240.287C468.381 258.054 467.65 272.766 467.497 274.004C446.722 434.1 269.954 521.828 128.785 442.022C47.147 395.875 2.85524 303.981 16.4076 209.63L15.9127 209.559L16.4076 209.63C30.0683 114.409 99.2655 48.3068 186.845 23.2809C222.354 13.1481 268.853 18.2375 271.368 18.5519ZM242.448 55.7126C138.139 55.6224 53.3004 139.463 53.0298 242.903C52.7593 347.753 136.758 432.704 240.873 432.817C345.16 432.929 430.021 349.066 430.291 245.604C430.584 140.754 346.54 55.8028 242.448 55.7126Z" stroke="#08E8DE"/>
      <path d="M350.716 324.361L350.716 324.361C358.688 332.333 358.003 344.135 350.4 351.76C346.477 355.695 341.911 357.707 337.231 357.724C332.549 357.74 327.692 355.759 323.191 351.589C305.208 334.912 285.945 320.308 261.163 315.243L261.162 315.243C223.77 307.624 192.88 321.205 165.531 345.353L165.529 345.354C164.401 346.357 163.348 347.438 162.31 348.507L162.257 348.562C161.233 349.617 160.221 350.658 159.142 351.629L159.14 351.63C154.728 355.622 149.913 357.601 145.215 357.625C140.52 357.65 135.886 355.723 131.83 351.799L131.83 351.799C127.924 348.025 125.846 343.402 125.717 338.631C125.589 333.861 127.41 328.9 131.383 324.453C146.975 307.021 189.166 271.331 240.894 271.331C290.499 271.331 320.53 294.153 350.716 324.361Z" stroke="#08E8DE"/>
      <path d="M150.464 192.478L150.464 192.478C150.708 175.595 164.784 161.811 181.548 162.054L181.548 162.054C198.432 162.298 212.215 176.375 211.972 193.116C211.729 209.999 197.652 223.783 180.888 223.54C164.004 223.297 150.221 209.219 150.464 192.478Z" stroke="#08E8DE"/>
      <path d="M304.613 162.054L304.614 162.054C321.497 162.253 335.325 176.33 335.104 193.071C334.883 209.955 320.828 223.761 304.087 223.539H304.087C287.181 223.318 273.397 209.286 273.596 192.522C273.817 175.638 287.85 161.833 304.613 162.054Z" stroke="#08E8DE"/>
    </g>
    <g filter="url(#filter0_i_1823_15576)">
      <path d="M278.173 4.55822C369.776 15.9356 447.506 84.8966 469.766 174.813C477.658 201.57 475.05 258.052 474.735 260.571C453.914 421.023 276.756 508.938 135.281 428.96C53.4591 382.708 9.07399 290.61 22.6548 196.062C36.3481 100.613 105.714 34.3731 193.45 9.30252C229.066 -0.860627 275.654 4.24343 278.173 4.55822ZM249.19 42.715C145.152 42.6251 60.5418 126.246 60.272 229.407C60.0022 333.984 143.781 418.707 247.616 418.819C351.631 418.931 436.264 335.288 436.533 232.105C436.826 127.528 353.002 42.805 249.19 42.715Z" fill="#08E8DE"/>
      <path d="M247.636 257.333C297.44 257.333 327.592 280.268 357.811 310.51C365.996 318.694 365.276 330.813 357.497 338.616C349.492 346.643 338.744 346.935 329.593 338.458C311.628 321.797 292.448 307.272 267.805 302.235C230.615 294.658 199.878 308.149 172.604 332.23C170.378 334.209 168.444 336.502 166.218 338.503C157.247 346.62 146.499 346.665 138.224 338.661C130.242 330.948 129.658 319.683 137.752 310.622C153.379 293.151 195.696 257.333 247.636 257.333Z" fill="#08E8DE"/>
      <path d="M219.214 179.626C218.967 196.782 204.667 210.79 187.623 210.542C170.467 210.295 156.459 195.995 156.706 178.974C156.954 161.818 171.254 147.81 188.298 148.057C205.453 148.304 219.462 162.605 219.214 179.626Z" fill="#08E8DE"/>
      <path d="M311.362 148.056C328.518 148.259 342.571 162.559 342.346 179.58C342.121 196.736 327.843 210.767 310.822 210.542C293.644 210.317 279.636 196.062 279.838 179.018C280.063 161.862 294.318 147.832 311.362 148.056Z" fill="#08E8DE"/>
    </g>
    <g filter="url(#filter1_f_1823_15576)">
      <circle cx="249.586" cy="230.103" r="209.109" stroke="white" strokeWidth="10"/>
    </g>
    <g filter="url(#filter2_f_1823_15576)">
      <circle cx="188.874" cy="178.388" r="13.4909" fill="white"/>
      <circle cx="312.538" cy="178.388" r="13.4909" fill="white"/>
    </g>
    <g filter="url(#filter3_f_1823_15576)">
      <path d="M152.898 324.264C183.253 290.537 264.198 243.319 345.144 324.264" stroke="white" strokeWidth="10" strokeLinecap="round"/>
    </g>
    <defs>
      <filter id="filter0_i_1823_15576" x="20.2344" y="3" width="455.164" height="455.974" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="8"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1823_15576"/>
      </filter>
      <filter id="filter1_f_1823_15576" x="20.4766" y="0.994385" width="458.219" height="458.218" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_1823_15576"/>
      </filter>
      <filter id="filter2_f_1823_15576" x="160.383" y="149.897" width="180.648" height="56.9819" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_1823_15576"/>
      </filter>
      <filter id="filter3_f_1823_15576" x="132.898" y="259.57" width="232.242" height="84.6943" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_1823_15576"/>
      </filter>
    </defs>
  </svg>
);
