import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { BREAKPOINT_LG } from '../../../styles/Breakpoints';
import FormWrapper from '../../common/forms/FormWrapper';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { Notification } from '../../common/general/Notification';
import { Input } from '../../common/forms/Input';
import { Body2Bold, PageViewHeadline } from '../../../styles/FontStyles';
import { SecondaryButton } from '../../../styles/Buttons';
import { useGetLocalizedString } from '../../../services/localization';
import { useRecoilValue } from 'recoil';
import { useAuthAPI } from '../../../services/auth';
import { UserState } from '../../../state/user';
import { PageView } from '../../layout/PageLayout';

const Section = styled.section`
  display: grid;
  place-self: center;

  ${BREAKPOINT_LG} {
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: auto;
  max-width: 21.5rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 0;
    grid-column: 8 / -2;
  }
`;

const ErrorNotification = styled(Notification).attrs({ type: 'error' })`
  margin-bottom: 1rem;
`;

const StyledText = styled.p`
  ${Body2Bold};
  margin-bottom: 1rem;
`;

const StyledButton = styled(SecondaryButton)`
  margin: 1.5rem auto 0;
`;

interface IConfirmPassword {
  onSuccess?: (password: string) => Promise<void>;
  title: string;
}

const ConfirmPassword: FC<IConfirmPassword> = ({
  onSuccess,
  title
}) => {
  const getLocalizedString = useGetLocalizedString();
  const form = useForm({
    mode: 'onChange',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<{ headline: string, text: string } | null>(null);
  const { login } = useAuthAPI();
  const userData = useRecoilValue(UserState);

  const handleSubmit = async ({ password }: FieldValues) => {
    setLoading(true);
    try {
      await login({ email: userData?.email!, password });
      await onSuccess?.(password);
      setErrorState(null);
    } catch (err: any) {
      switch (err?.response?.status || 500) {
        default:
        case 401:
          setErrorState({
            headline: getLocalizedString('app.v2.wallet-decrypt.wrong-password-error-headline'),
            text: getLocalizedString('app.v2.wallet-decrypt.wrong-password-error-text'),
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageView>
      <Section>
        <PageViewHeadline>{getLocalizedString('app.v2.wallet-decrypt.headline')}</PageViewHeadline>
        <Wrapper>
          <FormWrapper onSubmit={handleSubmit} form={form}>
            <StyledText>{title}</StyledText>
            {errorState && (
              <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />
            )}
            <Input
              name={'password'}
              label={getLocalizedString('app.v2.wallet-decrypt.password-input-label')}
              type={'password'}
            />
            <StyledButton>
              {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.wallet-decrypt.button-label')}
            </StyledButton>
          </FormWrapper>
        </Wrapper>
      </Section>
    </PageView>
  );
};

export default ConfirmPassword;