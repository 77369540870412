import styled from 'styled-components';
import { PageView } from '../components/layout/PageLayout';
import { ResetPasswordWithTokenModal } from '../components/views/login/ResetPasswordWithTokenModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const token = new URLSearchParams(location.search).get('token') || null;

  useEffect(() => {
    if (!token) {
      navigate('/404', { replace: true });
    }
  }, [location]);

  return (
    <PageView>
      <Section>
        {
          token &&
          <ResetPasswordWithTokenModal
            open={true}
            onClose={() => navigate('/')}
            onBackButton={() => navigate('/login')}
            onSuccess={() => navigate('/login')}
            token={token}
          />
        }
      </Section>
    </PageView>
  );
};
