import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import { ProfileTopWeb3 } from '../components/common/collectibles/ProfileTopWeb3';
import { BasicGridWrapper } from '../components/common/general/Grids';
import { LoadingOverlay, PageLoader } from '../components/common/general/LoadingOverlay';
import WalletItems from '../components/common/special/WalletItems';
import { PageView } from '../components/layout/PageLayout';
import { ChainInformation } from '../components/wallet/ChainInformation';
import { useAlchemy } from '../hooks/useAlchemy';
import { IUserCollectible } from '../services/collectible';
import { useGetLocalizedString } from '../services/localization';
import { useAuthToken } from '../state/auth';
import { useDataCache } from '../state/dataCache';
import { BREAKPOINT_MD, MAX_BREAKPOINT_MD, MAX_BREAKPOINT_SM } from '../styles/Breakpoints';
import { PrimaryButton, SecondaryButton } from '../styles/Buttons';
import { Headline3 } from '../styles/FontStyles';

const ContentWrapper = styled.section`
  position: relative;
  display: block;
  flex-direction: row;
  align-items: stretch;
  min-height: 200px;
`;

const HeadWrapper = styled.div`
  display: block;
`;

const WalletHeadline = styled.h1`
  ${Headline3}
  margin-bottom: 2rem;
`;

const WalletContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0 0 0;

  ${MAX_BREAKPOINT_MD} {
    display: block;
  }
`;

const WalletContentItems = styled(BasicGridWrapper)``;

const StyledProfileTop = styled(ProfileTopWeb3)`
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 2rem;
  }
`;

const ProfileWrapper = styled.div`
  margin: 3rem 0;
`;

const UserProfile = styled.div`
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;
`;

const UserNotLoggedIn = styled.div`
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;
`;

const ButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  gap: 1rem;

  ${MAX_BREAKPOINT_SM} {
    width: 100;
    display: block;
  }
`;

const Loader = styled(LoadingOverlay)`
  height: 100%;
  pointer-events: none;
  user-select: none;
  touch-action: none;
`;

export const Wallet = () => {
  const t = useGetLocalizedString();
  const navigate = useNavigate();
  const location = useLocation();

  const authToken = useAuthToken();
  const [dataCache, setDataCache] = useDataCache();
  const { profileData = null, userCollectiblesData = null, userAlbums = null, userPresaleCollectiblesData = null } = dataCache;

  const { isLoading, fetchWalletItems } = useAlchemy();
  const { address, isConnected, isConnecting } = useAccount();

  const [walletNfts, setWalletNfts] = useState<IUserCollectible[]>([]);

  useEffect(() => {
    if (!isConnected && authToken) {
      navigate('/profile');
    }

    if (!isConnected && !authToken) {
      navigate('/');
    }

    if (address) {
      getItems(address);
    } else {
      console.log('no address');
      setWalletNfts([]);
    }
  }, [address]);

  const getItems = async (wallet: string) => {
    const mergedData = await fetchWalletItems(wallet);
    setWalletNfts(mergedData);
  };

  return (
    <PageView>
      <ContentWrapper>
        <PageLoader visible={!isConnected || isConnecting} />

        <ProfileWrapper>
          {authToken ? (
            <UserProfile>
              <StyledProfileTop profileData={profileData} />
            </UserProfile>
          ) : (
            <UserNotLoggedIn>
              <p>{t('app.v2.wallet.description')}</p>
              <p>{t('app.v2.wallet.description2')}</p>
              <br />
              <ButtonWrapper>
                <PrimaryButton className='cy-login-button' onClick={() => navigate('/login')}>{t('app.v2.login.login')}</PrimaryButton>
                <br />
                <SecondaryButton
                  className='cy-registration-button'
                  onClick={() => navigate('/registration')}>
                  {t('app.v2.login.register-now')}
                </SecondaryButton>
              </ButtonWrapper>
            </UserNotLoggedIn>
          )}
        </ProfileWrapper>

        <HeadWrapper className='headWrapper'>
          <WalletHeadline>{t('app.v2.wallet.page.headline')}</WalletHeadline>
          <p>{t('app.v2.wallet.page.subline')}</p>
        </HeadWrapper>

        <ChainInformation />

        <WalletContentWrapper>
          <WalletContentItems gridColumnsSM={'2'} gridColumns={'3'} gridColumnsMD={'3'}>
            {walletNfts && !isLoading && <WalletItems nfts={walletNfts} />}
            {isLoading && <>Loading...</>}
          </WalletContentItems>
        </WalletContentWrapper>
      </ContentWrapper>
    </PageView>
  );
};
