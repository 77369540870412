import { IFavoritesList, useFavoritesAPI } from '../services/favorites';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { IUserCollectiblesDetails } from '../services/collectible';
import { useAddSystemNotification } from '../state/systemNotifications';
import { useActiveLocale, useGetLocalizedString } from '../services/localization';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '../services';

interface IFavoriteContextProps {
  favorites: IUserCollectiblesDetails | null;
  favoritesIds: IFavoritesList | null;
  toggleFavorite: (active: boolean, id: string) => Promise<void>;
  fetchFavorites: () => Promise<void>;
}

const FavoriteContext = createContext<Partial<IFavoriteContextProps>>({});

export const FavoriteContextProvider: FC<PropsWithChildren> = (props) => {
  const getLocalizedString = useGetLocalizedString();
  const activeLocale = useActiveLocale();
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const favoritesAPI = useFavoritesAPI();

  const addSystemNotification = useAddSystemNotification();

  const [favoritesIds, setFavoritesIds] = useState<IFavoritesList | null>(null);
  const [favorites, setFavorites] = useState<IUserCollectiblesDetails | null>(null);

  const fetchFavoritesCollectibles = async () => {
    return await favoritesAPI.favoritesDetails();
  };

  const fetchFavoritesCollectiblesIds = async () => {
    return await favoritesAPI.favorites();
  };

  const fetchFavorites = async () => {
    try {
      const [ids, collectibles] = await Promise.all([
        fetchFavoritesCollectiblesIds(),
        fetchFavoritesCollectibles(),
      ]);

      setFavoritesIds(ids.data);
      setFavorites(collectibles.data);
    } catch (error) {
      addSystemNotification({
        type: 'error',
        headline: getLocalizedString('app.v2.favorites.notifications.fetch-error.headline'),
        text: getLocalizedString('app.v2.favorites.notifications.fetch-error.text'),
      });
    }
  };

  const toggleFavorite = async (active: boolean, id: string) => {
    if (isAuthenticated) {
      if (!active) {
        try {
          await favoritesAPI.addFavorite(id);
          await fetchFavorites();
          addSystemNotification({
            type: 'success',
            headline: getLocalizedString('app.v2.favorites.notifications.add.headline'),
            text: getLocalizedString('app.v2.favorites.notifications.add.text'),
          });
        } catch (error) {
          addSystemNotification({
            type: 'error',
            headline: getLocalizedString('app.v2.favorites.notifications.add-error.headline'),
            text: getLocalizedString('app.v2.favorites.notifications.add-error.text'),
          });
        }
      } else {
        try {
          await favoritesAPI.deleteFavorite(id);
          await fetchFavorites();
          addSystemNotification({
            type: 'success',
            headline: getLocalizedString('app.v2.favorites.notifications.delete.headline'),
            text: getLocalizedString('app.v2.favorites.notifications.delete.text'),
          });
        } catch (error) {
          addSystemNotification({
            type: 'error',
            headline: getLocalizedString('app.v2.favorites.notifications.delete-error.headline'),
            text: getLocalizedString('app.v2.favorites.notifications.delete-error.text'),
          });
        }
      }
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchFavorites();
    } else {
      // reset after logout
      setFavorites(null);
      setFavoritesIds(null);
    }
  }, [isAuthenticated, activeLocale]);

  const context = {
    toggleFavorite,
    favorites,
    favoritesIds,
    fetchFavorites,
  };

  return (<FavoriteContext.Provider value={context}>{props.children}</FavoriteContext.Provider>);
};

export const useFavoriteCollectibles = () => {
  const context = useContext(FavoriteContext);

  return {
    ...context as IFavoriteContextProps,
  };


};
