import styled, { css, keyframes } from 'styled-components';
import { FC, useEffect, useState } from 'react';
import { Colors } from '../../../styles/Colors';
import { ICollectible } from '../../../services/collectible';
import { ButtonHover, SecondaryButton } from '../../../styles/Buttons';
import { SpinArrowIcon } from '../../icons/SpinArrowIcon';
import { BREAKPOINT_MD, MAX_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { useGetLocalizedString } from '../../../services/localization';
import { BuyButton } from '../collectibles/BuyButton';


const Wrapper = styled.div`
  display: flex;
  padding: 0 2.5rem 0 1.5rem;
  align-items: center;
  justify-content: space-between;

  ${MAX_BREAKPOINT_MD} {
    padding: 0 1rem;
  }

  grid-column: 1 !important;

  position: fixed;
  top: 0;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: url(.png),
    radial-gradient(
        90.16% 143.01% at 15.32% 21.04%,
        rgba(24, 95, 91, 0.8) 0%,
        rgba(110, 204, 244, 0.179167) 77.08%,
        rgba(70, 213, 204, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(53.7658px);
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0px 0px 20px 5px var(--color-primary);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 var(--color-primary);
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const PriceInCurrencies = styled.div`
  font-size: 0.8rem;
  line-height: 1rem;
`;

const ThreeDButton = styled.button<{ active: boolean }>`
  ${ButtonHover};
  display: flex;

  // gap: 0.5rem;
  padding: 0.375rem 0.625rem;
  margin: 1.5rem 0;
  border: 1px solid var(--color-primary);
  border-radius: 1rem;
  font-size: 0.875rem;
  line-height: 1rem;
  animation: ${pulse} 5s infinite ease-in;
  vertical-align: center;

  svg {
    font-size: 1.5rem;
    line-height: 1rem;
    margin: -0.25rem 0;
  }

  ${({ active }) =>
    active &&
    css`
      position: relative;

      :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        box-shadow: inset 0 0 8px var(--color-primary);
      }
    `}
`;

const BuyButtonTopEnabled = styled(SecondaryButton)`
  min-width: 10rem;
  animation: ${pulse} 0.5s 2 ease-out 1s;
`;

const BuyButtonTopDisabled = styled(SecondaryButton)`
  min-width: 10rem;
`;

const Price = styled.span<{ highlight: boolean }>`
  font-size: 1rem;

  ${({ highlight = false }) =>
    highlight &&
    css`
      color: ${Colors.primary};
    `};
`;

interface CollectiblesStickyPurchaseProps {
  toggleOverlay: () => void;
  openOverlay?: boolean;
  show3DButton?: boolean;
  collectible: ICollectible;
  activeLocale: string;
  setLoadingCallback : (isVisible: boolean) => void;
  setMintLoadingText: (text: string) => void;
}

export const CollectiblesStickyPurchase: FC<CollectiblesStickyPurchaseProps> = (props) => {
  const {
    openOverlay = false,
    toggleOverlay,
    show3DButton = false,
    collectible,
    activeLocale,
    setLoadingCallback,
    setMintLoadingText
  } = props;

  const t = useGetLocalizedString();
  const rawPrice = collectible?.subCollectibles.find((item) => item.type === 'base')?.price;
  const labelFree = collectible?.isFreemint ? t('app.v2.general.freemint-price') : t('app.v2.general.for-free');
  const isFree = !!(rawPrice === 0 || collectible?.isFreemint);
  const price = isFree
    ? labelFree
    : rawPrice?.toLocaleString(activeLocale || undefined, {
        currencyDisplay: 'code',
        style: 'currency',
        currency: 'eur',
        minimumFractionDigits: Number.isInteger(rawPrice) ? 0 : 2,
      });

  return (
    <Wrapper className='wrapper'>
      {show3DButton && (
        <ThreeDButton onClick={toggleOverlay} active={openOverlay}>
          3D <SpinArrowIcon />
        </ThreeDButton>
      )}
      <BuyButton collectible={collectible}
        activeLocale={activeLocale}
        setLoadingCallback={setLoadingCallback}
        setMintLoadingText={setMintLoadingText}
        />
    </Wrapper>
  );
};
