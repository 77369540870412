import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CollectibleList } from '../../components/common/collectibles/CollectibleItemList';
import { TextImageSection } from '../../components/common/collectibles/content/TextImageSection';
import { VideoSection } from '../../components/common/collectibles/content/VideoSection';
import CollectibleItemTeaser from '../../components/common/special/CollectibleItemTeaser';
import { PageView } from '../../components/layout/PageLayout';
import { useFavoriteCollectibles } from '../../hooks/useFavoriteCollectibles';
import { IAlbumContentItem } from '../../services/albums';
import { IUserCollectible, useCollectiblesAPI } from '../../services/collectible';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../styles/Breakpoints';
import { Headline3 } from '../../styles/FontStyles';
import CONTENT from './content.json';

const Content = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  ${BREAKPOINT_LG} {
    margin-top: 6.75rem;
  }
`;

const PageHeadline = styled.h1`
  font-size: 5.25rem;
  line-height: 1;
  margin-bottom: 5rem;
`;

const PagePunchline = styled.p``

const CollectiblesWrapper = styled.div`
  grid-column: 1 / -1;
  margin-top: 2rem;
  display: grid;

  ${BREAKPOINT_MD} {
    margin-top: 4rem;
  }

  ${BREAKPOINT_LG} {
    margin-top: 7rem;
  }
`;

const Headline = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 2rem;
  }

  ${BREAKPOINT_LG} {
    ${Headline3};
    margin-bottom: 3rem;
  }
`;

const CONTENT_SECTIONS: { [type: string]: FC<IAlbumContentItem & any> } = {
  'text-image-section': TextImageSection,
  'video-section': VideoSection,
};

export const ThreeDPrint = () => {
  const getLocalizedString = useGetLocalizedString();

  const activeLocale = useActiveLocale();
  const collectiblesAPI = useCollectiblesAPI();
  const { favoritesIds } = useFavoriteCollectibles();

  const [collectibles, setCollectibles] = useState<IUserCollectible[]>([]);

  const fetchFilteredCollectibles = () => {
    return collectiblesAPI.fetchAllCollectibles(0, 100);
  };

  const updateCollectibles = () => {
    fetchFilteredCollectibles()
      .then((res) => {
        const collectibles = res.data?.collectibles?.filter((c) => c.hasPhysicalProduct) || [];
        setCollectibles(collectibles);
      })
      .catch((err) => {
        setCollectibles([]);
      });
  };

  useEffect(() => {
    updateCollectibles();
  }, [activeLocale]);

  return (
    <PageView>
      <Content>
        <PagePunchline>{getLocalizedString('app.v2.roadmap.3d-print.page.topHeadline')}</PagePunchline>
        <PageHeadline>{getLocalizedString('app.v2.roadmap.3d-print.page.headline')}</PageHeadline>
        {CONTENT?.map((section, index) => {
          const sectionCollectible = collectibles.find(c => c.slug === section?.collectible?.slug)
          if(sectionCollectible) {
            section.collectible = sectionCollectible;
          }
          const Section = CONTENT_SECTIONS[section.type];
          if (Section) return <Section key={index} {...section} collectible={sectionCollectible} />;
        })}
      </Content>

      {collectibles?.length > 0 && (
        <CollectiblesWrapper>
          <Headline>{getLocalizedString('app.v2.roadmap.3d-print.collectibles.headline')}</Headline>
          <CollectibleList>
            {collectibles?.map((collectible) => (
              <CollectibleItemTeaser
                key={collectible.id}
                {...collectible}
                active={favoritesIds?.collectibles.includes(collectible.id) || false}
              />
            ))}
          </CollectibleList>
        </CollectiblesWrapper>
      )}
    </PageView>
  );
};
