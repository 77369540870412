import { FC, MouseEventHandler, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGetLocalizedString } from '../../../services/localization';
import { MAX_BREAKPOINT_LG, DIMEN_BREAKPOINT_LG, BREAKPOINT_LG } from '../../../styles/Breakpoints';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { bootstrapCameraKit, CameraKitSession, createMediaStreamSource, Lens, Transform2D } from "@snap/camera-kit";
import { PrimaryButton, SecondaryButton } from "src/styles/Buttons";
import { LoadingAnimation } from '../general/LoadingAnimation';
import { LinkHandler } from '../general/LinkHandler';
import { CameraIcon } from '../../icons/CameraIcon';
import { FontFamilies } from "src/styles/Fonts";

const DEVICE_WIDTH = 400;
const DEVICE_HEIGHT = 820;
const SCALE = 0.85;

const Wrapper = styled.div`
    margin: 0 auto;
    position: relative;
`;

const Left = styled.div`
    ${BREAKPOINT_LG}{
        position: absolute;
        width: 50%;
        left: 0px;
        padding-right: 2%
    }

`;

const CopyHeader = styled.p`
  
`;

const Copy = styled.p`
    text-align: justify;
    width: 100%;
`;

const RadioInputBox = styled.input.attrs({ type: 'radio' })`
    position: relative;
    height: 1.125rem;
    width: 1.125rem;
    flex-shrink: 0;
    --border-color: ${'var(--color-text-default)'};
    border: 1px solid var(--border-color);
    border-radius: 50%;
    appearance: none;
    background: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    :disabled {
        --border-color: var(--color-grey-700);
    }

    :after {
    content: '';
    position: absolute;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: var(--border-color);

    opacity: 0;
    transform: scale(.75);
    transition: opacity 25ms ease-out, transform 250ms ease-out;
    }

    :checked {
    :after {
        opacity: 1;
        transform: scale(1);
    }
    }
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
`;

const StyledSelect = styled.select<{ error?: boolean }>`
    position: relative;
    padding: 0.75rem 3rem 0.75rem 1rem;
    border: 1px solid var(--color-text-default);
    border-radius: 2px;
    appearance: none;
    caret-color: var(--color-primary);
    font-size: 1rem;
    line-height: 1;
    background: rgba(26, 26, 26, 0.4);
    backdrop-filter: blur(0.25rem);
    height: 3rem;
    top: 0;
    left: 0;
    width: 100%;
    text-overflow: ellipsis;

    :focus {
        border-color: var(--color-primary);
    }

    :disabled {
        border-color: var(--color-grey-700);
    }
`;

const SelectIcon = styled(ChevronDownIcon)`
  position: absolute;
  width: 1rem;
  height: 1rem;
  bottom: 1.5rem;
  transform: translateY(50%);
  right: 1rem;
  line-height: 1;
  pointer-events: none;
`;

const Right = styled.div`
    ${MAX_BREAKPOINT_LG}{
        display: none;
    }
    ${BREAKPOINT_LG}{
        position: absolute;
        width: ${DEVICE_WIDTH}px;
        height: ${DEVICE_HEIGHT}px;
        left: 55%;
    }
`;

const Shadow = styled.div`
    ${BREAKPOINT_LG}{
        background-image: url(images/mobile-phone-border.png);
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50px;
        background-position: top left;
        filter: drop-shadow(0px 0px 10px #08e8de);
        margin: 0 auto;
        position: absolute;
        width: ${DEVICE_WIDTH}px;
        height: ${DEVICE_HEIGHT}px;
        z-index: 0;
    }
`;

const Camera = styled.div`
    position: absolute;
    z-index: 1;
    ${BREAKPOINT_LG}{
        // background-image: url(images/noise.png);
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50px;
        margin: 0 auto;
        width: ${DEVICE_WIDTH};
        height: ${DEVICE_HEIGHT};
        filter: drop-shadow(0px 0px 10px #08e8de);
        mask-image: url('images/mobile-phone-mask.png');
    }
`;

const CameraBorder = styled.div`
    ${BREAKPOINT_LG}{
        background-image: url(images/mobile-phone-border.png);
        background-position: 50%;
        background-repeat: no-repeat;
        background-position: top left;
        margin: -10 auto;
        position: absolute;
        width: ${DEVICE_WIDTH}px;
        height: ${DEVICE_HEIGHT}px;
        z-index: 2;
    }
`;

const LoadingSpinner = styled(LoadingAnimation)`
    z-index: 0;
    position: absolute;
    width: 3rem;
    height: 3rem;
    border-width: 5px;
    top: 50%;
    left: 48%;
    ${BREAKPOINT_LG}{
        top: 35%;
        left: 42%;
    }
`;

const LoadingMessage = styled.p`
    position: absolute;
    top: 60%;
    left: 43%;
    ${BREAKPOINT_LG}{
        top: 42%;
        left: 32%;
    }
`;

const SnapchatProfileButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
    margin: 0 auto;
    ${BREAKPOINT_LG}{
        margin-left: 0;
    }
`;

const DesktopVersionHint = styled.div`    
    text-align: center;
    ${BREAKPOINT_LG}{
        display: none;
    }
`;

const CameraButtonIcon = styled(CameraIcon)`
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
`;

const CameraButton = styled.button`
    position: absolute;
    top: 47%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 9;
    font-size: 1.3em;
    &:hover{
        color: var(--color-primary);
    }
`;

interface ISnapchatCameraProps {
    groupID: string
}

const SnapchatCamera: FC<ISnapchatCameraProps> = ({
    groupID
}) => {
    const canvasContainerRef = useRef<HTMLDivElement | null>(null);
    const [lenses, setLenses] = useState<Lens[]>([]);
    const [selectedLens, setSelectedLens] = useState('0');
    const [isCameraOn, setCameraOn] = useState(false);
    const [cameraKitSession, setCameraKitSession] = useState<CameraKitSession>();
    const getLocalizedString = useGetLocalizedString();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedLens(event.target.value);
    };

    const activateCamera: MouseEventHandler<HTMLButtonElement> = () => {
        return setCameraOn(true);
      };

    const initializeLensesSelect = (lenses: Lens[]) => {
        const titlesMap = new Map<string, string>();
        titlesMap.set('Hartung Fechtmaske', 'Maximilian Hartung - Olympic Fencing Mask 2020');
        titlesMap.set('Messi Jersey 2022', 'Messi - Match Worn Jersey WC 2022');
        titlesMap.set('Maradona 1979', 'Maradona - Match Worn Jersey U20 WC 1979');
        
        return (
            <SelectWrapper className='select-wrapper'>
                <StyledSelect value={selectedLens} onChange={(e) => setSelectedLens(e.target.value)}>
                    {   
                        lenses.map((lens, index) => (
                            <option key={lens.id} value={index}>
                                {titlesMap.get(lens.name)}
                            </option>
                        ))
                    }
                </StyledSelect>
                <SelectIcon />
            </SelectWrapper>
        );
    }

    const initializeLensesRadio = (lenses: Lens[]) => {
        const titlesMap = new Map<string, string>();
        titlesMap.set('Hartung Fechtmaske', 'Maximilian Hartung - Olympic Fencing Mask 2020');
        titlesMap.set('Messi Jersey 2022', 'Messi - Match Worn Jersey WC 2022');
        titlesMap.set('Maradona 1979', 'Maradona - Match Worn Jersey U20 WC 1979');

        return (
            <>
                <br /><br />
                <p>
                    {   lenses.map((lens, index) => (
                            <>
                                <RadioInputBox
                                    value={index}
                                    checked={selectedLens === index+""}
                                    onChange={handleOptionChange}
                                />
                                <label>&nbsp;&nbsp;&nbsp;{titlesMap.get(lens.name)}</label>
                                <br />
                                <br />
                            </>
                        ))
                    }
                </p>
            </>
        );
    }

    const initializeCamera = async () => {
        console.log("initialize camera - start");
        if (isCameraOn && canvasContainerRef.current) {
            console.log("initialize camera - doing");
            canvasContainerRef.current.innerHTML = '';
            const apiToken = "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNjk2OTQxMTQyLCJzdWIiOiIyMDA0YzNiMy0xMjEyLTQyYzMtODQ5YS0yMmZiYmQ0ZGZhMjF-U1RBR0lOR343OGM0N2FiNi1mNzdmLTQ1MWEtYmQ1ZS05ZWExMWUyNzFlYjUifQ.eyI2fif4a3HOllF3dtlelEG2KENEwoMoW8yj8h6n5vA";
            const cameraKit = await bootstrapCameraKit({ 
                apiToken: apiToken,
            });
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            const source = createMediaStreamSource(stream, { transform: Transform2D.MirrorX, cameraType: 'front' });
            const session = await cameraKit.createSession();
            await session.setSource(source);
            await source.setRenderSize(DEVICE_WIDTH*SCALE, DEVICE_HEIGHT*SCALE);
            const lenses = await cameraKit.lensRepository.loadLensGroups([groupID]);
            setLenses(lenses.lenses);
            setCameraKitSession(session);
            await session.applyLens(lenses.lenses[parseInt(selectedLens)]);
            await session.play();
            canvasContainerRef.current.replaceChildren(session.output.live);
        }
    }

    const changeLens = async () => {
        // console.log("change lens - start");
        // console.log("lensesArray: '" + lenses + "'");
        // console.log("selectedLens: '" + parseInt(selectedLens) + "'");
        if(cameraKitSession){
            cameraKitSession.pause();
            await cameraKitSession.applyLens(lenses[parseInt(selectedLens)]);
            await cameraKitSession.play();
            //Damiano Giampaoli 22/12/2023 - forgot why initially added this, so just commenting for now...
            // if(canvasContainerRef.current){
            //     canvasContainerRef.current.innerHTML = '';
            //     canvasContainerRef.current.appendChild(cameraKitSession.output.live);
            // }
        }
    }

    useEffect(() => {
        console.log("use effect [] - '" + new Date + "'");
        initializeCamera();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
      
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isCameraOn]);

    useEffect(() => {
        changeLens();
    }, [selectedLens]);

    useEffect(() => {
        if (window.innerWidth >= DIMEN_BREAKPOINT_LG){
            initializeCamera();
        }

    }, [windowWidth]);

    return (
        <Wrapper>
            <Left>
                <CopyHeader>
                    <strong>{getLocalizedString('app.v2.snapchatcamera.header')}</strong>
                </CopyHeader>
                <Copy>
                    {getLocalizedString('app.v2.snapchatcamera.text')}
                </Copy>
                <br />
                {(window.innerWidth >= DIMEN_BREAKPOINT_LG) && initializeLensesRadio(lenses)}
                <br />
                <SnapchatProfileButton to={'https://www.snapchat.com/add/fanseagmbh'}>                    
                    Open in Snapchat
                </SnapchatProfileButton>
                <br/>
                <DesktopVersionHint>Or visit this page using a Laptop or Desktop device</DesktopVersionHint>
            </Left>
            <Right>
                <Shadow />
                <CameraBorder />
                {isCameraOn && 
                    <>
                        <LoadingSpinner />
                        <LoadingMessage>Loading lens...</LoadingMessage>
                    </>
                }
                {!isCameraOn &&
                    <>
                        
                        <CameraButton
                            onClick={activateCamera}>
                            <CameraButtonIcon/>
                            ACTIVATE CAMERA
                        </CameraButton>
                    </>
                }
                <Camera ref={canvasContainerRef} />
            </Right>
        </Wrapper>
    )
}

export default SnapchatCamera;