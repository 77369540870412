import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XS } from '../../styles/Breakpoints';
import { Wizard } from '../common/special/Wizard';
import { PageHeadline1 } from '../../styles/FontStyles';
import { FC, PropsWithChildren, ReactNode } from 'react';

const PageWrapper = styled.section`
  place-self: stretch;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content;
  align-content: flex-start;

  ${BREAKPOINT_LG} {
    gap: 2rem;
  }
`;

const StyledWizard = styled(Wizard)`
  grid-column: 1 / -1;
  margin: 0 2rem 1.5rem;

  ${BREAKPOINT_MD} {
    margin: 0 2rem 3rem;
  }
`;

const ContentLayout = styled.div`
  display: contents;
  position: relative;
  
  ${BREAKPOINT_LG} {
    grid-column: 1 / -1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4.5rem;
    
    & > * {
      flex-shrink: 0;
    }
  }
`;

const Headline = styled.h1`
  ${PageHeadline1};
  overflow: hidden;

  ${BREAKPOINT_LG} {
    flex: 3;
    
    white-space: normal;
    text-overflow: ellipsis;
    max-width: 100%;

    top: 35vh;
    //font-size: 7.75rem;
    //line-height: 6.75rem;
    
    font-size: 5.5rem;
    line-height: 1;
  }
  
  ${BREAKPOINT_XL} {
    //font-size: 9.75rem;
    //line-height: 8.25rem;
    font-size: 7.5rem;
    line-height: 1;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  align-self: center;
  grid-column: 1 / -1;

  ${BREAKPOINT_XS} {
    grid-column: 2 / -2;
  }

  ${BREAKPOINT_MD} {
    grid-column: 3 / -3;
  }

  ${BREAKPOINT_LG} {
    grid-column: 8 / -1;
    flex: 2;
  }
`;

interface IWizardLayoutProps {
  className?: string;
  headline: string;
  wizardStep: number;
  wizardContent: ReactNode[];
}

export const WizardLayout: FC<PropsWithChildren<IWizardLayoutProps>> = (props) => {
  const {
    className,
    headline,
    wizardStep,
    wizardContent,
    children,
  } = props;

  return (
    <PageWrapper className={className}>
      <StyledWizard active={wizardStep}>
        { wizardContent }
      </StyledWizard>

      <ContentLayout>
        <Headline dangerouslySetInnerHTML={{ __html: headline }} />

        <ContentWrapper>
          { children }
        </ContentWrapper>
      </ContentLayout>
    </PageWrapper>
  );
}