import styled from 'styled-components';
import { FC, PropsWithChildren } from 'react';

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  //--posx: 50%;
  //--posy: 50%;
  //--hyp: 0;
  //--o: 0;
  //--mx: 50%;
  //--my: 50%;

  --space: 5%;
  --angle: 133deg;
  --imgsize: 50% 42%;
`;

const CardBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  z-index: 3;
  transform: translate3d(var(--tx, 0), var(--ty, 0), 1px);
  transform-style: preserve-3d;
`;

const HoloTexture = styled(CardBase)`
  mix-blend-mode: color-dodge;
  background-image: url('/images/holo.webp'),
    repeating-linear-gradient(
      0deg,
      rgb(255, 119, 115) calc(var(--space) * 1),
      rgba(255, 237, 95, 1) calc(var(--space) * 2),
      rgba(168, 255, 95, 1) calc(var(--space) * 3),
      rgba(131, 255, 247, 1) calc(var(--space) * 4),
      rgba(120, 148, 255, 1) calc(var(--space) * 5),
      rgb(216, 117, 255) calc(var(--space) * 6),
      rgb(255, 119, 115) calc(var(--space) * 7)
    ),
    repeating-linear-gradient(
      var(--angle),
      #0e152e 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      #0e152e 10%,
      #0e152e 12%
    ),
    radial-gradient(
      farthest-corner circle at var(--mx) var(--my),
      rgba(0, 0, 0, 0.1) 12%,
      rgba(0, 0, 0, 0.15) 20%,
      rgba(0, 0, 0, 0.25) 120%
    );
  background-blend-mode: exclusion, hue, hard-light, exclusion;
  background-size: var(--imgsize), 200% 700%, 300%, 200%;
  background-position: center, 0% var(--posy), var(--posx) var(--posy), var(--posx) var(--posy);
  // filter: brightness(calc((var(--hyp) * 0.3) + 0.5)) contrast(2) saturate(1.5);
  filter: brightness(calc((var(--hyp) * 0.3) + 0.5)) contrast(2.125) saturate(3);
  opacity: calc(var(--o) + .35);

  //&::after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //
  //  mix-blend-mode: exclusion;
  //  background-image: url('/images/holo.webp'),
  //    repeating-linear-gradient(
  //      0deg,
  //      rgb(255, 119, 115) calc(var(--space) * 1),
  //      rgba(255, 237, 95, 1) calc(var(--space) * 2),
  //      rgba(168, 255, 95, 1) calc(var(--space) * 3),
  //      rgba(131, 255, 247, 1) calc(var(--space) * 4),
  //      rgba(120, 148, 255, 1) calc(var(--space) * 5),
  //      rgb(216, 117, 255) calc(var(--space) * 6),
  //      rgb(255, 119, 115) calc(var(--space) * 7)
  //    ),
  //    repeating-linear-gradient(
  //      var(--angle),
  //      #0e152e 0%,
  //      hsl(180, 10%, 60%) 3.8%,
  //      hsl(180, 29%, 66%) 4.5%,
  //      hsl(180, 10%, 60%) 5.2%,
  //      #0e152e 10%,
  //      #0e152e 12%
  //    ),
  //    radial-gradient(
  //      farthest-corner circle at var(--mx) var(--my),
  //      rgba(0, 0, 0, 0.1) 12%,
  //      rgba(0, 0, 0, 0.15) 20%,
  //      rgba(0, 0, 0, 0.25) 120%
  //    );
  //  background-blend-mode: exclusion, hue, hard-light, exclusion;
  //  background-size: var(--imgsize), 200% 400%, 195%, 200%;
  //  background-position: center, 0% var(--posy), calc(var(--posx) * -1) calc(var(--posy) * -1), var(--posx) var(--posy);
  //  filter: brightness(calc((var(--hyp) * 0.5) + 0.8)) contrast(1.6) saturate(1.4);
  //  -webkit-filter: brightness(calc((var(--hyp) * 0.5) + 0.8)) contrast(1.6) saturate(1.4);
  //}
`;

const ShineTexture = styled(CardBase)`
  //background: transparent;
  //mix-blend-mode: color-dodge;
  //filter: brightness(.85) contrast(2.75) saturate(.65);
  //background-size: cover;
  //background-position: center;
  opacity: calc(var(--o) * .2);

  --space: 5%;
  --angle: -22deg;
  --imgsize: 200% 400%;

  //clip-path: inset(2.8% 4% round 2.55% / 1.5%);
  background-image: repeating-linear-gradient(
    var(--angle),
    rgba(174, 102, 202, 0.75) calc(var(--space) * 1),
    rgba(228, 77, 72, 0.75) calc(var(--space) * 2),
    rgba(216, 197, 55, 0.75) calc(var(--space) * 3),
    rgba(124, 201, 62, 0.75) calc(var(--space) * 4),
    rgba(80, 177, 170, 0.75) calc(var(--space) * 5),
    rgba(136, 160, 255, 0.75) calc(var(--space) * 6),
    rgba(176, 105, 204, 0.75) calc(var(--space) * 7)
  );
  background-blend-mode: color-dodge;
  background-size: var(--imgsize);
  background-position: 0% calc(var(--posy) * 1), var(--posx) var(--posy);
  filter: brightness(calc((var(--hyp) * 0.3) + 0.6)) contrast(2.3) saturate(1.1);

  //&::after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //
  //  background-image: radial-gradient(
  //    farthest-corner ellipse at calc(((var(--mx)) * 0.5) + 25%) calc(((var(--my)) * 0.5) + 25%),
  //    rgb(255, 255, 255) 5%,
  //    rgba(55, 0, 55, 0.6) 25%,
  //    rgb(55, 55, 55) 90%
  //  );
  //  background-position: center;
  //  background-size: 200% 200%;
  //  filter: brightness(calc((var(--hyp) * 0.2) + 0.4)) contrast(0.85) saturate(1.1);
  //  mix-blend-mode: hard-light;
  //}
`;

const Light = styled(CardBase)`
  transform: translateZ(1.4px);
  background: radial-gradient(
    farthest-corner circle at var(--mx) var(--my),
    rgba(255, 255, 255, 0.8) 10%,
    rgba(255, 255, 255, 0.65) 20%,
    rgba(0, 0, 0, 0.5) 90%
  );
  mix-blend-mode: overlay;
  opacity: var(--o);
`;

export const HoloCard: FC<PropsWithChildren & { shineOnly?: boolean } & any> = ({ children, shineOnly = false, ...props }) => {
  return (
    <Wrapper {...props}>
      {children}
      {shineOnly ? <ShineTexture /> : <HoloTexture />}
      <Light />
    </Wrapper>
  );
};
