import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const DiscordIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 20 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.2989 1.2565C15.1203 0.669878 13.86 0.242973 12.5428 0C12.3806 0.311097 12.1921 0.730428 12.0613 1.06348C10.6608 0.839426 9.27317 0.839426 7.89833 1.06348C7.76818 0.730428 7.57472 0.311854 7.41113 0C6.09248 0.242973 4.83073 0.670638 3.65149 1.25953C1.27451 5.08276 0.630119 8.81137 0.952313 12.4863C2.52914 13.7397 4.0569 14.5004 5.55976 14.9985C5.93103 14.455 6.26176 13.8775 6.54626 13.2689C6.00358 13.0494 5.48366 12.7784 4.99219 12.4643C5.12234 12.3614 5.24966 12.2539 5.37341 12.1434C8.36918 13.6345 11.6245 13.6345 14.5848 12.1434C14.7092 12.2539 14.8365 12.3614 14.966 12.4643C14.4731 12.7799 13.9517 13.0509 13.4091 13.2704C13.6943 13.8775 14.0236 14.4565 14.3956 15C15.8991 14.5019 17.429 13.7405 19.0059 12.4863C19.3842 8.22551 18.36 4.53172 16.2989 1.2565ZM6.95451 10.2261C6.0555 10.2261 5.31794 9.33289 5.31794 8.24443C5.31794 7.15597 6.03985 6.26129 6.95451 6.26129C7.86917 6.26129 8.60745 7.15446 8.59109 8.24443C8.59251 9.33289 7.86917 10.2261 6.95451 10.2261ZM13.0037 10.2261C12.1046 10.2261 11.3671 9.33289 11.3671 8.24443C11.3671 7.15597 12.089 6.26129 13.0037 6.26129C13.9183 6.26129 14.6566 7.15446 14.6402 8.24443C14.6402 9.33289 13.9183 10.2261 13.0037 10.2261Z'
      fill='currentColor'
    />
  </svg>
);