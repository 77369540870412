import { Fansea3DStage } from '@fansea/3d-stage-library';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BuyButton } from '../../components/common/collectibles/BuyButton';
import { LoadingOverlay } from '../../components/common/general/LoadingOverlay';
import { useIsAPIInitialized } from '../../services';
import { ICollectible, useCollectiblesAPI } from '../../services/collectible';
import { useActiveLocale } from '../../services/localization';
import { BREAKPOINT_XL, BREAKPOINT_XXL } from '../../styles/Breakpoints';
import { BackButton, SecondaryButton } from '../../styles/Buttons';
import { useAlchemy } from '../../hooks/useAlchemy';
import { useAccount } from 'wagmi';

const VideoWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-grey-900);

  pointer-events: all;
  user-select: none;
  touch-action: none;

  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transform: translateY(${({ open }) => (open ? '0' : '-100%')});
  transition-property: opacity, visibility, transform;
  transition-duration: 450ms;
  transition-timing-function: ease;

  display: grid;
  grid-template-columns: minmax(var(--content-indent), 1fr) minmax(0, var(--content-max-width)) minmax(var(--content-indent), 1fr);
  grid-template-rows: min-content 1fr;

  > * {
    grid-column: 2;
  }
`;

const Styled3dStageWrapper = styled.div``;

const Styled3dStage = styled(Fansea3DStage)`
  overflow: hidden;
  width: 100vw;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const Loader = styled(LoadingOverlay)`
  height: 100%;
  pointer-events: none;
  user-select: none;
  touch-action: none;
`;

const TopBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  padding: 2rem;
`;

const ModelWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
`;

const TeaserWrapper = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
`;

const StyledVideo = styled.video`
  max-height: 1000px;
  // filter: drop-shadow(0px 0px 10px #08e8de);
  z-index: 0;
`;

const CTAWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  grid-gap: 1.5rem;

  //Damiano Giampaoli 03/09/2023 - Really horrible way to center the CTA Wrapper, sorry :)
  ${BREAKPOINT_XL} {
    left: -5%;
  }
  ${BREAKPOINT_XXL} {
    left: -10%;
  }
`;

const CTAText = styled.h1`
  font-size: clamp(3rem, 10vw, 6rem);
  line-height: clamp(3rem, 10vw, 6rem);
`;

export const CTAButton = styled(SecondaryButton)``;

export const Collectible3DStage = () => {
  const { id } = useParams();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const navigate = useNavigate();

  const activeLocale = useActiveLocale();
  const apiInitialized = useIsAPIInitialized();
  const collectiblesAPI = useCollectiblesAPI();

  const [collectible, setCollectible] = useState<ICollectible | null>(null);
  const [loading, setLoading] = useState(false);
  const [isModelLoading, setIsModelLoading] = useState<boolean>(true);

  const { address, isConnected } = useAccount();
  const [collectibleInWallet, setCollectibleInWallet] = useState(false);
  const { isCollectibleInConnectedWallet } = useAlchemy();

  const collectibleVideoBaseURL = 'https://res.cloudinary.com/fanseap/video/upload/v1677082416/fansea-app/collectibles/';
  const collectiblePosterBaseURL = 'https://res.cloudinary.com/fanseap/image/upload/v1677082416/fansea-app/common';
  const teaserVideoName = '/3D-stage-teaser.mp4';
  const teaserFallbackName = '/3D-stage-teaser-poster.jpg';
  const collectibleName = collectible?.slug;
  const teaserPosterURL = collectiblePosterBaseURL + teaserFallbackName;
  const teaserVideoURL = collectibleVideoBaseURL + collectibleName + teaserVideoName;

  useEffect(() => {}, [address]);

  useEffect(() => {
    if (apiInitialized) {
      if (id) {
        setLoading(true);
        collectiblesAPI
          .collectible(id)
          .then((response) => {
            if (response.data?.collectible.id) {
              setCollectible(response.data?.collectible || null);
            } else {
              navigate('/404');
            }
          })
          .catch((error) => {
            setCollectible(null);
            console.error('fetch collectible error:', id, error?.response?.status, error?.response?.data);

            // navigate to 404 page
            navigate('/404', { replace: true });
          })
          .finally(() => setLoading(false));
      } else {
        // navigate to 404 page
        navigate('/404', { replace: true });
      }
    }
  }, [id, activeLocale, apiInitialized]);

  useEffect(() => {
    console.log('Model loaded!');
  }, [isModelLoading]);

  const onLoad = (isLoading: boolean, progress: number) => {
    if (!isLoading && progress === 1) {
      setIsModelLoading(false);
    }
  };

  const onError = (error: Error) => {
    // console.log('3d Stage error =>', error);
  };

  const onResize = (event: Event) => {
    // console.log('Resized =>', event);
  };

  const is3DStageAlwaysOpenForCollectible = (collectibleSlug: string): boolean => {
    const collectiblesAlwaysAllowedString: string = process.env.REACT_APP_FREE_STAGE || '';
    const collectiblesAlwaysAllowed: string[] = collectiblesAlwaysAllowedString.split(',') || [];
    return collectiblesAlwaysAllowed.includes(collectibleSlug);
  };

  const isCollectibleInWallet = async () => {
    let inWallet = false;
    if (isConnected && address && collectible) {
      inWallet = await isCollectibleInConnectedWallet(address, `${collectible?.smartContractAddress}`);
    }
    setCollectibleInWallet(inWallet);
  };

  useEffect(() => {
    isCollectibleInWallet();
  }, [collectible]);

  const allow3Dstage = (collectible: ICollectible): boolean => {
    const isCollectibleOwned = (collectible?.ownedCollectiblesCount || 0) > 0;
    return is3DStageAlwaysOpenForCollectible(collectible?.slug) || isCollectibleOwned || collectibleInWallet;
  };

  return (
    <Styled3dStageWrapper>
      <TopBarWrapper>
        <BackButton />
      </TopBarWrapper>
      {collectible && !loading ? (
        <>
          {allow3Dstage(collectible) && (
            <ModelWrapper>
              <Styled3dStage
                customBackgrounds={collectible.backgrounds || []}
                blurRadius={10}
                mirrorRatio={1}
                hideMirror={false}
                modelUrl={collectible.rawImage3dUrl}
                className={'3d-stage-wrapper'}
                onError={onError}
                onLoad={onLoad}
                onResize={onResize}
                showMenu={true}
                backgroundTextureUrl='/hdr/dark.env'
                reflectionTextureUrl='/hdr/reflection.env'
                showFPS={false}
                style={{}}
              />
              <Loader visible={isModelLoading} absolute />
            </ModelWrapper>
          )}
          {!allow3Dstage(collectible) && (
            <VideoWrapper open={true}>
              <TeaserWrapper>
                <StyledVideo src={teaserVideoURL} ref={videoRef} playsInline autoPlay muted loop preload='auto' poster={teaserPosterURL} />
              </TeaserWrapper>
              <CTAWrapper>
                <CTAText>
                  GET THE FULL
                  <br />
                  3D EXPERIENCE
                </CTAText>
                <BuyButton collectible={collectible} activeLocale='' setLoadingCallback={setLoading} setMintLoadingText={(string) => {}} />
              </CTAWrapper>
            </VideoWrapper>
          )}
        </>
      ) : (
        <Loader visible absolute />
      )}
    </Styled3dStageWrapper>
  );
};
