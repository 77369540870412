import { css } from 'styled-components';

export const Colors = {
  white: 'var(--color-white)',
  primary: 'var(--color-primary)',

  background: 'var(--color-grey-900)',

  textDefault: 'var(--color-text-default)',
  textInactive: 'var(--color-grey-700)',
  textHighlight: 'var(--color-text-highlight)',
};

export const ColorTheme = css`
  :root {
    // base colors
    --color-black-900: #0F0F0F;
    --color-grey-900: #1A1A1A;
    --color-grey-800: #373737;
    --color-grey-700: #666666;
    --color-grey-600: #ADADAD;
    --color-grey-500: #D9D9D9;
    --color-grey-400: #F0F0F0;
    --color-grey-300: #FAFAFA;
    --color-white: #FFFFFF;
    --color-black: #000000;

    // signal colors
    --color-signal-red: #D14D4D;
    --color-signal-yellow: #F1CB6A;
    --color-signal-orange: #FFA800;
    --color-signal-green: #37B960;

    // font colors
    --color-text-default: var(--color-grey-300);
    --color-text-highlight: var(--color-black-900);

    // theme colors
    --color-primary: #08E8DE;
    --color-primary-dark: #185F5B;
    --color-primary-rgb: 8, 232, 222;

    // theme gradients
    --gradient-linear-100: linear-gradient(144.32deg, rgba(255, 255, 255, 0.24) 11.51%, rgba(255, 255, 255, 0) 94.95%), rgba(75, 75, 75, 0.2);
    --gradient-radial-900: radial-gradient(70.42% 70.42% at 50% 50%, #0F0F0F 2.48%, rgba(8, 232, 222, 0.34) 53.65%, rgba(255, 255, 255, 0) 100%);

    // box shadows
    --box-shadow-hover: 0px 0px 15px var(--color-primary);
  }
`;
