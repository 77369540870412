import styled from 'styled-components';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';

// Swiper
import Swiper from 'swiper';

// Styles
import 'swiper/css';

const Wrapper = styled.div`
  padding: 0 var(--content-indent);

  ${BREAKPOINT_LG} {
    padding: 0;
  }
`;

const StyledSwiper = styled.div.attrs({ className: 'swiper' })`
  margin: 0 -1rem;
  padding: 0 1rem;

  ${BREAKPOINT_MD} {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(30%, var(--min-grid-width, 330px)), 1fr));
    align-content: flex-start;
    gap: 2rem;
  }
`;

const SwiperWrapper = styled.div.attrs({ className: 'swiper-wrapper' })`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  ${BREAKPOINT_MD} {
    display: contents;
  }
`;

const SwiperSlide = styled.div.attrs({ className: 'swiper-slide' })`
  display: flex;
  flex-direction: column;
  height: auto;

  & > * {
    flex: 1;
  }

  ${BREAKPOINT_MD} {
    display: contents;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: .375rem;
  justify-content: center;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const Dot = styled.div<{ active?: boolean }>`
  height: .375rem;
  width: .375rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  opacity: ${({ active = false }) => active ? '1' : '.5'};
  filter: drop-shadow(0px 1px 9px var(--color-black));
`;

interface IResponsiveItemSlider {
  items?: Array<any>;
  children: (item: any) => ReactNode;
  className?: string;
}

const ResponsiveItemSlider: FC<IResponsiveItemSlider> = (props) => {
  const {
    items,
    children,
    className,
  } = props;

  const swiper = useRef<any>(null);
  const swiperContainer = useRef(null);
  const breakpoint = window.matchMedia('(min-width: 768px)');
  const [activeIndex, setActiveIndex] = useState(0);

  const onSlideChange = (e: any) => {
    setActiveIndex(e.realIndex);
  };

  const initSwiper = function() {
    if (swiperContainer.current) {
      swiper.current = new Swiper(swiperContainer.current, {
        breakpoints: {
          0: {
            slidesPerView: 1.125,
            spaceBetween: 16,
          },
          480: {
            slidesPerView: 1.875,
            spaceBetween: 16,
          },
          576: {
            slidesPerView: 2.125,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      });

      swiper.current.on('slideChange', onSlideChange);
    }
  };

  const checkBreakpoint = () => {
    if (breakpoint.matches === true) {
      if (swiper.current !== undefined) {
        swiper.current?.destroy(true, true);
      }
    } else {
      initSwiper();
    }
  };

  useEffect(() => {
    breakpoint.addEventListener('change', checkBreakpoint);
    checkBreakpoint();

    return () => breakpoint.removeEventListener('change', checkBreakpoint);
  }, []);

  return (
    <Wrapper className={className}>
      <StyledSwiper ref={swiperContainer}>
        <SwiperWrapper>
          {
            items?.map((item, i) => (
              <SwiperSlide key={i}>
                {
                  children(item)
                }
              </SwiperSlide>
            ))
          }
        </SwiperWrapper>
      </StyledSwiper>
      <PaginationWrapper>
        {items?.map((item, index) => (
          <Dot key={index}
               active={index === activeIndex}
               onClick={() => swiper.current.slideTo(index)}
               aria-label={`News ${index}`} />
        ))}
      </PaginationWrapper>
    </Wrapper>
  );
};

export default ResponsiveItemSlider;
