import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import CollectibleItemList from '../components/common/collectibles/CollectibleItemList';
import { LinkHandler } from '../components/common/general/LinkHandler';
import { PageLoader } from '../components/common/general/LoadingOverlay';
import { Roadmap } from '../components/common/Roadmap';
import CreatorItemList from '../components/common/special/CreatorItemList';
import Newsletter from '../components/common/special/Newsletter';
import { TeaserSlider } from '../components/common/TeaserSlider';
import { PageView } from '../components/layout/PageLayout';
import { useCollectiblesAPI } from '../services/collectible';
import { useActiveLocale, useGetLocalizedString } from '../services/localization';
import { usePublisherAPI } from '../services/publisher';
import { useDataCache } from '../state/dataCache';
import { UserState } from '../state/user';
import { BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, MAX_BREAKPOINT_MD } from '../styles/Breakpoints';
import { CircleArrowButton, CircleArrowInner, SecondaryButton } from '../styles/Buttons';
import { Headline4 } from '../styles/FontStyles';
import { GridLayout } from '../styles/Layout';
import SnapchatCameraContainer from '../components/common/special/SnapchatCameraContainer';

const Wrapper = styled.div`
  // TODO Fix complex layout css queries (main > * > *)
  grid-column: 1 / -1 !important;
  ${GridLayout}
`;

const SeeAllButton = styled(CircleArrowButton)`
  display: flex;
  width: max-content;
  font-size: 0.75rem;

  ${BREAKPOINT_XS} {
    font-size: 0.875rem;
    display: flex;
  }

  ${BREAKPOINT_SM} {
    font-size: 1rem;
    display: flex;
  }

  ${BREAKPOINT_MD} {
    display: none;
    margin: 3rem auto 0;
  }

  ${MAX_BREAKPOINT_MD} {
    text-transform: none;
    color: #fff;
    font-weight: 400;

    ${CircleArrowInner} {
      display: none;
    }
  }
`;

const Title = styled.h1`
  ${Headline4};
`;

const CallToActionWrapper = styled.div`
  margin-bottom: 2rem;
`;

const CTAButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
  margin: 1rem auto;
`;

export const Home = () => {
  const getLocalizedString = useGetLocalizedString();
  // const { allNews } = useNewsAPI();
  const { fetchLatestCollectibles, fetchMostWantedCollectibles, fetchUpcomingCollectibles } = useCollectiblesAPI();
  const { allPublishers } = usePublisherAPI();
  const userData = useRecoilValue(UserState);

  const navigate = useNavigate();
  const activeLocale = useActiveLocale();
  const [loading, setLoading] = useState(true);
  const [dataCache, setDataCache] = useDataCache();
  const {
    // news = null,
    publishers = null,
    collectiblesMostWanted = null,
    collectiblesLatest = null,
    collectiblesUpcoming = null,
  } = dataCache;

  const fetchContent = async () => {
    const dataAvailable = !!publishers && !!collectiblesMostWanted && !!collectiblesLatest;
    try {
      if (!dataAvailable) {
        setLoading(true);
      }
      const [collectiblesLatest, collectiblesMostWanted, collectiblesUpcoming, publishers] = await Promise.all([
        fetchLatestCollectibles(0, 6).catch(() => null),
        fetchMostWantedCollectibles(0, 3).catch(() => null),
        fetchUpcomingCollectibles(0, 3).catch(() => null),
        allPublishers(0, 15).catch(() => null),
      ]);
      setDataCache((state) => ({
        ...state,
        news: undefined,
        publishers: publishers?.data || undefined,
        collectiblesMostWanted: collectiblesMostWanted?.data || undefined,
        collectiblesLatest: collectiblesLatest?.data || undefined,
        collectiblesUpcoming: collectiblesUpcoming?.data || undefined,
      }));
    } catch {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [activeLocale]);

  useEffect(() => {}, []);

  return (
    <PageView >
      <Wrapper>
        <PageLoader visible={loading} />

        {/* {!loading && (
          <section>
            {userData?.username ? (
              <>
                <p>
                  {getLocalizedString('app.v2.home.greeting')} {userData?.username},{' '}
                </p>
                <Title>{getLocalizedString('app.v2.home.title-login')}</Title>
              </>
            ) : (
              <Title>{getLocalizedString('app.v2.home.title')}</Title>
            )}
          </section>
        )} */}

        <TeaserSlider />

        {!loading && publishers?.publishers && (
          <CreatorItemList
            headline={''}
            creators={publishers?.publishers}
            // link={}
          />
        )}

        {/* {
          !loading && collectiblesMostWanted?.collectibles &&
          <Tabs
            headlines={[
              getLocalizedString('app.v2.home.upcoming'),
              getLocalizedString('app.v2.home.trending'),
            ]}
            contents={[
              <CollectibleItemList
                collectibles={collectiblesUpcoming?.collectibles}
                type='small'
              />,
              <CollectibleItemList
                collectibles={collectiblesMostWanted?.collectibles}
                type='small'
              />,
            ]}
            links={[
              <LinkHandler
                to={'/collectibles'}
                state={{
                  filter: 'upcoming',
                }}>
                {getLocalizedString('app.v2.general.see-all')}
              </LinkHandler>,
              <LinkHandler
                to={'/collectibles'}
                state={{
                  filter: 'trending',
                }}>
                {getLocalizedString('app.v2.general.see-all')}
              </LinkHandler>,
            ]}
          />
        } */}

        {!loading && collectiblesLatest?.collectibles && (
          <>
            <CollectibleItemList
              headline={getLocalizedString('app.v2.home.latest-releases')}
              collectibles={collectiblesLatest?.collectibles}
              link={
                <SeeAllButton
                  to={'/collectibles'}
                  state={{
                    filter: 'latest',
                  }}>
                  {getLocalizedString('app.v2.general.see-all')}
                </SeeAllButton>
              }
            />
            <CTAButton to={'/collectibles'}>
              {getLocalizedString('app.v2.general.see-all') && getLocalizedString('app.v2.collectibles.title')}
            </CTAButton>
          </>
        )}

        <Roadmap />

        <SnapchatCameraContainer />
        
        <Newsletter />
      </Wrapper>
    </PageView>
  );
};
