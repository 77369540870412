import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { useWallet } from '../../hooks/useWallet';
import { useIsAuthenticated } from '../../services';
import { IUserAuthData, useAuthAPI } from '../../services/auth';
import { useGetLocalizedString, useLocalizedString } from '../../services/localization';
import { useDataCache } from '../../state/dataCache';
import { LanguageSwitchState } from '../../state/languageSwitch';
import { LogoutDialogOpenState } from '../../state/logout';
import { MenuOpenState } from '../../state/menu';
import { NewNotificationsState, NotificationOpenState } from '../../state/notification';
import { useAddSystemNotification } from '../../state/systemNotifications';
import { UserState } from '../../state/user';
import { BREAKPOINT_MD, BREAKPOINT_XL } from '../../styles/Breakpoints';
import { Tooltip } from '../../styles/Buttons';
import { Colors } from '../../styles/Colors';
import { ProfileHeader } from '../common/collectibles/ProfileHeader';
import { LinkHandler } from '../common/general/LinkHandler';
import Modal from '../common/general/Modal';
import ConnectButton from '../common/special/ConnectButton';
import { CloseIcon } from '../icons/CloseIcon';
import { LogInIcon } from '../icons/LogInIcon';
import { OptionsIcon } from '../icons/OptionsIcon';

const Wrapper = styled.header`
  position: sticky;
  z-index: 10;
  top: 0;

  grid-row: 1;

  display: flex;
  padding: 1.5rem var(--content-indent) 2.25rem;

  height: var(--nav-height, 5.5rem);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85%;
    z-index: -2;
    background: var(--header-background, linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0.5) 100%));
    backdrop-filter: blur(var(--header-blur, 40px));
  }

  ${BREAKPOINT_XL} {
    padding: 1.5rem var(--nav-padding) 2.25rem;
  }
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  height: 1.5rem;

  > * {
    grid-row: 1;
    grid-column: 1;
  }

  ${BREAKPOINT_MD} {
    height: 1.75rem;
  }
`;

export const HeaderButton = styled.button.attrs({ type: 'button' })`
  position: relative;
  z-index: 2;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin: -1rem;

  transition: color 250ms ease-out;
  font-size: 1.25rem;
  --tooltip-visibility: hidden;
  --tooltip-opacity: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (hover: hover) {
    :hover {
      color: ${Colors.primary};
      --tooltip-visibility: visible;
      --tooltip-opacity: 1;
    }
  }

  :focus-visible {
    color: ${Colors.primary};
  }

  :disabled {
    visibility: hidden;

    & > * {
      margin: auto;
    }
`;

const HeaderTooltip = styled(Tooltip)`
  top: calc(100% + 0.25rem);
  right: 0;
`;

const LogoWrapper = styled(LinkHandler)`
  justify-self: flex-start;
`;

const NavigationWrapper = styled.div`
  position: relative;
  height: 1rem;
  justify-self: flex-start;
`;

const NavigationButtonStyles = css<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  transform: translateX(${({ $visible }) => ($visible ? '0%' : '-10vw')});
  transition: opacity 450ms ease-out, transform 450ms ease-out, visibility 450ms ease-out;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const BackButton = styled(HeaderButton)`
  ${NavigationButtonStyles};
`;

const LogoutButton = styled(HeaderButton)`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

const NotificationButton = styled(HeaderButton)<{ newNotifications: boolean }>`
  ${({ newNotifications }) =>
    newNotifications &&
    css`
      position: relative;

      :after {
        content: '';
        position: absolute;
        bottom: 0.375rem;
        right: 0.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        background: var(--color-primary);
      }
    `}
`;

const LoginButton = styled(LogoutButton).attrs({ as: LinkHandler })<{ to: string }>``;

const IconWrapper = styled.div`
  justify-self: flex-end;
  display: flex;
  gap: 2rem;
  height: 1.5rem;
  align-items: center;

  ${BREAKPOINT_MD} {
    gap: 3rem;
    height: 1.75rem;
  }
`;

const ProfileTopWrapper = styled.div<{ active: boolean }>`
  transform: ${({ active }) => (active ? 'translateY(-1rem)' : 'translateY(-7.5rem)')};
  transition-duration: 1s;
`;

const Logo = styled.img.attrs({ src: '/assets/fansea-logo.svg', alt: 'Fansea Logo' })`
  width: auto;
  max-width: 100%;
  height: 1.5rem;

  ${BREAKPOINT_MD} {
    height: 1.75rem;
  }
`;

interface IHeaderProps {
  showBackButton: boolean;
}

export const Header: FC<IHeaderProps> = (props) => {
  const { showBackButton } = props;

  const t = useGetLocalizedString();
  const modalLogoutHeadline = useLocalizedString('app.v2.header.modal-logout.headline');
  const modalLogoutText = useLocalizedString('app.v2.header.modal-logout.text');
  const generalNo = useLocalizedString('app.v2.general.no');
  const generalYes = useLocalizedString('app.v2.general.yes');
  const [notificationOpen, setNotificationOpen] = useRecoilState(NotificationOpenState);

  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useRecoilState(MenuOpenState);
  const [logoutDialogOpen, setLogoutDialogOpen] = useRecoilState(LogoutDialogOpenState);
  const [languageSwitchOpen, setLanguageSwitchOpen] = useRecoilState(LanguageSwitchState);

  const isAuthenticated = useIsAuthenticated();
  const authInitialized = useRef(false);
  const loggedOutOnError = useRef(false);
  const authAPI = useAuthAPI();
  const [userState, setUserState] = useRecoilState(UserState);

  const isProfilePath = useMemo(() => location.pathname === '/profile', [location]);
  const [profileScrolledDown, setProfileScrolledDown] = useState<boolean>(false);
  const [{ profileData = null }] = useDataCache();

  const addSystemNotification = useAddSystemNotification();

  const web3Available = window.ethereum;

  const logout = useCallback(
    (redirectToHome = true) => {
      // TODO add loading spinner and error messages
      authAPI.logout().then(() => {
        if (redirectToHome) {
          navigate('/');
        }
      });
    },
    [authAPI, navigate],
  );

  // logout with error on registration break out
  const logoutOnRegistrationCancelled = useCallback(() => {
    if (!loggedOutOnError.current) {
      loggedOutOnError.current = true;
      logout(false);

      // add system notification registration breakup
      addSystemNotification({
        type: 'error',
        headline: t('app.v2.login.error.incomplete-registration.title'),
        text: t('app.v2.login.error.incomplete-registration.text'),
      });
    }
  }, [logout, addSystemNotification, t]);

  // returns false if user is not finalized
  const checkUserFinalized = useCallback(
    (userData?: IUserAuthData | null) => {
      const user = userData || userState || null;

      const loggedInButIncomplete = !!(user && !user?.walletAddress);

      // logout if leaving registration process
      // TODO understand and rewrite
      // if (loggedInButIncomplete && !['/login', '/registration'].some((path) => path === location.pathname)) {
      //   logoutOnRegistrationCancelled();
      // }

      return !loggedInButIncomplete;
    },
    [userState, location, logoutOnRegistrationCancelled],
  );

  const initUserState = useCallback(async () => {
    try {
      const { value } = (await authAPI.me()).data;
      setUserState(value);
      checkUserFinalized(value); // do finalization check
    } catch (err) {
      console.error('auth me', err);
      setUserState(null);
    }
  }, [setUserState, authAPI, location, checkUserFinalized]);

  // fetch auth/me (user state initialization)
  useEffect(() => {
    // TODO add loading spinner while auth initialization

    if (isAuthenticated) {
      // init auth me
      if (!authInitialized.current && location.pathname !== '/login') {
        authInitialized.current = true;
        loggedOutOnError.current = false;
        initUserState();
      } else {
        // check for registration break out on route changes after initialization
        checkUserFinalized();
      }
    } else {
      if (authInitialized.current) {
        setUserState(null);
      }
      authInitialized.current = false;
    }
  }, [isAuthenticated, location, setUserState, initUserState, checkUserFinalized]);

  // check scroll position
  useEffect(() => {
    function watchScroll() {
      const scrollPos = window.scrollY;
      if (scrollPos > 250) {
        setProfileScrolledDown(true);
      } else {
        setProfileScrolledDown(false);
      }
    }

    window.addEventListener('scroll', watchScroll);
    watchScroll();

    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  const onBackClick = useCallback(() => {
    if (notificationOpen) {
      setNotificationOpen(() => false);
    } else {
      if (menuOpen) {
        if (languageSwitchOpen) {
          setLanguageSwitchOpen(false);
        } else {
          setMenuOpen(false);
        }
      } else {
        navigate(-1);
      }
    }
  }, [menuOpen, setMenuOpen, navigate, languageSwitchOpen, setLanguageSwitchOpen, notificationOpen, setNotificationOpen]);

  const backUp = () => {
    navigate('/backup-wallet');
  };

  const editProfileVisible = location.pathname === '/profile' && !notificationOpen && !menuOpen;
  const backButtonVisible = showBackButton || (menuOpen && languageSwitchOpen) || notificationOpen;

  return (
    <Wrapper>
      <Content>
        {/* Damiano Giampaoli 20/02/2023 - disabled because IT SEEMS useles (not needed anymore in profile editing) */}
        {/* <NavigationWrapper>
          <BackButton onClick={onBackClick} $visible={showBackButton || (menuOpen && languageSwitchOpen) || notificationOpen}>
            <ArrowIcon />
          </BackButton>
        </NavigationWrapper> */}

        <LogoWrapper to={'/'}>
          <Logo />
        </LogoWrapper>

        <IconWrapper>
          {web3Available && <ConnectButton />}
          {isAuthenticated && <ProfileHeader profileData={profileData} />}
          <HeaderButtons />
        </IconWrapper>
      </Content>

      {/* logout modal */}
      <Modal
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        headline={modalLogoutHeadline}
        text={modalLogoutText}
        primaryButton={{
          title: generalNo,
          onClick: backUp,
        }}
        secondaryButton={{
          type: 'button',
          title: generalYes,
          onClick: () => logout(),
        }}
      />
    </Wrapper>
  );
};

export const HeaderButtons: FC<{ className?: string }> = ({ className }) => {
  const isAuthenticated = useIsAuthenticated();
  const { encryptedWallet } = useWallet();
  const newNotifications = useRecoilValue(NewNotificationsState);
  const setLogoutDialogOpen = useSetRecoilState(LogoutDialogOpenState);
  const [menuOpen, setMenuOpen] = useRecoilState(MenuOpenState);
  const [notificationOpen, setNotificationOpen] = useRecoilState(NotificationOpenState);

  const authAPI = useAuthAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const setLanguageSwitchOpen = useSetRecoilState(LanguageSwitchState);
  const getLocalizedString = useGetLocalizedString();

  const logout = useCallback(() => {
    // TODO add loading spinner and error messages
    authAPI.logout().then(() => {
      navigate('/');
    });
  }, [authAPI, navigate]);

  // If no wallet is connected immediately logout
  const handleLogoutClick = () => {
    if (typeof encryptedWallet === 'string') {
      setLogoutDialogOpen(true);
    } else {
      logout();
    }
  };

  const onMenuClick = useCallback(() => {
    setMenuOpen((open) => !open);
    if (menuOpen) setTimeout(() => setLanguageSwitchOpen(false), 450);
  }, [menuOpen, setMenuOpen, setLanguageSwitchOpen]);

  const onNotificationClick = useCallback(() => {
    setNotificationOpen((open) => !open);
    if (notificationOpen) setTimeout(() => setLanguageSwitchOpen(false), 450);
  }, [notificationOpen, setNotificationOpen, setLanguageSwitchOpen]);

  return (
    <IconWrapper className={className}>
      {/* {isAuthenticated && (
        <NotificationButton onClick={onNotificationClick} newNotifications={newNotifications}>
          {notificationOpen ? <BellIcon style={{ color: '#08E8DE' }} /> : <BellIcon />}
          <HeaderTooltip>{getLocalizedString('app.v2.header.tooltip.notification')}</HeaderTooltip>
        </NotificationButton>
      )} */}
      {/* {isAuthenticated && (
        <LogoutButton onClick={handleLogoutClick}>
          <LogoutIcon />
          <HeaderTooltip>{getLocalizedString('app.v2.header.tooltip.logout')}</HeaderTooltip>
        </LogoutButton>
      )} */}
      {!isAuthenticated && location.pathname !== '/login' && (
        <LoginButton to={'/login'}>
          <LogInIcon />
          <HeaderTooltip>{getLocalizedString('app.v2.header.tooltip.login')}</HeaderTooltip>
        </LoginButton>
      )}
      <HeaderButton onClick={onMenuClick}>
        {menuOpen ? <CloseIcon /> : <OptionsIcon />}
        <HeaderTooltip>{getLocalizedString('app.v2.header.tooltip.menu')}</HeaderTooltip>
      </HeaderButton>
    </IconWrapper>
  );
};
