import styled from 'styled-components';
import { FC, useEffect, useRef } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XS } from '../../../../styles/Breakpoints';
import { IAlbumContentItem } from '../../../../services/albums';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  --translate-amount: 50vw;
  transition: none;

  > * {
    margin: -1rem 0;
    transition: inherit;
  }

  > :nth-child(1) {
    margin-left: auto;
    transform: translateX(var(--translate-amount));
  }

  > :nth-child(2) {
    margin-right: 35%;
    transform: translateX(calc(var(--translate-amount) * -1));
  }

  > :nth-child(3) {
    margin-top: 1rem;
    margin-left: 25%;
    transform: translateX(clamp(0vw, calc(var(--translate-amount) * 2), 50vw));
  }

  > :nth-child(4) {
    margin-right: auto;
    margin-top: 0;
    transform: translateX(clamp(-50vw, calc(var(--translate-amount) * -2), 0vw));
  }

  ${BREAKPOINT_XS} {
    > :nth-child(1) {
      margin-left: 45%;
    }

    > :nth-child(2) {
      margin-right: 35%;
      margin-top: -2rem;
    }

    > :nth-child(3) {
      margin-top: -2rem;
    }

    > :nth-child(4) {
      margin-right: 25%;
    }
  }

  ${BREAKPOINT_MD} {
    > :nth-child(1) {
      margin-left: 15%;
    }

    > :nth-child(2) {
      margin-top: -2rem;
    }

    > :nth-child(3) {
      margin-top: -4rem;
      margin-left: 45%;
    }

    > :nth-child(4) {
      margin-right: 5%;
      margin-top: -4rem;
    }
  }

  ${BREAKPOINT_MD} {
    > :nth-child(1) {
      margin-left: 20%;
    }

    > :nth-child(2) {
      margin-top: -2rem;
    }

    > :nth-child(3) {
      margin-top: -8rem;
      margin-left: 45%;
    }

    > :nth-child(4) {
      margin-right: 20%;
    }
  }
`;

const Item = styled.div <{ rarity?: number }>`
  position: relative;
  width: 9.5rem;
  height: 9.5rem;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  background: url(.png), radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(24, 95, 91, 0.2) 0%, rgba(110, 204, 244, 0.0447917) 77.08%, rgba(70, 213, 204, 0) 100%);
  backdrop-filter: blur(40px);

  :before {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    border-radius: 100%;
    background: radial-gradient(rgba(8, 232, 222, .8) 10%, rgba(255, 255, 255, 0) 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  span {
    font-family: var(--font-family-headline);
    font-size: 2rem;
    line-height: 1;
  }

  p {
    font-size: .875rem;
  }

  ${BREAKPOINT_LG} {
    width: 16.25rem;
    height: 16.25rem;
    gap: .75rem;

    span {
      font-size: 3rem;
    }

    p {
      font-size: 1.5rem;
    }
  }
`;

interface KpiSectionProps extends IAlbumContentItem {
  items: { text: string; title: string }[];
}

export const KpiSection: FC<KpiSectionProps> = (props) => {
  const { type, items } = props;
  const sectionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const scrollHandler = () => {
      if (sectionRef.current) {
        const { top, bottom } = sectionRef.current.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const sectionHeight = sectionRef.current.scrollHeight;
        const useCSSTransition = true;

        const sectionCenter = top + ((bottom - top) / 2);
        const scrollDelta = Math.max(0, Math.min(2, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2)))) / 2;

        let translateAmount = 0;

        if (top < screenHeight && top > screenHeight * .5) {
          translateAmount = 100 - (screenHeight * .5 / top) * 100;
        } else if (bottom > 0 && top < 0) {
          translateAmount = (sectionHeight - bottom) / sectionHeight * 50;
        } else {
          translateAmount = 0;
        }

        if (useCSSTransition) {
          if (scrollDelta > .15 && scrollDelta < .85) {
            translateAmount = 0;
          } else {
            translateAmount = 50;
          }
        }

        sectionRef.current.style.setProperty('--translate-amount', `${translateAmount}vw`);
        sectionRef.current.style.transition = useCSSTransition ? 'transform .3s ease-out' : 'none';
      }
    };

    window.addEventListener('scroll', scrollHandler, { passive: true });
    scrollHandler();
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <Wrapper ref={sectionRef} data-type={type}>
      {
        items.slice(0, 4).map(({ title, text }, index) => (
          <Item key={index}>
            <span>{title}</span>
            <p>{text}</p>
          </Item>
        ))
      }
    </Wrapper>
  );
};