import styled from 'styled-components';
import { FC } from 'react';
import { PolygonIcon } from '../../icons/PolygonIcon';
import { OpenseaIcon } from '../../icons/OpenseaIcon';
import { MAX_BREAKPOINT_SM, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { LinkHandler } from '../general/LinkHandler';

const BlockchainInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${MAX_BREAKPOINT_SM} {
    font-size: 11px;
  }
`;

const CollectibleNumber = styled.div`
  margin-left: auto;
`;

const CollectibleLabelDesktop = styled.span`
  ${MAX_BREAKPOINT_SM} {
    display: none;
  }
`;

const CollectibleLabelMobile = styled.span`
  ${BREAKPOINT_SM} {
    display: none;
  }
`;


interface IBlockchainInfo {
  sku: string;
  initialSupply: Number;
  smartContractAddress: string;
}

const BlockchainInfo: FC<IBlockchainInfo> = (props) => {
  const {
    sku,
    initialSupply,
    smartContractAddress
  } = props;

  return (
    <BlockchainInfoWrapper>
      <>
        <LinkHandler to={process.env.REACT_APP_POLYGONSCAN_BASE_URL + '/token/' + smartContractAddress + '?a=' + sku}>
          <PolygonIcon style={{ width:'24px', height:'24px' }}/>
        </LinkHandler>
          &nbsp;&nbsp;
        <LinkHandler to={process.env.REACT_APP_OPENSEA_BASE_URL + '/' + smartContractAddress + '/' + sku}>
          <OpenseaIcon style={{ width:'24px', height:'24px' }}/>
        </LinkHandler>
        <CollectibleNumber>
          <><CollectibleLabelDesktop>Collectible</CollectibleLabelDesktop><CollectibleLabelMobile>#</CollectibleLabelMobile>&nbsp;{sku} / {initialSupply}</>
        </CollectibleNumber>
      </>
    </BlockchainInfoWrapper>
  );
}

export default BlockchainInfo;