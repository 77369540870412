import styled from 'styled-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { IAlbum, IUserAlbum, IUserAlbumDetails } from '../../../services/albums';
import { ShareIcon } from '../../icons/ShareIcon';
import { useGetLocalizedString } from '../../../services/localization';
import { CloseIcon } from '../../icons/CloseIcon';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, DIMEN_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { PrimaryButton } from '../../../styles/Buttons';
import { AlbumShareMenu } from './AlbumShareMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowIcon } from '../../icons/ArrowIcon';

const Wrapper = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--content-indent);
  padding: 1rem;
  background: rgba(0, 0, 0, .4);
  backdrop-filter: blur(21px);

  opacity: ${({ open }) => open ? 1 : 0};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  transform: scale(${({ open }) => open ? 1 : .8});
  pointer-events: ${({ open }) => open ? 'auto' : 'none'};
  transition: opacity 450ms ease, visibility 450ms ease, transform 450ms ease;

  ${BREAKPOINT_MD} {
    padding: 3rem;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: var(--page-grid);
`;

const GridWrapper = styled.div`
  grid-column: 2;
  display: flex;
  justify-content: center;
  padding: 0 var(--content-indent);
  align-items: center;
  gap: 1rem;
  font-weight: bold;
  width: 100%;

  ${BREAKPOINT_MD} {
    font-size: 1.5rem;
  }

  ${BREAKPOINT_LG} {
    grid-column: 3;
  }
`;

const BackButton = styled.button<{ open: boolean }>`
  position: relative;
  display: flex;
  gap: .5rem;
  align-items: center;
  opacity: ${({ open }) => open ? 1 : 0};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  transition: opacity 450ms ease, visibility 450ms ease;

  svg {
    font-size: .75rem;
  }

  p {
    display: none;
  }

  ${BREAKPOINT_MD} {
    p {
      position: absolute;
      right: -3rem;
      top: 0;
      display: initial;
      font-size: 1rem;
    }

    svg {
      font-size: 1rem;
      margin-bottom: .25rem;
    }
  }
`;

const Body = styled.div<{ open: boolean }>`
  position: relative;
  margin: 0 var(--content-indent);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 4;

  transform: scale(${({ open }) => open ? .8 : 1}) translateY(${({ open }) => open ? -10 : 0}%);
  transition: transform 450ms ease;

  > :last-child {
    bottom: -2rem;
  }

  ${BREAKPOINT_XL} {
    transform: none;

    > :last-child {
      bottom: 50%;
      transform: translate(150%, 50%);
    }
  }
`;

const ImageWrapper = styled.div`
  max-height: calc(100vh - 2 * var(--content-indent) - 2 * 3rem - 1.75rem - 3rem);
  max-width: calc((100vh - 2 * var(--content-indent) - 2 * 3rem - 1.75rem - 3rem) / 1.16);
`;

const AlbumCard = styled.div`
  position: relative;
  border-radius: 1.25rem;
  overflow: hidden;
  display: flex;
  max-height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  :before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 100%);
  }
`;

const AlbumCardFooter = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;

  ${BREAKPOINT_MD} {
    padding: 1.5rem;
  }
`;

const AlbumMetaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const Creator = styled.div`
  display: flex;
  gap: .5rem;
  align-items: center;
`;

const CreatorImageWrapper = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  padding: .25rem;
  background: linear-gradient(144.32deg, rgba(255, 255, 255, 0.24) 11.51%, rgba(255, 255, 255, 0) 94.95%), rgba(75, 75, 75, 0.2);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemCounter = styled.div`
  display: flex;
  gap: .25rem;
`;

const Footer = styled.div<{ open: boolean }>`
  position: relative;
  display: flex;
  align-self: center;

  opacity: ${({ open }) => open ? 1 : 0};
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  transition: opacity 450ms ease, visibility 450ms ease;
`;

const ShareButton = styled.div`
  position: absolute;
  top: 50%;
  right: -1rem;
  transform: translate(100%, -50%);
  display: flex;
  gap: .5rem;
  align-items: center;
  padding: .5rem;
  border-radius: 1.5rem;
  border: 1px solid var(--color-text-default);

  svg {
    font-size: 1.5rem;
    transform: translateY(-.125rem);
  }

  p {
    display: none;
    font-size: 1rem;
  }

  ${BREAKPOINT_MD} {
    padding: .5rem 1rem;
    right: -2rem;

    p {
      display: initial;
    }
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

const Title = styled.p`
  display: flex;
  gap: .5rem;
  align-items: center;
  margin: 0 auto;

  svg {
    font-size: 1.5rem;
    margin-bottom: .25rem;
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

interface ShareAlbumPopUpProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  album: IAlbum | IUserAlbum | null;
  initiallyOpen?: boolean;
}

export const ShareAlbumPopUp: FC<ShareAlbumPopUpProps> = (props) => {
  const { open, setOpen, album, initiallyOpen = false } = props;
  const getLocalizedString = useGetLocalizedString();
  const ownedCollectibles = ((album as IUserAlbumDetails)?.ownedCollectibles || 0);
  const [supportsNavigator, setSupportsNavigator] = useState<boolean>(false);
  const [openShareMenu, setOpenShareMenu] = useState<boolean>(initiallyOpen);
  const location = useLocation();
  const navigate = useNavigate();

  const albumUrl = useMemo(() => {
    return `${window.location.origin.toString()}/albums/${album?.id}`;
  }, [album, window]);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', open);
  }, [open]);

  useEffect(() => {
    // init
    if (!!navigator?.canShare) {
      setSupportsNavigator(true);
    }
    // cleanup
    return () => {
      document.body.classList.toggle('no-scroll', false);
    };
  }, []);

  const handleShareButtonClick = async (e: any) => {
    e.stopPropagation();
    if (supportsNavigator) {
      const shareData = {
        text: album?.title,
        url: location.pathname,
      };

      try {
        await navigator.share(shareData);
      } catch {
        console.log('navigator share error!');
      }
    } else {
      setOpenShareMenu(true);
    }
  };

  return (
    <Wrapper open={open} onClick={() => {
      if (window.innerWidth >= DIMEN_BREAKPOINT_MD) {
        setOpen(false);
        !initiallyOpen && setOpenShareMenu(false);
      }
    }}>
      <Header>
        <GridWrapper>
          <BackButton open={openShareMenu && !initiallyOpen} onClick={(e) => {
            e.stopPropagation();
            !initiallyOpen && setOpenShareMenu(false);
          }}>
            <ArrowIcon /><p>{getLocalizedString('app.v2.general.cta.back')}</p>
          </BackButton>

          <Title>
            <ShareIcon /><p>{getLocalizedString('app.v2.general.share')}</p>
          </Title>

          <CloseButton onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            !initiallyOpen && setOpenShareMenu(false);
          }}>
            <CloseIcon />
          </CloseButton>
        </GridWrapper>
      </Header>

      <Body onClick={(e) => e.stopPropagation()} open={openShareMenu}>
        <AlbumCard>
          <ImageWrapper>
            <img src={album?.coverImage} alt={album?.title} />
          </ImageWrapper>

          <AlbumCardFooter>
            <h4>{album?.title}</h4>
            <AlbumMetaWrapper>
              <Creator>
                <CreatorImageWrapper>
                  <img src={album?.publisherLogoUrl} alt={album?.publisherName} />
                </CreatorImageWrapper>
                <p>{album?.publisherName}</p>
              </Creator>

              <ItemCounter>
                <span>{ownedCollectibles}/{album?.collectiblesInAlbum}</span>
                {getLocalizedString('app.v2.albums.label.collectibles-count')}
              </ItemCounter>
            </AlbumMetaWrapper>
          </AlbumCardFooter>
        </AlbumCard>

        {/* <AlbumShareMenu open={openShareMenu} url={albumUrl} title={album?.title || ''} /> */}
      </Body>

      <Footer open={open && !openShareMenu}>
        <PrimaryButton onClick={(e) => {
          e.stopPropagation();
          navigate(`/albums/${album?.id}`);
        }}>
          {getLocalizedString('app.v2.album-collectibles.button.view-album')}
        </PrimaryButton>
        <ShareButton onClick={handleShareButtonClick}>
          <p>{getLocalizedString('app.v2.general.share')}</p>
          <ShareIcon />
        </ShareButton>
      </Footer>
    </Wrapper>
  );
};