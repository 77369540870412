import { FC } from 'react';
import styled from 'styled-components';
import { useGetLocalizedString } from '../../../services/localization';
import { MAX_BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { LinkHandler } from '../general/LinkHandler';

const TileContainer = styled(LinkHandler)<{ url: string; link: string }>`
  filter: drop-shadow(0px 0px 6.44px #08e8de);
  background-image: ${({ url }) => (url ? "url('" + url + "')" : "url('" + url + "')")};
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: end;
  padding: 0 1rem 0.5rem 1rem;
  aspect-ratio: 1/1;
  pointer-events: ${({ link }) => (!link ? 'none' : 'unset')}; ;
  position: relative;
`;

const TileHeadline = styled.h6`
  font-size: 1rem;
  line-height: 1rem;
  text-shadow: #000000 1px 0 10px;

  ${MAX_BREAKPOINT_MD} {
    font-size: 0.9rem;
    line-height: 1rem;
  }
`;

const ComingSoon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  // backdrop-filter: blur(2px);
`;
const ComingSoonBorder1 = styled.div`
  width: 75%;
  padding: 5px;
  border: 3px solid white;
  border-radius: 50px;
  text-align: center;
`;

const ComingSoonBorder2 = styled.div`
  background-color: white;
  border-radius: 50px;
  padding: 10px;
`;

const ComingSoonText = styled.p`
  color: black;
  font-weight: normal;
  
  ${MAX_BREAKPOINT_MD} {
    font-size: 0.6rem;
  }
`;

export const Tile: FC<any> = (props) => {
  const { imageURL, text, link, showComingSoon = false } = props;
  const t = useGetLocalizedString();
  return (
    <TileContainer to={link || ''} url={imageURL} link={link}>
      <TileHeadline>{text}</TileHeadline>
      {showComingSoon && (
        <ComingSoon>
          <ComingSoonBorder1>
            <ComingSoonBorder2>
              <ComingSoonText>{t('app.v2.home.hero.tile.coming-soon')}</ComingSoonText>
            </ComingSoonBorder2>
          </ComingSoonBorder1>
        </ComingSoon>
      )}
    </TileContainer>
  );
};
