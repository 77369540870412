import styled from 'styled-components';
import { PageView } from '../components/layout/PageLayout';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, BREAKPOINT_XS } from '../styles/Breakpoints';
import { useLocalizedString } from '../services/localization';
import { PageHeadline1 } from '../styles/FontStyles';
import { ChangePasswordForm } from '../components/views/login/ChangePassword';

const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-content: center;
  margin: auto;

  ${BREAKPOINT_LG} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4.5rem;

    & > * {
      flex-shrink: 0;
    }
  }
`;



const Headline = styled.h1`
  ${PageHeadline1};
  
  ${BREAKPOINT_LG} {
    flex: 3;
  }
`;

const Content = styled.div`
  grid-column: 1 / -1;
  align-self: center;

  ${BREAKPOINT_XS} {
    grid-column: 2 / -2;
  }

  ${BREAKPOINT_MD} {
    grid-column: 3 / -3;
  }

  ${BREAKPOINT_LG} {
    flex: 2;
  }
`;

const BoxedContent = styled(Content)`
  ${BREAKPOINT_XL} {
    max-width: 66.6%;
  }
`;

export const ChangePassword = () => {
  const title = useLocalizedString('app.v2.change-password.headline');

  return (
    <PageView>
      <Section>
        <Headline>{title}</Headline>
        <BoxedContent>
          <ChangePasswordForm />
        </BoxedContent>
      </Section>
    </PageView>
  );
};
