import { useEffect, useState } from 'react';

interface IWindowSize {
  width?: number;
  height?: number;
}
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({});

  useEffect(() => {
    const listener = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', listener);

    listener();

    return () => {
      window.removeEventListener('resize', listener);
    }
  }, []);

  return windowSize;
}