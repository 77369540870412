import styled from 'styled-components';
import Modal from '../../common/general/Modal';
import { FC, useState } from 'react';
import { useGetLocalizedString } from '../../../services/localization';
import { Input } from '../../common/forms/Input';
import FormWrapper from '../../common/forms/FormWrapper';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { useProfileAPI } from '../../../services/profile';
import { isEmail } from '../../../utils/validators';
import { ErrorNotification } from '../registration/SignUp';

const StyledInput = styled(Input)`
  padding: 0;
`;

const InputWrapper = styled.div`
  margin-bottom: 3rem;
`;

interface IForgotPasswordModalProps {
  open: boolean;
  onClose: () => void;
}

export const ForgotPasswordModal: FC<IForgotPasswordModalProps> = (props) => {
  const {
    open,
    onClose,
  } = props;

  const getLocalizedString = useGetLocalizedString();

  const profileAPI = useProfileAPI();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<{ headline: string, text: string } | null>(null);


  const submitHandler = async (form: FieldValues, e: any) => {
    setLoading(true);
    try {
      const {
        email,
      } = form;

      e.preventDefault();
      await profileAPI.resetPassword({ email });

      setSuccess(true);
      onClose();
    } catch (err: any) {
      console.log('could not reset password', err);
      setErrorState({
        headline: getLocalizedString('app.v2.reset-password-error-headline'),
        text: getLocalizedString('app.v2.reset-password-error-text'),
      });
    } finally {
      setLoading(false);
    }
  };

  const form = useForm({
    mode: 'onSubmit',
  });

  return (
    <>
      <FormWrapper form={form} onSubmit={submitHandler}>
        <Modal
          open={open}
          onClose={onClose}
          headline={getLocalizedString('app.v2.reset-password-headline')}
          text={getLocalizedString('app.v2.reset-password-text')}
          primaryButton={{
            title: getLocalizedString('app.v2.reset-password-primary-button'),
            onClick: onClose,
            type: 'button',
          }}
          secondaryButton={{
            title: loading ? <LoadingAnimation /> : getLocalizedString('app.v2.reset-password-secondary-button'),
            type: 'submit',
          }}
        >
          {
            errorState &&
            <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />
          }
          <InputWrapper>
            <StyledInput
              name={'email'}
              label={getLocalizedString('app.v2.reset-password-input-label')}
              placeholder={getLocalizedString('app.v2.reset-password-input-placeholder')}
              type={'email'}
              required
              autoComplete={'username'}
              options={{ required: true, validate: isEmail }}
              error={{
                required: getLocalizedString('app.v2.reset-password.validation.email.required'),
                validate: getLocalizedString('app.v2.reset-password.validation.email.invalid'),
              }}
            />
          </InputWrapper>
        </Modal>
      </FormWrapper>
      {
        success &&
        <Modal
          headline={getLocalizedString('app.v2.reset-password.success-headline')}
          text={getLocalizedString('app.v2.reset-password.success-text')}
          primaryButton={{
            title: getLocalizedString('app.v2.reset-password.success-primary-button'),
            onClick: () => setSuccess(false),
          }}
          open={success}
          onClose={() => setSuccess(false)}
        />
      }
    </>
  );
};
