import styled, { keyframes } from 'styled-components';
import { FC } from 'react';

const spin = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`;

const Spinner = styled.div`
  border: 3px;
  border-style: solid;
  border-radius: 100%;
  border-color: var(--spinner-color, --color-text-default);
  border-top-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
  margin: -.25rem;

  animation: ${spin} 1s infinite linear;
`;

export const LoadingAnimation: FC<{ className?: string }> = ({ className }) => (
  <Spinner className={className} />
);
