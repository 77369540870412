const FEATURE_CONFIG = [{
  name: "web3", enabled: true
}]

export const useFeatureToggle = () => {

  const isEnabled = (key: string) => {
    const feat = FEATURE_CONFIG.find(feature => feature.name === key);
    return feat?.enabled
  };

  return {
    isEnabled,
  };
};
