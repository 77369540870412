import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useAccount } from 'wagmi';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { useGetLocalizedString } from '../../services/localization';
import { BREAKPOINT_LG } from '../../styles/Breakpoints';
import { Tooltip } from '../../styles/Buttons';
import { Colors } from '../../styles/Colors';
import { AlbumsIcon } from '../icons/AlbumsIcon';
import { CollectiblesIcon } from '../icons/CollectiblesIcon';
import { FavoriteIcon } from '../icons/FavoriteIcon';
import { HomeIcon } from '../icons/HomeIcon';
import { UserIcon } from '../icons/UserIcon';
import { WalletIcon } from '../icons/WalletIcon';

const BREAKPOINT_DESKTOP = BREAKPOINT_LG;

const Wrapper = styled.nav`
  position: sticky;
  bottom: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  && {
    ${BREAKPOINT_DESKTOP} {
      grid-column: 1;
      grid-row: 1 / -1;
      height: 100%;
      max-height: 100vh;
      top: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
    linear-gradient(152.97deg, rgba(255, 255, 255, 0.235) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(21px);
  position: relative;

  border-top: 0.5rem solid var(--color-grey-900);

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: -2.25rem;
    width: 100%;
    height: 1.75rem;
    background: linear-gradient(rgba(26, 26, 26, 0), rgba(26, 26, 26, 1));
  }

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  ${BREAKPOINT_DESKTOP} {
    border-top: none;
    border-right: 0.5rem solid var(--color-grey-900);
    margin-right: -0.5rem;

    &::before {
      right: -2.25rem;
      top: 0;
      width: 1.75rem;
      height: 100%;
      background: linear-gradient(-90deg, rgba(26, 26, 26, 0), rgba(26, 26, 26, 1));
    }
  }
`;

const NavItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  ${BREAKPOINT_DESKTOP} {
    flex-direction: column;
    gap: 2.5rem;
    padding: 1.25rem 0;
  }
`;

const NavItem = styled(Link)<{ $active: boolean }>`
  position: relative;
  flex: 1;
  appearance: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  padding: 0.75rem 0.5rem 1rem;

  ${BREAKPOINT_DESKTOP} {
    padding: 2.25rem 1.75rem;
    flex: 0;
  }

  --tooltip-visibility: hidden;
  --tooltip-opacity: 0;

  @media (hover: hover) {
    :hover {
      --tooltip-visibility: visible;
      --tooltip-opacity: 1;
    }
  }

  & > div {
    margin: auto;
    z-index: 1;

    transition: transform 300ms ease-out;

    ${({ $active }) =>
      $active &&
      css`
        transform: translateY(-1.5rem);

        ${BREAKPOINT_DESKTOP} {
          transform: translateX(2.5rem);
        }
      `};
  }
`;

const NavTooltip = styled(Tooltip)<{ $active: boolean }>`
  max-width: 100%;
  word-break: break-word;
  bottom: 120%;

  ${BREAKPOINT_DESKTOP} {
    bottom: initial;
    word-break: initial;
    max-width: none;
    top: 50%;
    left: ${({ $active }) => ($active ? 130 : 80)}%;
    transform: translateY(-50%);
  }
`;

const ActiveNavBubble = styled.div`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  margin: auto;

  border: 1px solid rgba(8, 232, 222, 0.2);
  box-shadow: 0 1px 6px rgba(8, 232, 222, 0.5), inset 0 0 20px ${Colors.primary};

  &::before {
    content: '';
    position: absolute;
    width: 4.25rem;
    height: 2.125rem;
    border-radius: 0 0 4.25rem 4.25rem;
    background: var(--color-grey-900);
    backdrop-filter: blur(21px);
    left: 0;
    bottom: 1px;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: conic-gradient(from 55deg at 0 0, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2)),
      linear-gradient(145deg, rgba(255, 255, 255, 0.47), rgba(255, 255, 255, 0));
    z-index: -1;
  }
`;

const ActiveNavPositionWrapper = styled.div<{ activeIndex: number; tabsCount: number }>`
  position: absolute;
  transform: var(--translate-default);
  --position-delta: ${({ activeIndex, tabsCount }) => 100 / tabsCount / 2 + (100 / tabsCount) * activeIndex}%;
  left: var(--position-delta);

  transition: left 300ms ease-out, opacity 300ms ease-out, transform 300ms ease-out;

  // fade out if not needed
  ${({ activeIndex, tabsCount }) =>
    (activeIndex < 0 || tabsCount < 1) &&
    css`
      transform: var(--translate-default) scale(0);
      opacity: 0;
    `};

  ${BREAKPOINT_DESKTOP} {
    transition: top 300ms ease-out, opacity 300ms ease-out, transform 300ms ease-out;
    left: auto;
    top: var(--position-delta);
  }
`;

const ActiveNavBubbleWrapper = styled(ActiveNavPositionWrapper)`
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
  bottom: 100%;
  --translate-default: translate(-50%, 50%);
  transform-origin: center;

  ${BREAKPOINT_DESKTOP} {
    bottom: auto;
    --translate-default: translate(60%, -50%) rotate(90deg) scale(1.5);
  }

  display: flex;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -0.875rem;
    width: 1.125rem;
    height: 1.25rem;
    background: transparent;
    border-top-right-radius: 100%;
    box-shadow: 1px -0.5rem 0 0 var(--color-grey-900);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -0.875rem;
    width: 1.125rem;
    height: 1.25rem;
    background: transparent;
    border-top-left-radius: 100%;
    box-shadow: -1px -0.5rem 0 0 var(--color-grey-900);
  }
`;

const ActiveNavBubbleDropShadow = styled(ActiveNavPositionWrapper)`
  position: absolute;
  width: 3.75rem;
  height: 1rem;
  border-radius: 100%;
  background: #08e8de;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  bottom: 0;
  --translate-default: translate(-50%, 0);
  z-index: -1;

  ${BREAKPOINT_DESKTOP} {
    width: 1.75rem;
    height: 4.5rem;

    bottom: auto;
    left: 0;
    --translate-default: translate(-0.25rem, -50%);
    top: calc(
      50% - ${({ tabsCount }) => tabsCount / 2} * 8.5rem +
        ${({ activeIndex, tabsCount }) => 1 / tabsCount / 2 + (1 / tabsCount) * activeIndex} * ${({ tabsCount }) => tabsCount} * 8.5rem
    );
  }
`;

let NAV_ITEMS = [
  {
    icon: ($active: boolean) => <HomeIcon active={$active} />,
    to: '/',
    tooltip: 'app.v2.menu.tooltip.home',
    className: 'cy-nav-home'
  },
  {
    icon: ($active: boolean) => <CollectiblesIcon active={$active} />,
    to: '/collectibles',
    tooltip: 'app.v2.menu.tooltip.collection',
    className: 'cy-nav-collectibles'
  },
  {
    icon: ($active: boolean) => <AlbumsIcon active={$active} />,
    to: '/albums',
    tooltip: 'app.v2.menu.tooltip.albums',
    className: 'cy-nav-albums'
  },
  // {
    //   icon: ($active: boolean) => <FavoriteIcon active={$active} />,
    //   to: '/favorites',
    //   tooltip: 'app.v2.menu.tooltip.favorites',
    // },
    {
      icon: ($active: boolean) => <UserIcon active={$active} />,
      to: '/profile',
      tooltip: 'app.v2.menu.tooltip.profile',
      className: 'cy-nav-profile'
  },
  {
    icon: (active: boolean) => <WalletIcon active={active} />,
    to: '/wallet',
    tooltip: 'app.v2.general.wallet',
  },
];

export const NavBar = () => {
  const location = useLocation();
  const getLocalizedString = useGetLocalizedString();
  
  const { isConnected } = useAccount();
  const [navLinks, setNavLinks] = useState(NAV_ITEMS)
  const activeIndex = navLinks?.findIndex(({ to }) => to === location.pathname || (to.length > 1 && location.pathname.startsWith(to)));
  
  const { isEnabled } = useFeatureToggle();
  const web3Enabled = isEnabled("web3")

  useEffect(() => {
    const links = NAV_ITEMS.filter(({ to }) => {
      return !isConnected ? '/wallet' !== to : true;
    }).filter(({ to }) => {
      return isConnected ? '/profile' !== to : true;
    });

    setNavLinks(links)
  }, [isConnected])

  return (
    <Wrapper>
      <ContentWrapper>
        <NavItemsWrapper>
          {
            navLinks.filter(({ icon, to }) => {
              return web3Enabled ? (!isConnected ? to !== "/wallet" : true) : (to !== "/wallet" ? true : false);
            }).map(({ icon, to, tooltip, className }, index) => (
              <NavItem className={className} key={index} to={to} $active={index === activeIndex}>
                {icon(index === activeIndex)}
                <NavTooltip $active={index === activeIndex}>{getLocalizedString(tooltip)}</NavTooltip>
              </NavItem>
            ))
          }
          <ActiveNavBubbleWrapper
            activeIndex={activeIndex}
            // tabsCount={isConnected ? NAV_ITEMS.length : (NAV_ITEMS.length - 1)}
            tabsCount={navLinks.length}
            >
            <ActiveNavBubble />
          </ActiveNavBubbleWrapper>
        </NavItemsWrapper>
      </ContentWrapper>

      <ActiveNavBubbleDropShadow
        activeIndex={activeIndex}
        // tabsCount={isConnected ? NAV_ITEMS.length : (NAV_ITEMS.length - 1)}
        tabsCount={navLinks.length}
      />
    </Wrapper>
  );
};
