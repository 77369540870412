import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { useAccount } from 'wagmi';
import { AlbumItem } from '../components/common/albums/AlbumItem';
import { ProfileTop } from '../components/common/collectibles/ProfileTop';
import { BasicGridWrapper } from '../components/common/general/Grids';
import { PageLoader } from '../components/common/general/LoadingOverlay';
import { CollectibleItem } from '../components/common/special/CollectibleItem';
import { ClaimableTokenItem } from '../components/common/special/ClaimableTokenItem';
import { ProfileNavigation } from '../components/common/special/ProfileNavigation';
import { ProfileWelcomeTeaser } from '../components/common/special/ProfileWelcomeTeaser';
import { PageView } from '../components/layout/PageLayout';
import { useAlchemy } from '../hooks/useAlchemy';
import { useFavoriteCollectibles } from '../hooks/useFavoriteCollectibles';
import { useWallet } from '../hooks/useWallet';
import { useIsAuthenticated } from '../services';
import { useAlbumsAPI } from '../services/albums';
import { useCollectiblesAPI } from '../services/collectible';
import { useClaimableTokensAPI } from '../services/claimableToken';
import { useActiveLocale, useGetLocalizedString } from '../services/localization';
import { useProfileAPI } from '../services/profile';
import { useDataCache } from '../state/dataCache';
import { MenuOpenState } from '../state/menu';
import { NotificationOpenState } from '../state/notification';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../styles/Breakpoints';
import { BlurredButton } from '../styles/Buttons';
import { Body2, Headline4 } from '../styles/FontStyles';

const ContentWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledProfileTop = styled(ProfileTop)`
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 2rem;
  }
`;

const ResultCounter = styled.p`
  ${Body2};
  align-self: flex-end;
  margin: 1rem 0;
`;

const AppearAnim = keyframes`
  from {
    opacity: 0;
  }
`;

const BasicGridWrapperCustomCollectibles = styled(BasicGridWrapper)`
  grid-row-gap: 3.5rem;

  // appear animation
  animation: ${AppearAnim} 1s ease-out;
`;

const RedeemVoucherButton = styled(BlurredButton) <{ visible: boolean }>`
  position: fixed;
  bottom: 5rem;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  backdrop-filter: blur(6px);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: opacity 450ms ease, visibility 450ms ease;
  z-index: 1;

  ${BREAKPOINT_MD} {
    bottom: 4.5rem;
    left: var(--content-indent);
    transform: none;
  }

  ${BREAKPOINT_LG} {
    left: calc(5rem + var(--content-indent));
    bottom: 1.25rem;
  }
`;

const CollectiblesHeadline = styled.h4`
  ${Headline4};
`;

const CollectiblesHeadlineWrapper = styled.div<{ presale?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-grey-800);

  ${({ presale = false }) => (presale ? 'margin-top: 3rem;' : 'margin-top: 1.5rem;')}

  ${BREAKPOINT_MD} {
    ${({ presale = false }) => presale && 'margin-top: 6.5rem;'}
  }
`;

const CollectiblesCount = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
`;

export const Profile = () => {
  const getLocalizedString = useGetLocalizedString();
  const navigate = useNavigate();
  const activeLocale = useActiveLocale();

  const isAuthenticated = useIsAuthenticated();
  const profileAPI = useProfileAPI();
  const collectiblesAPI = useCollectiblesAPI();
  const albumsAPI = useAlbumsAPI();
  const claimableTokensAPI = useClaimableTokensAPI();

  const { favorites, favoritesIds } = useFavoriteCollectibles();
  const [dataCache, setDataCache] = useDataCache();
  const {
    profileData = null,
    userCollectiblesData = null,
    allClaimableTokens = null,
    userAlbums = null,
    userPresaleCollectiblesData = null
  } = dataCache;

  const [loading, setLoading] = useState(false);
  const [profileNavigationState, setProfileNavigationState] = useState<number>(1);
  const { encryptedWallet } = useWallet();
  const { address, isConnected, isConnecting } = useAccount();
  const menuOpen = useRecoilValue(MenuOpenState);
  const notificationOpen = useRecoilValue(NotificationOpenState);

  const { isLoading, fetchWalletItems } = useAlchemy();

  const getItems = async (wallet: string) => {
    const mergedData = await fetchWalletItems(wallet);
    setDataCache((state) => ({ ...state, userCollectiblesData: mergedData || null }));
  };

  const hasCollectibles = useMemo(() => {
    return !!userCollectiblesData?.length;
  }, [userCollectiblesData]);

  // Get Profile information
  useEffect(() => {
    if (isAuthenticated) {
      const hasCachedData = !!profileData && !!userCollectiblesData;
      if (!hasCachedData) {
        setLoading(true);
      }
      Promise.all([
        profileAPI.profile().then((response) => {
          setDataCache((state) => ({ ...state, profileData: response.data || null }));
        }),
        collectiblesAPI.userPresaleCollectibles().then((response) => {
          setDataCache((state) => ({ ...state, userPresaleCollectiblesData: response.data || null }));
        }),
        albumsAPI.fetchUserAlbums().then((response) => {
          setDataCache((state) => ({ ...state, userAlbums: response.data || null }));
        }),
        claimableTokensAPI.fetchAllClaimableTokens(0, 100).then((response) => {
          setDataCache((state) => ({ ...state, allClaimableTokens: response.data.collectibles || null }));
        }),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [isAuthenticated, activeLocale]);

  useEffect(() => {
    const walletAddress = profileData?.profile.walletAddress;
    if (walletAddress) {
      getItems(walletAddress);
    }
  }, [profileData]);

  useEffect(() => {
    if (isConnected) {
      navigate('/wallet');
    }
  }, [address]);

  const connectWallet = () => {
    navigate('/connect-wallet', { state: { redirect: '/profile' } });
  };

  const getCurrentResultAmount = useMemo(() => {
    switch (profileNavigationState) {
      case 1:
        return userCollectiblesData?.length || 0;
      case 2:
        return userAlbums?.length || 0;
      case 3:
        return favorites?.collectibles?.length || 0;
    }
  }, [profileNavigationState, userCollectiblesData, userAlbums, favorites]);

  const collectiblesCount = useMemo(() => {
    return userCollectiblesData?.length || 0;
  }, [userCollectiblesData]);

  const presaleCount = useMemo(() => {
    return userPresaleCollectiblesData?.length || 0;
  }, [userPresaleCollectiblesData]);

  const userOwnedClaimableToken = userCollectiblesData && userCollectiblesData?.length > 0 ? (
    userCollectiblesData
      .filter(collectible => allClaimableTokens?.map(t => t.id).includes(collectible.id))
      .map((claimableToken, index) => (
        <ClaimableTokenItem
          key={index}
          isProfile
          hideCreatorNameOnMobile
          {...claimableToken}
        />
      ))
  ) : (
    []
  )

  const userOwnedCollectible = userCollectiblesData && userCollectiblesData?.length > 0 ? (
    userCollectiblesData
      .filter(collectible => !allClaimableTokens?.map(t => t.id).includes(collectible.id))
      .map((collectible, index) => (
        <CollectibleItem
          key={index}
          isProfile
          hideCreatorNameOnMobile
          active={favoritesIds?.collectibles.includes(collectible.id) || false}
          {...collectible}
        />
    ))
  ) : (
    []
  )

  return (
    <>
      {profileNavigationState === 1 && (
        <RedeemVoucherButton visible={!menuOpen && !notificationOpen} onClick={() => navigate('/redeem-voucher')} buttonIcon={'plus'}>
          {getLocalizedString('app.v2.profile.cta.redeem')}
        </RedeemVoucherButton>
      )}

      <PageView>
        <ContentWrapper>
          <PageLoader visible={!isAuthenticated || loading || isLoading} />

          {isAuthenticated && !loading && !isLoading && (
            <>
              <StyledProfileTop profileData={profileData} collectiblesAvailable={hasCollectibles} onConnect={connectWallet} />

              <ProfileNavigation selected={profileNavigationState} setSelected={setProfileNavigationState} />

              {/* {profileNavigationState == 2 && (
                <ResultCounter>
                  {getCurrentResultAmount === 1
                    ? `1 ${getLocalizedString('app.v2.collectibles.result-count')}`
                    : `${getCurrentResultAmount} ${getLocalizedString('app.v2.collectibles.results-count')}`}
                </ResultCounter>
              )} */}

              {profileNavigationState === 1 && (
                <>
                  <CollectiblesHeadlineWrapper>
                    <CollectiblesHeadline>{getLocalizedString('app.v2.profile.category.collectibles')}</CollectiblesHeadline>
                    <CollectiblesCount>
                      {userOwnedCollectible?.length === 1
                        ? `1 ${getLocalizedString('app.v2.collectibles.result-count')}`
                        : `${userOwnedCollectible?.length} ${getLocalizedString('app.v2.collectibles.results-count')}`}
                    </CollectiblesCount>
                  </CollectiblesHeadlineWrapper>
                  <BasicGridWrapperCustomCollectibles gridColumns={hasCollectibles ? '2' : '1'} gridColumnsMD={'3'}>
                    {userOwnedCollectible?.length > 0 ? (
                      userOwnedCollectible
                    ) : (
                      <ProfileWelcomeTeaser />
                    )}
                  </BasicGridWrapperCustomCollectibles>
                  {userPresaleCollectiblesData && userPresaleCollectiblesData.length > 0 && (
                    <>
                      <CollectiblesHeadlineWrapper presale>
                        <CollectiblesHeadline>{getLocalizedString('app.v2.profile.category.presales')}</CollectiblesHeadline>
                        <CollectiblesCount>
                          {presaleCount === 1
                            ? `1 ${getLocalizedString('app.v2.collectibles.result-count')}`
                            : `${presaleCount} ${getLocalizedString('app.v2.collectibles.results-count')}`}
                        </CollectiblesCount>
                      </CollectiblesHeadlineWrapper>
                      <BasicGridWrapperCustomCollectibles gridColumns={'2'} gridColumnsMD={'3'}>
                        {userPresaleCollectiblesData.map((collectible, index) => (
                          <CollectibleItem
                            key={index}
                            isProfile
                            hideCreatorNameOnMobile
                            active={favoritesIds?.collectibles.includes(collectible.id) || false}
                            {...collectible}
                          />
                        ))}
                      </BasicGridWrapperCustomCollectibles>
                    </>
                  )}
                </>
              )}
              {profileNavigationState === 2 && (
                <>
                  <CollectiblesHeadlineWrapper>
                    <CollectiblesHeadline>{getLocalizedString('app.v2.profile.category.albums')}</CollectiblesHeadline>
                    <CollectiblesCount>
                      {!userAlbums
                        ? `0 ${getLocalizedString('app.v2.collectibles.results-count')}` : ``}
                      {userAlbums?.length === 1
                        ? `1 ${getLocalizedString('app.v2.collectibles.result-count')}`: ``}
                      {userAlbums && userAlbums.length > 1
                        ? `${userAlbums.length} ${getLocalizedString('app.v2.collectibles.results-count')}`: ``}
                    </CollectiblesCount>
                  </CollectiblesHeadlineWrapper>
                  <BasicGridWrapperCustomCollectibles gridColumns={'1'} gridColumnsMD={'2'}>
                    {userAlbums && userAlbums?.length > 0 ? (
                      userAlbums.map((album, index) => (
                        <AlbumItem inProfile={true} key={index} {...album} linkTo={`/profile/albums/${album.id}/collectibles`} />
                      ))
                    ) : (
                      <ProfileWelcomeTeaser />
                    )}
                  </BasicGridWrapperCustomCollectibles>
              </>
              )}
              {/* Damiano Giampaoli 17/05/2023 - Disabling favourites to make space for claimable token tab in profile */}
              {/* {profileNavigationState === 3 && (
                <BasicGridWrapperCustomCollectibles gridColumns={hasCollectibles ? '2' : '1'} gridColumnsMD={'3'}>
                  {favorites?.collectibles && favorites?.collectibles.length > 0 ? (
                    favorites.collectibles.map((collectible) => (
                      <CollectibleItem
                        key={collectible.id}
                        isProfile
                        hideCreatorNameOnMobile
                        active={favoritesIds?.collectibles.includes(collectible.id) || false}
                        {...collectible}
                      />
                    ))
                  ) : (
                    // <ProfileContentFallback />
                    <ProfileWelcomeTeaser />
                  )}
                </BasicGridWrapperCustomCollectibles>
              )} */}
              {profileNavigationState === 3 && (
                <>
                  <CollectiblesHeadlineWrapper>
                    <CollectiblesHeadline>{getLocalizedString('app.v2.profile.category.claimabletokens')}</CollectiblesHeadline>
                    <CollectiblesCount>
                      {userOwnedClaimableToken?.length === 1
                        ? `1 ${getLocalizedString('app.v2.collectibles.result-count')}`
                        : `${userOwnedClaimableToken?.length} ${getLocalizedString('app.v2.collectibles.results-count')}`}
                    </CollectiblesCount>
                  </CollectiblesHeadlineWrapper>
                  <BasicGridWrapperCustomCollectibles gridColumns={hasCollectibles ? '1' : '1'} gridColumnsMD={'3'}>
                    {userOwnedClaimableToken?.length > 0 ? (
                      userOwnedClaimableToken
                    ) : (
                      <ProfileWelcomeTeaser />
                    )}
                  </BasicGridWrapperCustomCollectibles>
                </>
              )}
            </>
          )}
        </ContentWrapper>
      </PageView>
    </>
  );
};
