import {css, keyframes} from "styled-components";

const SlideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const SlideOut = keyframes`
  to {
    transform: translateX(-100%);
    opacity: .5;
  }
`;

const Appear = keyframes`
  from {
    opacity: 0;
    transform: scale(.95);
    transform-origin: center;
  }
`;

export const PageAppear = css`
  animation: ${Appear} .5s ease-out;
`;

export const PageTransitions = css`
  .slide {
    &-transitioning {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;

      --duration: 450ms;
    }
    
    &-in {
      animation: ${SlideIn} var(--duration) ease-out;
    }
    
    &-out {
      animation: ${SlideOut} var(--duration) ease-out;
    }

    &-reverse {
      animation-direction: reverse;
    }
  }
`;