import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ILoginSession } from '../services/auth';

const { persistAtom } = recoilPersist({
  key: 'auth',
  storage: localStorage,
});

export const AuthState = atom<ILoginSession | null>({
  key: 'AuthState',
  default: null,
  effects_UNSTABLE: [
    persistAtom
  ],
});

export const useAuthState = () => useRecoilState(AuthState);
export const useAuthToken = () => useRecoilValue(AuthState)?.token;
export const useUserId = () => useRecoilValue(AuthState)?.userId;
