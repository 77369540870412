import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ICollectible } from '../../../services/collectible';
import { useFreemintAPI } from '../../../services/freemint';
import { useGetLocalizedString } from '../../../services/localization';
import { SecondaryButton } from '../../../styles/Buttons';
import { Colors } from '../../../styles/Colors';

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

const PriceInCurrencies = styled.div`
  font-size: 0.8rem;
  line-height: 1rem;
`;

const BuyButtonTop = styled(SecondaryButton)`
  min-width: 10rem;
`;

const Price = styled.span<{ highlight: boolean }>`
  font-size: 1rem;

  ${({ highlight = false }) =>
    highlight &&
    css`
      color: ${Colors.primary};
    `};
`;

interface BuyButtonProps {
  collectible: ICollectible;
  activeLocale: string;
  setLoadingCallback: (isloading: boolean) => void;
  setMintLoadingText: (text: string) => void;
}

export const BuyButton: FC<BuyButtonProps> = (props) => {
  const { collectible, activeLocale, setLoadingCallback, setMintLoadingText } = props;

  const t = useGetLocalizedString();
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);
  const freemintAPI = useFreemintAPI();
  const navigate = useNavigate();
  const [buttonTriggered, setButtonTriggered] = useState(false);
  const getLocalizedString = useGetLocalizedString();

  const rawPrice = collectible?.subCollectibles.find((item) => item.type === 'base')?.price;
  const labelFree = collectible?.isFreemint ? t('app.v2.general.freemint-price') : t('app.v2.general.for-free');
  const isFree = !!(rawPrice === 0 || collectible?.isFreemint);
  const price = isFree
    ? labelFree
    : rawPrice?.toLocaleString(activeLocale || undefined, {
        currencyDisplay: 'code',
        style: 'currency',
        currency: 'eur',
        minimumFractionDigits: Number.isInteger(rawPrice) ? 0 : 2,
      });
  const soldoutLabel = getLocalizedString('app.v2.sticker.sold-out');
  const isUpcoming = collectible?.status === 'not-buyable';

  const pricesInCurrenciesWithoutEUR = collectible?.priceInCurrencies?.filter((p) => p.currencyShortcode !== 'EUR'); // SHOW ONLY OTHER CURRENCIES NOT EUR. For now this is the workaround.
  const isAlreadyFreemintedByTheUser = collectible?.isFreemint && collectible?.ownedCollectiblesCount > 0;
  const isPurchaseForbidden =
    isUpcoming ||
    (rawPrice && rawPrice < 0) ||
    (rawPrice === 0 && !collectible.isFreemint) ||
    (rawPrice !== 0 && collectible.isFreemint) ||
    isAlreadyFreemintedByTheUser ||
    collectible?.currentSupply! <= 0;

  const buyLabel =
    (collectible?.ownedCollectiblesCount || 0) > 0
      ? getLocalizedString('app.v2.collectible.details.buy-again.button-label')
      : getLocalizedString('app.v2.collectible.details.buy.button-label');
  const buyButtonLabel = isUpcoming
    ? getLocalizedString('app.v2.general.coming-soon')
    : collectible?.isFreemint
    ? getLocalizedString('app.v2.general.freemint-cta')
    : buyLabel;

  const buyItem = () => {
    if (collectible?.id) {
      if (collectible?.isFreemint) {
        mintItemForFree();
      } else {
        navigate(`/collectibles/${collectible.id}/purchase`);
      }
    }
  };

  const mintItemForFree = () => {
    setMintLoadingText(getLocalizedString('app.v2.checkout.success.loading.text'));
    setLoadingCallback(true);
    setErrorState(null);
    freemintAPI
      .freemintCollectible({
        collectibleId: collectible?.id || '',
      })
      .then((res) => {
        setLoading(false);
        setMintLoadingText('');
        navigate('/purchase/success?product=' + collectible?.id);
      })
      .catch((err) => {
        setLoading(false);
        setMintLoadingText('');
        setButtonTriggered(false);
        if (err?.response?.status == '401') {
          navigate('/login', { state: { redirect: `/collectibles/${collectible?.id}` }, replace: true });
        } else {
          console.error('FREEMINT', err?.response?.status, err?.response?.data);
          setErrorState({
            headline: getLocalizedString('app.v2.checkout.freemint-error.morethanone.headline'),
            text: getLocalizedString('app.v2.checkout.freemint-error.morethanone.text'),
          });
        }
      });
  };

  if (isAlreadyFreemintedByTheUser) {
    return (
      <BuyButtonTop className='cy-buy-button' onClick={buyItem} disabled={true}>
        {t('app.v2.general.already-minted')}
      </BuyButtonTop>
    );
  }

  return (
    <>
      {collectible.isExclusive || collectible.isBonus || isAlreadyFreemintedByTheUser ? (
        collectible.isBonus ? (
          <Price highlight={true}>{t('app.v2.collectibles.bonus')}</Price>
        ) : (
          <Price highlight={true}>{t('app.v2.general.is-exclusive')}</Price>
        )
      ) : (
        <PriceWrapper>
          {collectible.isFreemint && <Price highlight={isFree}>{price}</Price>}
          {!collectible.isFreemint && (
            <PriceInCurrencies>
              <p>
                {collectible.price.toLocaleString(activeLocale || undefined, {
                  currencyDisplay: 'code',
                  style: 'currency',
                  currency: 'eur',
                  minimumFractionDigits: 0,
                })}
              </p>
              {pricesInCurrenciesWithoutEUR?.map((p, index) => {
                return (
                  <p key={index}>
                    {p.price.toLocaleString(activeLocale || undefined, {
                      currencyDisplay: 'code',
                      style: 'currency',
                      currency: p.currencyShortcode,
                      minimumFractionDigits: 0,
                    })}
                  </p>
                );
              })}
            </PriceInCurrencies>
          )}

          <BuyButtonTop className='cy-buy-button' onClick={buyItem} disabled={isPurchaseForbidden}>
            {collectible.currentSupply > 0 ? buyButtonLabel : soldoutLabel}
          </BuyButtonTop>
        </PriceWrapper>
      )}
    </>
  );
};
