import styled from 'styled-components';
import { Body3, Headline4 } from '../../../styles/FontStyles';
import { FC } from 'react';
import { IAlbum, IUserAlbum } from '../../../services/albums';
import { useGetLocalizedString } from '../../../services/localization';
import { LinkHandler } from '../general/LinkHandler';

const Wrapper = styled(LinkHandler)`
  position: relative;
  padding-top: 116%;

  display: flex;
  flex-direction: column;

  background: url("/images/background-epic-desktop.jpg") center no-repeat;
  background-size: cover;

  backdrop-filter: blur(40px);
  border-radius: 20px;
  overflow: hidden;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.01);
    }
  }
  transition: scale 300ms ease-out;
`;

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: .5rem;

  padding: 1.5rem 1.5rem .75rem;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 33%;

    background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #0F0F0F 100%);
    z-index: -1;
  }
`;

const Title = styled.h4`
  ${Headline4};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

const BottomLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
`;

const CreatorLogo = styled.img`
  --size: 1.75rem;
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);

  pointer-events: none;
  object-fit: contain;
  overflow: hidden;
`;

const CreatorName = styled.span`
  flex: 1;
  ${Body3};
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const CollectiblesCount = styled.span`
  flex-shrink: 0;
  ${Body3};
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

interface IAlbumItemProps{
  linkTo: string;
  inProfile?: boolean;
}

export const AlbumItem: FC<IAlbumItemProps & (IAlbum | IUserAlbum)> = (props) => {
  const {
    linkTo,
    coverImage,
    title,
    publisherName,
    publisherLogoUrl,
    collectiblesInAlbum,
    inProfile = false,
  } = props;

  const getLocalizedString = useGetLocalizedString();

  const ownedCollectibles = (props as IUserAlbum)?.ownedCollectibles || 0;

  return (
    <Wrapper to={linkTo}>
      <CoverImage src={coverImage} alt={`${title} cover`} />
      <ContentWrapper>
        <Title>{title}</Title>
        <BottomLine>
          <CreatorLogo src={publisherLogoUrl} alt={`${publisherName} logo`} />
          <CreatorName>{publisherName}</CreatorName>
          <CollectiblesCount>
            {
              ownedCollectibles > 0 &&
              `${ownedCollectibles}/`
            }
            {`${collectiblesInAlbum} ${getLocalizedString(collectiblesInAlbum === 1 ? 'app.v2.albums.label.collectibles-count-singular' : 'app.v2.albums.label.collectibles-count')}`}
          </CollectiblesCount>
        </BottomLine>
      </ContentWrapper>
    </Wrapper>
  );
};