import styled from 'styled-components';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Marquee } from './Marquee';
import Text from './Text';
import { FC, useEffect, useState } from 'react';
import { NotificationRevealItem } from './NotificationReveaIItem';
import { LinkHandler } from './LinkHandler';
import { TNotificationType } from '../../../services/notification';
import Tags from './Tags';

const Wrapper = styled(LinkHandler)<{ $read: boolean }>`
  gap: 0 1rem;
  z-index: 0;
  display: flex;
  flex-direction: row;
  background: ${({ $read }) => $read ? 'rgba(24, 95, 91, 0.3)' : 'none'};
  backdrop-filter: ${({ $read }) => $read ? 'blur(2px)' : 'none'};
  margin: 0 calc(-1 * var(--nav-padding)) 0 -3.375rem;
  padding: 1.5rem var(--nav-padding) 1rem 3.375rem;

  &:hover {
    text-decoration: none;
  }

  ${BREAKPOINT_MD} {
    gap: 0 .625rem;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(24, 95, 91, 0.6) 0%, rgba(110, 204, 244, 0.134375) 77.08%, rgba(70, 213, 204, 0) 100%);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(20px);
  border-radius: 2px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url('/images/noise.png');
    background-repeat: repeat;
    background-size: 60%;
    opacity: .08;
  }
`;

const StaticMarquee = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 .25rem;
  background: var(--color-primary);
  color: var(--color-text-highlight);
  z-index: 1;
  font-size: .75rem;
  line-height: .625rem;
  font-weight: bold;
`;

const CollectibleImage = styled.img`
  z-index: 2;
  position: absolute;
  top: -1.5rem;
`;

const StyledMarquee = styled(Marquee)`
  transform: unset;
  z-index: 1;
  height: .825rem;
  font-size: .75rem;
  line-height: 1;
`;

const Inner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 1;
`;

const CreatorIcon = styled.img`
  position: absolute;
  bottom: -.25rem;
  right: -.25rem;
  height: 1.625rem;
  width: 1.625rem;
  object-fit: contain;
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 6.25rem;
  height: 6.25rem;
  flex-shrink: 0;
`;

const UpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: .5rem;
`;

const Time = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--color-primary);
`;

const CountdownTime = styled(Time)`
  color: var(--color-signal-orange);
`;

const Label = styled(Time)<{ $highlighted?: boolean }>`
  font-weight: bold;
  white-space: nowrap;
  color: ${({ $highlighted }) => $highlighted ? 'var(--color-signal-orange)' : 'var(--color-text-default)'};
`;

const StyledText = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: .5rem;
`;

const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: .2rem
`;

const StyledTags = styled(Tags)`
  align-self: flex-start;
  font-size: .75rem;
`;

export interface INotificationItemProps {
  id: string;
  type: TNotificationType;
  item: string;
  rarity: number;
  time?: string;
  image: string;
  publisherLogoUrl: string;
  headline: string;
  createdAt: string;
  read: boolean;
  tags?: Array<string>;
}

export const NotificationItem: FC<INotificationItemProps> = (props) => {
  const {
    id,
    type,
    rarity,
    time,
    image,
    item,
    publisherLogoUrl,
    headline,
    createdAt,
    read,
    tags,
  } = props;

  return type === 'reveal' ? (
    <NotificationRevealItem {...props} />
  ) : (
    <Wrapper $read={!read} to={`/${type === 'news' ? 'news' : 'collectibles'}/${item}`}>

      <ImgWrapper>
        <Background />
        {
          rarity > 1 && <StaticMarquee>
            {Array.from(Array(6 - rarity)).map(() => <span>{'+'.repeat(rarity)}</span>)}
          </StaticMarquee>
        }
        <CreatorIcon src={publisherLogoUrl} alt={'Publisher Logo'} />
        <CollectibleImage src={image} alt={'Product img'} />
      </ImgWrapper>

      <Inner>
        {/*// TODO der key "type" wird zur unterscheidung von news und collectibles genutzt. um die jeweiligen collectibleNotifications zu unterscheiden fehlt aktuell noch ein key von der api*/}
        {/*<UpperWrapper>*/}
        {/*  {*/}
        {/*    type === 'upcoming' && <>*/}
        {/*      {NotificationCountdown(createdAt, time)}*/}
        {/*      <Label $highlighted>{'upcoming!!!'}</Label>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*  {*/}
        {/*    type === 'last-chance' && <>*/}
        {/*      <Time>{time}</Time>*/}
        {/*      <TextIconWrapper>*/}
        {/*        <Label>⏱ {'almost sold out!!!'}</Label>*/}
        {/*      </TextIconWrapper>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*  {*/}
        {/*    type === 'new-release' && <>*/}
        {/*      <Time>{time}</Time>*/}
        {/*      <TextIconWrapper>*/}
        {/*        <Label>🎉 {'New release'}</Label>*/}
        {/*      </TextIconWrapper>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*</UpperWrapper>*/}

        <StyledText content={headline} />

        {!!tags?.length && <StyledTags tags={tags} />}
      </Inner>
    </Wrapper>
  );
};

const NotificationCountdown = (createdAt: string, time = '') => {
  const [counter, setCounter] = useState(new Date(createdAt).getTime() / 1000 - new Date().getTime() / 1000);

  useEffect(() => {
    let interval = setInterval(() => {
      if (counter > 0) setCounter(state => state - 1);
      else clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  if (counter < 0) return <CountdownTime>{time}</CountdownTime>;

  return (
    <CountdownTime>
      {`${Math.floor(counter / 60 / 60)}h `}
      {`${Math.floor(counter / 60 % 60).toString().padStart(2, '0')}m `}
      {`${Math.floor(counter % 60 % 60).toString().padStart(2, '0')}s`}
    </CountdownTime>
  );
};