import styled from 'styled-components';
import { FC, PropsWithChildren } from 'react';

const Wrapper = styled.div<{ open: boolean }>`
  position: relative;
  width: var(--size, 1.5rem);
  height: var(--size, 1.5rem);

  svg {
    font-size: var(--size, 1.5rem);
    position: absolute;
    top: 0;
    left: 0;
  }

  & > * {
    transition: opacity 100ms ease-out;

    &:nth-child(1) {
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => open ? 1 : 0};
    }
  }
`;

export const OverlayIcon: FC<PropsWithChildren<{ className?: string, active?: boolean, onClick?: () => void }>> = (props) => {
  const {
    className,
    active = false,
    onClick,
    children,
  } = props;

  return (
    <Wrapper className={className} open={active || false} onClick={onClick}>
      {children}
    </Wrapper>
  );
};
