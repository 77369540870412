import styled, { css } from 'styled-components';
import { FC, useEffect, useMemo, useRef } from 'react';
import { InfoIcon } from '../../icons/notifications/InfoIcon';
import { SuccessIcon } from '../../icons/notifications/SuccessIcon';
import { AlertIcon } from '../../icons/notifications/AlertIcon';
import { ErrorIcon } from '../../icons/notifications/ErrorIcon';
import { CloseIcon } from '../../icons/CloseIcon';
import { Body3Bold, Body4 } from '../../../styles/FontStyles';
import { FontFamilies } from '../../../styles/Fonts';
import Text from './Text';
import { useScrollPage } from '../../../hooks/useScrollPage';

export const NotificationWrapper = styled.div<{ type?: 'info' | 'success' | 'alert' | 'error' }>`
  display: grid;
  z-index: 5;
  grid-template-columns: min-content 1fr min-content;
  gap: .625rem;
  grid-row-gap: .375rem;
  padding: .625rem;

  min-width: 15rem;

  --primary-gradient-color: rgba(8, 232, 222, .75);
  --secondary-gradient-color: rgba(8, 232, 222, .3);
  --hover-color: var(--color-primary);

  ${({ type }) => type === 'info' && css`
    --primary-gradient-color: rgba(255, 255, 255, .75);
    --secondary-gradient-color: rgba(255, 255, 255, .3);
  `}

  ${({ type }) => type === 'success' && css`
    --primary-gradient-color: rgba(51, 255, 133, .75);
    --secondary-gradient-color: rgba(51, 255, 133, .3);
    --hover-color: rgba(51, 255, 133, .75);
  `}

  ${({ type }) => type === 'alert' && css`
    --primary-gradient-color: rgba(255, 168, 0, .75);
    --secondary-gradient-color: rgba(255, 168, 0, .3);
    --hover-color: rgba(255, 168, 0, .75);
  `}

  ${({ type }) => type === 'error' && css`
    --primary-gradient-color: rgba(226, 10, 75, .75);
    --secondary-gradient-color: rgba(226, 10, 75, .3);
    --hover-color: rgba(226, 10, 75, .75);
  `}

  background: linear-gradient(-15deg,
  var(--primary-gradient-color) 0%,
  var(--secondary-gradient-color) 45%,
  var(--secondary-gradient-color) 60%,
  var(--primary-gradient-color) 100%);

  position: relative;
  border-radius: .25rem;
  overflow: hidden;

  *::selection {
    color: var(--hover-color);
    background: rgba(21, 21, 21, .25);
  }

  :after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: .25rem;

    height: calc(100% - 4px);
    width: calc(100% - 4px);

    background: var(--gradient-linear-100);
    backdrop-filter: blur(1rem);
    opacity: .8;
    pointer-events: none;
  }

  :before {
    z-index: -2;
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: .25rem;

    height: calc(100% - 4px);
    width: calc(100% - 4px);

    background: var(--color-grey-900);
    opacity: .9;
    pointer-events: none;
  }
`;

const IconWrapper = styled.div`
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
`;

const Headline = styled.h5`
  font-family: ${FontFamilies.default};
  ${Body3Bold};
  display: flex;
  align-items: center;
`;

const Content = styled(Text)`
  ${Body4};
  grid-column: 2 / -1;
  grid-row: 2;
`;

const CloseButton = styled.button.attrs({ type: 'button' })`
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  font-size: 1rem;

  @media (hover: hover) {
    :hover {
      color: var(--hover-color);
    }
  }
`;

export interface INotification {
  headline: string;
  text?: string;
  type?: 'info' | 'success' | 'alert' | 'error';
}

interface NotificationProps extends INotification {
  autoScroll?: boolean;
  autoScrollTop?: number;
  onClose?: () => void;
}

export const Notification: FC<NotificationProps & { className?: string }> = (props) => {
  const { headline, text, type, autoScroll, autoScrollTop, onClose, className } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollPage = useScrollPage(autoScrollTop !== undefined ? autoScrollTop : wrapperRef);

  const notificationIcon = useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoIcon />;
      case 'success':
        return <SuccessIcon />;
      case 'alert':
        return <AlertIcon />;
      case 'error':
        return <ErrorIcon />;
    }
  }, [type]);

  useEffect(() => {
    if (autoScroll) {
      scrollPage();
    }
  }, [autoScroll, scrollPage]);

  return (headline || text) ? (
    <NotificationWrapper ref={wrapperRef} type={type} className={className}>
      <IconWrapper>
        {notificationIcon}
      </IconWrapper>
      <Headline>
        {headline}
      </Headline>

      {
        onClose &&
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      }

      {
        text &&
        <Content content={text} />
      }
    </NotificationWrapper>
  ) : <></>;
};