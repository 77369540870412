import {AxiosResponse} from 'axios';
import {useAPIInstance} from './index';

interface IRedeemVoucherData {
    voucher: string;
}

interface IRedeemVoucherResponse {
    success: boolean;
}

export const useVoucherAPI = () => {
    const apiInstance = useAPIInstance();

    const redeemVoucher = (voucherData: IRedeemVoucherData) => {
        return apiInstance.patch<IRedeemVoucherData, AxiosResponse<IRedeemVoucherResponse>>('/redeemable-vouchers', voucherData);
    };

    return {
        redeemVoucher,
    };
};
