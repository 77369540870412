import styled from 'styled-components';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { ICollectible, useCollectiblesAPI } from '../../../services/collectible';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';
import { LoadingAnimation } from '../../../components/common/general/LoadingAnimation';
import { useNavigate, useParams } from 'react-router-dom';
import { WizardLayout } from '../../../components/layout/WizardLayout';
import { ShippingAddress } from '../../../components/views/checkout/ShippingAddress';
import { DeliveryIcon } from '../../../components/icons/DeliveryIcon';
import { PaymentIcon } from '../../../components/icons/PaymentIcon';
import { ICurrentShippingAddressResponse, IProfileAddressData, useProfileAPI } from '../../../services/profile';
import { useAuthToken } from '../../../state/auth';
import { useWallet } from '../../../hooks/useWallet';
import { PageView } from '../../../components/layout/PageLayout';
import Modal from '../../../components/common/general/Modal';

import { PrimaryButton } from '../../../styles/Buttons';
import QRCodeScanner from '../../../components/common/special/QRCodeScanner';
import SeedPhraseInput from '../../../components/common/special/SeedPhraseInput';
import { Divider } from '../../../components/common/special/WalletBackup';
import { Body2, Body2Bold, Body3, PageViewHeadline } from '../../../styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { Notification } from '../../../components/common/general/Notification';
import { useRecoilValue } from 'recoil';
import { UserState } from '../../../state/user';
import { useScrollPage } from '../../../hooks/useScrollPage';
import { ethers } from 'ethers';
import { client } from '../../../web3/client';
import { Colors } from '../../../styles/Colors';
import { usePurchaseState } from 'src/state/purchase';
import { useDataCache } from '../../../state/dataCache';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  place-self: center;
  justify-items: center;

  ${BREAKPOINT_LG} {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem;
  }
`;

const Wrapper = styled.div`
  ${Body3};
  display: grid;
  row-gap: 1rem;
  grid-column: 7 / -1;
`;

const Headline = styled.div`
  ${Body2Bold};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: center;

  ${BREAKPOINT_SM} {
    margin-top: 2.5rem;
    flex-direction: row;
    gap: 2rem;
  }

  ${BREAKPOINT_LG} {
    margin-top: 5rem;
  }
`;

const StyledButton = styled(PrimaryButton)`
  ${BREAKPOINT_SM} {
    min-width: unset;
    flex: 1;
  }
`;

const ErrorNotification = styled(Notification).attrs({ type: 'error' })`
  margin-bottom: 0.5rem;
`;

const AlertNotification = styled(Notification).attrs({ type: 'alert' })`
  margin-bottom: 0.5rem;
`;

const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-bottom: 3.25rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 3rem;
  }
`;

const ReviewContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ReviewContentTitle = styled.h6`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
`;

const ReviewContentText = styled.p`
  ${Body2};

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const EditButton = styled.button.attrs({ type: 'button' })`
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;

  ${Body2};
  font-weight: 600;
  color: ${Colors.primary};
  text-decoration: underline;
  text-transform: capitalize;

  ${BREAKPOINT_LG} {
    ${Body3};
    font-weight: 600;
  }
`;

const Separator = styled.div`
  height: 0.5px;
  background-color: ${Colors.textDefault};
`;

type TOldQRCode = {
  myfsqr: string;
  userEmail: string;
};

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 5.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClaimableTokenCheckout = () => {
  const { id } = useParams();

  const getLocalizedString = useGetLocalizedString();
  const activeLocale = useActiveLocale();

  const authToken = useAuthToken();
  const { encryptedWallet } = useWallet();
  const navigate = useNavigate();

  const collectiblesAPI = useCollectiblesAPI();
  const { getCurrentShippingAddress, profile } = useProfileAPI();

  const [purchaseState, setPurchaseState] = usePurchaseState();

  const [collectible, setCollectible] = useState<ICollectible | null>(null);
  const [loading, setLoading] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const fetchUsersShippingAddress = async (id: any) => {
    const shippingAddress = (await getCurrentShippingAddress())?.data?.address || {};
    setPurchaseState({
      collectibleId: id,
      shippingAddress,
      paymentMethod: 'stripe',
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      collectiblesAPI.collectible(id)
        .then(async (res) => {
          setLoading(false);
          setCollectible(res.data?.collectible || null);

          const shippingAddress = (await getCurrentShippingAddress())?.data?.address || {};
          setPurchaseState({
            collectibleId: id,
            shippingAddress,
            paymentMethod: 'stripe',
          });
        })
        .catch((err) => {
          setLoading(false);
          setCollectible(null);
          console.error("COLLECTIBLE CHECKOUT", id, err?.response?.status, err?.response?.data);

          if (err?.response?.status !== 401) {
            // navigate to 404 page
            navigate('/404', { replace: true });
          }
        });
    } else {
      // navigate to 404 page
      navigate('/404', { replace: true });
    }
  }, [id, activeLocale])

  useEffect(() => {
    if (!authToken) {
      navigate('/login', { state: { redirect: `/collectibles/${id}/purchase` }, replace: true });
      return;
    }
  }, [authToken, encryptedWallet]);

  const step = useMemo((): 'shipping-address' | 'claim-token' => {
    switch (checkoutStep) {
      case 0:
        return 'shipping-address';
      case 1:
        return 'claim-token';
    }
    return 'shipping-address';
  }, [checkoutStep, purchaseState]);

  const title = useMemo(() => {
    switch (step) {
      case 'shipping-address':
        return getLocalizedString('app.v2.checkout.shipping-address.title');
      case 'claim-token':
        return getLocalizedString('app.v2.claimabletokens.claim.title');
    }
  }, [step, getLocalizedString]);
  return (
    <PageView>
      <WizardLayout
        headline={title}
        wizardStep={checkoutStep}
        wizardContent={[<PaymentIcon key={'payment'} />, <DeliveryIcon key={'delivery'} />].filter(Boolean)}>
        {step === 'claim-token' && (
          <CollectibleUtilityClaim onSubmit={() => navigate(`/purchase/success?product=${id}&type=claimableToken`)} />
        )}
        {step === 'shipping-address' && (
          <ShippingAddress onSubmit={() => setCheckoutStep(1)} />
        )}

        {loading && (
          <LoadingWrapper>
            <LoadingAnimation />
          </LoadingWrapper>
        )}

        <Modal
          open={showCancelDialog}
          onClose={() => setShowCancelDialog(false)}
          headline={getLocalizedString('app.v2.checkout.cancel-dialog.headline')}
          text={getLocalizedString('app.v2.checkout.cancel-dialog.text')}
          primaryButton={{
            title: getLocalizedString('app.v2.general.no'),
          }}
          secondaryButton={{
            title: getLocalizedString('app.v2.general.yes'),
            onClick: () => navigate(`/collectibles/${id}`),
          }}
        />
      </WizardLayout>
    </PageView>
  );
};

interface ICollectibleUtilityClaimProps {
  onSubmit: () => void;
}

const CollectibleUtilityClaim: FC<ICollectibleUtilityClaimProps> = (props) => {
  const { importWallet, importWalletFromPrivateKey } = useWallet();
  const navigate = useNavigate();
  const t = useGetLocalizedString();

  const [loading, setLoading] = useState<boolean>(false);
  const [seedPhrase, setSeedPhrase] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);
  const [alertState, setAlertState] = useState<{ headline: string; text: string } | null>(null);
  const userData = useRecoilValue(UserState);
  const scrollPage = useScrollPage();
  const { id: collectibleId, sku } = useParams();
  const [dataCache, setDataCache] = useDataCache();
  const { userCollectiblesData = null } = dataCache;

  const isValid = useMemo(() => {
    const seedPhraseWords = seedPhrase.split(' ');
    return (seedPhraseWords.length === 12 && !seedPhraseWords.some((w) => !(!!w && w !== ' '))) || !!privateKey;
  }, [seedPhrase, privateKey]);
  const getLocalizedString = useGetLocalizedString();

  const onScanSuccess = (value: string) => {
    const isOldQR = value.includes('myfsqr');

    if (isOldQR) {
      const { myfsqr: privateKey } = JSON.parse(value) as TOldQRCode;
      setPrivateKey(privateKey);
      setAlertState({
        headline: t('app.v2.wallet-import.old-qr.headline'),
        text: t('app.v2.wallet-import.old-qr.text'),
      });
      scrollPage();
    } else {
      setSeedPhrase(value);
    }
  };

  const cancel = useCallback(() => {
    navigate(`/profile`);
  }, [navigate]);

  const connect = async () => {
    setLoading(true);
    setErrorState(null);

    try {
      const wallet = seedPhrase ? importWallet(seedPhrase) : importWalletFromPrivateKey(privateKey);
      if (userData?.walletAddress === wallet.address && userCollectiblesData) {
        const contractABI = require('../../../web3/contracts-abi/claimableContract.json');
        const connectedWallet = wallet.connect(client.provider);
        const tokenToClaim = userCollectiblesData.filter((collectible) => collectible.id === collectibleId);
        const { smartContractAddress } = tokenToClaim.filter((t) => t.sku === sku)[0];
        const contract = new ethers.Contract(smartContractAddress, contractABI.abi, connectedWallet);
        const gasLimit = await contract.estimateGas.claimToken(parseInt(sku || '-1'));
        const gasPrice = (await client.provider.getGasPrice()).mul(2);
        const tx = await contract.claimToken(parseInt(sku || '-1'), {
          gasLimit: gasLimit,
          gasPrice: gasPrice,
        });
        await tx.wait();
        console.log(tx);
      } else {
        throw Error();
      }
      props.onSubmit?.();
    } catch (err) {
      console.log(err);
      if (('' + err).startsWith('Error: invalid mnemonic')) {
        setErrorState({
          headline: t('app.v2.claimabletokens.claim.verifyseedphrase.errors.invalidmnemonic.title'),
          text: t('app.v2.claimabletokens.claim.verifyseedphrase.errors.invalidmnemonic.text'),
        });
      } else {
        setErrorState({
          headline: t('app.v2.claimabletokens.claim.verifyseedphrase.errors.generic.title'),
          text: t('app.v2.claimabletokens.claim.verifyseedphrase.errors.generic.text'),
        });
      }
      scrollPage();
    } finally {
      setLoading(false);
    }
  };

  const saveAddress = async () => {};

  return (
    <Wrapper>
      <Headline>{t('app.v2.claimabletokens.claim.headline')}</Headline>
      <p>{t('app.v2.claimabletokens.claim.headline')}</p>
      {alertState && <AlertNotification {...alertState} onClose={() => setAlertState(null)} />}
      {errorState && <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />}
      <SeedPhraseInput value={seedPhrase} onChange={setSeedPhrase} />
      <Divider>{t('app.v2.general.or')}</Divider>
      <QRCodeScanner onScanSuccess={onScanSuccess} />
      <ButtonWrapper>
        <StyledButton onClick={cancel}>{t('app.v2.wallet-import.cancel')}</StyledButton>
        <StyledButton disabled={!isValid} $highlighted={isValid} onClick={connect}>
          {loading ? <LoadingAnimation /> : t('app.v2.claimabletokens.claim.verifyseedphrase')}
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
};
