import { ICollectible } from '../../../services/collectible';
import { FC } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  position: absolute;
  width: 35%;
  top: 8%;
`;

interface ICollectibleImageProps {
  collectible: ICollectible;
}

export const UtilityImage: FC<ICollectibleImageProps> = (props) => {
  const {
    collectible,
  } = props;

  // get image url (animated or not)
  const url = 'https://res.cloudinary.com/fanseap/image/upload/fansea-app/collectibles/' + collectible.slug + '/utility-image.' + 'png';
  
  const showUtility = (collectible?.tags?.length > 0) && (collectible?.tags[0] !== "");
  return (
    <ImageWrapper>
      {
        (showUtility) && <img className="utility" src={url} alt="" />
      }
    </ImageWrapper>
  );
};