import styled from 'styled-components';
import Modal from '../../common/general/Modal';
import { FC, useMemo, useState } from 'react';
import { useGetLocalizedString } from '../../../services/localization';
import { Input } from '../../common/forms/Input';
import FormWrapper from '../../common/forms/FormWrapper';
import { FieldValues, useForm } from 'react-hook-form';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { useProfileAPI } from '../../../services/profile';
import { ErrorNotification, Indicator, Indicators } from '../registration/SignUp';

const StyledInput = styled(Input)`
  //
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-bottom: 3rem;
`;

interface IResetPasswordWithTokenModalProps {
  open: boolean;
  onClose: () => void;
  onBackButton?: () => void;
  onSuccess?: () => void;
  token: string;
}

export const ResetPasswordWithTokenModal: FC<IResetPasswordWithTokenModalProps> = (props) => {
  const {
    open,
    onClose,
    onBackButton = onClose,
    onSuccess = onClose,
    token,
  } = props;

  const getLocalizedString = useGetLocalizedString();

  const profileAPI = useProfileAPI();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<{ headline: string, text: string } | null>(null);

  const form = useForm({
    mode: 'onChange',
  });

  const [hasLowerCase, setHasLowerCase] = useState<boolean | undefined>();
  const [hasCapital, setHasCapital] = useState<boolean | undefined>();
  const [hasSymbol, setHasSymbol] = useState<boolean | undefined>();
  const [hasNumber, setHasNumber] = useState<boolean | undefined>();
  const [hasLength, setHasLength] = useState<boolean | undefined>();

  const validatePassword = (password: string) => {
    const hasLowerCase = /[a-z]/g.test(password);
    const hasCapital = /[A-Z]/g.test(password);
    const hasSymbol = /[^\w\d]/g.test(password);
    const hasNumber = /[0-9]/g.test(password);
    const hasLength = /.{8,}$/g.test(password);

    setHasLowerCase(hasLowerCase);
    setHasCapital(hasCapital);
    setHasSymbol(hasSymbol);
    setHasNumber(hasNumber);
    setHasLength(hasLength);

    return (
      hasLowerCase &&
      hasCapital &&
      hasSymbol &&
      hasNumber &&
      hasLength
    );
  };

  const isValid = useMemo(() => {
    return form.formState.isValid;
  }, [form.formState.isValid]);

  const submitHandler = async (form: FieldValues, e: any) => {
    setLoading(true);
    try {
      const {
        password,
      } = form;

      e.preventDefault();
      await profileAPI.createPassword({ password, token });

      setSuccess(true);
    } catch (err: any) {
      console.log('could not reset password', err);
      setErrorState({
        headline: getLocalizedString('app.v2.reset-password-from-token.error-headline'),
        text: getLocalizedString('app.v2.reset-password-from-token.error-text'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormWrapper form={form} onSubmit={submitHandler}>
        <Modal
          open={open}
          onClose={onClose}
          headline={getLocalizedString('app.v2.reset-password-from-token.headline')}
          text={getLocalizedString('app.v2.reset-password-from-token.text')}
          primaryButton={{
            title: getLocalizedString('app.v2.reset-password-from-token.primary-button'),
            onClick: (e) => {
              e.preventDefault();
              onBackButton();
            },
            type: 'button',
          }}
          secondaryButton={{
            title: loading ? <LoadingAnimation /> : getLocalizedString('app.v2.reset-password-from-token.secondary-button'),
            type: 'submit',
            disabled: !isValid || loading,
          }}
        >
          {
            errorState &&
            <ErrorNotification {...errorState} onClose={() => setErrorState(null)} />
          }
          <InputWrapper>
            <StyledInput
              name={'password'}
              label={getLocalizedString('app.v2.reset-password-from-token.input-label')}
              placeholder={getLocalizedString('app.v2.reset-password-from-token.input-placeholder')}
              type={'password'}
              required
              autoComplete={'new-password'}
              options={{
                required: true,
                validate: validatePassword,
                onChange: ({ target }) => {
                  !target.value && validatePassword(target.value)
                }
              }}
              error={{
                required: getLocalizedString('app.v2.reset-password-from-token.validation.password.required'),
                validate: getLocalizedString('app.v2.reset-password-from-token.validation.password.invalid'),
              }}
            />

            <Indicators>
              <Indicator value={hasLowerCase}>{getLocalizedString('app.v2.registration.password-criteria.lowercase')}</Indicator>
              <Indicator value={hasCapital}>{getLocalizedString('app.v2.registration.password-criteria.capital')}</Indicator>
              <Indicator value={hasSymbol}>{getLocalizedString('app.v2.registration.password-criteria.symbol')}</Indicator>
              <Indicator value={hasNumber}>{getLocalizedString('app.v2.registration.password-criteria.number')}</Indicator>
              <Indicator value={hasLength}>{getLocalizedString('app.v2.registration.password-criteria.length')}</Indicator>
            </Indicators>
          </InputWrapper>
        </Modal>
      </FormWrapper>
      <Modal
        headline={getLocalizedString('app.v2.reset-password-from-token.success-headline')}
        text={getLocalizedString('app.v2.reset-password-from-token.success-text')}
        secondaryButton={{
          title: getLocalizedString('app.v2.reset-password-from-token.success-primary-button'),
          onClick: (e) => {
            e.preventDefault();
            setSuccess(false);
            onSuccess();
          },
        }}
        open={success}
        onClose={() => {
          setSuccess(false);
          onClose();
        }}
      />
    </>
  );
};
