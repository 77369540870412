import styled from 'styled-components';
import { useLocalizedString } from '../../../services/localization';
import { PrimaryButton, SecondaryButton } from '../../../styles/Buttons';
import { Headline4 } from '../../../styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

const ImageWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: center;
  margin-bottom: 3rem;
  height: 15rem;
  width: 15rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  ${BREAKPOINT_LG} {
    grid-column: 1 / 7;
    align-self: center;
    height: 24rem;
    width: 24rem;
  }

  ${BREAKPOINT_XL} {
    height: 30rem;
    width: 30rem;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  max-width: 26rem;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  height: min-content;
  grid-column: 1 / -1;
  gap: 1rem;
  padding: 2rem 1.5rem 1.5rem;
  background: var(--gradient-linear-100);
  border: 1.5px solid white;
  border-radius: 2px;

  ${BREAKPOINT_LG} {
    max-width: none;
    grid-column: 7 / -1;
    align-self: center;
    background: none;
    border: none;
  }
`;

const Headline = styled.h4`
  ${Headline4};
  text-transform: uppercase;
`;

const Text = styled.p`
  margin-bottom: 1rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 2rem;
  }
`;

interface SuccessViewProps {
  reset: () => void;
}

export const SuccessView: FC<SuccessViewProps> = ({ reset }) => {
  const successHeadline = useLocalizedString('app.v2.redeem-voucher.success.headline');
  const successText = useLocalizedString('app.v2.redeem-voucher.success.text');
  const redeemAgainText = useLocalizedString('app.v2.redeem-voucher.success.redeem-again-button');
  const goHomeText = useLocalizedString('app.v2.redeem-voucher.success.home-button');

  const navigate = useNavigate();

  return (
    <>
      <ImageWrapper>
        <img src="/images/rocket.png" aria-hidden="true" alt="" />
      </ImageWrapper>

      <MessageWrapper>
        <Headline>{successHeadline}</Headline>
        <Text>{successText}</Text>

        <PrimaryButton onClick={reset}>{redeemAgainText}</PrimaryButton>
        <SecondaryButton onClick={() => navigate('/')}>{goHomeText}</SecondaryButton>
      </MessageWrapper>
    </>
  );
};