import styled from 'styled-components';
import { PageView } from '../../components/layout/PageLayout';
import { FC, useEffect, useState } from 'react';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_XL, MAX_BREAKPOINT_LG } from '../../styles/Breakpoints';
import { useNavigate, useParams } from 'react-router-dom';
import AspectRatio from '../../components/common/general/AspectRatio';
import { Headline3 } from '../../styles/FontStyles';
import { BackButton, CircleArrowButton } from '../../styles/Buttons';
import { Wysiwyg } from '../../components/common/general/Wysiwyg';
import { IPublisher, usePublisherAPI } from '../../services/publisher';
import { CollectibleList } from '../../components/common/collectibles/CollectibleItemList';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { IUserCollectible, useCollectiblesAPI } from '../../services/collectible';
import CollectibleItemTeaser from '../../components/common/special/CollectibleItemTeaser';
import { useFavoriteCollectibles } from '../../hooks/useFavoriteCollectibles';
import { LoadingOverlay } from '../../components/common/general/LoadingOverlay';
import { useCloudinary } from '../../hooks/useCloudinary';
import { FanseaImage } from '../../components/common/FanseaImage';
import { TextImageSection } from '../../components/common/collectibles/content/TextImageSection';
import { VideoSection } from '../../components/common/collectibles/content/VideoSection';
import { IAlbumContentItem } from '../../services/albums';

const Wrapper = styled.section`
  && {
    grid-column: 1 / -1;
    padding: 0 var(--content-indent);

    ${BREAKPOINT_LG} {
      grid-column: 3;
      padding: 0;
    }
  }
`;

const InnerWrapper = styled.div`
  ${BREAKPOINT_MD} {
    display: grid;
    gap: 0 2rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const StyledBackButton = styled(BackButton)`
  grid-column: 1 / -1;
  width: max-content;
  margin-bottom: 0.5rem;
`;

const ImageWrapper = styled(AspectRatio)`
  grid-column: 1 / -1;
  --aspect--ratio: 2 / 1;
  position: relative;
  margin: 0 calc(-1 * var(--content-indent)) 2.5rem;
  max-height: 60vh;
  min-width: 100%;

  ${BREAKPOINT_MD} {
    margin: 0 0 2.5rem;
  }
`;

const Image = styled(FanseaImage)`
  border-radius: 2px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.4));
`;

const PublisherIcons = styled.div`
  grid-column: 1 / -1;
  position: relative;
  display: flex;
  justify-content: center;

  ${BREAKPOINT_LG} {
    grid-column: 1 / 3;
    display: block;
  }

  ${BREAKPOINT_XL} {
    grid-column: 1 / 2;
  }
`;

const PublisherIconsImage = styled.img`
  filter: drop-shadow(0px 4px 30px var(--color-primary-dark));

  ${MAX_BREAKPOINT_LG} {
    position: absolute;
    height: 6rem;
    width: 6rem;
    top: -7rem;
  }
`;

const ContentWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;

  ${BREAKPOINT_LG} {
    grid-column: 3 / -1;
  }

  ${BREAKPOINT_XL} {
    grid-column: 2 / -2;
  }
`;

const CreatorContentBlocks = styled.div`
margin: 5rem 0 0 0;
`

const PublisherName = styled.div`
  margin-bottom: 0.375rem;

  ${BREAKPOINT_LG} {
    margin-bottom: 0.25rem;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;

  ${BREAKPOINT_LG} {
    ${Headline3};
  }
`;

const CollectiblesWrapper = styled.div`
  grid-column: 1 / -1;
  margin-top: 2rem;
  display: grid;

  ${BREAKPOINT_MD} {
    margin-top: 4rem;
  }

  ${BREAKPOINT_LG} {
    margin-top: 7rem;
  }
`;

const Headline = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 2rem;
  }

  ${BREAKPOINT_LG} {
    ${Headline3};
    margin-bottom: 3rem;
  }
`;

const StyledCircleArrowButton = styled(CircleArrowButton)`
  margin-top: 2rem;
`;

const PAGE_SIZE = 3;

const CONTENT_SECTIONS: { [type: string]: FC<IAlbumContentItem & any> } = {
  'text-image-section': TextImageSection,
  'video-section': VideoSection,
};

export const PublishersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getLocalizedString = useGetLocalizedString();
  const activeLocale = useActiveLocale();

  const { favoritesIds } = useFavoriteCollectibles();
  const { publishersById } = usePublisherAPI();
  const { collectiblesByPublisher } = useCollectiblesAPI();

  const [creator, setCreator] = useState<IPublisher>();
  const [collectibles, setCollectibles] = useState<IUserCollectible[]>([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [totalCollectiblesCount, setTotalCollectiblesCount] = useState<number | null>(0);

  const fetchData = async (replace: boolean = true) => {
    if (id) {
      try {
        // reset data on replace
        if (replace) {
          setCollectibles([]);
          setTotalCollectiblesCount(null);
        }
        setLoading(true);

        // fetch publisher
        if (replace) {
          const creatorResponse = await publishersById(id);
          if (creatorResponse?.data?.value) {
            setCreator(creatorResponse.data.value);
          }
        }

        const collectiblesResponse = await collectiblesByPublisher(id, limit - PAGE_SIZE, PAGE_SIZE);
        setCollectibles((state) => [...(replace ? [] : state || []), ...(collectiblesResponse?.data?.collectibles || [])]);
        setTotalCollectiblesCount(collectiblesResponse?.data?.totalRecords || 0);
      } catch {
        // navigate to 404 page
        navigate('/404', { replace: true });
      } finally {
        setLoading(false);
      }
    } else {
      // navigate to 404 page
      navigate('/404', { replace: true });
    }
  };

  useEffect(() => {
    if (limit === PAGE_SIZE) {
      fetchData();
    } else {
      setLimit(PAGE_SIZE);
    }
  }, [id, activeLocale]);

  useEffect(() => {
    fetchData(limit === PAGE_SIZE);
  }, [limit]);

  const showMore = () => {
    setLimit((limit) => limit + PAGE_SIZE);
  };

  return (
    <PageView>
      {creator && (
        <Wrapper>
          <InnerWrapper>
            <StyledBackButton />
            <ImageWrapper>
              <Image altText={creator?.name} imageUrl={creator?.coverImageUrl} />
            </ImageWrapper>
            <PublisherIcons>
              <PublisherIconsImage src={creator?.logoUrl} alt={creator?.name} />
            </PublisherIcons>
            <ContentWrapper>
              <PublisherName>{creator?.name}</PublisherName>
              <Title>{creator?.title}</Title>
              <Wysiwyg dangerouslySetInnerHTML={{ __html: creator?.description || '' }} />
              
              <CreatorContentBlocks>{creator.content &&
              creator.content?.map((section, index) => {
                const Section = CONTENT_SECTIONS[section.type];
                if (Section) return <Section key={index} {...section} />;
                else return <></>;
              })}</CreatorContentBlocks>
            </ContentWrapper>
            
            {collectibles.length > 0 && (
              <CollectiblesWrapper>
                <Headline>{getLocalizedString('app.v2.publisher.collectiblesByPublisher')}</Headline>
                <CollectibleList>
                  {collectibles?.map((collectible) => (
                    <CollectibleItemTeaser
                      key={collectible.id}
                      {...collectible}
                      active={favoritesIds?.collectibles.includes(collectible.id) || false}
                    />
                  ))}
                </CollectibleList>
                {(totalCollectiblesCount || 0) > (collectibles?.length || 0) && (
                  <StyledCircleArrowButton onClick={showMore}>{getLocalizedString('app.v2.general.load-more')}</StyledCircleArrowButton>
                )}
              </CollectiblesWrapper>
            )}
          </InnerWrapper>
        </Wrapper>
      )}
      {loading && <LoadingOverlay visible inline />}
    </PageView>
  );
};
