import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const SuccessIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.99609 12L9.49609 16.5L18.9961 7.5'
      stroke='#33FF85'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);