import { FC } from 'react';
import { OverlayIcon } from './OverlayIcon';

export const FavoriteIconOutline: FC<{ className?: string }> = ({ className }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M11.5965 1.01231C11.7615 0.677846 12.2385 0.677847 12.4035 1.01231L15.0158 6.30533C15.2998 6.88086 15.8489 7.27977 16.484 7.37206L22.3252 8.22083C22.6943 8.27447 22.8417 8.72806 22.5746 8.9884L18.3479 13.1084C17.8883 13.5564 17.6786 14.2019 17.7871 14.8344L18.7849 20.652C18.8479 21.0196 18.4621 21.3 18.1319 21.1264L12.9074 18.3797C12.3393 18.0811 11.6607 18.0811 11.0926 18.3797L5.86806 21.1264C5.53793 21.3 5.15208 21.0196 5.21513 20.652L6.21293 14.8344C6.32142 14.2019 6.1117 13.5564 5.65211 13.1084L1.42539 8.9884C1.1583 8.72806 1.30568 8.27447 1.67478 8.22083L7.51598 7.37206C8.15111 7.27977 8.70017 6.88086 8.98421 6.30533L11.5965 1.01231Z'
      stroke='currentColor' strokeWidth='1.5' />
  </svg>
);

export const FavoriteIconFilled: FC<{ className?: string }> = ({ className }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M11.5965 1.01231C11.7615 0.677846 12.2385 0.677847 12.4035 1.01231L15.0158 6.30533C15.2998 6.88086 15.8489 7.27977 16.484 7.37206L22.3252 8.22083C22.6943 8.27447 22.8417 8.72806 22.5746 8.9884L18.3479 13.1084C17.8883 13.5564 17.6786 14.2019 17.7871 14.8344L18.7849 20.652C18.8479 21.0196 18.4621 21.3 18.1319 21.1264L12.9074 18.3797C12.3393 18.0811 11.6607 18.0811 11.0926 18.3797L5.86806 21.1264C5.53793 21.3 5.15208 21.0196 5.21513 20.652L6.21293 14.8344C6.32142 14.2019 6.1117 13.5564 5.65211 13.1084L1.42539 8.9884C1.1583 8.72806 1.30568 8.27447 1.67478 8.22083L7.51598 7.37206C8.15111 7.27977 8.70017 6.88086 8.98421 6.30533L11.5965 1.01231Z'
      fill='currentColor' stroke='currentColor' strokeWidth='1.5' />
  </svg>
);

export const FavoriteIcon: FC<{ className?: string, active?: boolean, onClick?: () => void }> = (props) => (
  <OverlayIcon {...props}>
    <FavoriteIconOutline />
    <FavoriteIconFilled />
  </OverlayIcon>
);
