import { AxiosResponse } from 'axios';
import { useAPIInstance } from './index';

interface IFreemintCollectibleData {
  collectibleId: string;
}

interface IFreemintCollectibleResponse {
  success: boolean;
  message: string;
}

export const useFreemintAPI = () => {
  const apiInstance = useAPIInstance();

  const freemintCollectible = (freemintCollectibleData: IFreemintCollectibleData) => {
    const url = `/free-mint`;
    return apiInstance.post<IFreemintCollectibleData, AxiosResponse<IFreemintCollectibleResponse>>(url, freemintCollectibleData, { baseURL: process.env.REACT_APP_API_BASE_URL });
  };

  return {
    freemintCollectible
  };
};