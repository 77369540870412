import { atom, useRecoilState } from 'recoil';
import { IProfileAddressData } from '../services/profile';

interface ICurrency {
  currencyShortcode: string;
  currencySymbol: string;
  price: number;
}
interface IPurchaseState {
  collectibleId: string;
  shippingAddress: Partial<IProfileAddressData>;
  paymentMethod: 'stripe' | 'paypal' | undefined;
  currency?: ICurrency;
  walletAddress?: string;
  promoCode?: string;
  discountedPrice?: number;
}

const PurchaseState = atom<IPurchaseState | null>({
  key: 'PurchaseState',
  default: null,
});

export const usePurchaseState = () => useRecoilState(PurchaseState);
