import { FC } from 'react';
import { useFavoriteCollectibles } from '../../../hooks/useFavoriteCollectibles';
import { IUserCollectible } from '../../../services/collectible';
import { CollectibleItemWeb3 } from './CollectibleItemWeb3';

export interface Nft {
  contract: Contract;
  tokenId: string;
  tokenType: string;
  title: string;
  description: string;
  timeLastUpdated: Date;
  rawMetadata: RawMetadata;
  tokenUri: TokenURI;
  media: TokenURI[];
  balance: number;
}

export interface Contract {
  address: string;
  name: string;
  totalSupply: string;
  tokenType: string;
  openSea: OpenSea;
  contractDeployer: string;
  deployedBlockNumber: number;
}

export interface OpenSea {
  lastIngestedAt: Date;
}

export interface TokenURI {
  raw: string;
  gateway: string;
}

export interface RawMetadata {
  name: string;
  image: string;
  description: string;
  external_url: string;
  animation_url: string;
}

export interface IWalletCollectible extends IUserCollectible  {
  blockchainData?: Nft;
}

interface IWalletItemsProps {
  nfts: IWalletCollectible[];
}

const WalletItems: FC<IWalletItemsProps> = (props) => {
  const { favoritesIds } = useFavoriteCollectibles();

  return (
    <>
      {props?.nfts?.map((collectible) => {
        return (
          <CollectibleItemWeb3
            key={collectible.id}
            isProfile
            hideCreatorNameOnMobile
            active={favoritesIds?.collectibles.includes(collectible.id) || false}
            {...collectible}
          />
        );
      })}
    </>
  );
};

export default WalletItems;
