import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ConnectorNotFoundError, useAccount, useConnect, useDisconnect, useProvider } from 'wagmi';

import { useGetLocalizedString } from '../../../services/localization';
import { useAddSystemNotification } from '../../../state/systemNotifications';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { PrimaryButton, SecondaryButton } from '../../../styles/Buttons';
import { truncateWalletAddress } from '../../../utils/truncate';
import { chainId, injectedConnector } from '../../../web3/client';

const ConnectWalletWrapper = styled.div`
  margin-bottom: 1rem;
  // display: none; // todo hide on mobile via attributes/props
  justify-self: center;

  ${BREAKPOINT_MD} {
    display: flex;
  }
`;

const ConnectButton = styled(SecondaryButton)<{ rounded: boolean; small: boolean }>`
  width: 100%;
  line-height: unset;
  font-weight: normal;
  text-transform: none;
  border-radius: ${(p) => (p.rounded ? 5 : 0)}rem;
  height: ${(p) => (p.small ? '1.5rem' : '48px')};
`;

const DisconnectButton = styled(PrimaryButton)<{ rounded: boolean; small: boolean }>`
  width: 100%;
  line-height: unset;
  font-weight: normal;
  text-transform: none;
  border-radius: ${(p) => (p.rounded ? 5 : 0)}rem;
  height: ${(p) => (p.small ? '1.5rem' : '48px')};
`;

interface WalletConnectButtonProps {
  rounded?: boolean;
  small?: boolean;
}

// USED IN SIGN UP
const WalletConnectButton: FC<WalletConnectButtonProps> = ({ rounded = false, small = false }: WalletConnectButtonProps) => {
  const t = useGetLocalizedString();
  const { address, isConnected, connector: activeConnector } = useAccount();
  const addSystemNotification = useAddSystemNotification();

  const { connect, pendingConnector, error, isError } = useConnect({
    connector: injectedConnector,
    chainId: chainId,
    onError(error) {
      if (error instanceof ConnectorNotFoundError) {
        addSystemNotification({
          type: 'error',
          headline: t('app.v2.connect-wallet.error.no-provider.title'),
          text: t('app.v2.connect-wallet.error.no-provider.text'),
        });
      }
    },
  });
  const { disconnect } = useDisconnect();

  return (
    <ConnectWalletWrapper>
      {isConnected ? (
        <DisconnectButton type='button' small={small} rounded={rounded} onClick={() => disconnect()}>
          {t('app.v2.connect-wallet.disconnect.button')} ({truncateWalletAddress(address)})
        </DisconnectButton>
      ) : (
        <ConnectButton disabled={!window.ethereum} type='button' small={small} rounded={rounded} onClick={() => connect()}>
          {t('app.v2.connect-wallet.connect.button')}
        </ConnectButton>
      )}
    </ConnectWalletWrapper>
  );
};

export default WalletConnectButton;
