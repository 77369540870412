import { Alchemy, Network, NftContract, NftOrdering, NftTokenType, OwnedNft } from 'alchemy-sdk';
import { useState } from 'react';
import { IWalletCollectible } from 'src/components/common/special/WalletItems';
import web3 from 'web3';
import { IUserCollectible, useCollectiblesAPI } from '../services/collectible';
import { BLOCKCHAIN_NETWORKS } from '../web3/types';

const REACT_APP_BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

const alchemy = new Alchemy({
  apiKey: process.env.REACT_APP_ALCHEMY_KEY,
  network: REACT_APP_BLOCKCHAIN_NETWORK === BLOCKCHAIN_NETWORKS.POLYGON ? Network.MATIC_MAINNET : Network.MATIC_MUMBAI,
});

export const useAlchemy = () => {
  const collectiblesAPI = useCollectiblesAPI();
  const [isLoading, setIsLoading] = useState(false);

  const getNftsByWalletAddress = async (walletAddress: string) => {
    setIsLoading(true);
    // Get all NFTs
    const nfts = await alchemy.nft.getNftsForOwner(walletAddress, {omitMetadata: true, orderBy: NftOrdering.TRANSFERTIME});
    // Parse output
    const nftList: OwnedNft[] = nfts.ownedNfts;
    setIsLoading(false);

    return nftList;
  };

  const isCollectibleInConnectedWallet = async (walletAddress: string, collectibleContractAddress: string) => {
    if (!collectibleContractAddress || !walletAddress) {
      return false;
    }

    const nfts = await alchemy.nft.getNftsForOwner(walletAddress);
    const nft = nfts.ownedNfts.find((n) => {
      return web3.utils.toChecksumAddress(n?.contract?.address) === collectibleContractAddress;
    });

    return nft !== undefined;
  };

  const computeRank = (rarity: any[], totalSupply: number) => {
    let rank = 0;
    for (const key in rarity) {
      rank += rarity[key].prevalence;
    }
    rank = (rank / rarity.length) * totalSupply;

    return Math.round(rank);
  };

  const computeRarity = async (address: string, tokenID: string) => {
    return await alchemy.nft.computeRarity(address, tokenID);
  };

  const contractMetadata = async (address: string) => {
     return await alchemy.nft.getContractMetadata(address);
  };

  const nftMetadata = async (address: string, tokenId: number) => {
    return await alchemy.nft.getNftMetadata(address, tokenId, {refreshCache: true});
  };

  const refreshNftMetadata = async (address: string, tokenId: number) => {
    return await alchemy.nft.refreshNftMetadata(address, tokenId);
  };

  const collectibleOwners = async (address: string) => {
    const owners = await alchemy.nft.getOwnersForContract(address);
    return owners?.owners?.length || 0;
  }

  const fetchWalletItems = async (wallet: string): Promise<IUserCollectible[]> => {
    const nfts = await getNftsByWalletAddress(wallet);
    const addresses = nfts.map((n) => web3.utils.toChecksumAddress(n?.contract?.address));
    if (addresses.length === 0) {
      return [];
    }
    const uniqueArray = Array.from(new Set(addresses));
    const collectiblesDefinition = await getDetailsBySmartContract(uniqueArray);
    const collectiblesDefinitionAddresses = collectiblesDefinition.map((n) => n.smartContractAddress);
    const mergedData = nfts
      .filter(nft => collectiblesDefinitionAddresses.includes(web3.utils.toChecksumAddress(nft.contract?.address)))
      .map((nft) => {
        const collectibleData = collectiblesDefinition.find((coll) => coll.smartContractAddress === web3.utils.toChecksumAddress(nft.contract?.address));
        if(!collectibleData){
          throw new Error("Something went wrong while inspecting user wallet...");
        }
        collectibleData.sku = nft.tokenId;
        return { ...collectibleData, blockchainData: nft };
      });
    return mergedData;
  };

  const getDetailsBySmartContract = async (addresses: string[]) => {
    if (addresses.length === 0) {
      return [];
    }
    const resp = await collectiblesAPI.userWalletCollectibleDetails(addresses);
    return resp?.data;
  };

  return {
    fetchWalletItems,
    getNftsByWalletAddress,
    isCollectibleInConnectedWallet,
    contractMetadata,
    nftMetadata,
    refreshNftMetadata,
    computeRank,
    computeRarity,
    collectibleOwners,
    isLoading,
  };
};
