import styled from 'styled-components';
import { FC, ReactNode} from "react";
import { Headline4 } from '../../../styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';

const Wrapper = styled.section`
  && {
    grid-column: 1 / -1;
    margin-bottom: 2rem;
    
    ${BREAKPOINT_LG} {
      margin-bottom: 3.5rem;
      grid-column: 3;
    }

  }
`;

const Headline = styled.h2`
  ${Headline4};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  padding: 0 var(--content-indent);
  
  ${BREAKPOINT_LG} {
    padding: 0;
  }
`;

const MobileContent = styled.div`
  display: block;
  
  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const DesktopContent = styled.div`
  display: none;
  
  ${BREAKPOINT_MD} {
    display: block;
  }
`;

interface IItemListWrapper {
  children?: ReactNode;
  link?: ReactNode;
  headline?: string;
  className?: string;
}

const ItemListWrapper: FC<IItemListWrapper> = ({
  headline,
  children,
  className,
  link,
}) => (
  <Wrapper className={className}>
    {(headline || link) && (
      <Header>
        {headline && <Headline>{headline}</Headline>}
        {link && <MobileContent>{link}</MobileContent>}
      </Header>
    )}
    
    {children}

    {link && (
      <DesktopContent>
        {link}
      </DesktopContent>
    )}

  </Wrapper>
);

export default ItemListWrapper;