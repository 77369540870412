import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageLoader } from '../components/common/general/LoadingOverlay';
import { PageView } from '../components/layout/PageLayout';
import { useActiveLocale, useGetLocalizedString } from '../services/localization';
import { useTermsAPI } from '../services/terms';
import { useDataCache } from '../state/dataCache';
import { PrimaryButton } from '../styles/Buttons';
import { Body1Bold, Headline3 } from '../styles/FontStyles';
import { GridLayout } from '../styles/Layout';

const Wrapper = styled.div`
  // TODO Fix complex layout css queries (main > * > *)
  grid-column: 1 / -1 !important;
  ${GridLayout}
  padding: 2rem;
`;

const Title = styled.h2`
  ${Headline3};
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.p`
  ${Body1Bold};
  margin-bottom: 1.5rem;
`;

const AcceptTermsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const ContentWrapper = styled.div`
  > p {
    padding: 0.5rem 0;
  }
`;

const AcceptTermsButton = styled(PrimaryButton)``;

export const Terms = () => {
  const location = useLocation();
  const { showAcceptButton = false } = location.state || {};

  const getLocalizedString = useGetLocalizedString();
  const { latestTerms, acceptTerms } = useTermsAPI();

  const navigate = useNavigate();
  const activeLocale = useActiveLocale();

  const [loading, setLoading] = useState(true);
  const [dataCache, setDataCache] = useDataCache();
  const { termsData } = dataCache;

  const handleAcceptTerms = async () => {
    if (!termsData) {
      alert('Please logout and login again.');
      return;
    }

    const acceptResponse = await acceptTerms({ termsId: termsData?._id });
    console.log(acceptResponse);
    if (acceptResponse.data.success) {
      navigate('/', { replace: true });
      return;
    } else {
      alert('Please try again.');
    }
  };

  useEffect(() => {
    const hasCachedData = !!termsData;
    if (!hasCachedData) {
      setLoading(true);
    }
    Promise.all([
      latestTerms().then((response) => {
        let translatedTerms: any;

        if (activeLocale === 'es') {
          translatedTerms = response.data.terms.find((t) => t.language === "en");
        } else {
          translatedTerms = response.data.terms.find((t) => t.language === activeLocale);
        }
        
        setDataCache((state) => ({ ...state, termsData: translatedTerms || null }));
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [activeLocale]);

  return (
    <PageView>
      <Wrapper>
        <PageLoader visible={loading} />
        <Title>{getLocalizedString('app.v2.terms.title')}</Title>
        {showAcceptButton && <SubTitle>{getLocalizedString('app.v2.terms.subtitle')}</SubTitle>}
        {!loading && termsData && <ContentWrapper dangerouslySetInnerHTML={{ __html: termsData?.content }}></ContentWrapper>}
        {showAcceptButton && (
          <AcceptTermsButtonWrapper>
            <AcceptTermsButton onClick={handleAcceptTerms}>{getLocalizedString('app.v2.terms.accept.button')}</AcceptTermsButton>
          </AcceptTermsButtonWrapper>
        )}
      </Wrapper>
    </PageView>
  );
};
