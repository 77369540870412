import { css } from 'styled-components';
import { Headline2 } from '../../../styles/FontStyles';
import { BREAKPOINT, BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from '../../../styles/Breakpoints';
import { Colors } from '../../../styles/Colors';

export const AlbumCardCaptionStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100%;
  
  ${Headline2};
  font-size: calc(var(--font-size) * var(--text-size-multiplier, 1));
  --font-size: 6vw;
  line-height: 1.11;
  letter-spacing: .01em;
  text-transform: uppercase;

  ${BREAKPOINT_SM} {
    --font-size: 7vw;
  }
  
  ${BREAKPOINT_MD} {
    --font-size: 3.25vw;
  }

  ${BREAKPOINT_LG} {
    font-size: calc(var(--font-size) * var(--text-size-multiplier, 1));
    --font-size: 3vw;
    line-height: 1.11;
  }
  
  ${BREAKPOINT(1200)} {
    --font-size: 3.25vw;
  }

  ${BREAKPOINT(1300)} {
    --font-size: min(3.5vw, 3.375rem);
  }
  
  span {
    color: var(--color-grey-900);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${Colors.primary};
    text-shadow: 0px 0px 10px ${Colors.primary};
    paint-order: stroke fill;
  }
`;