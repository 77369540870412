import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const Checkmark: FC<IconProps> = ({ className }) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g filter="url(#filter0_b_1804_23246)">
        <rect width="41" height="41" rx="10" fill="url(#paint0_radial_1804_23246)" fill-opacity="0.2"/>
        <rect x="0.5" y="0.5" width="40" height="40" rx="9.5" stroke="url(#paint1_radial_1804_23246)"/>
      </g>
      <path d="M14 21L18.5 25.5L28 16.5" stroke="#08E8DE" stroke-width="1.5" stroke-linecap="round"/>
      <defs>
        <filter id="filter0_b_1804_23246" x="-10" y="-10" width="61" height="61" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1804_23246"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1804_23246" result="shape"/>
        </filter>
        <radialGradient id="paint0_radial_1804_23246" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.28097 8.6269) rotate(44.7841) scale(52.0793 56.4252)">
          <stop stopColor="#0F0F0F"/>
          <stop offset="0.770833" stopColor="#A6A6A6"/>
          <stop offset="1" stopColor="white" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="paint1_radial_1804_23246" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5 20.5) rotate(90) scale(28.8708)">
          <stop offset="0.0248283" stopColor="#0F0F0F"/>
          <stop offset="0.536458" stopColor="#08E8DE" stop-opacity="0.61"/>
          <stop offset="1" stopColor="white" stop-opacity="0"/>
        </radialGradient>
      </defs>
    </svg>

  )

}