import { useState } from 'react';
import styled from 'styled-components';
import { InlineButton } from '../../../styles/Buttons';
import Modal from '../general/Modal';
import { useGetLocalizedString } from '../../../services/localization';
import Text from '../general/Text';

const WalletInfoWrapper = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: .75rem;

  p {
    margin-bottom: .75rem;
  }
`;

const WalletDescriptionModal = () => {
  const getLocalizedString = useGetLocalizedString();
  const [helpModal, setShowHelpModal] = useState(false);

  const showHelpModal = () => {
    setShowHelpModal(true);
  };

  return (
    <>
      <InlineButton onClick={showHelpModal}>{getLocalizedString('app.v2.create-wallet.what-is-my-wallet')}</InlineButton>
      <Modal open={helpModal} onClose={() => setShowHelpModal(false)}>
        <WalletInfoWrapper>
          <Text as={'h3'} content={getLocalizedString('app.v2.create-wallet.what-is-my-wallet.secure-your-wallet')} />
          <Text content={getLocalizedString('app.v2.create-wallet.what-is-my-wallet.secure-your-wallet.text')} />
          <Text as={'h3'} content={getLocalizedString('app.v2.create-wallet.what-is-my-wallet.whats-a-wallet')} />
          <Text content={getLocalizedString('app.v2.create-wallet.what-is-my-wallet.whats-a-wallet.text')} />
        </WalletInfoWrapper>
      </Modal>
    </>
  );
};

export default WalletDescriptionModal;