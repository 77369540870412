import styled, { css, keyframes } from 'styled-components';
import { Body2Bold } from '../../../styles/FontStyles';
import { useGetLocalizedString } from '../../../services/localization';
import { Colors } from '../../../styles/Colors';
import { BagOpenIcon } from '../../icons/BagOpenIcon';
import { CheckIcon } from '../../icons/CheckIcon';
import { ChevronIcon } from '../../icons/ChevronIcon';
import { FC, HTMLAttributes, useMemo, useRef, useState } from 'react';
import { LoadingAnimation } from '../general/LoadingAnimation';

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  
  ${({ disabled = false }) => disabled && css`
    opacity: .5;
    pointer-events: none;
  `};
`;

const Headline = styled.span`
  ${Body2Bold};
  text-transform: uppercase;
  text-align: center;
`;

const SlideWrapper = styled.div<{ triggered: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  transition: width .75s ease-out;

  width: ${({ triggered }) => triggered ? '3.5rem' : '14rem'};
  margin: auto;

  padding: .375rem .75rem;
`;

const BackgroundSlide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 3rem;
  padding: .375rem .75rem;
  gap: .25rem;

  border-radius: 1.5rem;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), linear-gradient(152.97deg, rgba(255, 255, 255, 0.235) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(12px);
`;

const ArrowAnimation = keyframes`
  0% {
    opacity: .5;
    transform: scale(.75);
  }
  5% {
    opacity: 1;
  }
  12.5% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: .5;
    transform: scale(.75);
  }
  100% {
    opacity: .5;
    transform: scale(.75);
  }
`;

const ArrowIcon = styled(ChevronIcon)<{ disabled?: boolean }>`
  width: 1.25rem;
  height: 1.25rem;

  animation: ${ArrowAnimation} 2.5s ease-out infinite;

  &:nth-child(2) {
    animation-delay: .15s;
  }

  &:nth-child(3) {
    animation-delay: .3s;
  }
  
  ${({ disabled = false }) => disabled && css`
    animation: none;
    opacity: .5;
  `};
`;

const Ellipse = styled.div<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  margin: auto;
  transform: translateX(-50%);
  z-index: -1;

  width: 1.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${Colors.primary};
  opacity: ${({ disabled }) => disabled ? 0 : 1};
  transition: opacity 450ms ease;
`;

const SliderBubble = styled.div<{ visible?: boolean, dark?: boolean; disabled?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;

  transition: opacity 150ms ease-out, visibility 150ms ease-out, background-color .5s ease-out;
  opacity: ${({ visible = true }) => visible ? 1 : 0};
  visibility: ${({ visible = true }) => visible ? 'visible' : 'hidden'};

  background-color: ${({ dark = false, disabled = false }) => dark || disabled ? Colors.background : Colors.primary};
  box-shadow: ${({ dark = false, disabled = false }) => dark || disabled ? 'none' : '0 0 6px rgba(8, 232, 222, 0.5)'};
  pointer-events: ${({ disabled = false }) => disabled ? 'none' : 'pointer'};

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: ${({ dark = false, disabled = false }) => dark || disabled ? 1 : 0};
    background: linear-gradient(144.32deg, rgba(255, 255, 255, 0.24) 11.51%, rgba(255, 255, 255, 0) 94.95%), rgba(75, 75, 75, 0.2);
    transition: opacity 450ms ease;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
  }
`;

const LoadingSpinner = styled(LoadingAnimation)`
  width: 1rem;
  height: 1rem;
  border-width: 1.5px;
`;

const BagIcon = styled(BagOpenIcon)`
  filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.3));
`;

interface ISwipeButtonProps extends HTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  disabled?: boolean;
  type?: any;    // ts fix
  buttonTriggered: boolean;
  setButtonTriggered: (triggered: boolean) => void;
  showHeadline?: boolean;
}

export const SwipeButton: FC<ISwipeButtonProps> = (props) => {
  const {
    loading = false,
    disabled = false,
    className,
    buttonTriggered,
    setButtonTriggered,
    showHeadline = true,
    ...buttonProps
  } = props;

  const getLocalizedString = useGetLocalizedString();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const touchStarted = useRef(false);
  const [sliderPosition, setSliderPosition] = useState(0);

  const sliderBubbleStyle = useMemo(() => {
    return {
      left: `calc(${sliderPosition.toFixed(2)} * (100% - 3.5rem))`,
    };
  }, [sliderPosition]);

  const sliderBubbleEllipseStyle = useMemo(() => {
    return {
      left: `${(100 * (1 - sliderPosition)).toFixed(2)}%`,
    };
  }, [sliderPosition]);

  return (
    <Wrapper className={className} disabled={disabled}>
      {showHeadline && <Headline>{getLocalizedString('app.v2.checkout.review.swipe-to-place-order')}</Headline>}
      <SlideWrapper triggered={buttonTriggered}>
        <BackgroundSlide>
          <ArrowIcon disabled={disabled} />
          <ArrowIcon disabled={disabled} />
          <ArrowIcon disabled={disabled} />
        </BackgroundSlide>

        <SliderBubble style={{ right: 0 }} dark={!buttonTriggered}>
          {
            loading
              ? <LoadingSpinner />
              : <CheckIcon />
          }
        </SliderBubble>

        {
          !buttonTriggered &&
          <SliderBubble
            disabled={disabled}
            style={sliderBubbleStyle}
            visible={!buttonTriggered}
            onTouchStart={() => {
              touchStarted.current = true;
            }}
            onTouchMove={(e) => {
              if (touchStarted.current) {
                const touchClientX = e.touches[0]?.clientX || 0;
                const bubbleOffsetWidth = (e.target as HTMLDivElement)?.offsetWidth || 0;
                const offsetLeft = (e.target as HTMLDivElement)?.parentElement?.getBoundingClientRect().left || 0;
                const offsetWidth = (e.target as HTMLDivElement)?.parentElement?.offsetWidth || 0;

                const currentPosition = touchClientX - offsetLeft - bubbleOffsetWidth / 2;
                const maxPosition = offsetWidth - bubbleOffsetWidth;
                const movementX = Math.max(0, Math.min(currentPosition, maxPosition)) / maxPosition;

                setSliderPosition(movementX);
              }
            }}
            onTouchEnd={() => {
              if (sliderPosition === 1) {
                if (!disabled) {
                  setButtonTriggered(true);

                  // call button click
                  buttonRef.current?.click();

                  // do vibrate if available
                  if (window?.navigator?.vibrate) {
                    window.navigator.vibrate([100]);
                  }
                }
              }

              // reset touch
              touchStarted.current = false;
              setSliderPosition(0);
            }}
          >
            <BagIcon />
            <Ellipse style={sliderBubbleEllipseStyle} disabled={disabled} />
          </SliderBubble>
        }
      </SlideWrapper>
      <button
        {...buttonProps}
        style={{ width: 0, height: 0, overflow: 'hidden', opacity: 0 }}
        ref={buttonRef}
      />
    </Wrapper>
  );
};