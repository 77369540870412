import styled from 'styled-components';
import { PageView } from '../../components/layout/PageLayout';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { FC, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '../../components/common/general/LoadingOverlay';
import { useParams } from 'react-router-dom';
import { useDataCache } from '../../state/dataCache';
import { IAlbumContentItem, useAlbumsAPI } from '../../services/albums';
import { StageCampaign } from '../../components/common/campaigns/StageCampaign';
import { Footer } from '../../components/common/albums/content/Footer';
import { TextSection } from '../../components/common/albums/content/TextSection';
import { ForeverGalaxyAlbumCommonSection } from '../../components/common/campaigns/ForeverGalaxyAlbumCommonSection';
import { ForeverGalaxyAlbumEpicSection } from '../../components/common/campaigns/ForeverGalaxyAlbumEpicSection';
import { ForeverGalaxyAlbumLegendSection } from '../../components/common/campaigns/ForeverGalaxyAlbumLegendSection';
import { ForeverGalaxyTokenSection } from '../../components/common/campaigns/ForeverGalaxyTokenSection';
import { TextImageSection as TextImageStackedSection } from '../../components/common/collectibles/content/TextImageCenterSection';
import { BREAKPOINT_LG } from '../../styles/Breakpoints';
import { ImageSection } from '../../components/common/albums/content/ImageSection';
import { FaqSection } from '../../components/common/campaigns/FaqSection';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 3.75rem;

  ${BREAKPOINT_LG} {
    gap: 7.5rem;
  }
`;

const CONTENT_SECTIONS: { [type: string]: FC<IAlbumContentItem & any> } = {
  'stage-section': StageCampaign,
  'text-section': TextSection,
  'text-image-stacked-section': TextImageStackedSection,
  'text-image-galaxy-album-common-section': ForeverGalaxyAlbumCommonSection,
  'text-image-galaxy-album-epic-section': ForeverGalaxyAlbumEpicSection,
  'text-image-galaxy-album-legend-section': ForeverGalaxyAlbumLegendSection,
  'text-image-galaxy-token-section': ForeverGalaxyTokenSection,
  'image-section': ImageSection,
  'footer-section': Footer,
  'faq-section': FaqSection
};

export const CampaignDetails = () => {
  const { id } = useParams();
  const getLocalizedString = useGetLocalizedString();

  const activeLocale = useActiveLocale();

  const albumsAPI = useAlbumsAPI();
  const [dataCache, setDataCache] = useDataCache();
  const [loading, setLoading] = useState(false);

  const album = useMemo(() => (dataCache?.albumDetails || {})[id || ''] || null, [dataCache]);

  useEffect(() => {
    // make api call
    if (id) {
      setLoading(!album);
      albumsAPI.fetchAlbumDetails(id)
        .then((res) => {
          setDataCache((state) => ({
            ...state,
            albumDetails: {
              ...(state?.albumDetails || {}),
              [id]: res.data || null,
            },
          }));
          setLoading(false);
        })
        .catch((err) => {
          console.error('failed to load album details', err);
          setLoading(false);
        });
    }
  }, [id, activeLocale]);

  const sections = useMemo(() => {
    return (album?.content || []).slice().sort((a, b) => a.order - b.order);
  }, [album]);

  return (
    <PageView>
      <PageLoader visible={loading} />
      <SectionWrapper>
        {
          !loading &&
          <>
            {
              sections.map((section, index) => {
                const Section = CONTENT_SECTIONS[section.type];
                if (Section) {
                  return <Section key={index} {...section} album={album} />;
                }
                return <></>;
              })
            }
          </>
        }
      </SectionWrapper>

    </PageView>
  );
};
