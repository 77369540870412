import { FC } from 'react';
import { OverlayIcon } from './OverlayIcon';

export const AlbumsIconOutline: FC<{ className?: string }> = ({ className }) => (
  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_2539_17963)">
      <path d="M0.418945 4.74591C0.775876 4.05895 1.09431 3.92246 2.13128 4.03546C2.13128 4.13951 2.13128 4.24803 2.13128 4.35656C2.13128 8.1505 2.13245 11.9444 2.13011 15.7384C2.13011 16.3616 2.27125 16.9355 2.69117 17.4278C3.18108 18.0029 3.81445 18.2871 4.5843 18.343C5.49879 18.409 6.42028 18.4549 7.32311 18.6026C8.88497 18.8588 10.3372 19.4048 11.5946 20.3614C11.7218 20.4576 11.8431 20.5617 11.9655 20.6635C11.9912 20.6847 12.0122 20.7127 12.053 20.7574C11.499 20.6657 10.9752 20.5639 10.448 20.4934C8.88964 20.2864 7.32077 20.2338 5.75774 20.4017C4.40934 20.5471 3.0691 20.7709 1.72886 20.9745C1.08382 21.073 0.737384 20.8973 0.420112 20.2551V4.74591H0.418945Z" fill="#ADADAD"/>
      <path d="M14.3674 18.9998C14.1621 18.83 13.9932 18.684 13.8155 18.5468C12.2878 17.3654 10.4909 16.714 8.51619 16.4475C7.71814 16.3399 6.90686 16.3034 6.10109 16.255C5.44544 16.2165 5.07346 15.8967 5.07346 15.3144C5.07236 11.1819 5.07236 7.05039 5.07346 2.91789C5.07346 2.3563 5.46199 1.99013 6.06135 2C7.4135 2.02171 8.74467 2.1668 10.0328 2.56159C11.737 3.08371 13.1554 3.94732 14.1422 5.31725C14.2736 5.49886 14.3685 5.73968 14.3696 5.95386C14.3828 10.2462 14.3795 14.5386 14.3795 18.83C14.3795 18.8725 14.3729 18.9149 14.3674 18.9998Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M14.3931 18.9998C14.3931 18.8666 14.3931 18.7787 14.3931 18.6909C14.3931 14.5739 14.4053 10.4569 14.3799 6.33896C14.3766 5.7339 14.6048 5.27097 15.0071 4.82482C16.2946 3.40051 18.001 2.61482 19.9664 2.26738C20.8548 2.11044 21.7698 2.06405 22.6758 2.00285C23.2931 1.9614 23.6867 2.35029 23.6867 2.94055C23.6878 6.20373 23.6867 9.46691 23.6867 12.7301C23.6867 13.5839 23.6878 14.4377 23.6867 15.2915C23.6856 15.9025 23.3207 16.2391 22.6329 16.2489C20.9695 16.2736 19.3458 16.4888 17.7871 17.0307C16.5911 17.4462 15.5164 18.0335 14.5982 18.8409C14.5464 18.8863 14.489 18.9268 14.3931 18.9998Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M26.6282 4.01286C26.9594 4.01286 27.2674 3.97926 27.5648 4.0207C27.9812 4.07893 28.2903 4.44292 28.3335 4.85283C28.3428 4.93907 28.3405 5.02531 28.3405 5.11267C28.3405 10.036 28.3405 14.9594 28.3405 19.8839C28.3405 19.9534 28.3417 20.0228 28.337 20.0911C28.2997 20.6668 27.8121 21.0756 27.2196 20.9882C26.508 20.883 25.8059 20.7217 25.0944 20.6186C22.3031 20.2154 19.5166 20.1572 16.7067 20.715C17.0123 20.4876 17.3074 20.2457 17.6258 20.0363C18.9625 19.1582 20.4649 18.7024 22.0523 18.4963C22.7557 18.4045 23.4695 18.3798 24.1787 18.3361C25.3416 18.2633 26.2281 17.5992 26.5337 16.5218C26.6107 16.2508 26.6224 15.9573 26.6224 15.674C26.6282 11.9008 26.6259 8.12874 26.6259 4.35557C26.6259 4.24469 26.6259 4.13493 26.6259 4.01174L26.6282 4.01286Z" fill="#ADADAD"/>
    </g>
    <defs>
      <clipPath id="clip0_2539_17963">
        <rect width="27.9218" height="24" fill="white" transform="translate(0.418945)"/>
      </clipPath>
    </defs>
  </svg>
);

export const AlbumsIconFilled: FC<{ className?: string }> = ({ className }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M0 5.93239C0.383497 5.07369 0.725637 4.90307 1.83978 5.04432C1.83978 5.17438 1.83978 5.31004 1.83978 5.4457C1.83978 10.1881 1.84104 14.9306 1.83853 19.673C1.83853 20.452 1.99017 21.1694 2.44135 21.7848C2.96772 22.5036 3.64824 22.8588 4.47539 22.9288C5.45794 23.0113 6.44801 23.0686 7.41804 23.2532C9.09615 23.5735 10.6565 24.256 12.0075 25.4517C12.1441 25.572 12.2744 25.7021 12.406 25.8293C12.4336 25.8559 12.4561 25.8909 12.5 25.9468C11.9047 25.8321 11.342 25.7049 10.7755 25.6168C9.10116 25.358 7.41553 25.2923 5.73616 25.5021C4.2874 25.6839 2.8474 25.9636 1.40741 26.2181C0.714357 26.3412 0.34214 26.1216 0.00125326 25.3189V5.93239H0Z" fill="white"/>
    <path d="M13.7366 23.75C13.516 23.5378 13.3345 23.3552 13.1436 23.1837C11.5022 21.7069 9.57151 20.8927 7.44986 20.5596C6.59242 20.4251 5.72075 20.3794 4.85501 20.319C4.15055 20.2709 3.75089 19.8712 3.75089 19.1433C3.7497 13.9776 3.7497 8.81324 3.75089 3.64761C3.75089 2.94562 4.16834 2.48791 4.81231 2.50024C6.2651 2.52739 7.69535 2.70874 9.07935 3.20224C10.9104 3.85488 12.4344 4.93439 13.4946 6.64681C13.6358 6.87381 13.7377 7.17484 13.7389 7.44256C13.7532 12.8081 13.7496 18.1735 13.7496 23.5378C13.7496 23.5908 13.7425 23.6439 13.7366 23.75Z" fill="white"/>
    <path d="M16.2643 23.75C16.2643 23.5834 16.2643 23.4736 16.2643 23.3638C16.2643 18.2176 16.2773 13.0714 16.25 7.92391C16.2465 7.16758 16.4916 6.58892 16.9239 6.03124C18.3072 4.25085 20.1406 3.26874 22.2523 2.83444C23.2069 2.63826 24.1899 2.58027 25.1634 2.50378C25.8267 2.45196 26.2495 2.93808 26.2495 3.6759C26.2507 7.75488 26.2495 11.8339 26.2495 15.9128C26.2495 16.9801 26.2507 18.0473 26.2495 19.1146C26.2483 19.8783 25.8563 20.299 25.1172 20.3114C23.3301 20.3422 21.5855 20.6112 19.9109 21.2885C18.6258 21.808 17.4711 22.5421 16.4845 23.5514C16.4289 23.6081 16.3673 23.6587 16.2643 23.75Z" fill="white"/>
    <path d="M28.16 5.01607C28.5159 5.01607 28.8467 4.97407 29.1663 5.02587C29.6137 5.09867 29.9458 5.55365 29.9922 6.06604C30.0022 6.17384 29.9997 6.28163 29.9997 6.39083C29.9997 12.5451 29.9997 18.6993 29.9997 24.8549C29.9997 24.9417 30.001 25.0285 29.996 25.1139C29.9559 25.8335 29.432 26.3445 28.7954 26.2353C28.0309 26.1037 27.2765 25.9021 26.512 25.7733C23.513 25.2693 20.519 25.1965 17.5 25.8937C17.8283 25.6095 18.1454 25.3071 18.4875 25.0453C19.9237 23.9477 21.5379 23.378 23.2436 23.1204C23.9993 23.0056 24.7662 22.9748 25.5282 22.9202C26.7777 22.8292 27.7301 21.999 28.0585 20.6522C28.1412 20.3134 28.1537 19.9467 28.1537 19.5925C28.16 14.876 28.1575 10.1609 28.1575 5.44446C28.1575 5.30586 28.1575 5.16867 28.1575 5.01467L28.16 5.01607Z" fill="white"/>
  </svg>
);

export const AlbumsIcon: FC<{ className?: string, active?: boolean, onClick?: () => void }> = (props) => (
  <OverlayIcon {...props}>
    <AlbumsIconOutline />
    <AlbumsIconFilled />
  </OverlayIcon>
);
