import styled from 'styled-components';
import { MAX_BREAKPOINT_MD } from '../../styles/Breakpoints';
import { LinkHandler } from '../common/general/LinkHandler';
import { SOCIAL_MEDIA_LINKS } from './Menu';

const Wrapper = styled(LinkHandler)`
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 99;
  cursor: pointer;

  ${MAX_BREAKPOINT_MD} {
    bottom: 70px;
  }
`;

export const DiscordButton = () => {
  return (
    <Wrapper to={SOCIAL_MEDIA_LINKS.discord} target={'_blank'}>
      <img src='/discord.png' height={60} title={'Join Discord'} alt={'Join Discord'} />
    </Wrapper>
  );
};
