import styled from 'styled-components';
import { FC, useEffect, useRef } from 'react';
import { BasicGridWrapper } from '../../general/Grids';
import { BREAKPOINT_LG, BREAKPOINT_MD } from '../../../../styles/Breakpoints';
import { IAlbumContentItem } from '../../../../services/albums';

const Wrapper = styled(BasicGridWrapper).attrs({ as: 'section' })`
  min-height: 75vh;
  place-content: center;
  margin-top: var(--nav-height);
  transition: none;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: span 6;
  gap: 1rem;
  max-height: 60vh;
  align-items: flex-end;
  
  --offset: 0rem;

  &:last-child {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  h4 {
    font-size: 2rem;
    line-height: 1.125;
  }

  ${BREAKPOINT_MD} {
    &:first-child {
      grid-column: 3 / 7;
    }

    &:last-child {
      grid-column: 7 / -3;
    }
  }
  
  ${BREAKPOINT_LG} {
    gap: 2.5rem;
    --offset: 5rem;

    h4 {
      font-size: 3rem;
    }
  }

  opacity: var(--opacity);
  transition: inherit;

  position: fixed;
  top: 0;
  bottom: 0;
  left: var(--offset);
  right: 0;
  margin: auto 0;
  width: calc(50vw - var(--offset) - 3rem);

  &:first-child {
    transform: translate(var(--translate-x-amount, 0vw), var(--translate-y-amount, 0vh));
    left: auto;
    right: calc(50vw - (var(--offset) / 2));
    margin-right: .5rem;

    ${BREAKPOINT_MD} {
      margin-right: 1.5rem;
    }
  }

  &:last-child {
    transform: translate(calc(var(--translate-x-amount, 0vw) * -1), calc(var(--translate-y-amount, 0vh) * -1));
    left: calc(50vw + (var(--offset) / 2));
    right: auto;
    margin-left: .5rem;

    ${BREAKPOINT_MD} {
      margin-left: 1.5rem;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: min(calc(60vh / 1.75), 100%);

  :before {
    content: '';
    display: block;
    padding-top: 175%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`;

interface SplitImageSectionProps extends IAlbumContentItem {
  items: { title: string; image: string }[];
}

export const SplitImageSection: FC<SplitImageSectionProps> = (props) => {
  const { type, items } = props;
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
      const scrollHandler = () => {
        if (sectionRef.current) {
          const { top, bottom } = sectionRef.current.getBoundingClientRect();
          const useCSSTransition = true;

          const sectionCenter = top + ((bottom - top) / 2);
          const scrollDelta = Math.max(0, Math.min(2, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2)))) / 2;

          let translateY = -100;
          let opacity = 0;

          if (scrollDelta > 0) {
            translateY = -100 + 100 * (scrollDelta / .5);
            opacity = Math.max(0, Math.min(100, 100 * ((scrollDelta > .5 ? (1 - scrollDelta) : scrollDelta) / .5)));
          }

          if (useCSSTransition) {
            if (scrollDelta > .8) {
              translateY = 100;
              opacity = 0;
            } else if (scrollDelta > .15) {
              translateY = 0;
              opacity = 100;
            } else {
              translateY = -100;
              opacity = 0;
            }
          }

          if (sectionRef.current) {
            sectionRef.current.style.transition = useCSSTransition ? 'transform .5s ease-out, opacity .5s ease-out' : 'none';
          }

          sectionRef.current?.style.setProperty('--translate-y-amount', `${translateY}vh`);
          sectionRef.current?.style.setProperty('--opacity', `${opacity}%`);
        }
      };

      window.addEventListener('scroll', scrollHandler, { passive: true });
      scrollHandler();
      return () => window.removeEventListener('scroll', scrollHandler);
    }, [],
  );

  return (
    <Wrapper data-type={type} ref={sectionRef}>
      {
        items.slice(0, 2).map(({ image, title }, index) => (
          <Item key={index}>
            <ImageWrapper><img src={image} alt={title} /></ImageWrapper>
            <h4>{title}</h4>
          </Item>
        ))
      }
    </Wrapper>
  );
};