import styled from 'styled-components';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { Marquee } from './Marquee';
import Text from './Text';
import { Checkmark } from '../../icons/Checkmark';
import { FC } from 'react';
import { INotificationItemProps } from './NotificationItem';

const Wrapper = styled.div<{ $read: boolean }>`
  gap: 0 1rem;
  z-index: 0;
  flex-direction: row;
  background: ${({ $read }) => $read ? 'rgba(24, 95, 91, 0.3)' : 'none'};
  backdrop-filter: ${({ $read }) => $read ? 'blur(2px)' : 'none'};
  margin: 0 calc(-1 * var(--nav-padding)) 0 -3.375rem;
  padding: 1.5rem var(--nav-padding) 1rem 3.375rem;

  &:hover {
    text-decoration: none;
  }

  ${BREAKPOINT_MD} {
    gap: 0 .625rem;
  }
`;

// TODO add correct svg / png background
const Background = styled.div`

  &:before {
    display: block;
    content: '';
    position: absolute;
    bottom: 1rem;
    height: 100%;
    width: 100%;
    background-image: url('/images/collectible-glow.png');
    background-size: 100%;
  }

`;

const CollectibleImage = styled.img`
  z-index: 2;
  position: absolute;
  top: -1.5rem;
`;

const StyledMarquee = styled(Marquee)`
  transform: unset;
  z-index: 1;
  height: .825rem;
  font-size: .75rem;
  line-height: 1;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const CreatorIcon = styled.img`
  position: absolute;
  bottom: -.25rem;
  right: -.25rem;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 100%;
  object-fit: cover;
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 6.25rem;
  height: 6.25rem;
  background-image: url('/images/collectible-glow.png');
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpComing = styled.p`
  font-size: 0.875rem;
  color: var(--color-primary);
`;

const StyledText = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

const StyledCheckmark = styled(Checkmark)`
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
`;

export const NotificationRevealItem: FC<INotificationItemProps> = ({
  rarity,
  image,
  publisherLogoUrl,
  headline,
  read,
  id,
  time,
}) => (
  <Wrapper $read={!read}>
    <ImgWrapper>
      {rarity > 1 && (
        <StyledMarquee rarity={rarity} />
      )}
      <CreatorIcon
        src={publisherLogoUrl}
        alt={'Publisher Logo'} />
      <CollectibleImage
        src={image}
        alt={'Product img'} />
      <Background />
    </ImgWrapper>
    <Inner>
      <TextWrapper>
        <UpComing>{time}</UpComing>
        <StyledText content={headline} />
      </TextWrapper>
      <StyledCheckmark />
    </Inner>
  </Wrapper>
);