import { FC } from 'react';
import styled from 'styled-components';

const Content = styled.p`
  a {
    text-decoration: underline;
  }
`;

interface ITextProps {
  content: string;
  className?: string;
  as?: string;
}

export const Text: FC<ITextProps> = ({ content, className, as }) => (
  <Content as={as as any} className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

export default Text;
