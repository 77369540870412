import { ethers, Wallet } from "ethers";
import { useRecoilState, useResetRecoilState } from "recoil";
import { WalletState } from "../state/wallet";

export const useWallet = () => {
  const [encryptedWallet, setEncryptedWallet] = useRecoilState(WalletState);

  const removeWallet = useResetRecoilState(WalletState);

  /**
   * Encrypts the provided wallet with the also provided password
   * @param wallet Wallet object from ethers
   * @param password Encryption string
   * @param callback Callback function that provided a number between 0 - 1 for loading animations
   * @returns Encrypted wallet json
   */
  const encrpytWallet = async (wallet: Wallet, password: string, callback?: (progress: number) => void) => {
    return await wallet.encrypt(password , {}, callback);
  };

  /**
   * Decrypts the wallet that was encrypted before
   * @param password Encryption string
   * @returns Decrypted wallet
   */
  const decryptWallet = (password: string) => {
    if (typeof encryptedWallet === 'string') {
      return ethers.Wallet.fromEncryptedJson(encryptedWallet, password);
    }
  };

  /**
   * Encrypts the wallet provided and saves it into the store
   * @param wallet Wallet object from ethers
   * @param password Encryption string
   */
  const storeEncryptedWallet = async (wallet: Wallet, password: string, callback?: (progress: number) => void) => {
    const encryptedWallet = await encrpytWallet(wallet, password, callback);
    setEncryptedWallet(encryptedWallet);
    return encryptedWallet;
  };

  /**
   * Creates a new wallet
   */
  const createWallet = () => {
    return ethers.Wallet.createRandom();
  };

  /**
   * Imports a wallet by its mnemonic and saves it encrypted inside of the store
   * @param mnemonic Mneumonic string
   */
  const importWallet = (mnemonic: string) => {
    return ethers.Wallet.fromMnemonic(mnemonic);
  };

  /**
   * Imports a wallet by its mnemonic and saves it encrypted inside of the store
   * @param mnemonic Mneumonic string
   */
  const importWalletFromPrivateKey = (privateKey: string) => {
    return new ethers.Wallet(privateKey);
  };

  /**
   * Imports a wallet by its mnemonic and saves it encrypted inside of the store
   * @param mnemonic Mneumonic string
   * @param password Encryption string
   */
  const storeWallet = (wallet: Wallet, password: string, callback?: (progress: number) => void) => {
    return storeEncryptedWallet(wallet, password, callback);
  }

  return {
    createWallet,
    decryptWallet,
    importWallet,
    importWalletFromPrivateKey,
    storeWallet,
    removeWallet,
    encryptedWallet,
  }
}