import { createGlobalStyle } from 'styled-components';
import { FontFaces, FontFamilies, FontTheme } from './Fonts';
import { Colors, ColorTheme } from './Colors';
import { PageTransitions } from './PageTransitions';
import { BREAKPOINT_MD } from './Breakpoints';
import { Body2, Headline2, Headline3, Headline4, Headline5, Headline6 } from './FontStyles';

export const GlobalStyles = createGlobalStyle`
  ${FontFaces}
  ${FontTheme}
  ${ColorTheme}
  ${PageTransitions}
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 16px;
    overscroll-behavior-x: none;
  }

  body {
    background-color: ${Colors.background};
    font-family: ${FontFamilies.default};
    color: ${Colors.textDefault};
    ${Body2};
    max-width: 100%;
    overflow-x: hidden;

    scroll-behavior: smooth;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    &.no-scroll {
      overflow: hidden;
    }
  
    &.menu-open , &.notification-open {
      main > :not(nav) {
        transition: opacity 450ms ease-out;
        opacity: 0;

        ${BREAKPOINT_MD} {
          opacity: 1;
        }
      }
    }
  }

  main > :not(nav) {
    transition: opacity 450ms ease-out 250ms;
  }

  .app, html, body {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FontFamilies.headline};
    text-transform: uppercase;
  }

  h2 {
    ${Headline2};
  }

  h3 {
    ${Headline3};
  }

  h4 {
    ${Headline4};
  }

  h5 {
    ${Headline5};
  }

  h6 {
    ${Headline6};
  }
  
  p {
    ${Body2};
  }

  a {
    color: inherit;
    text-decoration: none;

    &[href=''] {
      display: contents;
      cursor: default;
    }

    @media (hover: hover) {
      :hover {
        text-decoration: underline;
      }
    }
  }

  textarea,
  select,
  button,
  input {
    font: inherit;
    color: inherit;
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0;
    outline: 0;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    &:focus {
      outline: 0;
    }
  }

  p,
  span,
  a {
    font: inherit;
  }

  svg {
    height: 1em;
    width: auto;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  picture {
    display: contents;
  }

  q:before,
  q:after {
    content: "";
  }

  fieldset {
    appearance: none;
    border: 0;
  }
`;