import { FC, useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ICollectible } from '../../../services/collectible';
import { useGetLocalizedString } from '../../../services/localization';
import { useDataCache } from '../../../state/dataCache';
import { usePurchaseState } from '../../../state/purchase';
import { BREAKPOINT, BREAKPOINT_LG, BREAKPOINT_MD } from '../../../styles/Breakpoints';
import { SecondaryButton, Tooltip } from '../../../styles/Buttons';
import { Colors } from '../../../styles/Colors';
import { Body2, Body2Bold, Body3, Body3Bold } from '../../../styles/FontStyles';
import { PageAppear } from '../../../styles/PageTransitions';
import FormWrapper from '../../common/forms/FormWrapper';
import { RadioInput } from '../../common/forms/RadioInput';
import { BackButton } from '../../common/general/BackButton';
import { LoadingAnimation } from '../../common/general/LoadingAnimation';
import { AmexIcon } from '../../icons/AmexIcon';
import { MastercardIcon } from '../../icons/MastercardIcon';
import { PayPalIcon } from '../../icons/PayPalIcon';
import { VisaIcon } from '../../icons/VisaIcon';
import { PromoCode } from '../../icons/PromoCode';
import { TrianglePointer } from '../../icons/TrianglePointer';
import { ErrorNotification } from '../registration/SignUp';
import { useAccount } from 'wagmi';
import { Input } from '../../common/forms/Input';
import { usePurchaseAPI } from '../../../services/purchase';
import { truncateWalletAddress } from '../../../utils/truncate';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  // page transition
  ${PageAppear};
`;

const BackBtn = styled(BackButton)`
  align-self: flex-start;
  margin: 1.25rem 0 1.5rem;

  ${BREAKPOINT_LG} {
    margin: 0 0 1.5rem;
  }
`;

const DescriptionTitle = styled.h2`
  ${Body2Bold};
  text-transform: none;
  margin-bottom: 0.5rem;

  ${BREAKPOINT_LG} {
    ${Body3Bold};
  }
`;

const DescriptionText = styled.p`
  ${Body2};
  margin-bottom: 1rem;

  ${BREAKPOINT_MD} {
    margin-bottom: 0.5rem;
  }

  ${BREAKPOINT_LG} {
    ${Body3};
  }
`;

const PaymentFormWrapper = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
`;

const CurrencyFormWrapper = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

const PromoCodeFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  text-transform: none;
  cursor: pointer;
`;

const PromoCodeForm = styled(FormWrapper)<{ visible: boolean }>`
  display: ${({ visible }) => visible ? 'block' : 'none'};
`;

const InputSquezeed = styled(Input)`
  width: 60%;
`

const RadioItem = styled(RadioInput)`
  label {
    padding: 1.5rem 2rem;
  }

  svg {
    font-size: 1.5rem;
  }
`;

const Separator = styled.div`
  height: 0.5px;
  background-color: ${Colors.textDefault};
`;

const ContinueButton = styled(SecondaryButton)`
  align-self: center;
  margin-top: 1.5rem;

  ${BREAKPOINT(1440)} {
    margin-top: 3rem;
  }
`;

const WalletConnectWrapper = styled.div`
  // align-self: center;
  // margin-top: 4rem;
  // text-overflow: ellipsis;
`;

interface IPaymentMethodProps {
  onBackButtonClick?: () => void;
  onSubmit: () => void;
  collectible?: ICollectible | null;
}

export const PaymentMethod: FC<IPaymentMethodProps> = (props) => {
  const { onBackButtonClick, onSubmit, collectible } = props;

  const getLocalizedString = useGetLocalizedString();
  const [purchaseState, setPurchaseState] = usePurchaseState();

  const [dataCache] = useDataCache();
  const { profileData = null } = dataCache;

  const form = useForm({
    mode: 'onChange',
    defaultValues: { payment: purchaseState?.paymentMethod, walletAddress: profileData?.profile.walletAddress },
  });
  const currencyForm = useForm({mode: 'onChange', defaultValues: purchaseState?.currency });
  const promoCodeForm = useForm({ mode: 'onChange'});
  const [errorState, setErrorState] = useState<{ headline: string; text: string } | null>(null);

  const { address, isConnected } = useAccount();

  const [loading, setLoading] = useState(false);
  const [showPaypal, setShowPaypal] = useState(true);
  const [isPromoCodeVisible, setPromoCodeVisible] = useState(false);

  const isValid = useMemo(() => {
    return form.formState.isValid && currencyForm.formState.isValid;
  }, [form.formState.isValid, currencyForm.formState.isValid]);

  const purchaseAPI = usePurchaseAPI();

  const currencies = collectible?.priceInCurrencies.map(({ currencyShortcode, price }) => ({
    value: currencyShortcode,
    name: currencyShortcode,
  }));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });

    const defaultCurrency = collectible?.priceInCurrencies?.find((c) => c.currencyShortcode === 'EUR');
    setPurchaseState((state) => ({
      ...(state as any),
      currency: defaultCurrency,
      walletAddress: profileData?.profile.walletAddress,
    }));

    if (defaultCurrency) {
      currencyForm.setValue('currencyShortcode', defaultCurrency?.currencyShortcode);
      currencyForm.setValue('currencySymbol', defaultCurrency?.currencySymbol);
      currencyForm.setValue('price', defaultCurrency?.price);
    }
  }, []);

  // useEffect(() => {
  //   setPurchaseState((state) => ({
  //     ...(state as any),
  //     walletAddress: profileData?.profile.walletAddress,
  //   }));
  // }, [profileData]);

  useEffect(() => {
    if (purchaseState?.currency?.currencyShortcode === 'ARS') {
      form.setValue('payment', 'stripe');
      setShowPaypal(false);
    } else {
      setShowPaypal(true);
    }
  }, [purchaseState?.currency]);

  useEffect(() => {
    if (!address) {
      form.setValue('walletAddress', profileData?.profile.walletAddress);
    }
  }, [address]);

  useEffect(() => {
    if (purchaseState?.promoCode) {
      promoCodeForm.setValue('code', purchaseState?.promoCode);
      setPromoCodeVisible(true);
    }
  }, [purchaseState?.promoCode]);

  const submitPurchase = async (e: FieldValues) => {
    setPurchaseState((state) => ({
      ...(state as any),
      paymentMethod: (e as any).payment,
      walletAddress: (e as any).walletAddress,
      currency: {
        currencyShortcode: currencyForm.getValues().currencyShortcode,
        currencySymbol: currencyForm.getValues().currencySymbol,
        price: currencyForm.getValues().price,
      },
    }));

    onSubmit?.();
  }

  const handleSubmit = async (e: FieldValues) => {
    if(!purchaseState?.promoCode){
      setPurchaseState((state) => ({
        ...(state as any),
        discountedPrice: null,
      }));
      submitPurchase(e);
    }
    else{
      purchaseAPI
      .applyDiscount(purchaseState?.promoCode, purchaseState.currency?.price || 0)
      .then((res) => {
          setPurchaseState((state) => ({
            ...(state as any),
            discountedPrice: res.data.toFixed(2),
          }));
          submitPurchase(e);
      })
      .catch((err) => {
        console.error('VALIDATE PROMO CODE ERROR', err?.response?.status, err?.response?.data);
        setErrorState({
          headline: getLocalizedString('app.v2.checkout.payment.fanseapromocode.invalid.headline'),
          text: getLocalizedString('app.v2.checkout.payment.fanseapromocode.invalid.text'),
        });
      });
    }
  };

  const handleChangeCurrencies = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCurrency = collectible?.priceInCurrencies?.find((c) => c.currencyShortcode === e?.target?.value);
    if (selectedCurrency) {
      setPurchaseState((state) => ({
        ...(state as any),
        currency: selectedCurrency,
      }));
      currencyForm.setValue('currencyShortcode', selectedCurrency?.currencyShortcode);
      currencyForm.setValue('currencySymbol', selectedCurrency?.currencySymbol);
      currencyForm.setValue('price', selectedCurrency?.price);
    }
  };

  const handleChangePromoCode = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const userProvidedPromoCode = e?.target?.value;
    setPurchaseState((state) => ({
      ...(state as any),
      promoCode: userProvidedPromoCode || "",
    }));
  };

  const handleShowPromoCode = async () => {
    setPromoCodeVisible(!isPromoCodeVisible);
  };

  return (
    <Wrapper>
      <BackBtn onClick={onBackButtonClick} />

      {currencies && (
        <>
          <DescriptionTitle>{getLocalizedString('app.v2.checkout.currency.description-title')}</DescriptionTitle>
          <DescriptionText>{getLocalizedString('app.v2.checkout.currency.description-text')}</DescriptionText>
          <CurrencyFormWrapper form={currencyForm as any} onChange={handleChangeCurrencies}>
            {currencies.map((c) => {
              return <RadioItem key={c.name} name={'currency'} value={c.value} label={c.name} required defaultChecked={c.name === 'EUR'} />;
            })}
          </CurrencyFormWrapper>
        </>
      )}

      <DescriptionTitle>{getLocalizedString('app.v2.checkout.payment.description-title')}</DescriptionTitle>
      <DescriptionText>{getLocalizedString('app.v2.checkout.payment.description-text')}</DescriptionText>

      <PaymentFormWrapper onSubmit={handleSubmit} form={form as any}>
        <RadioItem
          className='cy-radio-stripe'
          name={'payment'}
          value={'stripe'}
          label={
            <>
              <MastercardIcon key={'mastercard'} />
              <VisaIcon key={'visa'} />
              <AmexIcon key={'amex'} />
              {getLocalizedString('app.v2.checkout.payment.method.stripe')}
            </>
          }
          placeholder={getLocalizedString('app.v2.checkout.payment.method.stripe')}
          required
        />
        <Separator />
        {showPaypal && (
          <RadioItem
            className='cy-radio-paypal'
            name={'payment'}
            value={'paypal'}
            label={
              <>
                <PayPalIcon key={'paypal'} />
                {getLocalizedString('app.v2.checkout.payment.method.paypal')}
              </>
            }
            placeholder={getLocalizedString('app.v2.checkout.payment.method.paypal')}
            required
          />
        )}
        <br/>
        <PromoCodeFormWrapper>
          <DescriptionTitle>
            <a onClick={handleShowPromoCode}>{getLocalizedString('app.v2.checkout.payment.fanseapromocode.header')}<PromoCode /><TrianglePointer direction={isPromoCodeVisible? '':'right'}/></a>
          </DescriptionTitle>
          <PromoCodeForm form={promoCodeForm as any} onChange={handleChangePromoCode} visible={isPromoCodeVisible}>
            <DescriptionText>{getLocalizedString('app.v2.checkout.payment.fanseapromocode.description')}</DescriptionText>
            <br />
            <InputSquezeed
              name={'code'}
              // label={getLocalizedString('app.v2.checkout.payment.fanseapromocode.codelabel')}
              placeholder={getLocalizedString('app.v2.checkout.payment.fanseapromocode.codeexample')}
              options={{ required: false }}
              error={{
                validate: getLocalizedString('app.v2.checkout.payment.fanseapromocode.invalid'),
              }}
            />
          </PromoCodeForm>
        </PromoCodeFormWrapper>

        {profileData?.profile.walletAddress && (
          <WalletConnectWrapper>
            <DescriptionTitle>{getLocalizedString('app.v2.connect-wallet.profile-wallet.title')}</DescriptionTitle>
            <DescriptionText>{getLocalizedString('app.v2.connect-wallet.profile-wallet.desc')}</DescriptionText>
            {/* <ProfileWallet>
              {getLocalizedString('app.v2.connect-wallet.profile-wallet.label')} {profileData?.profile.walletAddress}
            </ProfileWallet> */}
            <RadioItem
              className='cy-radio-wallet'
              name={'walletAddress'}
              value={profileData?.profile.walletAddress as any}
              label={`${truncateWalletAddress(profileData?.profile.walletAddress)} ${getLocalizedString('app.v2.connect-wallet.profile-wallet.fansea-signup')}`}
              placeholder={'User Wallet'}
              required
            />
            {isConnected && profileData?.profile.walletAddress !== address && (
              <RadioItem
                className='cy-radio-wallet'
                name={'walletAddress'}
                value={address as any}
                label={`${truncateWalletAddress(address)}`}
                placeholder={'Connected Wallet'}
                required
              />
            )}
            {/* <RadioItem
            className='cy-radio-paypal'
            name={'payment'}
            value={'paypal'}
            label={
              <>
                <PayPalIcon key={'paypal'} />
                {getLocalizedString('app.v2.checkout.payment.method.paypal')}
              </>
            }
            placeholder={getLocalizedString('app.v2.checkout.payment.method.paypal')}
            required
          /> */}
            {/* {isConnected ? (
            <VerifytWalletCTA>
              {!walletMismatch && (
                <WalletMismatchWarning>{getLocalizedString('app.v2.connect-wallet.mismatch.label')}</WalletMismatchWarning>
              )}
              {getLocalizedString('app.v2.connect-wallet.connected.label')} ${address}
            </VerifytWalletCTA>
          ) : (
            <>
              <VerifyWallet>{getLocalizedString('app.v2.connect-wallet.not-connected.label')}</VerifyWallet>
              <br />
              <ProfileWallet>
                {getLocalizedString('app.v2.connect-wallet.profile-wallet.label')} {profileData?.profile.walletAddress}
              </ProfileWallet>
            </>
          )} */}
          </WalletConnectWrapper>
        )}

        {errorState && <ErrorNotification {...errorState} autoScroll onClose={() => setErrorState(null)} />}

        <ContinueButton className='cy-button-next' disabled={!isValid}>
          {loading ? <LoadingAnimation /> : getLocalizedString('app.v2.general.continue')}
        </ContinueButton>

        {/* {typeof encryptedWallet === 'string'
          ?
          <span />
        ) : (
          <>
            <br />
            <VerifytWalletCTA>{getLocalizedString('app.v2.verify-wallet.purchase.warning')}</VerifytWalletCTA>
            <VerifyWallet onClick={() => navigate('/connect-wallet', { state: { redirect: location } })}>
              {getLocalizedString('app.v2.profile.user.wallet.connect')}
            </VerifyWallet>
          </>
        } */}
      </PaymentFormWrapper>
    </Wrapper>
  );
};
