import styled, { css } from 'styled-components';
import { Body3Bold } from './FontStyles';
import { BREAKPOINT_MD, MAX_BREAKPOINT_MD } from './Breakpoints';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowIcon } from '../components/icons/ArrowIcon';
import { useLocalizedString } from '../services/localization';
import { LinkHandler, TLink } from '../components/common/general/LinkHandler';

export const ButtonHover = css`
  transition: box-shadow 250ms ease-out;

  @media (hover: hover) {
    :hover {
      box-shadow: var(--box-shadow-hover);
    }
  }

  :focus-visible {
    box-shadow: var(--box-shadow-hover);
  }
`;

export const DropShadowHover = css`
  transition: filter 250ms ease-out, text-shadow 250ms ease-out;

  @media (hover: hover) {
    :hover {
      filter: drop-shadow(var(--box-shadow-hover));
      text-shadow: var(--box-shadow-hover);
    }
  }

  :focus-visible {
    filter: drop-shadow(var(--box-shadow-hover));
    text-shadow: var(--box-shadow-hover);
  }
`;

export const PrimaryButton = styled.button<{ $highlighted?: boolean }>`
  appearance: none;
  width: max-content;
  min-width: 15rem;
  height: 48px;
  padding: 0 1rem;
  border: 1px solid var(--color-text-default);
  border-radius: 2px;
  background: var(--color-grey-800);
  white-space: nowrap;
  line-height: 1;
  text-transform: uppercase;

  ${Body3Bold};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ButtonHover};

  :disabled {
    pointer-events: none;
    color: var(--color-grey-700);
    border-color: var(--color-grey-700);
    background: var(--color-grey-800);
  }
  
  transition: background 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out;
  
  ${({ $highlighted = false }) => $highlighted && css`
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-text-highlight);

    :disabled {
      border-color: var(--color-grey-800);
    }
  `};
`;

export const SecondaryButton = styled(PrimaryButton).attrs({ $highlighted: true })``;

export const TertiaryButton = styled(PrimaryButton)`
  border-color: var(--color-grey-800);

  :disabled {
    border-color: var(--color-grey-800);
  }
`;

export const InlineButton = styled.button`
  appearance: none;
  width: max-content;
  ${Body3Bold};
  color: var(--color-primary);
  font-weight: 600;
  text-decoration: underline;
`;

export const BlurredButton = styled(PrimaryButton)<{ buttonIcon?: string }>`
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.24) 20%, rgba(255, 255, 255, 0) 95%), rgba(75, 75, 75, 0.2);
  backdrop-filter: blur(4px);
  font-weight: normal;
  text-transform: none;
  border-radius: .125rem;
  border: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);

  ${MAX_BREAKPOINT_MD} {
    display: inline-flex;
    padding: .625rem 1.25rem;
    height: auto;
    width: auto;
    min-width: auto;
    font-size: .75rem;
  }

  ${({ buttonIcon }) => buttonIcon && css`
    :before {
      display: block;
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      background-size: contain;
      background-position: center center;
      margin-right: .25rem;
    }
  `}

  ${({ buttonIcon }) => buttonIcon === 'plus' && css`
    :before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI1IDI0Ij4KICA8cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xMy4yMjMgMTcuNXYtMTFtLTUuNSA1LjVoMTEiLz4KPC9zdmc+Cg==")
    }
  `}
`;

export const CircleArrowInner = styled.div`
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--color-primary);

  ${BREAKPOINT_MD} {
    height: 1.75rem;
    width: 1.75rem;
  }

  :after,
  :before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5h14M5 1 1.707 4.293a1 1 0 0 0 0 1.414L5 9' stroke='currentColor'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    transform: rotate(180deg);
  }

  :after {
    transform: rotate(180deg) translateX(100%);
  }
`;

const CirlceArrowButtonStyles = css`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  color: var(--color-primary);
  text-transform: uppercase;
  gap: .75rem;

  :hover {
    text-decoration: none;
    filter: drop-shadow(0px 0px 11px var(--color-primary));

    ${CircleArrowInner} {
      :before {
        transform: rotate(180deg) translateX(-100%);
        transition-timing-function: ease-in;
        transition-duration: .4s;
      }

      :after {
        transform: rotate(180deg) translateX(0%);
        transition-timing-function: ease-out;
        transition-delay: .2s;
        transition-duration: .3s;
      }
    }
  }
`;

const CircleArrowButtonWrapper = styled.button`
  ${CirlceArrowButtonStyles}
`;

const CircleArrowLink = styled(LinkHandler)`
  ${CirlceArrowButtonStyles}
`;

type TCircleArrowButton = Omit<TLink, 'to'> & {
  to?: string;
}

export const CircleArrowButton: FC<TCircleArrowButton> = (props) => {
  const { children, ...componentProps} = props;
  const Component = (props.to ? CircleArrowLink : CircleArrowButtonWrapper) as React.ElementType;

  return (
    <Component {...componentProps}>
      {children}
      <CircleArrowInner />
    </Component>
  );
};

const BackButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;

  font-size: .875rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;

  svg {
    height: .625rem;
    width: auto;
    margin-right: .375rem;
    margin-top: -.125rem;
  }
`;

interface BackButtonWrapperProps {
  className?: string;
}

export const BackButton: FC<BackButtonWrapperProps> = (props) => {
  const buttonText = useLocalizedString('app.v2.general.cta.back');
  const {
    className,
  } = props;

  const navigate = useNavigate();

  return (
    <BackButtonWrapper onClick={() => navigate(-1)} className={className}>
      <ArrowIcon />{buttonText}
    </BackButtonWrapper>
  );
};

export const Tooltip = styled.p`
  position: absolute;
  
  font-size: .75rem;
  line-height: 1.125;
  color: var(--color-text-default);
  
  background: linear-gradient(144.32deg, rgba(255, 255, 255, 0.24) 11.51%, rgba(255, 255, 255, 0) 94.95%), rgba(75, 75, 75, 0.2);
  border: 1px solid var(--color-text-default);
  border-radius: .125rem;
  
  backdrop-filter: blur(8px);
  padding: .25rem 1rem;
  
  transition: opacity 450ms ease, visibility 450ms ease;
  visibility: var(--tooltip-visibility, hidden);
  opacity: var(--tooltip-opacity, 0);
  
  // delay tooltip display
  transition-delay: calc((.5 + 1.5 * var(--tooltip-opacity, 0)) * 1s);
`;
