import { useRecoilState, useSetRecoilState } from 'recoil';
import { NewNotificationsState, NotificationOpenState } from '../../state/notification';
import { BackButton, MenuWrapper, NavigationWrapper } from './Menu';
import { ArrowIcon } from '../icons/ArrowIcon';
import { HeaderButtons } from './Header';
import styled from 'styled-components';
import { BREAKPOINT_LG, BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from '../../styles/Breakpoints';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useActiveLocale, useGetLocalizedString } from '../../services/localization';
import { INotificationItemProps, NotificationItem } from '../common/general/NotificationItem';
import { INotification, useNotificationAPI } from '../../services/notification';
import { MenuOpenState } from '../../state/menu';
import usePrevious from '../../hooks/usePrevious';
import { useIsAuthenticated } from '../../services';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const Headline = styled.h3`
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 700;

  ${BREAKPOINT_LG} {
    font-size: 2.25rem;
  }
`;

const StyledMenuWrapper = styled(MenuWrapper)`
  overflow-y: auto;

  ${BREAKPOINT_MD} {
    background: linear-gradient(167.66deg, #0F0F0F 0%, rgba(26, 26, 26, 0.7) 99.31%);
    width: 31.5rem;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;

const GroupWrapper = styled.div`
  &:not(:last-child) {
    padding-bottom: .5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--color-primary-dark);
  }
`;

const GroupHeadline = styled.h4`
  font-size: 1rem;
  line-height: 1;
  margin-bottom: .5rem;
  text-transform: initial;
`;

export const NotificationSideBar = () => {
  const [notificationOpen, setNotificationOpen] = useRecoilState(NotificationOpenState);
  const setHasNewNotifications = useSetRecoilState(NewNotificationsState);
  const setMenuOpen = useSetRecoilState(MenuOpenState);
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const isDesktop = useMediaQuery(DIMEN_BREAKPOINT_MD);

  const isAuthenticated = useIsAuthenticated();
  const activeLocale = useActiveLocale();
  const location = useLocation();

  const getLocalizedString = useGetLocalizedString();
  const notificationAPI = useNotificationAPI();

  // Previous state of notificationOpen to needed to check whether the notifications had been closed
  const previousNotificationOpenState = usePrevious(notificationOpen);

  // Notifications ordered by specified dates
  const [sortedNotifications, setSortedNotifications] = useState<INotificationItemProps[][] | null>(null);

  // Unread notifications
  const [newNotifications, setNewNotifications] = useState<INotification[]>();

  // Notification filters mapped together with sortedNotifications to create a list of notifications
  const notificationFilters = ['today', 'yesterday', 'lastWeek', 'older'];

  const getHoursFromString = useCallback((time: string): number => {
    return (new Date().getTime() - new Date(time).getTime()) / 1000 / 60 / 60;
  }, []);

  // Sort notifications by creation date into an array list mappable onto notificationFilters
  const sortNotifications = useCallback((notifications: INotification[]) => {
    const today = new Date().toDateString();
    var yesterday = new Date().setDate(new Date().getDate() - 1);
    var lastWeek = new Date().setDate(new Date().getDate() - 7);

    return notifications.reduce((acc, curr) => {
      const createdAt = curr.commons.createdAt;
      const created = new Date(createdAt).getTime();
      const { commons, ...props } = curr;
      const flattenedNotification = { ...commons, ...props };
      if (created < lastWeek) {
        acc[3].push({
          ...flattenedNotification,
          time: `${Math.floor(getHoursFromString(createdAt) / 24)}d`,
        });
      } else if (created < yesterday) {
        acc[2].push({
          ...flattenedNotification,
          time: `${Math.floor(getHoursFromString(createdAt) / 24)}d`,
        });
      } else if (new Date(createdAt).toDateString() === today) {
        acc[0].push({
          ...flattenedNotification,
          time: `${Math.floor(getHoursFromString(createdAt))}h`,
        });
      } else {
        acc[1].push({
          ...flattenedNotification,
          time: `${Math.floor(getHoursFromString(createdAt))}h`,
        });
      }
      return acc;
    }, [[], [], [], []] as Array<INotificationItemProps[]>);
  }, [getHoursFromString]);

  // Get notifications marked as unread
  const getNewNotifications = useCallback((notifications: INotification[]) => {
    return notifications.filter(elem => !elem.commons.read);
  }, []);

  // Mark a list of notifications as read
  const markAsRead = async (notifications?: INotification[]) => {
    if (!notifications) return;
    const requests = notifications.map(elem => notificationAPI.readNotification(elem.commons.id));
    return await Promise.all(requests);
  };

  const fetchData = async () => {
    try {
      const notifications = (await notificationAPI.notifications()).data.notifications;
      const sortedNotifications = sortNotifications(notifications);
      const newNotifications = getNewNotifications(notifications);

      setNewNotifications(newNotifications);
      setHasNewNotifications(newNotifications.length > 0);
      setSortedNotifications(sortedNotifications);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated, activeLocale]);

  useEffect(() => {
    document.body.classList.toggle('notification-open', notificationOpen);
    if (notificationOpen) {
      setMenuOpen(false);
    }

    if (previousNotificationOpenState === true) {
      markAsRead(newNotifications);
    }

    const mainTag = document.querySelector('main');
    if (mainTag) {
      if (notificationOpen && !isDesktop) {
        mainTag.style.height = `${notificationRef.current?.scrollHeight}px`;
        mainTag.style.overflow = 'hidden';
      } else {
        mainTag.style.height = '';
        mainTag.style.overflow = '';
      }
    }
  }, [notificationOpen, isDesktop]);

  useEffect(() => {
    setNotificationOpen(false);
  }, [location, setNotificationOpen]);

  return (
    <StyledMenuWrapper open={notificationOpen} ref={notificationRef}>
      <NavigationWrapper>
        <BackButton onClick={() => setNotificationOpen(false)} visible>
          <ArrowIcon />
        </BackButton>
        <HeaderButtons />
      </NavigationWrapper>
      <Headline>
        {getLocalizedString('app.v2.notification.headline')}
      </Headline>
      {notificationFilters.map((filter, i) => (
        sortedNotifications && sortedNotifications?.[i].length > 0 && (
          <GroupWrapper>
            <GroupHeadline>{getLocalizedString(`app.v2.notification.${filter}.label`)}</GroupHeadline>
            <ItemWrapper>
              {sortedNotifications?.[i].map((item, index) => (
                <NotificationItem
                  {...item}
                  key={index}
                />
              ))}
            </ItemWrapper>
          </GroupWrapper>
        )
      ))}
    </StyledMenuWrapper>
  );
};