import styled from 'styled-components';
import FormWrapper from '../forms/FormWrapper';
import { Input } from '../forms/Input';
import { isEmail } from '../../../utils/validators';
import { useGetLocalizedString } from '../../../services/localization';
import { FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { DiscordIcon } from '../../icons/socialMedia/DiscordIcon';
import { InstagramIcon } from '../../icons/socialMedia/InstagramIcon';
import { LinkedInIcon } from '../../icons/socialMedia/LinkedInIcon';
import { TwitterIcon } from '../../icons/socialMedia/TwitterIcon';
import { SOCIAL_MEDIA_LINKS } from '../../layout/Menu';
import { GridLayout } from '../../../styles/Layout';
import { SecondaryButton } from '../../../styles/Buttons';
import { Headline4 } from '../../../styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL, BREAKPOINT_XS } from '../../../styles/Breakpoints';
import { useState } from 'react';
import { useNewsletterAPI } from '../../../services/newsletter';
import { LoadingAnimation } from '../general/LoadingAnimation';
import { useAddSystemNotification } from '../../../state/systemNotifications';
import { LinkHandler } from '../general/LinkHandler';

const NewsLetter = styled.section`

  &:last-child {
    margin-bottom: -4.5rem;
  }

  ${GridLayout};
  grid-column: 1 / -1 !important;
  padding: 3rem 0;
  background: linear-gradient(167.66deg, rgba(24, 95, 91, 0.95) 0%, rgba(26, 26, 26, 0.7) 99.31%);
  z-index: 1;

  ${BREAKPOINT_LG} {
    padding: 4rem 0;
  }
  
  ${BREAKPOINT_XL} {
    padding: 5rem 0;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
`;

const StyledFormWrapper = styled(FormWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  
  ${BREAKPOINT_SM} {
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
  }
`;

const MailInput = styled.div`
  width: 100%;

  ${BREAKPOINT_SM} {
    flex: 2;
  }
`;

const Button = styled(SecondaryButton)`
  ${BREAKPOINT_SM} {
    flex: 1;
    margin-top: 1.25rem;
  }
`;

const Headline = styled.h3`
  ${Headline4};
  margin-bottom: 1rem;
`;

const Text = styled.p`
  margin-bottom: 1.5rem;
`;

const Content = styled.div`
  text-align: center;
  grid-column: 1 / -1;
  
  ${BREAKPOINT_XS} {
    grid-column: 2 / -2;
  }
  
  ${BREAKPOINT_SM} {
    grid-column: 1 / -1;
    text-align: left;
  }
  
  ${BREAKPOINT_MD} {
    grid-column: 1 / 10;
  }
  
  ${BREAKPOINT_LG} {
    grid-column: 1 / 9;
  }
  
  ${BREAKPOINT_XL} {
    grid-column: 1 / 7;
  }
`;

const SocialsWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  
  ${BREAKPOINT_MD} {
    grid-column: -2;
    align-items: flex-end;
    flex-direction: column;
  }
`;

const Newsletter = () => {
  const form = useForm();
  const getLocalizedString = useGetLocalizedString();

  const newsletterAPI = useNewsletterAPI();
  const [loading, setLoading] = useState(false);
  const addSystemNotification = useAddSystemNotification();

  const submitForm = (values: FieldValues, e: any) => {
    const {
      email,
    } = values;

    e.preventDefault();

    setLoading(true);
    newsletterAPI.subscribe(email)
      .then((res) => {
        setLoading(false);
        addSystemNotification({
          type: 'success',
          headline: getLocalizedString('app.v2.newsletter.success200.title'),
          text: getLocalizedString('app.v2.newsletter.success200.text'),
        });
        form.reset();
      })
      .catch((err) => {
        setLoading(false);
        addSystemNotification({
          type: 'error',
          headline: getLocalizedString('app.v2.newsletter.error400.title'),
          text: getLocalizedString('app.v2.newsletter.error400.text'),
        });
        console.log('newsletter subscribe error', err?.response?.status, err?.response?.data);
      });
  }

  return (
    <NewsLetter>
      <Inner>
        <Content>
          <Headline>{getLocalizedString('app.v2.newsletter.headline')}</Headline>
          <Text>{getLocalizedString('app.v2.newsletter.text')}</Text>
          <StyledFormWrapper form={form} onSubmit={submitForm}>
            <MailInput>
              <Input
                name={'email'}
                label={getLocalizedString('app.v2.login.label.email')}
                type={'email'}
                options={{ required: true, validate: isEmail }}
                placeholder={getLocalizedString('app.v2.login.placeholder.email')}
                error={{
                  required: getLocalizedString('app.v2.login.validation.email.required'),
                  validate: getLocalizedString('app.v2.login.validation.email.invalid'),
                }}
              />
            </MailInput>
            <Button disabled={loading}>{ loading ? <LoadingAnimation /> : getLocalizedString('app.v2.newsletter.button.submit')}</Button>
          </StyledFormWrapper>
        </Content>
        <SocialsWrapper>
          <LinkHandler to={SOCIAL_MEDIA_LINKS.discord}> <DiscordIcon /></LinkHandler>
          <LinkHandler to={SOCIAL_MEDIA_LINKS.instagram}> <InstagramIcon /></LinkHandler>
          <LinkHandler to={SOCIAL_MEDIA_LINKS.linkedIn}> <LinkedInIcon /></LinkHandler>
          <LinkHandler to={SOCIAL_MEDIA_LINKS.twitter}> <TwitterIcon /></LinkHandler>
        </SocialsWrapper>
      </Inner>
    </NewsLetter>
  );
}

export default Newsletter;
