import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const QRCode: FC<IconProps> = ({ className }) => (
  <svg className={className} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.75' y='0.75' width='5.5' height='5.5' rx='1.25' stroke='currentColor' strokeWidth='1.5' />
    <rect x='0.75' y='9.75' width='5.5' height='5.5' rx='1.25' stroke='currentColor' strokeWidth='1.5' />
    <rect x='9.75' y='0.75' width='5.5' height='5.5' rx='1.25' stroke='currentColor' strokeWidth='1.5' />
    <path d='M9.75 15.25H15' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    <path d='M15 9.5L15 13' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    <path d='M12 11L15 11' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    <path d='M10 13L13 13' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
    <path d='M10 13V11' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);

export default QRCode;
