import styled, { css } from 'styled-components';
import { FC } from 'react';

const Overlay = styled.div<{ inline?: boolean; absolute?: boolean; visible: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: all;

  ${({ visible }) =>
    visible &&
    css`
      min-height: 60vh;
    `};

  ${({ inline = false }) =>
    !inline &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 16;

      background: rgba(26, 26, 26, 0.8);
      backdrop-filter: blur(21px);
    `};

  ${({ absolute = false }) =>
    absolute &&
    css`
      position: absolute;
    `};

  transition: opacity 300ms ease-out, transform 300ms ease-out, visibility 300ms ease-out;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'none' : 'scale(.5)')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const LoadingMesh = styled.object.attrs({ data: '/images/loading-mesh.svg', type: 'image/svg+xml', alt: '' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoadingTextWrapper = styled.div`
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
`;

interface ILoadingOverlayProps {
  className?: string;
  visible: boolean;
  inline?: boolean;
  absolute?: boolean;
  text?: string;
}

export const LoadingOverlay: FC<ILoadingOverlayProps> = (props) => {
  return (
    <Overlay {...props}>
      {props.text && (
        <LoadingTextWrapper>
          <p>{props.text}</p>
        </LoadingTextWrapper>
      )}
      <LoadingMesh />
    </Overlay>
  );
};

export const PageLoader = styled(LoadingOverlay).attrs({ inline: true })<{ visible: boolean }>`
  height: 0;
  transition: height 300ms ease-out;

  ${({ visible }) =>
    visible &&
    css`
      transition: height 0s;
      height: calc(100vh - var(--nav-height));
    `};
`;
