import { FC } from 'react';
import { IconProps } from '../../../types/Icon';

export const WhatsappIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512.000000 512.000000'
    preserveAspectRatio='xMidYMid meet'
  >
    <g
      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
      fill='currentColor'
      stroke='none'
    >
      <path
        d='M2415 5114 c-381 -39 -668 -122 -972 -279 -371 -192 -701 -488 -931 -833 -487 -731 -553 -1666 -172 -2447 32 -66 71 -142 86 -168 l28 -48 -41 -122 c-22 -67 -125 -369 -227 -671 -119 -351 -181 -546 -172 -543 8 3 321 103 695 222 l680 216 58 -30 c989 -499 2169 -314 2943 459 638 639 881 1573 634 2432 -274 955 -1072 1647 -2069 1794 -97 14 -459 26 -540 18z m423 -405 c377 -41 737 -185 1047 -418 124 -93 308 -276 402 -398 637 -831 562 -1991 -179 -2735 -422 -424 -990 -649 -1578 -625 -386 16 -692 110 -1057 323 l-31 18 -394 -124 c-216 -68 -398 -125 -404 -127 -6 -2 43 155 109 349 162 477 150 414 95 495 -344 512 -444 1160 -268 1751 213 718 831 1292 1563 1453 213 46 484 61 695 38z'
      />
      <path
        d='M1666 3739 c-80 -42 -195 -187 -236 -297 -12 -31 -29 -97 -38 -147 -38 -224 37 -459 241 -750 301 -430 599 -705 942 -870 194 -93 535 -207 662 -221 142 -15 331 45 470 150 113 86 160 181 170 349 5 75 3 90 -12 107 -32 36 -523 266 -575 270 -14 1 -33 2 -42 1 -10 -1 -61 -56 -115 -123 -143 -179 -157 -191 -207 -186 -58 7 -261 106 -381 186 -213 142 -525 503 -525 607 0 18 25 55 80 120 89 106 120 159 120 207 0 29 -157 423 -216 540 -36 72 -50 78 -184 78 -96 -1 -121 -4 -154 -21z'
      />
    </g>
  </svg>
);