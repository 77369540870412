import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginView, { ILoginFunctionProps } from '../components/views/login/Login';
import WelcomeBack from '../components/views/login/WelcomeBack';

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>();

  const navigateToApp = useCallback(() => {
    const { redirect = '/' } = location.state || {};
    navigate(redirect, { state: { login: true }});
  }, [location, navigate]);

  const login = ({ password }: ILoginFunctionProps) => {
    setPassword(password);
  }

  return password ? (
    <WelcomeBack
      onStartImport={() => navigate('/connect-wallet', { state: { ...location.state, password }})}
      onSkipImport={navigateToApp}
    />
  ) : (
    <LoginView onLogin={login} />
  )
};