import { FC } from 'react';
import { IconProps } from '../../types/Icon';

export const RegistrationIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 25 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.4238 7.625H21.6738V5.875C21.6738 5.39375 21.2837 5 20.7988 5C20.3139 5 19.9238 5.39193 19.9238 5.875V7.625H18.1738C17.6926 7.625 17.2988 8.01875 17.2988 8.5C17.2988 8.98125 17.6908 9.375 18.1738 9.375H19.9238V11.125C19.9238 11.6099 20.3176 12 20.7988 12C21.2801 12 21.6738 11.6081 21.6738 11.125V9.375H23.4238C23.9087 9.375 24.2988 8.9849 24.2988 8.5C24.2988 8.0151 23.9087 7.625 23.4238 7.625Z'
      fill='currentColor'
    />
    <ellipse
      rx='4'
      ry='4.11429'
      transform='matrix(-1 0 0 1 8.69922 5.11429)'
      stroke='currentColor'
      strokeWidth='1.5'
    />
    <path
      d='M1.69922 15.2531C1.69922 14.4145 2.22241 13.6649 3.00961 13.3757V13.3757C6.68269 12.0264 10.7157 12.0264 14.3888 13.3757V13.3757C15.176 13.6649 15.6992 14.4145 15.6992 15.2531V16.6846C15.6992 17.9049 14.6158 18.8407 13.4085 18.6633L12.6244 18.5481C10.0216 18.1657 7.37684 18.1657 4.77402 18.5481L3.98997 18.6633C2.78262 18.8407 1.69922 17.9049 1.69922 16.6846V15.2531Z'
      stroke='currentColor'
      strokeWidth='1.5'
    />
  </svg>
);