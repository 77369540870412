import styled from 'styled-components';
import { FC, ReactNode} from "react";
import { IUserCollectible } from "../../../services/collectible";
import CollectibleItemTeaser from '../special/CollectibleItemTeaser';
import { CollectibleItem } from '../special/CollectibleItem';
import { useFavoriteCollectibles } from '../../../hooks/useFavoriteCollectibles';
import ResponsiveItemSlider from '../general/ResponsiveItemSlider';
import ItemListWrapper from '../general/ItemListWrapper';
import { BREAKPOINT_MD } from '../../../styles/Breakpoints';

export const CollectibleList = styled.div`
  ${BREAKPOINT_MD} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(30%, 380px), 1fr));
    gap: 1rem 2rem;
  }
`;

interface ICollectibleItemList {
  collectibles?: IUserCollectible[];
  link?: ReactNode;
  headline?: string;
  type?: 'small' | 'full'
}

const CollectibleItemList: FC<ICollectibleItemList> = ({
  collectibles,
  link,
  headline,
  type = 'full'
}) => {
  const {favoritesIds} = useFavoriteCollectibles();

  return (
    <ItemListWrapper
      headline={headline}
      link={link}
      className="cy-collectibles-home"
    >
      {type === 'full' ? (
        <ResponsiveItemSlider items={collectibles}>
          {(collectible) => (
            <CollectibleItem className='cy-collectible-item' {...collectible} active={favoritesIds?.collectibles.includes(collectible.id) || false} />
          )}
        </ResponsiveItemSlider>
      ) : (
        <CollectibleList>
          {collectibles?.map(collectible => (
            <CollectibleItemTeaser {...collectible} key={collectible.id} {...collectible} active={favoritesIds?.collectibles.includes(collectible.id) || false}  />
          ))}
        </CollectibleList>
      )}
    </ItemListWrapper>
  );
};

export default CollectibleItemList;
