import { css } from 'styled-components';
import { BREAKPOINT_LG } from './Breakpoints';

export const GridLayout = css`
  display: grid;
  grid-template-columns: var(--page-grid);
  grid-template-rows: min-content;
  align-items: flex-start;
  align-content: flex-start;

  & > * {
    grid-column: 2;

    ${BREAKPOINT_LG} {
      grid-column: 3;
    }
  }
`;