import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Body1Bold, Body2Bold, Headline1 } from '../styles/FontStyles';
import { useGetLocalizedString } from '../services/localization';
import { PageView } from '../components/layout/PageLayout';

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BackToHome = styled(Link)`
  ${Body2Bold};
  font-weight: 600;
  color: var(--color-primary);
  text-decoration: underline;
`;

const Headline = styled.h1`
  ${Headline1};
  margin-bottom: 2.25rem;
`;

const Text = styled.p`
  ${Body1Bold};
  margin-bottom: 1rem;
`;

export const PageNotFound = () => {
  const getLocalizedString = useGetLocalizedString();

  return (
    <PageView>
      <Wrapper>
        <Headline>404</Headline>
        <Text>{getLocalizedString('app.v2.page-not-found.not-found')}</Text>
        <BackToHome to={'/'}>
          {getLocalizedString('app.v2.page-not-found.back-to-home')}
        </BackToHome>
      </Wrapper>
    </PageView>
  );
};
