import styled from 'styled-components';
import { Body1Bold, Body2Bold, Headline4 } from '../../../styles/FontStyles';
import { FC } from 'react';
import { IUserAlbumCollectible } from '../../../services/albums';
import { useActiveLocale, useGetLocalizedString } from '../../../services/localization';
import Tags from '../general/Tags';
import { BREAKPOINT_LG, BREAKPOINT_XL } from '../../../styles/Breakpoints';
import { Marquee } from '../general/Marquee';
import { AlbumCardItem, IAlbumCardItemProps } from './AlbumCardItem';
import { Colors } from '../../../styles/Colors';
import { AlbumCardCaptionStyle } from './AlbumCardCaption';

const ImageWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: 0;
`;

const MarqueeOverlay = styled(Marquee)`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  font-size: .875rem;

  height: 2rem;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: contain;
  pointer-events: none;
  user-select: none;
`;

const Title = styled.h4`
  ${Body2Bold};
  text-transform: none;
  text-align: left;
  
  height: 2.375rem;
  
  ${BREAKPOINT_LG} {
    height: 3rem;
  }

  ${BREAKPOINT_XL} { // 3 items on LG -> larger font on XL not LG
    ${Body1Bold};
    height: 3.75rem;
  }

  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const BottomLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;

  height: 1.5rem;

  ${BREAKPOINT_LG} {
    height: 2.375rem;
  }
`;

const Price = styled.div`
  ${Headline4};

  display: flex;
  align-items: center;
`;

export const OwnedMark = styled.img.attrs({ src: '/images/bought-checkmark.png', alt: '' })`
  width: 1.25rem;
  height: 1.25rem;
  object-fit: contain;

  pointer-events: none;
  user-select: none;

  ${BREAKPOINT_LG} {
    width: 1.875rem;
    height: 1.875rem;
  }
`;

const Utilities = styled(Tags)`
  flex: 1;
`;

const BonusOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, .8) 48.52%, rgba(0, 0, 0, .3) 100%);
  z-index: 5;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BonusOverlayText = styled.h6<{ locale: string }>`
  ${AlbumCardCaptionStyle};
  text-align: center;
  padding: .5rem;
  
  color: var(--color-grey-900);
  text-shadow: 0px 0px 10px ${Colors.primary};
  -webkit-text-stroke-color: ${Colors.primary};
  -webkit-text-stroke-width: 1px;

  --text-size-multiplier: ${({ locale = 'en' }) => {
    switch (locale) {
      case 'en':
        return 1;
      case 'de':
        return .85;
      case 'es':
      default:
        return .75;
    }
  }};
`;

interface IAlbumCollectibleProps extends Partial<IUserAlbumCollectible>, Omit<IAlbumCardItemProps, 'renderTitle'> {
  //
}

export const AlbumCollectibleItem: FC<IAlbumCollectibleProps> = (props) => {
  const {
    // album collectible props
    title,
    image,
    price,
    utilities,
    isBonus,
    totalCollectibleSupply,
    collectibleIndex,

    // card props
    className,
    style,
    rarity = 1,
    active = false,
    withBorder = !active,
    onClick,
    to,
    fullscreen,
  } = props;

  const isOwned = (props as IUserAlbumCollectible).isOwned || false;

  const getLocalizedString = useGetLocalizedString();
  const locale = useActiveLocale();

  return (
    <AlbumCardItem
      className={className}
      style={style}
      holoEffect={isBonus}
      fillBackground={isOwned || isBonus}
      rarity={rarity}
      active={active}
      withBorder={withBorder}
      onClick={onClick}
      to={to}
      fullscreen={fullscreen}
      renderTitle={() => (
        isOwned
          ? <><span>{collectibleIndex || 0}</span>/{totalCollectibleSupply || 0}</>
          : <p>{`${getLocalizedString('app.v2.album-collectibles.label.quantity')} ${totalCollectibleSupply || 0}`}</p>
      )}
    >
      <MarqueeOverlay rarity={rarity} />
      <ImageWrapper>
        <Image src={image} alt={`${title} cover`} />
      </ImageWrapper>
      <Title>{title}</Title>
      <BottomLine>
        {
          isOwned &&
          <OwnedMark />
        }
        {
          !isOwned &&
          <Price>
            {
              price === 0
                ? isBonus ? '' : getLocalizedString('app.v2.general.freemint-price')
                : `${price?.toLocaleString(undefined, {
                  minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
                  useGrouping: true,
                })}€`
            }
          </Price>
        }
        <Utilities align={'flex-end'} tags={utilities} />
      </BottomLine>
      {
        isBonus && !isOwned &&
        <BonusOverlay>
          <BonusOverlayText locale={locale || 'en'}>
            {getLocalizedString('app.v2.album-collectibles.card.bonus-collectible')}
          </BonusOverlayText>
        </BonusOverlay>
      }
    </AlbumCardItem>
  );
};