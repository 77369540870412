import styled, { keyframes } from 'styled-components';
import { IAlbumContentItem, IAlbumDetails } from '../../../services/albums';
import { FC, useEffect, useMemo, useRef } from 'react';
import { CollectiblesTeaserWall } from '../albums/content/CollectiblesTeaserWall';
import { BackButton } from '../general/BackButton';
import { SecondaryButton } from '../../../styles/Buttons';
import { LinkHandler } from '../general/LinkHandler';
import { useGetLocalizedString } from '../../../services/localization';
import { Headline2 } from '../../../styles/FontStyles';
import { BREAKPOINT_LG, BREAKPOINT_MD, DIMEN_BREAKPOINT_LG } from '../../../styles/Breakpoints';
import { Countdown } from "../../common/special/Countdown";

const Wrapper = styled.section`
  
`;

const ContentWrapper = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
`;

const CollectionButton = styled(SecondaryButton).attrs({ as: LinkHandler })<{ to: string }>`
  margin: 0 auto 1rem;

  @media (min-height: 750px) {
    margin: 0 auto 4.5rem;
  }

  ${BREAKPOINT_LG} {
    margin: 0 auto 7.5rem;
  }
`;

const Title = styled.h1`
  ${Headline2};
  overflow: hidden;
  text-overflow: ellipsis;
  
  font-size: 4rem;
  line-height: 1;
  
  ${BREAKPOINT_LG} {
    font-size: 5.25rem;
    line-height: 1;
  }
  
  margin-bottom: 12.5rem;
`;

const Ellipse = styled.div`
  --size: 5.5rem;
  --startY: 5.5rem;

  @media (min-height: 750px) {
    --startY: 7.5rem;
  }
  
  ${BREAKPOINT_MD} {
    --size: 9rem;
  }
  
  position: fixed;
  bottom: var(--startY, -50%);
  left: var(--startX, -50%);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  z-index: -1;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    margin: auto;
    opacity: var(--icon-opacity, 1);
    
    ${BREAKPOINT_MD} {
      width: 3rem;
      height: 3rem;
    }
  }
  
  transform: translate(var(--x, 0), var(--y, 0));
  transition: none;
  
  will-change: transform;
`;

const EllipseContent = styled.div`
  flex: 1;
  align-self: stretch;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: radial-gradient(82.48% 82.48% at 31.3% 26.18%, #08E8DE 0%, #185F5B 100%);
  background-blend-mode: overlay, normal;
  box-shadow: 0px 0px 100px #08E8DE;

  border-radius: 50%;

  transform: scale(var(--scale, 1));
  transition: inherit;

  will-change: transform;
`;

const LinesWrapper = styled.div`
  width: 100%;
  height: 0;
  
  span {
    position: fixed;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    z-index: -2;
    
    background: var(--color-primary-dark);
    
    will-change: transform;
    transform: translateX(var(--x, 0));
  }
`;

const RotateAnim = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const CircleTextWrapper = styled.div<{ segments?: number }>`
  position: absolute;
  top: 50%;
  left: 50%;

  opacity: var(--icon-opacity, 1);

  ${BREAKPOINT_LG} {
    animation: ${RotateAnim} 60s linear infinite;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: .75rem;
    height: calc(var(--size) + 2.5rem);
    margin: calc((var(--size) + 2.5rem) / -2) -.375rem;
    text-transform: uppercase;
    
    ${BREAKPOINT_LG} {
      height: calc(var(--size) + 3.25rem);
      margin: calc((var(--size) + 3.25rem) / -2) -.375rem;
    }
    
    ${({ segments = 32 }) => {
      const fractionDeg = 360 / segments;
      
      let css = '';
      for(let index = 0; index < segments; index++) {
        const rotation = index * fractionDeg;
        
        css += `
          &:nth-child(${index+1}) {
            transform: rotate(${rotation}deg);
          }
        `;
      }
      
      return css;
    }};
  }
`;

const ImageWrapper = styled.div`
    position: relative;
    text-align: center;
    margin: 0 auto;
  `;

interface IStageProps extends IAlbumContentItem {
  altText: string;
  image: string;
  album: IAlbumDetails;
}

export const StageCampaign: FC<IStageProps> = (props) => {
  const {
    type,
    altText,
    image,
    album,
  } = props;

  const getLocalizedString = useGetLocalizedString();

  const stage = useRef<HTMLDivElement | null>(null);
  const ellipse = useRef<HTMLDivElement | null>(null);
  const linesWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const listener = () => {
      if (ellipse.current && linesWrapper.current) {
        const parent = ellipse.current.parentElement as HTMLDivElement;

        const isMobile = window.innerWidth < DIMEN_BREAKPOINT_LG;
        const useCSSTransition = true;
        const circleTextOffset = isMobile ? 40 : 52;
        const parentStart = parent.getBoundingClientRect().x;
        const parentWidth = parent.offsetWidth;
        const ellipseOffsetTop = ellipse.current.offsetTop + (ellipse.current.offsetHeight / 2);

        const rightEnd = parentWidth - ellipse.current.offsetWidth;
        const centerX = rightEnd / 2;
        const centerY = (window.innerHeight / 2) - ellipseOffsetTop;

        // ellipse position
        const scrollDelta = Math.max(0, Math.min(1, window.scrollY / (window.innerHeight / 2)));
        const posXStart = isMobile ? centerX : 0;
        const posXEnd = rightEnd - circleTextOffset;
        let positionX = Math.max(posXStart, Math.min(posXEnd, posXEnd - scrollDelta * (posXEnd - posXStart)));
        let positionY = scrollDelta * centerY;
        let scale = Math.max(1, Math.min(3, 1 + scrollDelta * 2));
        let opacity = 1 - scrollDelta;

        if (useCSSTransition) {
          if (scrollDelta > .15) {
            positionX = posXStart;
            positionY = centerY;
            scale = 2.75;
            opacity = 0;
          } else {
            positionX = posXEnd;
            positionY = 0;
            scale = 1;
            opacity = 1;
          }
        }

        // lines positions
        const lines = (linesWrapper.current?.querySelectorAll('span') as unknown || []) as HTMLDivElement[];
        let linePositions = Array(lines.length).fill('').map((_, index, array) => {
          const wrapperWidth = Math.max(0, (linesWrapper.current?.offsetWidth || 0) - 2 * circleTextOffset);
          return index * (wrapperWidth / (array.length - 1));
        });

        // handle kpi section
        const kpiSection = document.querySelector('[data-type="kpi-section"]') as HTMLDivElement;
        if (kpiSection) {
          const { top, bottom } = kpiSection.getBoundingClientRect();
          const kpiSectionCenter = top + ((bottom - top) / 2);

          const kpiSectionScrollDelta = Math.max(0, Math.min(1, 1 - ((kpiSectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 2))));
          const posXStart = isMobile ? centerX : 0;
          const posXEnd = centerX;
          if (kpiSectionScrollDelta > 0) {
            positionY = Math.min(kpiSectionCenter - ellipseOffsetTop, positionY);
            positionX = Math.max(posXStart, Math.min(posXEnd, kpiSectionScrollDelta * posXEnd));
          }

          if (useCSSTransition) {
            if (kpiSectionScrollDelta > 0) {
              positionX = posXEnd;
              positionY = -window.innerHeight;
            }
          }
        }

        // handle lines
        const checkLinePositions = (section: HTMLDivElement, calcPosition?: (data: { scrollDelta: number, wrapperWidth: number, pos: number, index: number }) => number) => {
          if (section) {
            const { top, bottom } = section.getBoundingClientRect();
            const sectionCenter = top + ((bottom - top) / 2);

            const sectionScrollDelta = Math.max(0, Math.min(1, 1 - ((sectionCenter - (window.innerHeight / 2)) / (window.innerHeight / 3))));
            if (sectionScrollDelta > 0) {
              linePositions = linePositions.map((pos, index) => {
                const wrapperWidth = Math.max(0, (linesWrapper.current?.offsetWidth || 0) - 2 * circleTextOffset);
                const scrollDelta = useCSSTransition ? 1 : sectionScrollDelta;
                const position = calcPosition
                  ? calcPosition({ pos, index, scrollDelta, wrapperWidth })
                  : (pos + scrollDelta * ((wrapperWidth / 2) - pos));
                return Math.max(0, Math.min(wrapperWidth, position));
              });
            }
          }
        }

        const sections = (stage.current?.parentElement?.querySelectorAll('section') as unknown || []) as HTMLDivElement[];
        for (let section of sections) {
          // handle split image sections
          if (section.dataset.type === 'split-image-section') {
            checkLinePositions(section);
          }
          // handle text image sections
          if (section.dataset.type === 'text-image-section') {
            checkLinePositions(section, ({ pos, index, wrapperWidth, scrollDelta }) => {
              const offset = index >= Math.floor(lines.length / 2) ? circleTextOffset : 0;  // use circle text offset as space between lines
              return pos + scrollDelta * ((section.dataset.direction === 'left' ? wrapperWidth - offset : offset) - pos);
            });
          }
          // handle video sections
          if (section.dataset.type === 'video-section') {
            checkLinePositions(section, ({ pos, index, wrapperWidth, scrollDelta }) => {
              return pos + scrollDelta * ((index * (wrapperWidth / (lines.length - 1))) - pos);
            });
          }
        }

        // set header blur
        const header = document.querySelector('header');
        header?.style.setProperty('--header-blur', `${4 + scrollDelta * 36}px`);
        const headerOpacity = .15 + scrollDelta * .35;
        header?.style.setProperty('--header-background', `linear-gradient(0deg, transparent, rgba(0, 0, 0, ${headerOpacity}) 75%, rgba(0, 0, 0, ${headerOpacity}) 100%)`);

        // set properties
        ellipse.current.style.setProperty('--startX', `${parentStart}px`);
        ellipse.current.style.setProperty('--x', `${positionX}px`);
        ellipse.current.style.setProperty('--y', `${positionY}px`);
        ellipse.current.style.setProperty('--scale', `${scale}`);
        ellipse.current.style.setProperty('--icon-opacity', `${opacity}`);

        if (useCSSTransition) {
          ellipse.current.style.transition = 'transform .5s ease-out';
        } else {
          ellipse.current.style.transition = '';
        }

        // set line properties
        lines.forEach((line, index) => {
          line.style.setProperty('--x', `${parentStart + circleTextOffset + linePositions[index]}px`);

          if (useCSSTransition) {
            line.style.transition = 'transform .5s ease-out';
          } else {
            line.style.transition = '';
          }
        });
      }
    }

    window.addEventListener('scroll', listener, { passive: true });
    listener();

    return () => {
      window.removeEventListener('scroll', listener);
      const header = document.querySelector('header');
      header?.style.setProperty('--header-blur', null);
      header?.style.setProperty('--header-background', null);
    }
  }, []);

  const circleText = useMemo(() => {
    const label = getLocalizedString('app.v2.album-details.label.scroll-down');
    let text = `${label} `;
    while (text.length < 32) {
      text += `${label} `;
    }
    const res = [];
    for (let i = 0; i < text.length; i++) {
      res.push(text.charAt(i));
    }
    return (
      <CircleTextWrapper segments={text.length}>
        {
          res.map((char, index) => (<span key={index}>{char}</span>))
        }
      </CircleTextWrapper>
    );
  }, [getLocalizedString]);

  return (
    <Wrapper data-type={type} ref={stage}>
      <CollectiblesTeaserWall
        album={album}
      />
      <BackButton />
      <br /><br />
      <ContentWrapper>
        <ImageWrapper><img src={image} alt={altText} /></ImageWrapper>
        <br /><br /><br /><br /><br />
        <Countdown
          description={getLocalizedString('app.v2.forevergalaxy.campaign.countdown.endcampaign')} 
          lastClaimMessage={''}
          targetDate={'2023-06-15T17:00:00.000+02:00'} />
        {/* <Ellipse ref={ellipse}>
          <EllipseContent>
            <ArrowDownIcon />
            { circleText }
          </EllipseContent>
        </Ellipse>

        <LinesWrapper ref={linesWrapper}>
          <span />
          <span />
          <span />
          <span />
        </LinesWrapper> */}
        <br /><br />
      </ContentWrapper>
    </Wrapper>
  );
}